import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { iIcon } from "../../assets/images";
import Tooltip from "../Tooltip";
import "./FormTextarea.scss";

const FormTextarea = (props) => {
  const {
    color,
    columns,
    rows,
    content,
    placeholder,
    placement,
    maxLength,
    innerRef,
    id,
    name,
    value,
    onChange,
    containerStyle,
    error,
    textareaClass,
    formStyle,
    formClass,
    errorClass,
    errorStyle,
    showTextLength,
    currentLenght,
    label,
    audienceTooltip,
    tabIndex,
    ...rest
  } = props;

  return (
    <React.Fragment>
      <form style={formStyle}>
        <div
          className={classnames("form-group", formClass)}
          style={containerStyle}
        >
          {(label && audienceTooltip) ?
            <>
              <div className="d-flex">
                <p className="formLabel mb-1">{label}</p>
                <div className="ml-1 bigscreentooltip">
                  <Tooltip
                    content="Share any important details about the audience such as their seniority, knowledge level, skill set, demographics, and any specific training needs"
                    direction="right"
                  >
                    <img src={iIcon} alt="" />
                  </Tooltip>
                </div>
                <div className="ml-1 phoneInfo">
                  <Tooltip
                    content="Share any important details about the audience such as their seniority, knowledge level, skill set, demographics, and any specific training needs"
                    direction="bottom"
                  >
                    <img src={iIcon} alt="" />
                  </Tooltip>
                </div>
              </div>
            </> :
            <p className="formLabel mb-1">{label}</p>
          }
          <textarea
            className={classnames(textareaClass, "form-control textareaBox")}
            id="my-input"
            rows={rows}
            ref={innerRef}
            columns={columns}
            placeholder={placeholder}
            maxLength={maxLength}
            value={value && value }
            name={name}
            onChange={onChange && onChange}
            tabIndex={tabIndex}
            {...rest}
          ></textarea>
          {showTextLength && (
            <div className={classnames("text-right ml-auto")}>
              <span className="placeholderText">{currentLenght}/{maxLength} Characters</span>
            </div>
          )}
        </div>
      </form>
      <div className="d-flex w-100">
        {error && (
          <div className={errorClass}>
            <span style={errorStyle} className="d-flex align-items-center mb-2">
              <i className="fa fa-exclamation-circle errorIcon" />
              <p className="errorText mb-0 ml-0">{error}</p>
            </span>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

FormTextarea.propTypes = {
  columns: PropTypes.number,
  rows: PropTypes.number.isRequired,
};

export default FormTextarea;