import {
  saveLoginInfo,
  saveLoginInfoAll,
  deleteLoginInfo,
  deleteLoginInfoRefresh,
  saveNewUser,
  saveChatUser
} from "../../util/localStorage";
import { deleteJobId } from "../jobDetails";
import { deleteMyInformation } from "../myInformation";
import { removecompletedSteps } from "../SideMenuLinks";
import { removeFilesProgress } from "../uploadProgress";

export const SET_LOGIN_DATA = "SET_LOGIN_DATA";
export const REMOVE_LOGIN_DATA = "REMOVE_LOGIN_DATA";

export const initialState = {
  email: null,
  password: null,
  rememberMe: null,
  token: null,
  refreshToken: null,
  auth: null,
  loginId: null,
  contactId: null,
  clientId: null,
  instrId: null,
  fullName: null,
  chatEmail: null
};
const loginReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_LOGIN_DATA:
      return { ...state, ...payload };
    case REMOVE_LOGIN_DATA:
      return {
        email: null,
        password: null,
        rememberMe: null,
        token: null,
        refreshToken: null,
        auth: null,
        loginId: null,
        contactId: null,
        clientId: null,
        instrId: null,
        fullName: null,
        chatEmail: null
      };
    default:
      return state;
  }
};

export const setLoginData = (payload, wizardCompleted) => {
  return (dispatch) => {
    dispatch({
      type: SET_LOGIN_DATA,
      payload,
    });
    saveLoginInfoAll(
      payload.contactId,
      payload.clientId,
      payload.instrId,
      payload.webRole
    );
    saveNewUser(payload.email)
    saveChatUser(payload.chatEmail)
    saveLoginInfo(payload.token, payload.refreshToken);
    if (payload.nextPage && !payload.nextPage?.includes("logout")) {
      window.location.href = payload.nextPage
      return
    }
    if (payload.agencyInstrId > 0) {
      window.location.href = "/user/dashboard";
      return
    }
    if (!payload.fromForce) {
      if (payload.instrId > 0) {
        if (wizardCompleted) {
          window.location.href = "/user/dashboard";
        } else if (payload?.showTalentSplashScreen) {
          window.location.href = "/welcome";
        } else if (payload?.isWizardFinished === true) {
          window.location.href = "/user/dashboard";
        } else if (payload?.lastVisitedPage) {
          window.location.href = payload.lastVisitedPage;
        } else {
          window.location.href = "/welcome";
        }
      } else if (payload.clientId || payload.contactId) {
        if (wizardCompleted) {
          window.location.href = "/client-dashboard";
        } else if (!payload.isWizardFinished) {
          window.location.href = "/client-profile/welcome-user";
        } else if (payload?.showContactSplashScreen) {
          window.location.href = "/welcome";
        } else if (payload.isWizardFinished === true) {
          window.location.href = "/client-dashboard";
        } else if (payload?.lastVisitedPage) {
          window.location.href = payload.lastVisitedPage;
        } else {
          window.location.href = "/client-profile/welcome-user";
        }
      }
    }
  };
};

export const updateUserData = (payload) => {
  return (dispatch) => {
    dispatch({
      type: SET_LOGIN_DATA,
      payload,
    });
    saveLoginInfo(payload.token, payload.refreshToken);
  };
};
export const logoutUser = () => {
  return (dispatch) => {
    dispatch({
      type: REMOVE_LOGIN_DATA,
    });
    dispatch(removecompletedSteps());
    dispatch(deleteMyInformation());
    dispatch(deleteJobId());
    dispatch(removeFilesProgress());
    dispatch(removeFilesProgress())
    deleteLoginInfo();
    deleteLoginInfoRefresh();
  };
};

export const deleteLoginData = () => {
  return (dispatch) => {
    dispatch({
      type: REMOVE_LOGIN_DATA,
    });
  }
}

export default loginReducer;
