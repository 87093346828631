import React from "react";
import Label from "../../../../../components/label";
import Modal from "../../../../../components/modal";
import Button from "../../../../../components/button";

const ReactivateContact = ({
    showReactivateConfirm,
    successfullReactivate,
    reactivateContactData,
    reactivateContact,
    toggleReactivate

}) => {

    return (
        <>
            <Modal
                isOpen={showReactivateConfirm}
                size="extrasmall"
                title={<Label label={"Reactivate Contact"} className="font18Black boldFont" />}
                titleClass="ml-1"
                hideCloseButton={true}
                toggleModal={() => toggleReactivate()}
                disableBackdropToggle={true}
                containerClass="deactivateModal"
            >
                <div className="DeativatemodalTextHolder">
                    {   successfullReactivate ?
                        <Label
                            label={
                            <span>
                                Your contact {reactivateContactData.email} has been successfully reactivated.
                            </span>
                            }
                            className="subTextWithoutColorWithoutBold mt-4 ml-4 mr-4"
                        /> :
                        <Label
                            label={
                            <span>
                                You are about to reactivate a contact<br/><br/>
                                Are you sure you want to reactivate contact {reactivateContactData.email} from your company.
                            </span>
                            }
                            className="subTextWithoutColorWithoutBold mt-4 ml-4 mr-4"
                        />
                        
                    }
                
                </div>
                <div
                className="border-top text-center"
                style={{ left: "0", width: "100%" }}
                >
                    {   successfullReactivate ?
                        <div className="buttonHolder">
                            <Button
                                label="Close"
                                type="button"
                                onClick={() => toggleReactivate()}
                                className="btn-blue main_btn"
                            />
                        </div> :
                        <div className="buttonHolder">
                            <Button
                                label="Cancel"
                                type="button"
                                onClick={() => toggleReactivate()}
                                className="btn-light main_btn mr-2 portfolioButtonDelete"
                            />
                            <Button
                                label="Reactivate"
                                type="submit"
                                onClick={() => reactivateContact()}
                                className="btn-blue main_btn  "
                            />
                        </div> 
                            

                
                    }
                
                </div>
            </Modal>
        </>
    )
}

export default ReactivateContact;