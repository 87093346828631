import Axios from "../util/axiosInstance";

export const GetProposalDetail = (params) => {
    return Axios.get("/api/Project/GetTalentProjectDetails",{params});
};
export const GetFreelancerDetail = (params) => {
    return Axios.get("/api/TrainerProfile/GetFilteredProfile",{params})
}

export const GetFreelancerReview = (params) => {
    return Axios.get("/api/Project/GetTalentFeedbacks",{params})
}

export const GetSubmittalDetail = (params) => {
    return Axios.get("/api/PreviewSubmittal/GetPreviewSubmittal", {params})
}

export const DownloadFreelancerBlobByEntity = (guid, entityType, entityId, projectId, hash, instrId  ) => {
    return Axios.get(
        `/api/PreviewSubmittal/GetByEntity?blobNameGUID=${guid}&entityName=${entityType}&entityId=${entityId}&projectId=${projectId}&hash=${hash}&instrId=${instrId}`,{responseType:"arraybuffer"})
  }

export const GetTalentProfile = (instrId) => {
    return Axios.get(`/api/TrainerProfile/GetTalentProfile?instrID=${instrId}`)
}

export const UpdateNotes = (payload) => {
    return Axios.put("/api/Project/UpdateReviewNotes", payload)
}

export const GetNotes = (params) => {
    return Axios.get("/api/Project/GetSubmissionNotes", {params})
}

