export const inProgressJobs = "/jobcenter/4"
export const openProposal = "/jobcenter/3"
export const inprogressJobDetailsPath = (projectId, tabId) => `/jobcenter/inprogress/job-details/${projectId}/${tabId}`
export const  manageMembers = "/settings/manage-members"
export const globalCalendar = "/calendar-view"
export const dashboard = "/user/dashboard"
export const myInformationSettings = "/settings/my-information";
export const talentMyInformation = "/profile/my-information";
export const paymentInformation = "/settings/payment-information"
export const manageReference = "/settings/manage-reference";
export const completedJobDetails = (projectId, tabId) => `/jobcenter/completed/job-details/${projectId}/${tabId}`
export const signLegalDocument = (type) => "/sign-document?docType="+type 
export const acceptTravelFee = "/accept-travel-fee";
export const searchJobs = "/jobcenter/6"
export const paymentURL = (amount, invoiceNumber) => `https://paylink.paytrace.com?m=eyJhbGciOiJBMjU2S1ciLCJlbmMiOiJBMTI4R0NNIn0.YQlZcTzjtEjeYKDOkQXAFN3sLt30nUkV.5jcRucIVLLYUvN9L.NAhS6A07snaTfqU2VJPmWM49sB2ulLqYjvJ6gvpK9k7Ezrk8uuvdpIn3WB4zk0g64-BKq85MZMjRSTByRxhtT16aewGE7sZKcGQdt9oVWE-O5AhmtKxhYBGvfvW1nbfzYqN4XxON5uCnil5P2zPAfngxFax2esQ9APCgLZuZBGIK6pHfBca6ONZ8wf3PlSSr4vAam1GRGH_4BugGLF9mgElg_HQOz4by0ek6CkvxjB1cRjWupMVC3BUeBxCkz0k7usOqSvjqqSexF7GZL4h7hA1ftMLrzu6TrweI0WA.vtP_v2R0xqZvNUCV9FHzJw&amount=${amount}&invoice=${invoiceNumber}&amountReadOnly=true&invoiceReadOnly=true`
export const clientJobDetails = (tabId, projectId) => `/client-job/job-entity/${tabId}/${projectId}`
