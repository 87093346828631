import React, {useState} from "react";
import TextInputDropdown from "../textInputDropdownNew";
import Button from "../button";
import "./languages.scss";

let Languages = ({
  title,
  proficiencyTitle,
  data,
  handleCancel,
  handleLanguage,
  handleProficiency,
  addLanguage,
  error,
  errorLanguageDropdown,
  errorlanguageDropdownProffiency,
  editProficiencyTitle,
  editTitle,
  handleEdit,
  languagesList,
  removeProficiencyError,
  removeLanguageNameError
}) => {
  const [proficiencyValue,setProficiencyValue] = useState("");
  let handleLanguageProficiency = (value) => {
    if (value === 1) {
      return "Basic";
    } else if (value === 2) {
      return "Conversational";
    } else if (value === 3) {
      return "Fluent";
    }
    return value
  };
  const handleProficiencyChange = (e) => {
    setProficiencyValue(e.value)
  }
  return (
    <React.Fragment>
      <div className="mx-auto" style={{ width: "375px", maxWidth: "90%" }}>
        {!data && (
          <React.Fragment>
            <p className="mb-0 font16Black boldFont mt-3">
              {data ? editTitle : title}
            </p>
            <TextInputDropdown
              optionnew={languagesList}
              firstval="Select Language"
              onChange={
                (e)=> {
                  handleLanguage(e)
                  setProficiencyValue(undefined)
                  if(removeLanguageNameError) {
                   removeLanguageNameError()
                  }
                }
              }
            />
 {errorLanguageDropdown && (
        <p
          className="mt-1 text-center text-danger"
          style={{ fontSize: "13px" }}
        >
          {errorLanguageDropdown}
        </p>
      )}
            <p className="mb-0 font16Black boldFont mt-3">
              {data ? editProficiencyTitle : proficiencyTitle}
            </p>
            <div className="mb-5">
            <TextInputDropdown
              containerClass="ml-auto"
              optionnew={["Fluent", "Conversational", "Basic"]}
              firstval="Select Proficiency"
              onChange={
                (e) => {
                  handleProficiency(e)
                  handleProficiencyChange(e)
                  if(removeProficiencyError) {
                    removeProficiencyError()
                  }
                }
              }
              selectedValue={proficiencyValue}

            />
            {errorlanguageDropdownProffiency && (
        <p
          className="mt-1 text-center text-danger"
          style={{ fontSize: "13px" }}
        >
          {errorlanguageDropdownProffiency}
        </p>
        
      )}
      </div>
          </React.Fragment>
        )}
        {data && (
          <React.Fragment>
            <p className="mb-0 font16Black boldFont mt-3">
              {data ? editProficiencyTitle : proficiencyTitle}
            </p>
            <div
              className="languagesContainer mt-1"
              style={{
                paddingLeft: "30px",
                paddingRight: "30px",
                paddingTop: "20px",
                paddingBottom: "20px",
              }}
            >
              <div className="d-md-flex d-lg-flex d-block align-items-center">
                <p className="selectedLanguageSubTitle mb-0 mr-2">
                  {data?.language?data?.language:data?.name}
                </p>
                <TextInputDropdown
                  containerStyle={{ width: "220px" }}
                  containerClass="ml-md-auto ml-lg-auto ml-0 mt-md-0 mt-lg-0 mt-2"
                  optionnew={["Fluent", "Conversational", "Basic"]}
                  selectedValue={handleLanguageProficiency(data?.proficiency?data?.proficiency:data?.value)}
                  onChange={(e) => handleProficiency(e)}
                />
              </div>
            </div>{" "}
          </React.Fragment>
        )}
      </div>
      <div className="w-100 my-4 border-top" />
      {error && (
        <p
          className="mt-3 text-center text-danger"
          style={{ fontSize: "13px" }}
        >
          {error}
        </p>
      )}
      <div className="text-center">
        <Button
          label="Cancel"
          type="button"
          onClick={handleCancel}
          className="btn-light mx-2 mt-md-0 mt-2"
        />
        <Button
          label={data ? "Update" : "Add"}
          type="button"
          onClick={data ? handleEdit : addLanguage}
          className="btn-blue mx-2 mt-md-0 mt-2"
        />
      </div>
    </React.Fragment>
  );
};
export default Languages;
