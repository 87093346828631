/* eslint-disable no-useless-escape */
import React, { useState } from 'react';
import { DownloadBlobByEntity } from '../../../../services/portfolio';
import { editIcon, rolesDelete } from '../../../../assets/images';
import Button from '../../../../components/button';
import Modal from "../../../../components/modal";
import ConfirmFileDownLoad from '../../../ClientScreens/JobStatus/FileDownloaded';
import ShowMore from '../../../../components/showMore';
import LoginModal from "../../../Authentication/LoginModal";
import "./index.scss"

const ViewPortfolioModal = ({
  viewPortfolioData,
  toggleModal,
  handleEditPortfolio,
  handleDeleteModal,
  handleDeleteItem,
  handleDeleteVideo,
  edit,
  fromCourse = false,
  fromClient = false,
  instrId,
  client,
  preview
}) => {
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false)

  const [showConfirmation, setShowConfirmation] = useState(false)
  const downloadDoc = () => {
    if(preview) {
      toggleModal();
      setIsLoginModalOpen(true);
      return;
    }

    DownloadBlobByEntity(
      fromClient ? viewPortfolioData?.courseOutline : fromCourse ? viewPortfolioData?.portfolioData?.courseOutline : viewPortfolioData?.portfolioData?.item,
      "instructor",
      instrId
    ).then((response) => {
      setShowConfirmation(true)
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", viewPortfolioData?.portfolioData?.blobFileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
    return;
  };

  const embedURL = (url) => {
    if (url?.includes(`vimeo.com/`)) {
      const vimeoPlayerURL = `https://player.vimeo.com/video/`;
      const embedVimeoID = url?.split("/").slice(-1)[0];
      return `${vimeoPlayerURL}${embedVimeoID}`;
    } else {
      const youtubePlayerURL = `//www.youtube.com/embed/`;
      var regExp =
        /^.*(youtu.be\/|v\/|u\/\w\/|shorts\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
      var match = url.match(regExp);

      if (match && match[2].length === 11) {
        return `${youtubePlayerURL}${match[2]}?rel=0`;
      } else {
        return "error";
      }
    }
  };

  const redirect = (url) => {
    let redirectUrl = url;
    if (url.startsWith("www.")) {
      redirectUrl = `https://${url}`
    }
    window.open(redirectUrl, "_blank")
  }

  const getAudienceType = (audience = {}) => {
    const tempArr = []
    if (audience.individualContributors) {
      tempArr.push("Individual contributors")
    }
    if (audience.firstLevelLeaders) {
      tempArr.push("First-level leaders")
    }
    if (audience.midLevelLeaders) {
      tempArr.push("Mid-level leaders")
    }
    if (audience.executives) {
      tempArr.push("Executives")
    }
    return tempArr
  }

  return (
    <>
      <LoginModal isOpen={isLoginModalOpen} toggleModal={() => setIsLoginModalOpen(false)} />
      <Modal
        isOpen={viewPortfolioData?.showModal}
        size="extralarge"
        hideCloseButton={true}
        title={(fromCourse || client) ? "Course" : "Portfolio"}
        toggleModal={toggleModal}
        disableBackdropToggle={true}
        containerClass="viewPortfolioModal"
      >
        <ConfirmFileDownLoad
          showConfirmation={showConfirmation}
          toggleModal={() => setShowConfirmation(false)}
        />
        <div className='border-top mt-4 mobileView' />
        <div className="mt-5 ml-4 mr-4">
          <div className="d-flex justify-content-between" >
            <label className="font18Black boldFont">{viewPortfolioData?.title}</label>
            {
              edit &&
              <div className="text-nowrap">
                <img
                  src={editIcon}
                  alt=""
                  className="mr-1 ml-1 pointer"
                  onClick={() => {
                    handleEditPortfolio(viewPortfolioData?.portfolioData, viewPortfolioData?.type)
                  }}
                />
                <img
                  src={rolesDelete}
                  alt=""
                  className="mr-1 ml-1 pointer"
                  onClick={() => {
                    if (viewPortfolioData?.type === "File") {
                      handleDeleteModal(
                        viewPortfolioData?.portfolioData,
                        fromCourse ? "Course" : "Portfolio Item",
                        handleDeleteItem
                      )
                    } else {
                      handleDeleteModal(
                        viewPortfolioData?.portfolioData,
                        `Portfolio ${viewPortfolioData?.type}`,
                        handleDeleteVideo
                      )
                    }
                  }}
                />
              </div>
            }
          </div>
          {
            viewPortfolioData.type === "Video" &&
            <div>
              <iframe
                className="PortfolioVideoFrame"
                height="100%"
                src={embedURL(viewPortfolioData?.portfolioData?.url)}
                frameborder="0"
                allowFullScreen
                control
                title='url'
              />
            </div>
          }
          <div className="font14Grey boldFont mt-2">Description</div>
          <div className="font14Grey mt-1">
            <ShowMore
              text={viewPortfolioData?.description}
              textClass="description"
              anchorClass="moreText"
              lines={7}
              id={`description`}
            />
          </div>
          {
            getAudienceType(viewPortfolioData?.portfolioData).length > 0 &&
            <>
              <div className="font14Grey boldFont mt-3 mb-2">Audience</div>
              <ul className="pl-4">
                {
                  getAudienceType(viewPortfolioData?.portfolioData).map((res) => {
                    return (
                      <>
                        <li className="font14Grey">{res}</li>
                      </>)
                  })
                }
              </ul>
            </>
          }
          {
            viewPortfolioData.type === "File" &&
            <>
              {
                fromCourse ?
                  (
                    viewPortfolioData?.portfolioData?.courseOutline ||
                    viewPortfolioData?.courseOutline
                  ) ?
                    <div div className="blueWrapForActionButton">
                      <Button
                        label={"Download"}
                        className="btn-blue mb-0"
                        onClick={downloadDoc}
                      />
                    </div> : ""
                  :
                  <div div className="blueWrapForActionButton">
                    <Button
                      label={"Download"}
                      className="btn-blue mb-0"
                      onClick={downloadDoc}
                    />
                  </div>
              }
            </>
          }
          {
            viewPortfolioData.type === "Link" &&
            <div className="blueWrapForActionButton">
              <Button
                label={"Open Url"}
                className="btn-blue mb-0"
                onClick={() => { redirect(viewPortfolioData?.portfolioData?.url) }}
              />
            </div>
          }
          <div>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default ViewPortfolioModal