import React from "react";
import classnames from "classnames";
import "./checkbox.css";

const CheckBox = ({
  id,
  label,
  onChange,
  checked = false,
  className,
  disabled,
  style,
  containerClass,
  innerRef,
  name,
  error,
  checkboxClass,
  containerStyle,
  checkBoxsection,
  labelStyle,
  errorStyle,
  checkBoxClass,
  title
}) => {

  return (
    <div
      className={error ? "" : classnames(containerClass, "d-flex")}
      style={containerStyle}
    >
      <div className={classnames("checkboxWrapper", checkBoxsection)} title={title}>
        <input
          className={classnames(
            checkboxClass
              ? "activeCheck form-control mr-2"
              : "check form-control mr-2 ",
            className
          )}
          type="checkbox"
          id={id}
          hidden
          ref={innerRef}
          name={name}
          checked={checked || null}
          disabled={disabled}
          onChange={onChange}
          style={style}
        />
        <label htmlFor={id} className={classnames("checkMark mb-0", checkBoxClass, !checked ? "unChecked" : "")}></label>
        <label className="checkboxLabel mb-0" style={labelStyle}>{label}</label>
      </div>
      {error && (
        <div className="d-flex align-items-center my-2" style={errorStyle}>
          <i className="fa fa-exclamation-circle errorIcon" />
          <p className="errorText mb-0 ml-0">{error}</p>
        </div>
      )}
    </div>
  );
};

export default CheckBox;
