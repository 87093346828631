import axios from "axios";
import Axios from "../util/axiosInstance";

let apiVersion = process.env.REACT_APP_API_BASE_URL;
  const axiosInstance = axios.create({
    baseURL: apiVersion,
    withCredentials: true,
    headers: { 'Content-Type': 'application/json', 'charset': 'utf-8' }
  })


export const GetJobPostDetails = (params) => {
    return Axios.get("/api/Project/Get",{params});
};

export const getJobDetailsPublic = (params) => {
    return axiosInstance.get(`/api/Project/ViewJobDetails`, {params})
}