import React from "react";
import { iIcon } from "../../../assets/images";
import { roleWithCourses } from "../../../constants/roleWithCourse";
import Tooltip from "../../../components/Tooltip";
import Modal from "../../../components/modal"
import RoleForm from "../rolesForm";
import "./index.scss"

const AddRoleModal = ({
    isOpen,
    toggleModal,
    roleDetails,
    onSaveSuccess,
    userRoles
}) => {

    return (
        <>
            <Modal
                isOpen={isOpen}
                size="extralarge"
                hideCloseButton={true}
                title={
                    <>
                        <div className="d-flex ">
                            <div className="font18Black boldFont mr-2">
                                Roles and Skills
                            </div>
                            <div className="d-flex">
                                <div className="rolesandskillsRolenameWrapperMain mr-1 ">
                                    {roleDetails.role === "SpeakerPresenter"
                                        ? "Speaker/Presenter"
                                        : roleDetails.role}
                                </div>
                                {roleDetails.role && roleWithCourses.includes(roleDetails.role) && (
                                    <>
                                        <div className="rolesandskillsTaxLine">
                                            <Tooltip
                                                content={
                                                    <span>
                                                        Read our best practices for completing an Instructor
                                                        profile.
                                                    </span>
                                                }
                                                direction="right"
                                            >
                                                <img src={iIcon} alt="" />
                                            </Tooltip>
                                        </div>
                                        <div className="rolesandskillsInstructorPhone">
                                            <Tooltip
                                                content={
                                                    <span>
                                                        Read our best practices for completing an Instructor
                                                        profile.
                                                    </span>
                                                }
                                                style={{ left: "78px" }}
                                                direction="bottom"
                                            >
                                                <img src={iIcon} alt="" />
                                            </Tooltip>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </>
                }
                toggleModal={toggleModal}
                disableBackdropToggle={true}
                containerClass="addWorkExperienceModal"
            >   
                <div className="mobilePaddingTop">
                    <RoleForm
                        currentSelectedRoleData={roleDetails}
                        onSaveSuccess={onSaveSuccess}
                        toggleModal={toggleModal}
                        userRoles={userRoles}
                    />
                </div>
            </Modal>
        </>
    )
}

export default AddRoleModal;