import Axios from "../util/axiosInstance";

export const GetAllSubmissions = (params) => {
  return Axios.get("/api/Project/GetSubmissions", {params})
};

export const GetAllPublicSubmissions = (params) => {
  return Axios.get("/api/PreviewSubmissions/GetPreviewSubmissions", {params})
};

export const GetPreviewSubmissionsDetails = (params) => {
  return Axios.get("/api/PreviewSubmissions/GetPreviewSubmissionDetails", {params})
};

export const GetContactPublicPhoto = (params) => {
  return Axios.get("/api/PreviewSubmissions/GetByEntity", {params, responseType: "arraybuffer"})
}