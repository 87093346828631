import React, { Fragment } from "react";
import DesktopTimelineTable from "../TrainingTimeline/timelineDesktopTable";
import TimelineMobileTable from "../TrainingTimeline/timeLineMobileTable";
import ShowMore from "../../../../../components/showMore";
import "./JobTimeline.scss";

function JobTimeLine({
    trainingTimeLineDetails,
    timelineList,
    submitProposal
}) {
    return (
        <Fragment>
            {
                (trainingTimeLineDetails?.length > 0 || timelineList?.length > 0) &&
                <div className={!submitProposal ? "JobTimeLineWrapper" : "proposalJobTimeLineWrapper border-top"}>
                    <div className="jobSpecificationTextt">
                        <div className="font18Black boldFont mt-1">Training Timeline </div>
                        {trainingTimeLineDetails && (
                            <ShowMore
                                id="trainingTimeLineDetails"
                                lines={2}
                                textClass="font14Grey"
                                anchorClass="moreText"
                                text={trainingTimeLineDetails}
                            />
                        )}
                    </div>
                    {timelineList?.length > 0 && (
                        <>
                            <div className="desktopView">
                                <DesktopTimelineTable
                                    timelineData={timelineList}
                                    review={true}
                                />
                            </div>
                            <div className="mobileView">
                                <TimelineMobileTable
                                    timeLineData={timelineList}
                                    review={true}
                                />
                            </div>
                        </>
                    )}
                </div>
            }
        </Fragment>
    );
}

export default JobTimeLine;
