import Axios from "../util/axiosInstance";

export const getRepProjects = (params) => {
    return Axios.get("/api/Messages/GetAllRepProjects", { params });
};

export const getRepProjectDetails = (params) => {
    return Axios.get("/api/Messages/GetRepProjectDetails", { params })
}

export const getTwilioChatToken = (params) => {
    return Axios.get("/api/Messages/GetAccessToken", { params })
}

export const getProjectChats = (projectId, email) => {
    return Axios.get(`/api/Messages/GetProjectChatIds?projectId=${projectId}&User1Email=${email}`)
}

export const postNewProjectChat = (payload) => {
    return Axios.post("/api/Messages/Post", payload)
}

export const getUserGroupChat = (params) => {
    return Axios.get("api/Messages/GetGroupChat", { params })
}

export const addUserGroupChat = (payload) => {
    return Axios.post("/api/Messages/AddGroupChat", payload)
}

export const addUserToGroupChat = (payload) => {
    return Axios.post("/api/Messages/AddGroupMember", payload)
}

export const removeFromGroup = (params) => {
    return Axios.delete(`/api/Messages/LeaveGroupChat`, { params })
}

export const renameGroupName = (payload) => {
    return Axios.put("/api/Messages/RenameGroup", payload)
}

export const sendMentionEmail = (payload) => {
    return Axios.post("/api/Messages/SendEmailToMentioned", payload)
}

export const updateChatWelcomeModal = () => {
    return Axios.put("/api/Messages/UpdateMsgWelcomeModal", {})
}

export const getChatWelcomeModal = () => {
    return Axios.get("/api/Messages/GetMsgWelcomeModal")
}

export const createGeneralGroup = (payload) => {
    return Axios.post("/api/Messages/GenerateGeneralGroup", payload)
}