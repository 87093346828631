export const SEND_PROFILE_IMAGE = "SEND_PROFILE_IMAGE";
export const GET_COUNTRIES = "GET_COUNTRIES";
export const GET_STATES = "GET_STATES";
export const GET_MY_INFORMATION = "GET_MY_INFORMATION";
export const POST_MY_INFORMATION = "POST_MY_INFORMATION";
export const UPDATE_PROFILE_IMAGE = "UPDATE_PROFILE_IMAGE";
export const DELETE_MY_INFORMATION = "DELETE_MY_INFORMATION";

export const initialState = {
  instructorId: null,
  fileName: null,
  imageContent: null,
  countries: null,
  states: null,
  myInformation: null
};
const informationReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SEND_PROFILE_IMAGE:
      return { ...state, ...payload };
    case GET_COUNTRIES:
      return { ...state, countries: [...payload] };
    case GET_STATES:
      return { ...state, states: [...payload] };
    case GET_MY_INFORMATION:
      return { ...state, myInformation: { ...payload } };
    case UPDATE_PROFILE_IMAGE:
      return { ...state, myInformation: { ...state.myInformation, imageAsBase64String: payload } };
    case POST_MY_INFORMATION:
      return { ...state };
    case DELETE_MY_INFORMATION:
      return {};
    default:
      return state;
  }
};

export const sendProfileImage = (payload) => {
  return (dispatch) => {
    dispatch({
      type: SEND_PROFILE_IMAGE,
      payload,
    });
  };
};

export const deleteMyInformation = () => {
  return (dispatch) => {
    dispatch({
      type: DELETE_MY_INFORMATION,
      payload: {}
    })
  }
}

export const getCountries = (payload) => {
  return (dispatch) => {
    dispatch({
      type: GET_COUNTRIES,
      payload,
    });
  };
};

export const getStates = (payload) => {
  return (dispatch) => {
    dispatch({
      type: GET_STATES,
      payload,
    });
  };
};

export const getMyInformation = (payload) => {
  return (dispatch) => {
    dispatch({
      type: GET_MY_INFORMATION,
      payload,
    });
  };
};

export const postMyInformation = () => {
  return (dispatch) => {
    dispatch({
      type: POST_MY_INFORMATION
    });
  };
};

export const updateProfileImage = (payload) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_PROFILE_IMAGE,
      payload,
    });
  };
};

export default informationReducer;
