import React from "react";
import { useForm } from "react-hook-form";
import Modal from "../../../../../components/modal";
import Button from "../../../../../components/button";
import "./RemoveFromFavourite.scss";

const RemoveFromFavourite = ({ isOpen, toggleModal,instId, RemoveToFavouriteFuncAPi }) => {
  const { handleSubmit } = useForm({
    reValidateMode: "onBlur",
  });

  const closeModal = () => {
    toggleModal();
  };
  const onSubmit = (data) => {
    RemoveToFavouriteFuncAPi(instId)
  };

  return (
    <Modal
      isOpen={isOpen}
      containerStyle={{
        width: "400px",
        maxWidth: "90%",
        minHeight: "200px",
        paddingBottom: "0px",
      }}
      size="medium"
      hideCloseButton={true}
      toggleModal={closeModal}
      title={"Remove from favorite?"}
      disableBackdropToggle={true}
      containerClass={""}
    >
      <div className="mt-2 border-top mobileSep" />

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="modalContentWrapper mx-auto ">
          <div className="mb-3 mt-4">
            <span className="subHeader font14Grey">
            Are you sure you want to remove this person from your favorites?
            </span>
          </div>
        </div>
        <div className="">
          <div className="mt-3 border-top" />
          <div className="text-center mt-4 mb-3 float-bottom">
            <Button
              label="Cancel"
              onClick={closeModal}
              className="btn-light mx-2 cancelButtonDelete"
            />
            <Button label="Remove" type="submit" className="btn-blue mx-2" />
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default RemoveFromFavourite;
