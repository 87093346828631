import React from "react";
import classnames from "classnames";
import ShowMore from "../../../../../components/showMore";
import Label from "../../../../../components/label/Label";
import "./index.scss";

const AboutMe = ({ aboutMe, fromTalent, client }) => {
  return (
    <div className={classnames((fromTalent) ? "freelancerModalComponentWrap ml-0" : "freelancerModalComponentWrap", client ? "boxShadowNone" : "")}>
      {
        aboutMe ?
          <>
            {/* {!client && <Label label='Additional Profile Information' className="font18Black boldFont mt-3 mb-0"></Label>} */}
            <Label
              label="About Me"
              className={classnames(client ? "font14Black" : "font18Black", "boldFont mt-1 mb-0")}
            />
            <div className="font14Black">
              <ShowMore
                text={aboutMe}
                more="More"
                less="Less"
                textClass="font14Grey"
                anchorClass="font14Blue noWrap"
                lines={4}
                id={"freelanceAboutMe"}
              />
            </div>
          </>
          : ""
      }
    </div>
  );
};

export default AboutMe;
