/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { manageMembers } from '../constants/pagePath';
import { setCurrentAgencyMember } from '../ducks/AgencyMember';
import { setAllAgencyMember } from "../ducks/AgencyMember/AllAgencyMemberDetail";
import { setCurrentAgencyMemberDetail } from '../ducks/AgencyMember/CurrentAgencyMemeberDetail';
import { GetAllAgencies, GetAllProjectMembers } from '../services/agencyList';
import { DownloadBlobByEntity } from '../services/portfolio';
import { useGlobalClick } from '../util/clickListener';
import { GetCurrentAgencyMember, GetUserLoginData } from '../util/reduxData';
import TextInput from '../components/textInput';
import { blackTick, uploadImg } from '../assets/images';
import "./index.scss";

const AgencyDropdown = ({
    justDropdown,
    hideAddTalent,
    hideEveryoneOption = false,
    allText,
    agencyMemeber = null,
    setAgencyMember = null,
    fromSubmitProposal = false,
    projectId,
    photoUpdate,
    setPhotoUpdated
}) => {
    const ref = useRef()
    const history = useHistory()
    const dispatch = useDispatch()
    const currentInstrID = GetCurrentAgencyMember()
    const [memberList, setMemberList] = useState([])
    const [showDropdown, setShowDropdown] = useState(false)
    const [searchText, setSearchText] = useState("")
    const [allMemberList, setAllMemberList] = useState([])
    const instrID = GetUserLoginData().instrId
    const [thumbnailDict, setThumbnailDict] = useState({})

    useEffect(() => {
        getAllMemberList()
    }, [])

    useEffect(() => {
        if (photoUpdate) {
            getAllMemberList()
        }
    }, [photoUpdate])

    useEffect(() => {
        if (fromSubmitProposal) {
            setSearchText(getMemberName(agencyMemeber))
        } else {
            setSearchText(getMemberName(currentInstrID))
        }
    }, [showDropdown])

    useEffect(() => {
        if (allMemberList.length > 0) {
            onDropChange(null, allMemberList)
        }
    }, [allMemberList])

    const getTalentThumbnails = (talentArray) => {
        const tempDict = { ...thumbnailDict }
        // Downloads the thumbnail of talents and updates it to the thumbnail dict
        talentArray.map((dataDict) => {
            if (dataDict.blobNameGuid) {
                DownloadBlobByEntity(
                    dataDict?.blobNameGuid,
                    "instructor",
                    dataDict?.instrId
                ).then((response) => {
                    const Imagedata = `data:${response?.headers["content-type"]
                        };base64,${new Buffer(response?.data, "binary")?.toString(
                            "base64"
                        )}`;
                    tempDict[dataDict?.instrId] = Imagedata
                });
            }
        })
        setThumbnailDict(tempDict)
    }

    const getAllMemberList = () => {
        let api = GetAllAgencies()
        if (projectId) {
            api = GetAllProjectMembers(projectId)
        }
        api.then((res) => {
            if (setPhotoUpdated) {
                setPhotoUpdated(false)
            }
            const memberRecord = res.data
            setAllMemberList(memberRecord)
            if (justDropdown) {
                setMemberList(memberRecord)
                setAllMemberList(memberRecord)
            } else {
                if (hideEveryoneOption) {
                    setMemberList(memberRecord)
                    setAllMemberList(memberRecord)
                } else {
                    setMemberList([
                        {
                            "instrId": "all",
                            "isEveryone": 1
                        },
                        ...memberRecord
                    ])
                    setAllMemberList([
                        {
                            "instrId": "all",
                            "isEveryone": 1
                        },
                        ...memberRecord
                    ])
                }
            }
            dispatch(setAllAgencyMember(memberRecord))
            getTalentThumbnails(memberRecord)
        })
            .catch((e) => {
                console.log(e);
            })
    }

    const handleClick = (event) => {
        if (ref?.current && !ref?.current?.contains(event.target)) {
            setShowDropdown(false);
        }
        return
    };

    const searchMembers = (value) => {
        setSearchText(value)
        if (value.length > 0) {
            let arrayToBeSearchedFrom = allMemberList.map((res) => {
                return getMemberName(res.instrId)
            })
            const results = []
            for (var i = 0; i < arrayToBeSearchedFrom.length; i++) {
                if (
                    arrayToBeSearchedFrom[i].toLowerCase().startsWith(value.toLowerCase())
                ) {
                    results.push(allMemberList[i])
                }
            }
            setMemberList(results)
        } else {
            setMemberList(allMemberList)
        }
    }

    const getMemberName = (instrId) => {
        const memberData = allMemberList.filter((member) => {
            if (member.instrId === instrId) {
                return member
            }
        })
        if (memberData.length > 0) {
            if (memberData[0].isOwner && memberData[0].instrId === instrID) {
                return `${memberData[0].firstName} ${memberData[0].lastName} (You) (Owner)`
            }
            if (memberData[0].isOwner) {
                return `${memberData[0].firstName} ${memberData[0].lastName} (Owner)`
            }
            if (memberData[0].isAgency) {
                return "Talent Service Provider Profile"
            }
            if (memberData[0].isEveryone) {
                return allText || "Everyone"
            }

            if (memberData[0].instrId === instrID) {
                return `${memberData[0].firstName} ${memberData[0].lastName} (You)`
            }
            return `${memberData[0].firstName} ${memberData[0].lastName}`

        }
        return ""
    }

    useGlobalClick(handleClick, ref)

    const onDropChange = (res, dataList = allMemberList) => {
        let whenEveryOneSelected = []
        const whenOtherSelected = []
        dataList.map((data) => {
            if (data.firstName) {
                whenOtherSelected.push(data)
            }
        })
        const ownerRecord = dataList.filter(memberData => {
            if (memberData.instrId === instrID) {
                return memberData
            }
        })
        const otherRecords = []
        dataList.map(memberData => {
            if (memberData.instrId !== instrID && memberData.firstName) {
                otherRecords.push(memberData)
            }
        })
        const sortedOtherRecords = [...otherRecords].sort((a, b) => getMemberName(a["instrId"]).toLowerCase().localeCompare(getMemberName(b["instrId"]).toLowerCase()))
        if (!hideEveryoneOption) {
            whenEveryOneSelected =
                [
                    {
                        "instrId": "all",
                        "isEveryone": 1
                    },
                    ...sortedOtherRecords
                ]
            if (ownerRecord.length > 0) {
                whenEveryOneSelected.splice(1, 0, ownerRecord[0])
                whenEveryOneSelected[1] = ownerRecord[0]
            }
        } else {
            whenEveryOneSelected = [
                ...sortedOtherRecords
            ]
            if (ownerRecord.length > 0) {
                whenEveryOneSelected.splice(0, 0, ownerRecord[0])
            }
        }
        setShowDropdown(false)
        if (res) {
            if (fromSubmitProposal) {
                setAgencyMember(res.instrId)
            } else {
                dispatch(setCurrentAgencyMember(res.instrId))
                if (res.instrId === "all") {
                    if (dataList.length > 0) {
                        const owner = allMemberList.filter(memberData => {
                            if (memberData.instrId === instrID) {
                                return memberData
                            }
                        });
                        setMemberList([...whenEveryOneSelected])
                        if (owner.length > 0) {
                            dispatch(setCurrentAgencyMemberDetail(owner[0]))
                        }
                    }
                } else {
                    const sortedMembers = [...whenOtherSelected].sort((a, b) => getMemberName(a["instrId"]).toLowerCase().localeCompare(getMemberName(b["instrId"]).toLowerCase()))
                    if (!hideEveryoneOption) {
                        sortedMembers.unshift({
                            "instrId": "all",
                            "isEveryone": 1
                        })
                    }
                    setMemberList([

                        ...sortedMembers
                    ])
                    dispatch(setCurrentAgencyMemberDetail(res))
                }
            }
        } else {
            if (dataList.length > 0) {
                let owner = ""
                if (currentInstrID === "all" && !hideEveryoneOption) {
                    owner = dataList.filter(memberData => {
                        if (memberData.isOwner) {
                            return memberData
                        }
                    });
                    setMemberList([...whenEveryOneSelected])
                } else {
                    owner = dataList.filter(memberData => {
                        if (memberData.instrId === instrID) {
                            return memberData
                        }
                    });
                    const sortedMembers = [...whenOtherSelected].sort((a, b) => getMemberName(a["instrId"]).toLowerCase().localeCompare(getMemberName(b["instrId"]).toLowerCase()))
                    if (!hideEveryoneOption) {
                        sortedMembers.unshift({
                            "instrId": "all",
                            "isEveryone": 1
                        })
                    }
                    setMemberList([
                        ...sortedMembers
                    ])
                }
                if (fromSubmitProposal) {
                    setAgencyMember(owner[0].instrId)
                } else {
                    dispatch(setCurrentAgencyMemberDetail(owner))
                }
            }
        }
    }

    return (
        <>
            <div className='d-flex align-items-center agencyDropdownMainWrapper ' ref={ref}>
                {
                    !justDropdown &&
                    <div className='mr-2 font14Black text-nowrap'>
                        Show For
                    </div>
                }
                <div className={"relative"}>
                    <div className='d-flex justify-content-between align-items-center w-100 pointer' onClick={() => setShowDropdown(true)} >
                        <TextInput
                            name="title"
                            containerClass="mb-0 "
                            containerStyle={{ "width": "295px" }}
                            dropDownField={true}
                            placeholder={"Search for Members"}
                            className="pr-2 agencyDropdownInput"
                            onChange={(e) => {
                                searchMembers(e.target.value)
                            }}
                            onFocus={() => {
                                setShowDropdown(true)
                            }}
                            value={showDropdown ? searchText : getMemberName(fromSubmitProposal ? agencyMemeber : currentInstrID)}
                        />
                    </div>
                    {
                        showDropdown &&
                        <div className='memberListWrap'>
                            <div className='memberNameWrap'>
                                {
                                    memberList.map((res, idx) => {
                                        return (
                                            <React.Fragment key={idx}>
                                                <div
                                                    className='d-flex align-items-center justify-content-between pointer'
                                                    onClick={() => {
                                                        onDropChange(res)
                                                    }}
                                                >
                                                    <div className='d-flex align-items-center ml-2'>
                                                        {
                                                            !res?.isEveryone &&
                                                            <img src={thumbnailDict[res.instrId] ? thumbnailDict[res.instrId] : uploadImg} alt="" className="talentPic mr-2" />
                                                        }
                                                        <div className='font16Black'>
                                                            {getMemberName(res.instrId)}
                                                        </div>
                                                    </div>
                                                    {fromSubmitProposal ?
                                                        res?.instrId === agencyMemeber &&
                                                        <img src={blackTick} alt="" className='mr-2' />
                                                        :
                                                        currentInstrID === res?.instrId &&
                                                        <img src={blackTick} alt="" className='mr-2' />
                                                    }
                                                </div>

                                                {
                                                    (idx < memberList.length - 1) &&
                                                    <div className='border-top mt-2 mb-2' />
                                                }
                                            </React.Fragment>
                                        )
                                    })
                                }
                            </div>
                            {!hideAddTalent &&
                                <>
                                    <div className='addMemberBorder' />
                                    <div className='addMemberWrapp'>
                                        <div
                                            className='font14Blue boldFont'
                                            onClick={() => {
                                                history.push(manageMembers)
                                            }}
                                        >+ Add Member</div>
                                    </div>
                                </>
                            }

                        </div>
                    }
                </div>
            </div>
        </>
    )
}

export default AgencyDropdown;