/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import Dropzone from 'react-dropzone';
import { useForm } from 'react-hook-form';
import { AddItemAPI, AddVideoAPI, UpdateItemAPI, UpdateVideoAPI, UploadDocumentPut, UploadThumbnailPut } from '../../../../services/portfolio';
import { CloseCircular, fileUploadIcon } from '../../../../assets/images';
import { dataURLtoFile } from "../../../../constants/datToFileConverter";
import { pdfjs } from 'react-pdf';
import { URLPattern, vimeoValidation, youtubeValidation } from '../../../../constants/Validations';
import handleDocument from '../../../../constants/handleDocument';
import Button from '../../../../components/button';
import FormTextarea from '../../../../components/formTextarea/FormTextarea';
import Label from '../../../../components/label';
import TextInput from '../../../../components/textInput';
import { GetCurrentAgencyMember } from '../../../../util/reduxData';
import "./index.scss"

const AddPortfolio = ({
    onSuccess,
    existingItems,
    editPortfolioData,
    toggleModal
}) => {
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

    const { register, handleSubmit, errors, setError, clearErrors } = useForm({
        reValidateMode: "onBlur",
    });
    const [btnDisabled, setBtnDisabled] = useState(false)
    const [activeState, setActiveState] = useState("File");
    const [portfolioData, setPortfolioData] = useState({});
    const [documentName, setDocumentName] = useState(null);
    const [submitClicked, setSubmitClicked] = useState(false)
    const [binaryDocument, setBinaryDocument] = useState(null);
    const [documentSizeError, setDocumentSizeError] = useState(null);
    const [progress, setProgress] = useState(0);
    const [disableFileUpload, setDisableFileUpload] = useState(false);
    const [inValidFileTypeError, setInValidFileTypeError] = useState(false);
    const [documentSize, setDocumentSize] = useState(0)
    const [existError, setExistError] = useState(false);
    const [linkExists, setLinkExists] = useState(false);
    const [tabOptions, setTabOptions] = useState(["File", "Video", "Link"])
    const currentInstrId = GetCurrentAgencyMember()

    useEffect(() => {
        if (editPortfolioData?.webID || editPortfolioData?.docID) {
            setPortfolioData(editPortfolioData)
            setTabOptions([editPortfolioData?.portfolioType])
            setActiveState(editPortfolioData?.portfolioType)
            if (editPortfolioData?.portfolioType === "File") {
                setDocumentName(editPortfolioData?.blobFileName)
                const fileSize = (editPortfolioData?.fileSize / 1024).toFixed(2);
                setDocumentSize(fileSize)
            }
        } else {
            setPortfolioData({})
            setTabOptions(["File", "Video", "Link"])
            setActiveState("File")
            setDocumentName(null)
        }
    }, [editPortfolioData])

    const toggleState = (state) => {
        if (state === activeState) {
            return
        }
        setPortfolioData({
            title: portfolioData.title,
            description: "",
            url: ""
        })
        setDocumentSizeError(false)
        setBinaryDocument(null)
        setInValidFileTypeError(false)
        setDocumentName(null)
        setDocumentSize(0)
        setActiveState(state);
        clearErrors(["url", "description"])
        setSubmitClicked(false)
    };

    const setBinaryDocumentFunc = (event) => {
        setBinaryDocument(event)
    }
    const setDocumentSizeErrorFunc = (value) => {
        setDocumentSizeError(value)
    }
    const setDocumentNameFunc = (value) => {
        setDocumentName(value)
    }
    const setInvalidFileTypeFunc = (value) => {
        setInValidFileTypeError(value)
    }

    const readFileData = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (e) => {
                resolve(e.target.result);
            };
            reader.onerror = (err) => {
                reject(err);
            };
            reader.readAsDataURL(file);
        });
    };

    const getFirstImage = async (file) => {
        const images = [];
        const data = await readFileData(file);
        const pdf = await pdfjs.getDocument(data).promise;
        const canvas = document.createElement("canvas");
        const page = await pdf.getPage(1);
        const viewport = page.getViewport({ scale: 1 });
        const context = canvas.getContext("2d");
        canvas.height = viewport.height;
        canvas.width = viewport.width;
        await page.render({ canvasContext: context, viewport: viewport }).promise;
        images.push(canvas.toDataURL());
        canvas.remove();
        return images;
    }

    const clearDocument = () => {
        setDocumentSizeError(false)
        setBinaryDocument(null)
        setDocumentName(null)
        setDocumentSize(0)
        setDocumentSizeError(false)
    }
    const setDocumentSizeFunc = (size) => {
        setDocumentSize(size)
    }
    const handleDocumentUpload = (event) => {
        setInValidFileTypeError(false)
        handleDocument(event, setDocumentSizeErrorFunc, setDocumentNameFunc, setBinaryDocumentFunc, setInvalidFileTypeFunc, true, setDocumentSizeFunc, ["pdf"])
    }
    const handleRejectedDrop = () => {
        setDocumentSizeError(false)
        setInValidFileTypeError(true)
    }

    const handleAddLinkOrVideo = () => {
        let linkObj = {
            title: portfolioData.title,
            url: portfolioData?.url,
            description: portfolioData?.description,
            urlType: activeState === "Video" ? 13 : 1,
            instrId: currentInstrId
        };
        setBtnDisabled(true);
        const res = AddVideoAPI(linkObj)
            .then((response) => {
                onSuccess()
            })
            .catch((err) => {
                console.log(err?.response?.data);
                setBtnDisabled(false);
            });
    }

    const handleEditLinkOrVideo = () => {
        let linkObj = {
            title: portfolioData.title,
            url: portfolioData?.url,
            description: portfolioData?.description,
            urlType: activeState === "Video" ? 13 : 1,
            webId: editPortfolioData?.webID,
            instrId: currentInstrId
        };
        setBtnDisabled(true);
        const res = UpdateVideoAPI(linkObj)
            .then((response) => {
                onSuccess()
            })
            .catch((err) => {
                console.log(err?.response?.data);
                setBtnDisabled(false);
            });
    }
    const progressConfig = () => {
        return {
            onUploadProgress: progressEvent => {
                const progress = (progressEvent.loaded / progressEvent.total) * 100;
                setProgress(progress)
            }
        }
    }

    const getExtension = (filename) => {
        const split = filename?.split(".");
        if (split?.length > 1) {
            return split[split.length - 1];
        } else {
            return "--";
        }
    };

    const uploadDocAndThumb = (fileObj, itemId) => {
        const thumbData = new FormData();
        thumbData.append("formFile", fileObj);
        const docData = new FormData();
        docData.append("formFile", binaryDocument);
        const uploadItemRes = UploadDocumentPut("Item", itemId, docData, progressConfig(), currentInstrId)
        const uploadThumRes = UploadThumbnailPut("Item", itemId, thumbData, () => { }, currentInstrId)
        Promise.allSettled([uploadItemRes, uploadThumRes])
            .then(() => {
                onSuccess()
            });
    }
    const handleAddItem = () => {
        const itmeObj = {
            title: portfolioData?.title,
            description: portfolioData?.description,
            instrId: currentInstrId
        };
        try {
            setBtnDisabled(true);
            AddItemAPI(itmeObj)
                .then((response) => {
                    const fileType = getExtension(documentName)
                    if (binaryDocument && fileType === "pdf") {
                        getFirstImage(binaryDocument).then((base64Data) => {
                            const fileObj = dataURLtoFile(base64Data[0])
                            uploadDocAndThumb(fileObj, response.data)
                        })
                    } else if (binaryDocument) {
                        uploadDocAndThumb(binaryDocument, response.data)
                    } else {
                        onSuccess()
                    }
                })
                .catch((err) => {
                    console.log("errr", err);
                    setBtnDisabled(false);
                });
        } catch (err) {
            console.err("err", err);
            setBtnDisabled(false);
        }
    };

    const handleEditItem = () => {
        const itmeObj = {
            title: portfolioData?.title,
            description: portfolioData?.description,
            docID: editPortfolioData?.docID,
            instrId: currentInstrId
        };
        try {
            setBtnDisabled(true);
            UpdateItemAPI(itmeObj)
                .then((response) => {
                    const fileType = getExtension(documentName)
                    if (binaryDocument && fileType === "pdf") {
                        getFirstImage(binaryDocument).then((base64Data) => {
                            const fileObj = dataURLtoFile(base64Data[0])
                            uploadDocAndThumb(fileObj, editPortfolioData?.docID)
                        })
                    } else if (binaryDocument) {
                        uploadDocAndThumb(binaryDocument, editPortfolioData?.docID)
                    } else {
                        onSuccess()
                    }
                })
                .catch((err) => {
                    console.log("errr", err);
                    setBtnDisabled(false);
                });
        } catch (err) {
            console.err("err", err);
            setBtnDisabled(false);
        }
    }

    const onSubmit = () => {
        if (existError) {
            return
        }
        if (activeState === "Video" && !matchVimeoUrl(portfolioData?.url) && !matchYoutubeUrl(portfolioData?.url)) {
            setError('url', { type: 'pattern' });
            return
        }
        if (activeState === "File") {
            if (!documentName) {
                return
            }
            if (editPortfolioData?.docID) {
                handleEditItem()
            } else {
                handleAddItem()
            }
        } else {
            setDisableFileUpload(true)
            if (editPortfolioData?.webID) {
                handleEditLinkOrVideo()
            } else {
                handleAddLinkOrVideo()
            }
        }
    }

    const checkPortfolioExists = (value) => {
        if (editPortfolioData?.title?.toLowerCase() === value?.toLowerCase()) {
            return
        }
        const itemExists = existingItems?.find((element) => {
            return value?.toLowerCase() === element?.title?.toLowerCase();
        });
        setExistError(itemExists ? true : false)
    }

    const checkLinkExists = (value) => {
        if (editPortfolioData?.title?.toLowerCase() === value?.toLowerCase()) {
            return
        }
        const linkExists = existingItems?.find((element) => {
            return value?.toLowerCase() === element?.url?.toLowerCase();
        });
        setLinkExists(linkExists ? true : false);
    }

    const matchYoutubeUrl = (url) => {
        const shortsRegext = youtubeValidation
        if (url.match(shortsRegext)) {
            return true;
        }
        return false;
    }

    const matchVimeoUrl = (url) => {
        const p = vimeoValidation;
        if (url.match(p)) {
            return true;
        }
        return false;
    }

    const validateVideoURL = (url) => {
        if (url.length > 10) {
            if (!matchVimeoUrl(url) && !matchYoutubeUrl(url)) {
                setError('url', { type: 'pattern' });
            } else {
                clearErrors("url")
            }
        }
    }

    return (
        <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <div className='border-top mt-4 mobileView' />
            <div className="addPortfolioModalWrap">
                <div className="d-md-flex d-block">
                    <TextInput
                        name="title"
                        innerRef={register({
                            required: true,
                        })}
                        containerClass="mb-3 w-100"
                        placeholder="Portfolio Item Title"
                        label="Portfolio Item Title*"
                        id="linkName"
                        value={portfolioData?.title}
                        onChange={(e) => {
                            checkPortfolioExists(e.target.value);
                            setPortfolioData({
                                ...portfolioData,
                                title: e.target.value
                            })
                        }}
                        error={
                            (errors.title && "Portfolio Sample Title is Required") ||
                            (existError && "Item with same Title already exist")
                        }

                    />
                </div>
                <span className="formLabel mb-0">Portfolio Item*</span>
                <div className="tabWrappStyle mr-5">
                    {
                        tabOptions.map((tabs, idx) => {
                            return (
                                <div
                                    key={idx}
                                    className={
                                        activeState === tabs ? "activeTabStyle" : "inactiveTabStyle mb-0"
                                    }
                                    onClick={() => toggleState(tabs)}
                                >
                                    <div>{tabs}</div>
                                </div>
                            )
                        })
                    }
                </div>
                <div className="mt-4">
                    {
                        activeState === "File" ?
                            <>
                                {
                                    !documentName ?
                                        <Dropzone
                                            onDrop={acceptedFiles => handleDocumentUpload(acceptedFiles)}
                                            multiple={false}
                                            disabled={disableFileUpload}
                                            onDropRejected={(data) => handleRejectedDrop(data)}
                                        >
                                            {({ getRootProps, getInputProps }) => (
                                                <div
                                                    className={
                                                        !documentName ?
                                                            "addCourseOutlineInput text-center d-flex flex-column align-items-center mb-4" :
                                                            "d-flex justify-content-between"}
                                                    {...getRootProps()}
                                                >
                                                    <input
                                                        {...getInputProps()}
                                                    />

                                                    <>
                                                        <label htmlFor="courseDocument" className={!documentName ? "imageLabelMargin" : "imageLabelNoMargin"}>
                                                            <img
                                                                className="m-auto fileUploadIcon"
                                                                src={fileUploadIcon}
                                                                alt=""
                                                            />
                                                        </label>

                                                        <Label
                                                            label={
                                                                <span>
                                                                    Drag & Drop or Upload a portfolio item (PDF/JPG/PNG)
                                                                    <br />
                                                                    Max file size: 20 MB
                                                                </span>
                                                            }
                                                            className="inputFileBoxText"
                                                            id="courseDocument"
                                                        />
                                                    </>
                                                    {progress !== 0 &&
                                                        <div style={{ width: 200 }}>
                                                            <span className='font14Black'>Uploading Document....</span>
                                                            <div className="uploadProgressBar">
                                                                <div className="progressBarPercent" role="progressbar" style={{ width: `${progress}%` }}></div>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            )}
                                        </Dropzone> :
                                        <div className='d-flex justify-content-between align-items-center'>
                                            <div>
                                                <span className='font14Black'>
                                                    {documentName}
                                                </span>
                                                {progress !== 0 &&
                                                    <div style={{ width: 200 }}>
                                                        <span className='font14Black'>Uploading Document....</span>
                                                        <div className="uploadProgressBar">
                                                            <div className="progressBarPercent" role="progressbar" style={{ width: `${progress}%` }}></div>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                            <div className='d-flex ml-4 align-items-center'>
                                                <div className='font14Black   text-nowrap'>{documentSize} MB</div>
                                                <img src={CloseCircular} alt="" onClick={clearDocument} className=" ml-1 clearDocumentIcon pointer" />
                                            </div>
                                        </div>
                                }

                                {documentSizeError &&
                                    <span className="d-flex justify-content-center align-items-center my-2">
                                        <i className="fa fa-exclamation-circle errorIcon" />
                                        <p className="errorText mb-0 ml-0 ">
                                            {"Document size should be less than 20MB"}
                                        </p>
                                    </span>
                                }
                                {(submitClicked && !documentName) &&
                                    <span className="d-flex justify-content-center align-items-center my-2">
                                        <i className="fa fa-exclamation-circle errorIcon" />
                                        <p className="errorText mb-0 ml-0 ">
                                            {"Portfolio item is Required"}
                                        </p>
                                    </span>
                                }
                                {
                                    inValidFileTypeError && !documentName && (
                                        <div>
                                            <span className="d-flex align-items-center justify-content-center my-1">
                                                <i className="fa fa-exclamation-circle errorIcon" />
                                                <p className="errorText mb-0 ml-0 ">
                                                    Only PDF and Image file type allowed
                                                </p>
                                            </span>
                                        </div>
                                    )
                                }
                            </> :
                            <>
                                <TextInput
                                    name="url"
                                    innerRef={register({
                                        required: true,
                                        pattern: URLPattern,
                                    })}
                                    containerClass="w-100"
                                    placeholder="https://"
                                    id="linkName"
                                    label={
                                        activeState === "Link" ?
                                            "Web Link*" :
                                            "Video Link*"
                                    }
                                    value={portfolioData?.url}
                                    onChange={(e) => {
                                        setPortfolioData({
                                            ...portfolioData,
                                            url: e.target.value
                                        })
                                        checkLinkExists(e.target.value)
                                        if (activeState === "Video") {
                                            validateVideoURL(e.target.value)
                                        }
                                    }}
                                    error={
                                        (errors.url?.type === "required" && activeState === "Link") ?
                                            "Web Link required, please use a valid URL" :
                                            (errors.url?.type === "required" && activeState === "Video")
                                                ? "Video Link required, please use a valid URL"
                                                : errors.url?.type === "pattern"
                                                    ? "Please use a valid URL"
                                                    : null || (linkExists && "Link with same URL already exists.")
                                    }
                                />
                                {
                                    activeState !== "Link" &&
                                    <span className="font12Grey">
                                        Only YouTube and Vimeo links are allowed
                                    </span>
                                }

                            </>
                    }
                    <div className="border-top mt-3 mb-3" />
                    <FormTextarea
                        maxLength={100}
                        rows={3}
                        name="description"
                        formClass=""
                        textareaClass="resizeFalse"
                        containerStyle={{ marginBottom: "0" }}
                        errorClass="w-100"
                        innerRef={register({
                            required: true,
                        })}
                        id="courseDescription"
                        value={portfolioData?.description}
                        placeholder=""
                        label="Description*"
                        error={errors.description && "Description is Required and can be a maximum of 100 characters"}
                        onChange={(e) =>
                            setPortfolioData({ ...portfolioData, description: e.target.value })
                        }
                        showTextLength={true}
                        currentLenght={portfolioData?.description?.length || 0}
                    />
                </div>
            </div>
            <div
                className=" border-top addPortfolioButtonWrapp"

            >
                <div className="text-center ">
                    <div className="my-4">
                        <Button
                            label="Cancel"
                            type="button"
                            className="btn-light mx-2"
                            onClick={() => {
                                toggleModal()
                            }}
                        />
                        <Button
                            label={(editPortfolioData?.webID || editPortfolioData?.docID) ? "Update" : "Add"}
                            type="submit"
                            onClick={() => {
                                setSubmitClicked(true)
                            }}
                            disabled={btnDisabled}
                            className="btn-blue "
                        />
                    </div>
                </div>
            </div>
        </form>
    )
}

export default AddPortfolio;