import Axios from "../util/axiosInstance";

export const getAllTestimonials = (instrId) => {
  return Axios.get("/api/Testimonial/GetAll?instrId="+instrId)
};

export const updateTestimonialVisiblity = (payload) => {
  return Axios.post("/api/Testimonial/SetTestimonialVisibility", payload) 
};

export const requestTestimonialApi = (payload) => {
  return Axios.post("/api/Testimonial/RequestTestimonial", payload)
};

export const submitTestimonialApi = (payload) => {
  return Axios.post("/api/Testimonial/SubmitTestimonial", payload)
};

export const updateTestimonialsAsRead = (payload) => {
  return Axios.post("/api/Testimonial/SetTestimonialRead", payload)
};