/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { whiteArrowDown, crossIcon } from "../../assets/images";
import { GetCancelFileIndex } from "../../util/reduxData";
import { removeCancelFileIndex } from "../../ducks/cancelledFiles";
import { removeFilesToken } from "../../ducks/cancelToken";
import { startBackgroundUpload } from "../../ducks/uploadBackground";
import { removeFilesProgress } from "../../ducks/uploadProgress";
import "./index.scss"

const UploadProgress = () => {
    const dispatch = useDispatch()
    const [cancelledFiles, setCancelledFiles] = useState([])
    const [minimize, setMinimize] = useState(false)


    const filesUploading = useSelector(state => state.filesProgressReducer.fileList, () => { });

    const cancelledFilesRedux = GetCancelFileIndex()

    const startUpload = useSelector((state) => {
        return state.backgroudProgressReducer.upload
    })

    useEffect(() => {
        if (cancelledFilesRedux?.length > 0) {
            setCancelledFiles([...cancelledFiles, ...cancelledFilesRedux])
        }

    }, [cancelledFilesRedux])

    useEffect(() => {
        window.addEventListener("beforeunload", alertUser);
        return () => {
            window.removeEventListener("beforeunload", alertUser);
        };
    }, []);

    const alertUser = (e) => {
        dispatch(removeFilesToken())
        dispatch(removeFilesProgress())
        dispatch(startBackgroundUpload({ upload: false }))
        dispatch(removeCancelFileIndex())
    };

    const cancelToken = useSelector((state) => state.filesTokenReducer.tokenList)

    const cancelUpload = (index) => {
        const cancelledFilesArray = [...cancelledFiles]
        if (cancelToken[index]) {
            cancelToken[index].cancel("Cancel")
            cancelledFilesArray.push(index)
            setCancelledFiles(cancelledFilesArray)
        }
    }

    return (
        <>
            {
                (filesUploading?.length > 0 && startUpload) &&
                <>
                    <div className="uploadCard">
                        <div className="progressHolder">
                            <span>Uploading Files ({filesUploading.filter((res) => res.progressBar === 100).length}/{filesUploading.length - [...new Set(cancelledFiles)].length})</span>
                            <img src={whiteArrowDown} alt="" className={minimize ? "" : "rotate"} onClick={() => setMinimize(!minimize)} />
                        </div>

                        {
                            filesUploading?.map((data, idx) => {
                                return (
                                    <div key={idx} className={minimize ? "d-none" : "contentWrap"}>
                                        {
                                            (data.progressBar !== 100 && !cancelledFiles.includes(idx)) &&
                                            <>
                                                <div className="firstColumn">
                                                    <div className="progressHolder">
                                                        <span>{data?.fileName}</span>
                                                    </div>
                                                    <div className="profileProgressBar mb-2">
                                                        <div
                                                            className="profileProgressBarPercent"
                                                            role="progressbar"
                                                            style={{ width: `${data?.progressBar}%` }}
                                                        ></div>
                                                    </div>
                                                </div>
                                                <div className="secondColumn">
                                                    <img src={crossIcon} alt="" onClick={() => cancelUpload(idx)} className="pointer" />
                                                </div>
                                            </>
                                        }
                                    </div>
                                )
                            })
                        }
                    </div>
                </>
            }
        </>
    )
}

export default UploadProgress;