import React, { useState } from "react";
import { Edit } from "../../../../../assets/images/index";
import { useHistory } from "react-router-dom";
import Label from "../../../../../components/label";
import ShowMore from "../../../../../components/showMore";
import "./index.scss";

const WorkExperienceCard = ({ edit, workExperience, fromTalent, client }) => {
  const [itemsToShow, setitemsToShow] = useState(3);
  const history = useHistory();

  const showMore = () => {
    if ( itemsToShow === 3) {
      setitemsToShow(workExperience?.length);
    } else {
       setitemsToShow(3) 
    }
  };

  const editWorkExp = () => {
    history.push("/edit/work-experience");
  };

  return (
    <div className={(fromTalent && !client)?"freelancerModalComponentWrap ml-0":"freelancerModalComponentWrap"}>
      <div className="d-flex justify-content-between">
        <Label
          className="font18Black boldFont mt-3 mb-0"
          label="Work Experience"
        ></Label>
        {edit && <img src={Edit} style={{cursor:"pointer"}} onClick={editWorkExp} alt=""/>}
      </div>
      {workExperience?.slice(0, itemsToShow).map((work, idx) => {
        return (
          <div className="mb-3" style={{ width: "100%" }} key={idx}>
            <p className="workExpJobTitle mb-0">{work?.title}</p>
            <p className="workExpCompanyName mb-0">{work?.company}</p>
            <div className="d-flex flex-wrap workExpCityFromSection">
              <span>{work?.city}</span>
              {work.city && <span className="mx-2">|</span>}
              <span className="">
                {work?.startMonth.substr(0, 3)} {work?.startYear} {" - "}
                {work?.endMonth ? (
                  <span>
                    {work?.endMonth.substr(0, 3)} {work.endYear}
                  </span>
                ) : (
                  "Present"
                )}
              </span>
            </div>
            <div className="workExpListDescriptionText mb-0 mt-2">
            <ShowMore
                text={work?.description}
                more="More"
                less="Less"
                textClass="font14Grey"
                anchorClass="font14Blue noWrap"
                lines={2}
                id={"idForWord"+work?.id}
              />
            </div>
          </div>
        );
      })}
      {workExperience?.length > 3 && (
        <div
          style={{
            justifyContent: "center",
            textAlign: "center",
          }}
          className="profileEducationMore"
        >
          <span className="profileEducationMore" onClick={showMore}>
            { itemsToShow === 3 ? "More" : "Less"}
          </span>
        </div>
      )}
    </div>
  );
};

export default WorkExperienceCard;
