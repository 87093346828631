export const UPDATE_SCHEDULE_LIST = "UPDATE_SCHEDULE_LIST";

export const initialState = null;

const updateScheduleReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case UPDATE_SCHEDULE_LIST:
            return { ...state, ...payload };

        default:
            return state;
    }
};
export const updateScheduleList = (payload) => {
    return (dispatch) => {
        dispatch({
            type: UPDATE_SCHEDULE_LIST,
            payload,
        });
    };
};

export default updateScheduleReducer