import axios from "axios";
import { getToken, getRefreshToken } from "../util/localStorage";

const refreshAccessToken = () => {
  const token = getToken();
  const refreshToken = getRefreshToken();

let apiVersion = process.env.REACT_APP_API_BASE_URL;  

  const payload = {
    jwtToken: token,
    refreshToken: refreshToken,
  };

  const axiosInstance = axios.create({
    baseURL: apiVersion,
    headers: { "Content-Type": "application/json" },
  });

  return axiosInstance.post(`/api/User/RefreshToken`, payload);
};

export default refreshAccessToken;
