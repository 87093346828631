export const UPDATE_JOB_ID = "UPDATE_JOB_ID";
export const DELETE_JOB_ID = "DELETE_JOB_ID";


export const initialState = null;

const jobDetailReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case UPDATE_JOB_ID:
      return { ...state, ...payload };
    case DELETE_JOB_ID:
      return {};
    default:
      return state;
  }
};
export const updateJobId = (payload) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_JOB_ID,
      payload,
    });
  };
};


export const deleteJobId = () => {
  return (dispatch) => {
    dispatch({
      type: DELETE_JOB_ID,
    });
  };
};

export default jobDetailReducer;
