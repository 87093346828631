import React from "react";
import { convertTime, convertDate } from "../../../../../util/dateTime";
import "./TrainingTimeline.scss";

const DesktopTimelineTable = ({
  timelineData,
}) => {

  const getStartAndEndDate = (data) => {
    if(
        (data.projectDate && !data?.projectEndDate) || (data.projectDate === data?.projectEndDate)
      ) {
      return convertDate(data.projectDate)
    } else {
      return `${convertDate(data.projectDate)} - ${convertDate(data?.projectEndDate)}`
    }
  }
  return (
    <div className="tableWrapperSchedule mt-3 overflow-x-css">
      <table className="table border-bottom table-hover noHeadSeperator">
        <thead className='headerJobPost'>
          <tr>
            <th className="jobPostheaderclass"> Date(s)</th>
            <th className="jobPostheaderclass">Start Time</th>
            <th className="jobPostheaderclass">End Time</th>
            <th className="jobPostheaderclass">Time Zone </th>
          </tr>
        </thead>
        <tbody>
          {timelineData?.map((data, index) => {
            return (
              <tr key={index}>
                <td className="JobDescriptionId">{getStartAndEndDate(data)}</td>
                <td className="JobDescriptionId">{convertTime(data.startTime)}</td>
                <td className="JobDescriptionId">{convertTime(data.endTime)}</td>
                <td className="JobDescriptionId">{data.timeZone}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default DesktopTimelineTable;
