// action types
export const AUTH_SAVE = "AUTH_SAVE";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const REDIRECT_PATH_SAVE = "REDIRECT_PATH_SAVE";

// action creator
export const saveAuth = (payload) => ({
  type: AUTH_SAVE,
  payload,
});

export const logoutAuth = () => ({
  type: AUTH_LOGOUT,
});

export const saveRedirectPath = (payload) => ({
  type: REDIRECT_PATH_SAVE,
  payload,
});

// selectors
export const authSelector = (state) => state.auth;

// initial state
export const initialState = {
  id: "",
  emailAddress: "",
  password: "",
  rememberMe:false
};

// reducers
const authReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case AUTH_SAVE:
      return { ...state, ...payload };

    case REDIRECT_PATH_SAVE: {
      const { path } = payload;
      return { ...state, path };
    }

    default:
      return state;
  }
};

export default authReducer;
