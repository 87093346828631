// action types
export const HASH_SAVE = "HASH_SAVE";

// action creator
export const saveLoginHash = (payload) => ({
  type: HASH_SAVE,
  payload,
});

// initial state
export const initialState = {
  id: "",
  emailAddress: "",
  hash: "",
};

// reducers
const loginHashReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case HASH_SAVE:
      return { ...state, ...payload };

    default:
      return state;
  }
};

export default loginHashReducer;
