import Axios from "../util/axiosInstance";


export const GetProjectContact = (projectId) => {
    return Axios.get(`/api/Project/GetProjectContacts?projectId=${projectId}`)
}

export const PostProjectContact = (payload) => {
    return Axios.post("/api/Project/AddProjectContact", payload)
}

export const DeleteProjectContact = (jobId, contactId) => {
    return Axios.delete(`/api/Project/DeleteProjectContact?contactId=${contactId}&projectId=${jobId}`)
}

export const GetProjectTalents = projectId => {
    return Axios.get(`/api/Project/GetProjectTalents?projectId=${projectId}`)
}