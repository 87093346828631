import Axios from "../util/axiosInstance";

export const GetAllAgencies = () => {
    return Axios.get("/api/ManageTalents/GetAgencyTalents")
};

export const GetAllProjectMembers = (projectId) => {
  return Axios.get("/api/ManageTalents/GetAgencyTalentsOnProject?projectId="+projectId)
};
  
