export const UPDATE_LAST_VISITED_PAGE = "UPDATE_LAST_VISITED_PAGE"

export const initialState = []
const lastVisitedPageReducer = (state=initialState, action) => {
    const { type, payload } = action;
    switch(type) {
      case UPDATE_LAST_VISITED_PAGE:
        return payload
      default:
        return state;
    }
}

export const setLastVisitedPage = (payload) => {
    return (dispatch) => {
      dispatch({
        type: UPDATE_LAST_VISITED_PAGE,
        payload,
      });
    };
  };
export default lastVisitedPageReducer;