export const GET_COUNTRIES = "GET_COUNTRIES";
export const GET_STATES = "GET_STATES";

export const initialState = {
  countries: null,
  states: null,
};
const companyReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_COUNTRIES:
      return { ...state, countries: [...payload] };
    case GET_STATES:
      return { ...state, states: [...payload] };

    default:
      return state;
  }
};

export const getCountries = (payload) => {
  return (dispatch) => {
    dispatch({
      type: GET_COUNTRIES,
      payload,
    });
  };
};

export const getStates = (payload) => {
  return (dispatch) => {
    dispatch({
      type: GET_STATES,
      payload,
    });
  };
};

export default companyReducer;
