/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { GetJobPostDetails, getJobDetailsPublic } from "../../../../services/jobpost";
import { backArrow, savedJobIcon, emptyBookmark, Failed } from "../../../../assets/images";
import { PostSaveJob } from "../../../../services/saveJob";
import { RemoveProjectFromBookmark } from "../../../../services/removeFromBookmark";
import { CheckProjectTalentAccess } from "../../../../services/jobAccess";
import Button from "../../../../components/button";
import JobDetails from "../../../ClientScreens/JobPost/JobPost/JobDetails/JobDetails";
import Document from "../../../ClientScreens/JobPost/JobPost/Documents/Document";
import JobCertificationDetails from "../../../ClientScreens/JobPost/JobPost/JobCertificationDetails/JobCertificationDetails";
import Label from "../../../../components/label";
import JobTimeLine from "../../../ClientScreens/JobPost/JobPost/JobTimeline/JobTimeline";
import Contact from "../../../ClientScreens/JobPost/JobPost/Contacts/Contact";
import { GetUserLoginData } from "../../../../util/reduxData";
import RemoveFromBookmark from "../RemoveFromBookmark/removeFromBookmark";
import { path } from "../../../../constants/Path";
import { getJobAddressString } from "../../../../util/jobAddressString";
import "./jobDetails.scss"

const TalentsJobDetails = ({ isPublic, submitProposal }) => {
    const params = useParams();
    const [data, setData] = useState();
    const [hasAccess, setHasAccess] = useState(null)
    const history = useHistory()
    const userData = GetUserLoginData()
    const projectId = params.jobId;
    const hash = params.hash;
    isPublic = isPublic === undefined ? hash !== undefined : isPublic
    const [saved, setSaved] = useState(false);
    const [removeFromBookmark, setRemoveFromBookmark] = useState(false)

    const addToBookmark = () => {
        PostSaveJob({
            projectId: projectId,
            instrId: userData?.instrId
        })
            .then((res) => {
                GetJobPostDetailsFunc();
            })
    }

    const removeFromBookmarkFunc = () => {
        // removes a project from bookmark
        RemoveProjectFromBookmark(projectId, userData?.instrId)
            .then((res) => {
                GetJobPostDetailsFunc();
                setRemoveFromBookmark(false)
            })
    }

    useEffect(() => {
        checkAccess();
    }, []);

    const checkAccess = () => {
        if (isPublic) {
            getJobDetalPublicHash();
        } else {
            const editProposal = history.location.pathname.includes("edit")
            CheckProjectTalentAccess(projectId, (submitProposal && editProposal) ? "ProposalSubmission" : "SearchJobs").then((res) => {
                if (res.data) {
                    GetJobPostDetailsFunc();
                    setHasAccess(1);
                } else {
                    setHasAccess(2);
                }
            });
        }
    };

    const getJobDetalPublicHash = () => {
        getJobDetailsPublic({ projectId: projectId, hash: hash }).then((response) => {
            setData(response.data);
            setHasAccess(1)
            setSaved(response.data.projectDetails.isSaved)
        })
            .catch((err) => {
                setHasAccess(2)
                console.log("err", err)
            })
    }

    const GetJobPostDetailsFunc = () => {
        GetJobPostDetails({ projectId: projectId, Instrid: userData?.instrId })
            .then((response) => {
                setData(response.data);
                setHasAccess(1)
                setSaved(response.data.projectDetails.isSaved)
            })
            .catch((err) => {
                setHasAccess(2)
                console.log("err", err)
            })

    };
    const goBack = () => {
        const historyState = history.location.state
        if (historyState?.pathname) {
            const searchParams = new URLSearchParams();
            searchParams.append('page', historyState.page || 1);
            searchParams.append('view', historyState.View || "gridView")
            history.push({
                pathname: historyState.pathname,
                state: historyState,
                search: searchParams.toString(),
            })
        } else {
            history.goBack()
        }

    }
    return (
        <>
            <RemoveFromBookmark
                isOpen={removeFromBookmark}
                toggleModal={() => setRemoveFromBookmark(!removeFromBookmark)}
                jobId={projectId}
                removeFromBookmarkFunc={(data) => removeFromBookmarkFunc(data)}
            />
            {

                hasAccess === 1 &&
                <div className={!submitProposal ? "talentJobDetailsWrapp" : "ProposaltalentJobDetailsWrapp"}>
                    {!isPublic && !submitProposal && <div className="mb-3 desktopView">
                        <img src={backArrow} alt="" className="pointer" onClick={() => goBack()} /><span onClick={() => goBack()} className="font14Blue ml-2">Back</span>
                    </div>}
                    {!submitProposal && <div className="jobpostlayouttexttop d-flex justify-content-between align-items-center mb-3">
                        <div className="JobsTitleStatusPost jobTitles">
                            {data?.projectDetails?.projectId} - {data?.projectDetails?.title}
                        </div>
                        {!isPublic && <div className="actionButtonWrapp">
                            {
                                (saved) ?
                                    <div>
                                        <img src={savedJobIcon} alt="" className="Recommendedfav" onClick={() => setRemoveFromBookmark(true)} />
                                    </div> :
                                    <div>
                                        <img src={emptyBookmark} alt="" className="Recommendedfav" onClick={() => addToBookmark()} />
                                    </div>

                            }
                            <Button
                                label={"Submit Proposal"}
                                className="proposal-btn"
                                onClick={() => {
                                    history.push(
                                        path.submitProposal + projectId
                                    )
                                }}
                            />
                        </div>}
                    </div>}
                    {!submitProposal && <div className="mobileView">
                        {!isPublic && <div className="d-flex justify-content-between px-3 py-3">
                            <Button
                                label={"Submit Proposal"}
                                className="proposal-btn"
                                onClick={() => {
                                    history.push(
                                        `${path.submitProposal}` + projectId
                                    )
                                }}
                            />
                            {
                                (saved) ?
                                    <div>
                                        <img src={savedJobIcon} alt="" className="Recommendedfav" onClick={() => setRemoveFromBookmark(true)} />
                                    </div> :
                                    <div>
                                        <img src={emptyBookmark} alt="" className="Recommendedfav" onClick={() => addToBookmark()} />
                                    </div>

                            }

                        </div>}
                    </div>}

                    <div className="desktopView">
                        <div className={!submitProposal ? "d-flex" : ""}>
                            <div className={!submitProposal ? "JobDescriptionLeftWrapper" : "ProposalJobDescriptionWrapper"}>
                                <JobDetails
                                    role={data?.projectDetails.projectRoles}
                                    roleGroup={data?.projectDetails.roleGroup}
                                    projectId={data?.projectId}
                                    postedOn={data?.projectDetails.stagePostingDate}
                                    JobDescription={data?.projectDetails.description}
                                    skill={data?.projectSkills}
                                    location={getJobAddressString(data?.projectSpecification, true)}
                                    city={data?.projectSpecification.nearCity}
                                    country={data?.projectSpecification.country}
                                    state={data?.projectSpecification.state}
                                    TimeZone={data?.projectSpecification.timeZone}
                                    audienceData={data?.projectAudience}
                                    showmore={true}
                                    RateType={data?.projectBudget?.rateType}
                                    rateMin={data?.projectBudget?.rateMin}
                                    rateMax={data?.projectBudget?.rateMax}
                                    rateNegotiable={data?.projectBudget?.rateNegotiable}
                                    noOfPositions={data?.projectDetails?.noOfPositions}
                                    estimatedTime={data?.projectSpecification.timeRequirementID}
                                    talent={true}
                                    data={data}
                                />
                                {(data?.projectDetails.roleGroup === "A" || data?.projectDetails.roleGroup === "TC") &&
                                    <JobTimeLine
                                        submitProposal={submitProposal}
                                        trainingTimeLineDetails={
                                            data?.projectSpecification?.trainingTimeLineDetails
                                        }
                                        timelineList={data?.projectSpecificationTimelines}
                                    />
                                }
                                <JobCertificationDetails
                                    submitProposal={submitProposal}
                                    skill={data?.projectSkills}
                                    roleGroup={data?.projectDetails.roleGroup}
                                    AllIndustry={data?.projectIndustryExperience}
                                    languageList={data?.projectLanguages}
                                    contentRequirement={data?.projectSpecification?.contentRequirement}
                                    isCertificationRequired={
                                        data?.projectSpecification?.isCertificationRequired
                                    }
                                    certificationRequiredComments={
                                        data?.projectSpecification?.certificationRequiredComments
                                    }
                                    projectDuration={data?.projectSpecification.duration}
                                    projectTimeframe={data?.projectSpecification.timeFrame}
                                    timeRequirement={data?.projectSpecification.timeRequirement}
                                    projectSpecificationDetails={data?.projectSpecification}
                                    projectCertificationDetails={data?.projectCertification}
                                    projectDetails={data?.projectDetails}
                                />
                                <Document
                                    submitProposal={submitProposal}
                                    attachedDoc={data?.projectDocuments}
                                    talent={"true"}
                                    isPublic={isPublic}
                                />
                            </div>

                            {!submitProposal && <div className="JobDescriptionRightWrapper">
                                <Contact repDetails={data?.repDetails} talent={"true"} remove={"true"} isPublic={isPublic} isChatVisible={data?.projectDetails?.isChatVisible}/>
                            </div>}

                        </div>
                    </div>

                    <div className="jobDetailsmobileView mobileView">
                        {!submitProposal &&
                            <Contact repDetails={data?.repDetails} talent={"true"} remove={"true"} isPublic={isPublic} isChatVisible={data?.projectDetails?.isChatVisible}/>
                        }
                        <div className="mt-4">
                            <JobDetails
                                submitProposal={submitProposal}
                                role={data?.projectDetails.projectRoles}
                                roleGroup={data?.projectDetails.roleGroup}
                                projectId={data?.projectId}
                                postedOn={data?.projectDetails.stagePostingDate}
                                JobDescription={data?.projectDetails.description}
                                skill={data?.projectSkills}
                                location={getJobAddressString(data?.projectSpecification)}
                                city={data?.projectSpecification.nearCity}
                                country={data?.projectSpecification.country}
                                state={data?.projectSpecification.state}
                                TimeZone={data?.projectSpecification.timeZone}
                                audienceData={data?.projectAudience}
                                showmore={true}
                                RateType={data?.projectBudget?.rateType}
                                rateMin={data?.projectBudget?.rateMin}
                                rateMax={data?.projectBudget?.rateMax}
                                rateNegotiable={data?.projectBudget?.rateNegotiable}
                                noOfPositions={data?.projectDetails?.noOfPositions}
                                estimatedTime={data?.projectSpecification.timeRequirementID}
                                data={data}
                            />
                        </div>
                        {(data?.projectDetails.roleGroup === "A" || data?.projectDetails.roleGroup === "TC") &&
                            <div className={!submitProposal ? "mt-4" : ""}>
                                <JobTimeLine
                                    submitProposal={submitProposal}
                                    trainingTimeLineDetails={
                                        data?.projectSpecification?.trainingTimeLineDetails
                                    }
                                    timelineList={data?.projectSpecificationTimelines}
                                />
                            </div>
                        }

                        <div className={!submitProposal ? "mt-4" : ""}>
                            <JobCertificationDetails
                                submitProposal={submitProposal}
                                skill={data?.projectSkills}
                                roleGroup={data?.projectDetails.roleGroup}
                                AllIndustry={data?.projectIndustryExperience}
                                languageList={data?.projectLanguages}
                                contentRequirement={data?.projectSpecification?.contentRequirement}
                                isCertificationRequired={
                                    data?.projectSpecification?.isCertificationRequired
                                }
                                certificationRequiredComments={
                                    data?.projectSpecification?.certificationRequiredComments
                                }
                                projectDuration={data?.projectSpecification.duration}
                                projectTimeframe={data?.projectSpecification.timeFrame}
                                timeRequirement={data?.projectSpecification.timeRequirement}
                                projectSpecificationDetails={data?.projectSpecification}
                                projectCertificationDetails={data?.projectCertification}
                                projectDetails={data?.projectDetails}
                            />
                        </div>

                        <div className={!submitProposal ? "mt-4" : ""}>
                            <Document
                                submitProposal={submitProposal}
                                attachedDoc={data?.projectDocuments}
                                talent={"true"}
                                isPublic={isPublic}
                            />
                        </div>
                    </div>
                </div>
            }
            {
                hasAccess === 2 &&
                <>
                    <div className="forbiddenWrapper">
                        <div className="forbiddenBox">
                            <div>
                                <img src={Failed} className="mb-3" alt="" />{" "}
                                <Label
                                    className="font18Black boldFont"
                                    label="Not Found"
                                ></Label>
                                <div className="forbiddenText">Job Not Found</div>
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    )
}

export default TalentsJobDetails;