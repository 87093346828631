import Axios from "../util/axiosInstance";

export const ChangePrimaryEmailAPI = (payload) => {
  return Axios.post("/api/User/ChangeEmail", payload)
};

export const ChangePrimaryPasswordAPI = (payload) => {
  if(JSON.stringify(payload) !== '{}'){
    return Axios.post("/api/User/ChangePassword", payload)
  }
};