import React, { useState } from "react";
import {
  DownAccord,
  upArrow
} from "../../../../../assets/images";
import { convertTime } from "../../../../../util/dateTime";
import "./timelineTable.scss";

const TimelineMobileTable = ({
  timeLineData,
}) => {
  const [openedColumns, setOpenedColumns] = useState([]);

  const toggleRows = (rowId) => {
    if (openedColumns.includes(rowId)) {
      const index = openedColumns.indexOf(rowId);
      if (index > -1) {
        openedColumns.splice(index, 1);
      }
      setOpenedColumns(openedColumns);
    } else {
      openedColumns.push(rowId);
    }
    const openColumns = [...openedColumns];
    setOpenedColumns(openColumns);
  };

  const convertDate = (date) => {
    const dateObj = new Date(date);
    const dateStr = dateObj?.toDateString();
    const readableDateFormat = dateStr.slice(4, date?.length);
    const final =
      readableDateFormat?.substring(0, 6) +
      "," +
      readableDateFormat?.substring(6, readableDateFormat?.length);
    return final;
  };

  return (
    <>
      <div className="dataContainerSchedular">
        {timeLineData?.map((data, index) => {
          return (
            <React.Fragment key={index}>
              <div className="tableRow">
                <span className="jobPostvisibleTimeLineDataSchedule font-weight-bold">
                  Date:{" "}
                  <span className="jobPostvisibleTimeLineDataSchedule">
                    {convertDate(data.projectDate)}
                  </span>
                </span>
                <span>
                  <img
                    src={openedColumns.includes(data.projectDateID) ? upArrow : DownAccord}
                    onClick={() => toggleRows(data.projectDateID)}
                    alt=""
                  />
                </span>
              </div>
              {openedColumns.includes(data.projectDateID) && (
                <>
                  <div className="jobPosthiddenRecords">
                    <div className="times">
                      <div className="jobPosthiddenTimelineRows">
                        <span className="jobPosttimelinehiddenRowHeaders mr-1">
                          Start Time
                        </span>
                        <div className="hiddenRowsData">
                          {convertTime(data.startTime)}
                        </div>
                      </div>
                      <div className="jobPosthiddenTimelineRows">
                        <span className="jobPosttimelinehiddenRowHeaders mr-1">
                          End Time
                        </span>
                        <div className="hiddenRowsData">
                          {convertTime(data.endTime)}
                        </div>
                      </div>
                    </div>
                    <div className="timeZone">
                      <div className="jobPosthiddenTimelineRows">
                        <span className="jobPosttimelinehiddenRowHeaders">Time Zone</span>
                        <div className="hiddenRowsData">{data.timeZone}</div>
                      </div>

                    </div>
                  </div>
                </>
              )}
            </React.Fragment>
          );
        })}
      </div>
    </>
  );
};

export default TimelineMobileTable;
