export const TOGGLE_NOTIFICATION_EXIT = "NOTIFICATION_EXIT"

export const initialState = {
    exit: false
}

const toggleNotificationExitReducer = (state = initialState, action) => {
    const { type } = action;
    switch (type) {
        case TOGGLE_NOTIFICATION_EXIT:
            return {
                exit: !state.exit
            }
        default:
            return state;
    }
}

export const togglenotificationExit = () => {
    return (dispatch) => {
        dispatch({
            type: TOGGLE_NOTIFICATION_EXIT,
        });
    };
};
export default toggleNotificationExitReducer;