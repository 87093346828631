/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router";
import classnames from "classnames";
import JobDescription from "./components/JobDescription";
import {
  GetProposalDetail,
  GetFreelancerDetail,
  GetFreelancerReview,
  GetSubmittalDetail,
  GetTalentProfile,
  UpdateNotes,
  GetNotes,
} from "../../../services/freelancer";
import { Failed } from "../../../assets/images";
import { convertDate } from "../../../util/dateTime";
import Label from "../../../components/label";
import Certification from "./components/Certification";
import Courses from "./components/Courses";
import Education from "./components/Education";
import AboutMe from "./components/AboutMe";
import RolesAndSkills from "./components/RolesAndSkills";
import WorkExperience from "./components/WorkExperience";
import LanguagesSection from "./components/Languages";
import TestimonialsAndReview from "./components/TestimonialAndReview";
import UserDetails from "./components/UserDetail";
import UserJobs from "./components/Jobs";
import FormTextarea from "../../../components/formTextarea/FormTextarea";
import Button from "../../../components/button";
import ProposalHeader from "./components/ProposalHeader";
import PortfolioSection from "../../Dashboard/Components/Portfolio";
import "./index.scss";

const FreelanceDetail = ({
  instrId,
  handleAcceptClick,
  projectId,
  handleInterviewClick,
  rejectTalent,
  tab,
  isFavorite,
  cancelInterview,
  removeFromRejected,
  talentDetails,
  viewAllTalentJobs,
  fromAccepted,
  refreshList,
  completed,
  fromTalent,
  client,
  preview
}) => {
  const [proposalData, setProposalData] = useState();
  const [freelancer, setFreelancer] = useState({
    portfolioCourses: [],
    portfolioItems: [],
    portfolioLinks: [],
    educations: [],
    workExperiances: [],
    certifications: [],
    profileRoles: [],
    industryExperiances: [],
    languages: []
  });
  const params = useParams();
  const [review, setReview] = useState();
  const [apiError, setApiError] = useState(false);
  const [notes, setNotes] = useState("")
  const [notesSaved, setNotesSaved] = useState(false);
  const [lastUpdated, setLastUpdated] = useState(null)
  const history = useHistory()
  const queryString = history.location.search
  const searchParams = new URLSearchParams(queryString);
  const lowerCaseParams = new URLSearchParams(
    Array.from(searchParams, ([key, value]) => [key.toLowerCase(), value])
  );
  const instrIDParam = instrId || parseInt(lowerCaseParams.get("instrid"));
  const projectIDParam = projectId || lowerCaseParams.get("project_id");
  const hash = params.hash || lowerCaseParams.get("hash");
  
  useEffect(() => {
    if ((projectIDParam && hash && instrIDParam && history.location.pathname === "/preview") || preview) {
      fetchPreviewDetails()
    } else if (history.location.pathname === "/preview" || preview) {
      setApiError(true)
    } else if (tab === "Client") {
      fetchTalentProfile();
      getNotes()
    } else {
      fetch()
      getNotes()
    }
  }, []);

  const fetchPreviewDetails = () => {
    const params = {
      projectID: projectIDParam,
      instrID: instrIDParam,
      hash: hash
    }
    GetSubmittalDetail(params)
      .then((res) => {
        setFreelancer(res.data.slicedTrainerProfile);
        setProposalData(res.data.talentProjectDetails);
        //setReview(res.data.talentFeedback) // 01/26/2024 Commented out by Mtarga, reviews are not enabled yet

      })
      .catch((err) => {
        setApiError(true)
      })
  }
  const fetch = () => {
    const param = {
      projectID: projectIDParam,
      instrID: instrId,
    };
    GetFreelancerDetail(param).then((response) => {
      setFreelancer({
        ...response.data,
        testimonials: response.data?.testimonials?.filter((res) => {
          if (res.isResponsePublic) {
            return res
          }
        })
      });

    });
    GetProposalDetail(param).then((response) => {
      setProposalData(response.data);
    });
    GetFreelancerReview(param).then((response) => {
      setReview(response.data);
    });
  };

  const fetchTalentProfile = () => {
    GetTalentProfile(instrId)
      .then((res) => {
        setFreelancer(res.data)
      })
    GetFreelancerReview({ instrID: instrId }).then((response) => {
      setReview(response.data);
    });
  }

  const getNotes = () => {
    const params = {
      projectId: parseInt(projectIDParam) || null,
      instrId: instrId
    }
    GetNotes(params)
      .then((res) => {
        setNotes(res.data?.notes)
        setLastUpdated(res.data?.notesDateUpdated)
      })
  }

  const saveNotes = () => {
    const payload = {
      instrId: instrId,
      note: notes,
      projectId: parseInt(projectIDParam) || null
    }
    UpdateNotes(payload)
      .then(() => {
        setNotesSaved(true)
        setLastUpdated(new Date())
      })
  }
  const componentRef = React.createRef();
  
  return (
    <div>
      {
        apiError ?
          <>
            <div className="forbiddenWrapper">
              <div className="forbiddenBox">
                <div>
                  <img src={Failed} className="mb-3" alt="" />{" "}
                  <Label
                    className="smallHeaderTextSmallFont"
                    label="Error"
                  ></Label>
                  <div className="forbiddenText">
                    Invalid Credentials Provided
                  </div>
                </div>
              </div>
            </div>
          </> :
          <div ref={componentRef}>
            <JobDescription
              proposalData={proposalData}
              tab={tab}
            />
            <div className="d-flex justify-content-center pb-3">
              <div className={classnames((client && !fromTalent) ? "clientWrap" : "userDetailsWrap")}>
                <div className={(!fromTalent || client) ? "firstContainerWidth" : "w-100"}>
                  <div className={(fromTalent && !client) ? "wrapperProposalFreelancer ml-0" : "wrapperProposalFreelancer"}>
                    {
                      !fromTalent &&
                      <>
                        <ProposalHeader
                          proposalData={proposalData}
                          instrID={instrId}
                          projectID={projectIDParam}
                          handleAcceptClick={handleAcceptClick}
                          handleInterviewClick={handleInterviewClick}
                          rejectTalent={rejectTalent}
                          tab={tab}
                          isFavorite={isFavorite}
                          cancelInterview={cancelInterview}
                          removeFromRejected={removeFromRejected}
                          hideOptions={projectIDParam}
                          refreshList={refreshList}
                          completed={completed}
                          client={client}
                        />
                        <div className="border-top freelanceSep" />
                      </>
                    }
                    <UserDetails
                      componentRef={componentRef}
                      proposalData={proposalData}
                      myInformation={freelancer?.myInformation}
                      projectID={projectIDParam}
                      instrID={instrId}
                      handleAcceptClick={handleAcceptClick}
                      handleInterviewClick={handleInterviewClick}
                      rejectTalent={rejectTalent}
                      refreshList={refreshList}
                      tab={tab}
                      isFavorite={isFavorite}
                      cancelInterview={cancelInterview}
                      removeFromRejected={removeFromRejected}
                      hideOptions={projectIDParam}
                      fromAccepted={fromAccepted}
                      completed={completed}
                      fromTalent={fromTalent}
                      client={client}
                      aboutMe={freelancer?.aboutMe}
                    />
                  </div>
                  <div className="freelanceSecondWrapper">
                    {
                      (!fromTalent || client) &&
                      <div className="freelancerModalComponentWrap pt-3 mobileView">
                        <div className="font19Black boldFont">
                          Your Notes
                        </div>
                        <div className="font14Grey">
                          This section is only visible to you
                        </div>
                        <FormTextarea
                          rows={10}
                          name="notes"
                          label=""
                          onChange={
                            (e) => {
                              setNotesSaved(false)
                              setNotes(e.target.value)
                            }
                          }
                          containerStyle={{ marginBottom: 0 }}
                          value={notes}
                          placeholder="Write your notes about the candidate here."
                        />
                        <div className="font14Grey mt-2">
                          Last Updated: {convertDate(lastUpdated)}
                        </div>
                        <Button
                          label={notesSaved ? "Notes Updated" : `Save`}
                          className="btn-blue w-100 mt-3 mb-3"
                          onClick={() => saveNotes()}
                          disabled={notesSaved}
                        />
                      </div>
                    }
                    {
                      talentDetails?.noOfJobs > 0 &&
                      <>
                        <UserJobs
                          data={talentDetails}
                          viewAllTalentJobs={viewAllTalentJobs}
                          fromTalent={fromTalent}
                          client={client}
                        />
                      </>
                    }
                    {
                      <div className="talentProfileWrap font18Black boldFont">Talent Profile</div>
                    }
                    {
                      freelancer?.aboutMe && !client &&
                      <AboutMe aboutMe={freelancer?.aboutMe} fromTalent={fromTalent} client={client}/>
                    }
                    {
                      [...freelancer?.profileRoles, ...freelancer?.industryExperiances].length > 0 &&
                      <RolesAndSkills
                        profileRoles={freelancer?.profileRoles}
                        industry={freelancer?.industryExperiances}
                        fromTalent={fromTalent}
                        client={client}
                      />
                    }
                    {
                      freelancer?.languages.length > 0 &&
                      <LanguagesSection languages={freelancer?.languages} fromTalent={fromTalent} client={client}/>
                    }

                    {
                      [...freelancer?.portfolioCourses,
                      ...freelancer?.portfolioLinks,
                      ...freelancer?.portfolioItems].length > 0 &&
                      <PortfolioSection
                        edit={false}
                        courses={[]}
                        linksAndVideos={freelancer?.portfolioLinks}
                        portfolioItems={freelancer?.portfolioItems}
                        setRefresh={() => { }}
                        fromFreelanceModal={true}
                        fromTalent={fromTalent}
                        instrId={instrId}
                        client={client}
                        projectId={projectIDParam}
                        preview={preview}
                      />
                      }
                    {
                      freelancer?.portfolioCourses?.length > 0 &&
                      <Courses courses={freelancer?.portfolioCourses} fromTalent={fromTalent} instrId={instrId} client={client} preview={preview} />
                    }
                    {
                      freelancer?.workExperiances?.length > 0 &&
                      <WorkExperience workExperience={freelancer?.workExperiances} fromTalent={fromTalent} client={client}/>
                    }
                    {
                      freelancer?.educations?.length > 0 &&
                      <Education Education={freelancer?.educations} fromTalent={fromTalent} client={client}/>
                    }
                    {
                      freelancer?.certifications?.length > 0 &&
                      <Certification Certifications={freelancer?.certifications} fromTalent={fromTalent} client={client}/>
                    } 
                    {
                      (review?.length > 0 || freelancer?.testimonials?.length > 0) &&
                      <TestimonialsAndReview
                        review={review}
                        fromTalent={fromTalent}
                        testimonials={{
                          "12321": freelancer?.testimonials
                        }}
                        instrId={"12321"}
                        client={client}
                      />
                    }
                  </div>
                </div>
                {
                  (!preview  && (!fromTalent || client)) &&
                  <div className="notesWrap desktopView">
                    <div className="font19Black boldFont">
                      Your Notes
                    </div>
                    <div className="font14Grey">
                      This section is only visible to you
                    </div>
                    <FormTextarea
                      rows={15}
                      name="notes"
                      label=""
                      onChange={
                        (e) => {
                          setNotesSaved(false)
                          setNotes(e.target.value)
                        }
                      }
                      containerStyle={{ marginBottom: 0 }}
                      value={notes}
                      placeholder="Write your notes about the candidate here."
                    />
                    <div className="font14Grey mt-2">
                      Last Updated: {convertDate(lastUpdated)}
                    </div>
                    <Button
                      label={notesSaved ? "Notes Updated" : `Save`}
                      className="btn-blue w-100 mt-3 mb-3"
                      onClick={() => saveNotes()}
                      disabled={notesSaved}
                    />
                  </div>
                }
              </div>
            </div>
          </div>
      }
    </div>
  );
};

export default FreelanceDetail;