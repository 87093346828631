import Axios from "../util/axiosInstance";

export const AddVideoAPI = (payload) => {
  return Axios.post("/api/Portfolio/AddLink", payload);
};

export const GetVideoAPI = (instrId) => {
  if (instrId) {
    return Axios.get(`/api/Portfolio/GetAllLinks?instrId=${instrId}`);
  } else {
    return Axios.get(`/api/Portfolio/GetAllLinks?type=All`);

  }
};

export const DeleteVideoAPI = (id, instrId) => {
  return Axios.delete(`/api/Portfolio/DeleteLink?WebIDToDelete=${id}&instrId=${instrId}`);
};

export const UpdateVideoAPI = (payload) => {
  return Axios.put(`/api/Portfolio/UpdateLink`, payload);
};

export const AddVideoRolesAPI = (payload) => {
  return Axios.post(`/api/Portfolio/AddLinkRoles`, payload);
};

export const DeleteVideoRolesAPI = (payload) => {
  return Axios.put(`/api/Portfolio/DeleteLinkRoles`, payload);
};


export const AddItemAPI = (payload) => {
  return Axios.post("/api/Portfolio/AddItem", payload);
};

export const GetItemAPI = (instrId) => {
  if (instrId) {
    return Axios.get(`/api/Portfolio/GetAllItems?instrId=${instrId}`);
  } else {
    return Axios.get(`/api/Portfolio/GetAllItems?type=All`);
  }
};

export const DeleteItemAPI = (id, instrId) => {
  return Axios.delete(`/api/Portfolio/DeleteItem?DocIDToDelete=${id}&instrId=${instrId}`);
};

export const UpdateItemAPI = (payload) => {
  return Axios.put(`/api/Portfolio/UpdateItem`, payload);
};

export const AddItemRolesAPI = (payload) => {
  return Axios.post(`/api/Portfolio/AddItemRoles`, payload);
};

export const DeleteItemRolesAPI = (payload) => {
  return Axios.put(`/api/Portfolio/DeleteItemRoles`, payload);
};

export const GetURLTypeAPI = () => {
  return Axios.get("/api/Master/URLTypes");
};

export const AddCourseAPI = (payload) => {
  return Axios.post("/api/Portfolio/AddCourse", payload);
};

export const EditCourseAPI = (payload) => {
  return Axios.put("/api/Portfolio/UpdateCourse", payload);
};

export const GetCourseAPI = (instrId) => {
  if (instrId) {
    return Axios.get(`/api/Portfolio/GetAllCourses?instrId=${instrId}`);
  } else {
    return Axios.get(`/api/Portfolio/GetAllCourses?type=All`);
  }
};

export const DeleteCourseAPI = (id, instrId) => {
  return Axios.delete(`api/Portfolio/DeleteCourse?CourseIDToDelete=${id}&instrId=${instrId}`);
};

export const UpdateCourseSkillsAPI = (payload) => {
  return Axios.post("/api/Portfolio/AddCourseSkills", payload)
};

export const DeleteCourseSkillsAPI = (payload) => {
  return Axios.put("/api/Portfolio/DeleteCourseSkills", payload)
}

export const UploadThumbnailPut = (entityType, entityId, payload, progressConfig, instrId) => {
  let url = `/api/Blob/UploadThumbnail?entityType=${entityType}&entityId=${entityId}`
  if (instrId) {
    url = `/api/Blob/UploadThumbnail?entityType=${entityType}&entityId=${entityId}&instrId=${instrId}`
  }
  return Axios.put(url, payload, progressConfig)
}

export const UploadCompanyLogo = (entityType, payload, progressConfig) => {
  return Axios.put(`/api/Blob/UploadThumbnail?entityType=${entityType}`, payload, progressConfig)
}

export const DownloadDocGet = (guid) => {
  return Axios.get(`/api/Blob/Get?blobNameGUID=${guid}`, { responseType: "arraybuffer" })
}

export const UploadDocumentPut = (entityType, entityId, payload, progressConfig, instrId) => {
  let url = `/api/Blob/UploadDocument?entityType=${entityType}&entityId=${entityId}`
  if (instrId) {
    url = `/api/Blob/UploadDocument?entityType=${entityType}&entityId=${entityId}&instrId=${instrId}`
  }
  return Axios.put(url, payload, progressConfig)
}

export const DownloadBlobGet = (guid, onProgress) => {
  return Axios.get(`/api/Blob/Get?blobNameGUID=${guid}`, {
    responseType: "blob",
    onDownloadProgress: (progressEvent) => {
      const progress = Math.round(
        (progressEvent.loaded / progressEvent.total) * 100
      );
      if (onProgress) {
        onProgress(progress);
      }
    },
  });
};

export const DownloadChatDocument = (guid, entityType, entityId, onProgress, TTARep = false) => {
  return Axios.get(`/api/Blob/GetByEntity?blobNameGUID=${guid}&entityName=${entityType}&entityId=${entityId}`, {
    responseType: "blob",
    onDownloadProgress: (progressEvent) => {
      const progress = Math.round(
        (progressEvent.loaded / progressEvent.total) * 100
      );
      onProgress(progress);
    },
  });
};


export const DownloadBlobByEntity = (guid, entityType, entityId) => {
  return Axios.get(`/api/Blob/GetByEntity?blobNameGUID=${guid}&entityName=${entityType}&entityId=${entityId}`, { responseType: "arraybuffer" })
}

export const DownloadDocByEntity = (guid, entityType, entityId) => {
  return Axios.get(`/api/Blob/GetByEntity?blobNameGUID=${guid}&entityName=${entityType}&entityId=${entityId}`, { responseType: "blob" })
}