/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

const ShowMore = ({
    lines,
    text,
    textClass,
    id,
    more = "More",
    less = "Less",
    anchorClass = "font14Blue",
    lineHeight = 21,
    onClick = () => { },
    showMoreClick = () => { }
}) => {

    const [style, setStyle] = useState({})
    const [showMore, setShowMore] = useState(false)
    const [showMoreText, setShowMoreText] = useState(false)

    function waitForElm(selector) {
        return new Promise(resolve => {
            if (document.querySelector(selector)) {
                return resolve(document.querySelector(selector));
            }

            const observer = new MutationObserver(mutations => {
                if (document.querySelector(selector)) {
                    resolve(document.querySelector(selector));
                    observer.disconnect();
                }
            });

            observer.observe(document.body, {
                childList: true,
                subtree: true
            });
        });
    }

    const executeAfterLoad = () => {
        waitForElm("#" + id).then((elm) => {
            checkOverFlow(id)
        })
    }


    const checkOverFlow = (elem) => {
        let element = document.getElementById(elem);
        if (element) {
            setShowMoreText(element.scrollHeight !== Math.max(element.offsetHeight, element.clientHeight))
        }
    }

    useEffect(() => {
        executeAfterLoad()
    }, [])

    useEffect(() => {
        if (showMore) {
            setStyle({ whiteSpace: "pre-wrap" })
        } else if (showMoreText) {
            setStyle({
                height: `${lineHeight * lines}px`,
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "pre-wrap"
            })
        } else {
            setStyle({
                height: `${lineHeight}px`,
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "pre-wrap"
            })
        }
    }, [showMore, showMoreText])

    return (
        <>
            <div>
                <div className={textClass} style={style} id={id} onClick={onClick}>
                    {text}
                </div>
                {
                    showMoreText &&
                    <span className={anchorClass} onClick={() => {
                        setShowMore(!showMore)
                        showMoreClick()
                    }}>
                        {
                            showMore ?
                                less :
                                "..." + more
                        }
                    </span>
                }
            </div>
        </>
    )
}
export default ShowMore;