import React, { Fragment, useState } from "react";
import Label from "../../../../../components/label";
import ViewPortfolioModal from "../../../../Dashboard/Components/viewPortfolioModal";
import "./index.scss";

const Courses = ({ courses, fromTalent, instrId, client, preview }) => {
  const [courseViewData, setCourseViewData] = useState({
    showModal: false,
  });
  const [itemsToShow, setitemsToShow] = useState(10);
  const [expanded, setExpanded] = useState(false);


  const toggleViewModal = () => {
    setCourseViewData({
      showModal: false,
    })
  }

  const showMore = () => {
    itemsToShow === 10 ? setitemsToShow(courses?.length) : setitemsToShow(10);
    itemsToShow === 10 ? setExpanded(true) : setExpanded(false);
  };

  return (
    <Fragment>
      <ViewPortfolioModal
        viewPortfolioData={courseViewData}
        toggleModal={toggleViewModal}
        edit={false}
        fromClient={true}
        instrId={instrId}
        client={client}
        fromCourse={true}
        preview={preview}
      />
      <div className={(fromTalent && !client) ? "freelancerModalComponentWrap ml-0" : "freelancerModalComponentWrap"}>
        <div className="d-flex justify-content-between">
          <Label
            className="font18Black boldFont mt-3 mb-0"
            label="Courses"
          ></Label>
        </div>
        <div className="courseGrid mt-2">
          {courses?.slice(0, itemsToShow)?.map((course, index) => {
            return (
              <div key={index} className={(index % 2 === 0 && courses?.length > 1) ? "courseListDiv borderRight" : "courseListDiv pl-3"}>
                <div className="border-right" />
                <div className="d-flex justify-content-between align-items-center w-100">
                  <div className="d-flex align-items-center">
                    <span className="courseListDot " />
                    <span
                      key={course?.courseID}
                      className="font14Blue"
                      onClick={() => {
                        setCourseViewData({
                          showModal: true,
                          title: course?.title,
                          description: course?.description,
                          type: "File",
                          courseOutline: course?.courseOutline,
                          portfolioData: {
                            blobFileName: course?.blobFileName
                          }
                        });
                      }}
                    >
                      {course?.title}
                    </span>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        {courses?.length > 10 && (
          <>
            <div className="profileEducationMore justify-content-center text-align-center mt-2">
              <span className="profileEducationMore" onClick={showMore}>
                {expanded === true ? "Less" : "More"}
              </span>
            </div>
          </>
        )}
      </div>
    </Fragment>
  );
}

export default Courses;
