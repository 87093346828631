
export const navbarMenuLink = [

    {
        name: 'Documents',
        path: '/documents/1',
        legacy: false
    },
    {
        name: 'Profile Builder',
        path: '/profile/my-information'
    },
    {
        name: 'Payments',
        path: '/all-payments',
    },
    {
        name: 'Logout',
        path: '/logout',
        legacy: false
    }
]
