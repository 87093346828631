import moment from "moment";

// Returns the time in 12 hour format
export const convertTime = (timeString) => {
  if (!timeString) return '--';
  const [time, modifier] = timeString.split(' ');
  if (modifier === "AM" || modifier === "PM") {
    return timeString;
  }
  const [hours, minutes] = time.split(':');
  const formattedHours = hours > 12 ? hours - 12 : hours;
  const ampm = hours >= 12 ? "PM" : "AM";
  return `${formattedHours}:${minutes} ${ampm}`;
};

// Returns the date in format of MMM DD, YYYY or MMM DD, YYYY, h:mm A
export const convertDate = (date, showTime) => {
  if (!date) return '--';

  const momentObj = moment(new Date(date));
  const dateStr = momentObj.format('MMM DD, YYYY');
  const timeStr = momentObj.format('h:mm A');

  if (showTime) {
    return `${dateStr}, ${timeStr}`;
  } else {
    return dateStr;
  }
};

// Returns the date in format of MM/DD/YYYY or MM/DD/YYYY h:mm A
export const convertDateToUSFormat = (date, showTime) => {
  if (!date) return '--';

  const momentObj = moment(date);
  const dateStr = momentObj.format('MM/DD/YYYY');
  const timeStr = momentObj.format('h:mm A');

  if (showTime) {
    return `${dateStr} ${timeStr}`;
  } else {
    return dateStr;
  }
};
export const convertDateToLocal = (dateString) => {
  // Returns date in format of YYYY-MM-DD
  const date = new Date(dateString);
  const localDate = new Date(date.getTime() - (date.getTimezoneOffset() * 60000));
  const localDateString = localDate.toISOString().slice(0, 10);
  return localDateString;
};

export const isDateWithinDaysDiff = (date, daysDiff) => {
  const date1 = moment();
  const date2 = moment(date);
  const diffDays = date1.diff(date2, 'days');
  return diffDays <= daysDiff;
};


// call this function, passing-in your date
export function dateToFromNowDaily(myDate) {

  // get from-now for this date
  // var fromNow = moment(myDate).fromNow();

  // ensure the date is displayed with today and yesterday
  return moment(myDate).calendar(null, {
    // when the date is closer, specify custom values
    lastWeek: 'ddd, MMMM DD YYYY',
    lastDay: '[Yesterday]',
    sameDay: '[Today]',
    nextDay: '[Tomorrow]',
    nextWeek: 'dddd',
    // when the date is further away, use from-now functionality             
    sameElse: 'ddd, MMMM DD YYYY',
  });
}


export function dateFromNowToday(myDate) {
  // var fromNow = moment(myDate).fromNow()

  return moment(myDate).calendar(null, {
    // when the date is closer, specify custom values
    lastWeek: 'MM/DD/YY',
    lastDay: '[Yesterday]',
    sameDay: function (now) {
      var diff = moment.duration(moment(now).diff(myDate));
      var hours = Math.abs(Math.floor(diff.asHours()));
      var minutes = Math.abs(diff.minutes());
      var seconds = Math.abs(diff.seconds());
      var formattedTime = "";

      if (hours >= 1) {
        formattedTime += hours + "h ";
      } else if (minutes >= 1 && hours === 0) {
        formattedTime += minutes + "m ";
      } else {
        formattedTime += seconds + "s ";
      }

      return "[" + formattedTime.trim() + "]";
    },
    nextDay: '[Tomorrow]',
    nextWeek: 'dddd',
    // when the date is further away, use from-now functionality             
    sameElse: function (value) {
      return moment(value).format('MM/DD/YY');
    }
  });
}

export const convertMinToHours = (minutes) => {
  if (minutes > 59) {
    const hours = Math.floor(minutes / 60)
    const min = minutes % 60
    if (min > 0) {
      return `${hours}h ${min}m`
    } else {
      return `${hours}h`
    }
  } else if (minutes < 59 && minutes > 0) {
    return `${0}h ${minutes}m`
  } else if (minutes === 0) {
    return "0h"
  } else {
    return null
  }
}

export const convertHoursToMin = (hours) => {
  if (hours > 0) {
    const minutes = Math.floor(hours * 60)
    return minutes
  }
  return 0
}