import { convertDate } from "./dateTime"

export const getJobAddressString = (job, noHeader, showLocationHeader) => {
    const tempArray = []
    if ((job?.locationType?.toLowerCase() === "remote") || (job?.locationType?.toLowerCase() === "virtual")) {
        if (noHeader) {
            tempArray.push(job?.locationType)
        } else {
            tempArray.push(`Location: ${job?.locationType}`)
        }

        if (job?.startDate && job?.endDate) {
            if(showLocationHeader) {
                tempArray.push("Job Date(s): " + convertDate(job?.startDate) + " - " + convertDate(job?.endDate))
            } else {
                tempArray.push(convertDate(job?.startDate) + " to " + convertDate(job?.endDate))
            }
        }
        if (job?.startDate && !job?.endDate) {
            if(showLocationHeader) {
                tempArray.push("Job Date(s): " + convertDate(job?.startDate))
            } else {
                tempArray.push(convertDate(job?.startDate))
            }
        }
        return tempArray.join(' - ');
    } else {
        if (job?.nearCity) {
            tempArray.push(job.nearCity)
        }
        if (job?.state) {
            tempArray.push(job.state)
        }
        if (job?.country) {
            tempArray.push(job.country)
        }
        if (job?.startDate && job?.endDate) {
            if(showLocationHeader) {
                tempArray.push("Job Date(s): " + convertDate(job?.startDate) + " - " + convertDate(job?.endDate))
            } else {
                tempArray.push(convertDate(job?.startDate) + " to " + convertDate(job?.endDate))
            }
        }
        if (job?.startDate && !job?.endDate) {
            if(showLocationHeader) {
                tempArray.push("Job Date(s): " + convertDate(job?.startDate))
            } else {
                tempArray.push(convertDate(job?.startDate))
            }
        }
        if(showLocationHeader) {
            return "Location: " + tempArray.join(', ')
        }
        return tempArray.join(', ');
    }

}

export const getAddressOnly = (job, noHeader) => {
    const tempArray = []
    if ((job?.locationType === "Remote") || (job?.locationType === "Virtual")) {
        if (noHeader) {
            tempArray.push(job?.locationType)
        } else {
            tempArray.push(`Location: ${job?.locationType}`)
        }

        if (job?.startDate) {
            tempArray.push(convertDate(job?.startDate) + " to " + convertDate(job?.endDate))
        }
        return tempArray.join(' - ');
    } else {
        if (job?.nearCity || job?.city) {
            tempArray.push(job.nearCity || job.city)
        }
        if (job?.state) {
            tempArray.push(job.state)
        }
        if (job?.country) {
            tempArray.push(job.country)
        }
        return tempArray.join(', ');

    }
}