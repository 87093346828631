/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";

const useClick = (callback,ref) => {
  useEffect(() => {
    document.addEventListener("click", callback, true);
    return () => {
      document.removeEventListener("click", callback, true);
    };
  }, [ref]);
}

export const useGlobalClick = (callback,ref) => {
    return useClick( callback,ref);
};