export const UPDATE_AGENCY_MEMBER = "UPDATE_AGENCY_MEMBER"

export const initialState = ""

const currentAgencyMemberReducer = (state=initialState, action) => {
    const { type, payload } = action;
    switch(type) {
      case UPDATE_AGENCY_MEMBER:
        return payload
      default:
        return state;
    }
}

export const setCurrentAgencyMember = (payload, cb=()=>{}) => {
    return (dispatch) => {
      dispatch({
        type: UPDATE_AGENCY_MEMBER,
        payload,
        cb,
      });
    };
};


export default currentAgencyMemberReducer;