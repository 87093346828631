import Axios from "../util/axiosInstance";

export const signUpApi = (payload) => {
  return Axios.post("/api/user/signup", payload)
 
};

export const emailExistsApi = (email) => {
  return Axios.get(`/api/User/EmailExists?Email=${email}`)
 
};