/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import $ from "jquery";
import { useForm, Controller } from "react-hook-form";
import classnames from "classnames";
import { useOnClickOutside } from "../../../components/navbar/Navbar";
import {
    GetAllSkills,
    GetSuggestedSkills,
    GetMySkills,
    postSkills,
    postAllSkillsInfo,
    deleteSkills,
    instructorPost,
    getAllRoleInfo,
    postUnregisteredSkill,
} from "../../../services/rolesandskills";
import { CirclePlus, iIcon } from "../../../assets/images";
import { PostRoles } from "../../../services/role";
import { GetCurrentAgencyMember } from "../../../util/reduxData";
import { useGlobalClick } from "../../../util/clickListener";
import { roleWithCourses } from "../../../constants/roleWithCourse";
import handleDropdownErrors from "../../../constants/handleErrorFields";
import BrowseAndSelectSkill from "../../../components/browseSkill/browseSkill";
import NumberFormat from "react-number-format";
import Tooltip from "../../../components/Tooltip";
import Checkbox from "../../../components/checkbox/Checkbox";
import Label from "../../../components/label";
import Button from "../../../components/button";
import Chips from "../../../components/chips/Chips";
import TextInputDropdown from "../../../components/textInputDropdownNew";
import TextInput from "../../../components/textInput";
import AddCustomSkills from "../../../components/addCustomSkill/addCustomSkill";
import CopySkillsFromRole from "../../../components/copySkill";
import "./index.scss";

const RoleForm = ({
    currentSelectedRoleData,
    onSaveSuccess,
    toggleModal,
    userRoles
}) => {
    const newRefSearchbox = useRef();
    const [roleFormData, setRoleFormData] = useState({})
    const [allSkills, setAllSkills] = useState([])
    const [selectedSkills, setSelectedSkills] = useState([])
    const [suggestedSkills, setSuggestedSkills] = useState([])
    const [selectedSuggestedSkills, setSelectedSuggestedSkills] = useState([])
    const [skillsToBeDeleted, setSkillsToBeDeleted] = useState([])
    const [allSuggestedCategories, setAllSuggestedCategories] = useState();
    const [newSkillModal, setNewSkillModal] = useState(false);
    const [selectedSkillsSoftCategories, setSelectedSkillsSoftCategories] = useState([]);
    const [selectedSkillsTechCategories, setSelectedSkillsTechCategories] = useState([]);
    const [popularSkills, setPopularSkills] = useState([])
    const [selectedPopularSkill, setSelectedPopularSkill] = useState([])
    const [searchInput, setSearchInput] = useState(undefined);
    const [result, setResult] = useState([]);
    const [, setChangeCheck] = useState(false)
    const [showAddCustomModal, setShowAddCustomModal] = useState(false);
    const [technicalCategory, setTechnicalCategory] = useState([])
    const [softSkillCategory, setSoftSkillCategory] = useState([])
    const [customSkillName, setCustomSkillName] = useState("");
    const [submitClicked, setSubmitClicked] = useState(false)
    const [allYears, setAllYears] = useState([])
    const currentInstrID = GetCurrentAgencyMember()
    const [myRoles, setMyRoles] = useState([])
    const [showCopySkillsModal, setShowCopySkillsModal] = useState(false)

    useEffect(() => {
        if (currentSelectedRoleData?.serviceCharge !== null) {
            let myRole = userRoles.filter(res => res?.myRole?.role !== currentSelectedRoleData?.role)
            let mySkills = myRole.filter(res => res?.mySkills.length > 0)
            setMyRoles(mySkills)
        } else {
            setMyRoles(userRoles)
        }
    }, [userRoles])

    const {
        register,
        handleSubmit,
        errors,
        setValue,
        control,
        clearErrors,
    } = useForm({ reValidateMode: "onBlur" });

    useEffect(() => {
        getAllSkillsOfRole()
        getSuggestedSkills()
        getAllRoleInfoFunc()
        getMySkills()
        getAllYears()
        setRoleFormData(currentSelectedRoleData)
        setSubmitClicked(false)
    }, [currentSelectedRoleData])

    useEffect(() => {
        const dropDownFields = ["rateType", "startDate"];
        handleDropdownErrors(dropDownFields, errors);
    }, [errors]);

    const getAllSkillsOfRole = () => {
        GetAllSkills(currentSelectedRoleData.roleId, currentInstrID)
            .then((res) => {
                setAllSkills(res.data)
                getSelectedAndPopularSkills(res.data)
                getAllCategories(res.data)
            })
    }

    const getAllRoleInfoFunc = () => {
        getAllRoleInfo(currentSelectedRoleData.roleId, currentInstrID).then(
            (response) => {
                setValue("startDate", response.data.startYear);
                setValue("rateType", response.data.rateType);
                setRoleFormData(response.data);
            }
        )
    };

    const getAllYears = () => {
        const allTempYears = [];
        const thisYear = new Date().getFullYear();
        for (let x = 0; x <= 80; x++) {
            allTempYears.push((thisYear - x).toString());
        }
        setAllYears(allTempYears)
    }

    const getSelectedAndPopularSkills = (data) => {
        const tempSelectedSkills = []
        const tempPopularSkills = []
        const selectedIds = []
        const selectedPopularSkill = []
        data.forEach((res) => {
            if (res.hasSkill) {
                selectedIds.push(res.skillId)
                tempSelectedSkills.push(res)
            }
            if (res.isPopularSkill) {
                tempPopularSkills.push(res)
            }
            if (res.isPopularSkill && res.hasSkill) {
                selectedPopularSkill.push(res)
            }
        })
        setSelectedPopularSkill([...selectedPopularSkill])
        setPopularSkills([...tempPopularSkills])
    }

    const getMySkills = () => {
        const tempTechCategory = []
        const tempSoftCategory = []
        GetMySkills(currentSelectedRoleData.roleId, currentInstrID)
            .then((res) => {
                const mySkills = []
                res.data.forEach((data) => {
                    mySkills.push({
                        ...data,
                        group: data.group || "Other"
                    })
                    if (data.discipline === "Technical" && !tempTechCategory.includes(data.group)) {
                        tempTechCategory.push(data.group || "Other")
                    } else if (data.discipline === "Soft Skills" && !tempSoftCategory.includes(data.group)) {
                        tempSoftCategory.push(data.group || "Other")
                    }
                })
                setSelectedSkills(mySkills)
                setSelectedSkillsSoftCategories(tempSoftCategory)
                setSelectedSkillsTechCategories(tempTechCategory)
            })

    }

    const getSuggestedSkills = () => {
        GetSuggestedSkills(currentSelectedRoleData.roleId)
            .then((res) => {
                const data = res.data
                const tempCategory = []
                const tempSuggestedSkills = []
                const tempSelectedSuggestedSkill = []
                data.forEach((skillData) => {
                    if (skillData.hasSkill) {
                        tempSelectedSuggestedSkill.push(skillData)

                    }
                    tempSuggestedSkills.push(skillData)
                    tempCategory.push(skillData.category)
                })
                setAllSuggestedCategories([...new Set(tempCategory)]);
                setSelectedSuggestedSkills([
                    ...selectedSuggestedSkills,
                    ...tempSelectedSuggestedSkill
                ])
                setSuggestedSkills(tempSuggestedSkills);
            })
    }

    const handleSkillChipClick = (skillData) => {
        setChangeCheck(true)
        const selectedSuggestedSkillIds = selectedSuggestedSkills.map((res) => {
            return res.skillId
        })
        const suggestedSkillIds = suggestedSkills.map((res) => {
            return res.skillId
        })
        const selectedPopularSkillIds = selectedPopularSkill.map((res) => {
            return res.skillId
        })
        const popularSkillIds = popularSkills.map((res) => {
            return res.skillId
        })
        if (selectedSuggestedSkillIds.includes(skillData.skillId) || selectedPopularSkillIds.includes(skillData.skillId)) {
            removeSelectedSkills(skillData)
        } else {
            if (suggestedSkillIds.includes(skillData.skillId)) {
                setSelectedSuggestedSkills([...selectedSuggestedSkills, { ...skillData, new: true }])
            }
            if (popularSkillIds.includes(skillData.skillId)) {
                setSelectedPopularSkill([
                    ...selectedPopularSkill,
                    { ...skillData, new: true }]
                )
            }
            setSelectedSkills([...selectedSkills, { ...skillData, new: true }])
        }
    }

    const removeSelectedSkills = (skillData) => {
        if (currentSelectedRoleData.role && roleWithCourses.includes(currentSelectedRoleData.role)) {
            const remainingSkills = selectedSkills.filter((res) => {
                if (res.group === skillData.group) {
                    return res
                }
            })
            if (remainingSkills.length === 1) {
                if (skillData.discipline === "Technical") {
                    const tempCat = [...selectedSkillsTechCategories]
                    const idx = tempCat.indexOf(skillData.group)
                    tempCat.splice(idx, 1)
                    setSelectedSkillsTechCategories(tempCat)
                } else {
                    const tempCat = [...selectedSkillsSoftCategories]
                    const idx = tempCat.indexOf(skillData.group)
                    tempCat.splice(idx, 1)
                    setSelectedSkillsSoftCategories(tempCat)
                }
            }
        }
        setChangeCheck(true)
        const selectedSuggestedSkillIds = selectedSuggestedSkills.map((res) => {
            return res.skillId
        })
        const selectedPopularSkillIds = selectedPopularSkill.map((res) => {
            return res.skillId
        })
        if (selectedPopularSkillIds.includes(skillData.skillId)) {
            const filteredPopularSkill = selectedPopularSkill.filter((res) => {
                return res.skillId !== skillData.skillId
            })
            setSelectedPopularSkill(filteredPopularSkill)
        }
        if (selectedSuggestedSkillIds.includes(skillData.skillId)) {
            const filteredSuggestedSkill = selectedSuggestedSkills.filter((res) => {
                return res.skillId !== skillData.skillId
            })
            setSelectedSuggestedSkills(filteredSuggestedSkill)
        }
        const filterSelectedSkill = selectedSkills.filter((res) => {
            return res.skill !== skillData.skill
        })
        setSkillsToBeDeleted([...skillsToBeDeleted, skillData])
        setSelectedSkills([...filterSelectedSkill])
    }
    const onBrowseSkillSave = (data) => {
        const selectedSuggestedSkillIds = selectedSuggestedSkills.map((res) => {
            return res.skillId
        })
        const suggestedSkillIds = suggestedSkills.map((res) => {
            return res.skillId
        })
        const popularSkillIds = popularSkills.map((res) => {
            return res.skillId
        })
        const selectedPopularSkillIds = selectedPopularSkill.map((res) => {
            return res.skillId
        })
        const alreadySelectedSkillIds = selectedSkills.map((res) => {
            return res.skillId
        })
        if (data.skillToBeRemoved.length > 0) {
            setChangeCheck(true)
            data.skillToBeRemoved.map((res) => {
                if (selectedSuggestedSkillIds.includes(res.skillId) || selectedPopularSkillIds.includes(res.skillId)) {
                    removeSelectedSkills(res)
                    return
                }
            })

            setChangeCheck(true)
            setSkillsToBeDeleted([
                ...skillsToBeDeleted,
                ...data.skillToBeRemoved
            ])
        }
        if (data.selectedSkillIds.length > 0) {
            setChangeCheck(true)
            const tempTechCategory = []
            const tempSoftCategory = []
            data.selectedSkillIds.map((res) => {
                if (res.discipline === "Technical" && !tempTechCategory.includes(res.group)) {
                    tempTechCategory.push(res.group)
                } else if (res.discipline === "Soft Skills" && !tempSoftCategory.includes(res.group)) {
                    tempSoftCategory.push(res.group)
                }
                if ((suggestedSkillIds.includes(res.skillId) && !selectedSuggestedSkillIds.includes(res.skillId))
                    ||
                    (popularSkillIds.includes(res.skillId) && !selectedPopularSkillIds.includes(res.skillId))) {
                    handleSkillChipClick(res)
                }
            })
            setSelectedSkillsSoftCategories(tempSoftCategory)
            setSelectedSkillsTechCategories(tempTechCategory)
            const tempSelectedSkills = []
            data.selectedSkillIds.map((res) => {
                if (!alreadySelectedSkillIds.includes(res.skillId)) {
                    tempSelectedSkills.push(res)
                }
            })
            setSelectedSkills([
                ...selectedSkills,
                ...tempSelectedSkills
            ])
        }
        setNewSkillModal(false)
    }

    const handlePopularSkillChipClick = (res) => {
        setChangeCheck(true)
        const selectedPopularSkillIds = selectedPopularSkill.map((res) => {
            return res.skillId
        })
        if (selectedPopularSkillIds.includes(res.skillId)) {
            handleSkillChipClick(res)
        } else {
            const tempTechCategory = [...selectedSkillsTechCategories]
            const tempSoftCategory = [...selectedSkillsSoftCategories]
            if (res.discipline === "Technical" && !tempTechCategory.includes(res.group)) {
                tempTechCategory.push(res.group)
            } else if (res.discipline === "Soft Skills" && !tempSoftCategory.includes(res.group)) {
                tempSoftCategory.push(res.group)
            }
            setSelectedSkillsSoftCategories(tempSoftCategory)
            setSelectedSkillsTechCategories(tempTechCategory)
            setSelectedSkills([
                ...selectedSkills,
                res
            ])
            setSelectedPopularSkill([
                ...selectedPopularSkill,
                res
            ])
        }
    }

    const handleCertifiedToTeachCheck = (e, skillData) => {
        setChangeCheck(true)
        const tempArr = [...selectedSkills]
        const index = selectedSkills.indexOf(skillData)
        tempArr[index] = {
            ...skillData,
            certifiedToTeach: e.target.checked
        }
        setSelectedSkills(tempArr)
    }

    const handleOwnCourseMaterialCheck = (e, skillData) => {
        setChangeCheck(true)
        const tempArr = [...selectedSkills]
        const index = selectedSkills.indexOf(skillData)
        tempArr[index] = {
            ...skillData,
            ownCourseMaterial: e.target.checked
        }
        setSelectedSkills(tempArr)
    }

    const getAllCategories = (allSkillsData) => {
        const allTechCategory = []
        const allSoftCategory = []
        allSkillsData.filter((res) => {

            if (res.discipline === "Technical" && !allTechCategory.includes(res.group || "Other") && res.skill) {
                allTechCategory.push(res.group || "Other")
            }
            if (res.group && res.discipline === "Soft Skills" && !allSoftCategory.includes(res.group) && res.skill) {
                allSoftCategory.push(res.group)
            }
        })
        setTechnicalCategory(allTechCategory)
        setSoftSkillCategory(allSoftCategory)
    }

    const handleSkillResultClick = (skillData) => {
        const selectedSkillsIds = selectedSkills.map((res) => {
            return res.skillId
        })
        if (selectedSkillsIds.includes(skillData.skillId)) {
            setResult([]);
            setSearchInput(undefined);
            return
        } else {
            onBrowseSkillSave({
                selectedSkillIds: [skillData, ...selectedSkills],
                skillToBeRemoved: []
            })
            setResult([]);
            setSearchInput(undefined);
        }
    };

    const handleKeyPress = (e) => {
        if (e.which === 13) {
            e.preventDefault();
            if (result.length > 0) {
                result?.slice(0, 1)?.map((value) => {
                    handleSkillResultClick(value);
                });
                setResult([]);
                setSearchInput("");
            } else if (result?.length === 0 && searchInput) {
                addCustomSkills(searchInput)
            }
            setResult([]);
            setSearchInput("");
        }
    };

    const handleSearchInput = (e) => {
        setSearchInput(e.target.value);
        if (e.target.value.length > 0) {
            const results = [];

            for (var i = 0; i < allSkills?.length; i++) {
                if (
                    allSkills[i].skill
                        .toLowerCase()
                        .startsWith(e.target.value.toLowerCase())
                ) {
                    results.push(allSkills[i]);
                }
            }
            for (var j = 0; j < allSkills?.length; j++) {
                if (
                    !allSkills[j].skill
                        .toLowerCase()
                        .startsWith(e.target.value.toLowerCase()) &&
                    allSkills[j].skill
                        .toLowerCase()
                        .includes(e.target.value.toLowerCase())
                ) {
                    results.push(allSkills[j]);
                }
            }

            setResult(results.slice(0, 10));
        } else {
            setResult([]);
        }
    };

    const addCustomSkills = (data) => {
        setCustomSkillName(data)
        setShowAddCustomModal(true)
    }

    useOnClickOutside(newRefSearchbox, () => {
        $(".searchResultBox").hide();
        setResult([]);
        setSearchInput("");
    });

    const toggleCustomSkillModal = () => {
        setShowAddCustomModal(false)
    }
    const onAddCustomAddSkillSubmit = (data) => {
        const payload = {
            "skill": customSkillName,
            ...data
        }
        postUnregisteredSkill(payload)
            .then((res) => {
                onBrowseSkillSave({
                    skillToBeRemoved: [],
                    selectedSkillIds: [
                        ...selectedSkills,
                        {
                            skillId: res.data.skillId,
                            isUnapprovedSkill: true,
                            ...payload
                        }
                    ]
                })
                toggleCustomSkillModal()
            })
    }

    const onSubmit = () => {
        if (parseFloat(roleFormData?.rateMin) > parseFloat(roleFormData?.rateMax)) {
            return
        }
        if (selectedSkills.length === 0) {
            return
        }
        const promisses = []

        const skillIdsToBeAdded = selectedSkills.map((res) => {
            return res.skillId
        })
        if (skillsToBeDeleted.length > 0) {
            const prom = deleteSkills({
                data: {
                    roleId: currentSelectedRoleData.roleId,
                    SkillIds: skillsToBeDeleted.map((res) => { return res.skillId }),
                    instrId: currentInstrID
                }
            })
            promisses.push(prom)
        }

        const payload1 = {
            roleId: currentSelectedRoleData.roleId,
            SkillIds: skillIdsToBeAdded,
            instrId: currentInstrID
        };
        PostRoles({
            roleIds: [currentSelectedRoleData.roleId],
            instrId: currentInstrID
        }).then(() => {
            const respo2 = postSkills(payload1)
            const respo = postAllSkillsInfo({
                ...roleFormData,
                roleId: currentSelectedRoleData?.roleId,
                instrId: currentInstrID
            })
            promisses.push(respo)
            promisses.push(respo2)
            Promise.allSettled(promisses)
                .then(() => {
                    if (roleWithCourses.includes(currentSelectedRoleData.role)) {
                        const apiPayload = {
                            roleId: currentSelectedRoleData.roleId,
                            skills: selectedSkills,
                            instrId: currentInstrID
                        }
                        onSaveSuccess()
                        instructorPost(apiPayload)
                    } else {
                        onSaveSuccess()
                    }
                })
        })

    }

    const ref = useRef()
    const [showRole, setShowRole] = useState(false)

    const handleClick = (event) => {
        if (ref?.current && !ref?.current?.contains(event.target)) {
            setShowRole(false);
            //   setOpenedOption(null)
        }
        return
    };

    useGlobalClick(handleClick, ref)

    const copySkillsFromRole = (skills) => {
        onBrowseSkillSave({
            selectedSkillIds: [...selectedSkills, ...skills],
            skillToBeRemoved: []
        })
        setShowCopySkillsModal(false)
    }

    const getSearchSkillsInput = () => {
        return (
            <>
                <div className="rolesandskillssearchinputwrapperr">
                    <div className={classnames("rolesSearchBar", currentSelectedRoleData.role && roleWithCourses.includes(currentSelectedRoleData.role) && "w-100")} ref={newRefSearchbox}>
                        <div>
                            <TextInput
                                name="title"
                                containerClass={""}
                                type="searchIcon"
                                placeholder="Type to search"
                                className={"overFlowSearchPadding"}
                                filterField={true}
                                onChange={handleSearchInput}
                                value={searchInput}
                                showCross={searchInput?.length > 0}
                                onCrossClick={() => {
                                    setSearchInput("")
                                    setResult([])
                                }}
                                onSearchClick={(e) => {
                                    if (!result.length) {
                                        setNewSkillModal(!newSkillModal);
                                    } else {
                                        result?.slice(0, 1)?.map((value) => {
                                            handleSkillResultClick(value);
                                        });
                                        setResult([]);
                                        setSearchInput("");
                                    }
                                }}
                                onKeyPress={(e) => {
                                    handleKeyPress(e);
                                }}
                            />
                        </div>

                        {result?.length === 0 && searchInput && (
                            <div className="searchResultBox w-100" style={{ width: "46%" }}>
                                <div
                                    className="d-flex justify-content-between align-items-center font14Black"
                                >
                                    <div className="notFoundTextWrap font12Black ">
                                        We could not find “{searchInput}”. Do you wish to add this skill ?
                                    </div>
                                    <span className="font12Blue" onClick={() => addCustomSkills(searchInput)}>Add Skill</span>
                                </div>
                            </div>
                        )}

                        {result?.length > 0 && (
                            <div className="searchResultBox w-100 p-3">
                                {result?.slice(0, 10)?.map((value, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className="d-block"
                                            style={{ fontSize: "14px", cursor: "pointer" }}
                                        >
                                            <p
                                                className="mb-0"
                                                onClick={() => {
                                                    handleSkillResultClick(
                                                        value,
                                                    );
                                                    setSearchInput("");
                                                }}
                                            >
                                                {value.skill} ({value.group})
                                            </p>
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                    </div>
                </div>
            </>
        )
    }

    return (
        <>
            {
                showCopySkillsModal &&
                <CopySkillsFromRole
                    myRoles={myRoles}
                    showModal={showCopySkillsModal}
                    hideModal={() => setShowCopySkillsModal(false)}
                    onSave={(data) => copySkillsFromRole(data)}
                />
            }
            <BrowseAndSelectSkill
                showModal={newSkillModal}
                hideModal={() => setNewSkillModal(!newSkillModal)}
                allSkillsAndCategories={allSkills}
                selectedSkillsProps={selectedSkills.map((res) => { return { skillID: res.skillId, name: res.skill } })}
                onSave={(data) => onBrowseSkillSave(data)}
                fetchLatestSkills={() => { }}
            />
            <AddCustomSkills
                isOpen={showAddCustomModal}
                newSkill={customSkillName}
                vendorList={technicalCategory}
                categoryList={softSkillCategory}
                onSubmit={(data) => { onAddCustomAddSkillSubmit(data) }}
                toggleModal={toggleCustomSkillModal}
            />
            <form onSubmit={handleSubmit(onSubmit)}>
                {
                    currentSelectedRoleData.role && roleWithCourses.includes(currentSelectedRoleData.role) &&
                    <>
                        <div className="roleFormFieldWrap" >
                            <div className="font16Black boldFont mt-2">Instructor Type</div>
                            <div className="font14Grey">
                                What type of instructor are you?
                            </div>
                            <div className="instructorTypeWrap">
                                <Checkbox
                                    label="Technical Instructor"
                                    name="isTechnicalInstructor"
                                    innerRef={register({
                                        required: false,
                                    })}
                                    checked={roleFormData?.isTechnicalInstructor}
                                    containerClass=""
                                    onChange={(e) => {
                                        setRoleFormData({ ...roleFormData, isTechnicalInstructor: e.target.checked });
                                        setChangeCheck(true);
                                    }}
                                    id="checkboxtechnical"
                                />
                                <Checkbox
                                    label="Soft Skill Instructor"
                                    name="isSoftSkillInstructor"
                                    innerRef={register({
                                        required: false,
                                    })}
                                    checked={roleFormData?.isSoftSkillInstructor}
                                    containerClass=""
                                    onChange={(e) => {
                                        setRoleFormData({ ...roleFormData, isSoftSkillInstructor: e.target.checked });
                                        setChangeCheck(true);
                                    }}
                                    id="checkboxsoftSkill"
                                />
                            </div>
                        </div>
                        <div className="border-top w-100 mt-3 mb-3" />
                    </>
                }
                <div className="roleFormFieldWrap" >
                    <div className="font16Black boldFont mt-2">
                        Your Experience
                    </div>
                    <div className="font14Grey mt-2 mb-1">
                        Please indicate the year you started working in this role
                    </div>
                    <Controller
                        name="startDate"
                        control={control}
                        defaultValue={roleFormData?.startYear}
                        rules={{ required: true }}
                        render={({ onChange }) => (
                            <TextInputDropdown
                                firstval="Year"
                                containerStyle={{ width: "155px" }}
                                label="Enter your start year*"
                                optionnew={allYears}
                                id="startDate"
                                containerClass
                                onChange={(val) => {
                                    setChangeCheck(true);
                                    clearErrors("startDate");
                                    onChange(val.value);
                                    setRoleFormData({
                                        ...roleFormData,
                                        startYear: val.value,
                                    });
                                }}
                                selectedValue={roleFormData?.startYear}
                                name="startDate"
                                error={errors.startDate && "Your start date is Required"}
                            />
                        )}
                    />
                </div>
                <div className="border-top w-100 mt-3 mb-3" />
                <div className="roleFormFieldWrap">
                    <div className="font16Black boldFont">Your Rate</div>
                    <div className="font14DarkGrey boldFont mt-2">Rate you Wish to Receive</div>
                    <div className="d-flex">
                        <Controller
                            name="rateType"
                            control={control}
                            rules={{ required: true }}
                            render={({ onChange }) => (
                                <TextInputDropdown
                                    label="Rate Type*"
                                    error={
                                        errors.rateType?.type === "required"
                                            ? "The Rate Type you wish to receive is Required"
                                            : null
                                    }
                                    firstval="Select"
                                    optionnew={
                                        currentSelectedRoleData.role === "Voice Talent" ?
                                            ["Per Minute", "Per Word", "Daily", "Hourly", "Flat"] :
                                            ["Daily", "Hourly", "Flat"]
                                    }
                                    containerStyle={{ width: "120px" }}
                                    onChange={(val) => {
                                        setChangeCheck(true);
                                        onChange(val.value);
                                        clearErrors("rateType");
                                        setRoleFormData({
                                            ...roleFormData,
                                            rateType: val.value,
                                        });
                                    }}
                                    selectedValue={roleFormData?.rateType}
                                    id="rateType"
                                    name="rateType"
                                    containerClass="my-2 mr-2"
                                />
                            )}
                        />
                        <div className="minMaxFieldWrap mr-2">
                            <Label
                                label="Min*"
                                className="subTextWithoutColor mb-0 mt-2"
                            ></Label>

                            <NumberFormat
                                thousandsGroupStyle="thousand"
                                value={roleFormData?.rateMin === 0.0 ? "" : roleFormData?.rateMin}
                                prefix="$"
                                displayType="input"
                                className="inputBox w-100"
                                type="text"
                                id={currentSelectedRoleData.role}
                                placeholder="$0.00"
                                allowLeadingZeros={true}
                                thousandSeparator={true}
                                decimalScale={2}
                                maxLength="10"
                                fixedDecimalScale={true}
                                isNumericString={true}
                                onChange={() => {
                                    setChangeCheck(true);
                                }}
                                onValueChange={(e) => {
                                    setRoleFormData({
                                        ...roleFormData,
                                        rateMin: e.floatValue,
                                    });
                                }}
                            />

                            {submitClicked && (!roleFormData?.rateMin ||
                                roleFormData?.rateMin === undefined ||
                                roleFormData?.rateMin === "") && (
                                    <div id="receiveMinError">
                                        <span className="d-flex align-items-center my-2">
                                            <i className="fa fa-exclamation-circle errorIcon" />
                                            <p className="errorText mb-0 ml-0 ">
                                                Minimum rate must be greater than $0.00 in US Dollars
                                            </p>
                                        </span>
                                    </div>
                                )}
                        </div>

                        <div className="minMaxFieldWrap mr-3">
                            <Label
                                label="Max*"
                                className="subTextWithoutColor mb-0 mt-2"
                            ></Label>
                            <NumberFormat
                                thousandsGroupStyle="thousand"
                                value={roleFormData?.rateMax === 0.0 ? "" : roleFormData?.rateMax}
                                prefix="$"
                                displayType="input"
                                className="inputBox w-100"
                                type="text"
                                id={currentSelectedRoleData.role}
                                placeholder="$0.00"
                                allowLeadingZeros={true}
                                thousandSeparator={true}
                                decimalScale={2}
                                maxLength="10"
                                fixedDecimalScale={true}
                                isNumericString={true}
                                onChange={() => {
                                    setChangeCheck(true);
                                }}
                                onValueChange={(e) => {
                                    setRoleFormData({
                                        ...roleFormData,
                                        rateMax: e.floatValue,
                                    });
                                }}
                            />

                            {
                                submitClicked && (!roleFormData?.rateMax ||
                                    roleFormData?.rateMax === undefined ||
                                    roleFormData?.rateMax === "") && (
                                    <div className="" id="receiveMaxError">
                                        <span className="d-flex align-items-center my-2">
                                            <i className="fa fa-exclamation-circle errorIcon" />
                                            <p className="errorText mb-0 ml-0 ">
                                                Maximum rate must be greater than $0.00 in US Dollars
                                            </p>
                                        </span>
                                    </div>
                                )}
                        </div>
                    </div>

                    {((roleFormData?.rateMax && roleFormData?.rateMin) &&
                        (parseFloat(roleFormData?.rateMin) > parseFloat(roleFormData?.rateMax))
                    ) ? (
                        <div id="receiveMinMaxError">
                            <span className="d-flex align-items-center my-2">
                                <i className="fa fa-exclamation-circle errorIcon" />
                                <p className="errorText mb-0 ml-0 ">
                                    Maximum Rate should be greater than Minimum Rate
                                </p>
                            </span>
                        </div>
                    ) : null
                    }

                </div>
                <div className="border-top w-100 mt-3 mb-3" />
                <div className="roleFormFieldWrap">
                    {currentSelectedRoleData.role && roleWithCourses.includes(currentSelectedRoleData.role) ? (
                        <>
                            <div className="">
                                <div className="d-flex align-items-center">
                                    <div className="font16Black boldFont mr-1">Your Skills</div>
                                    <Tooltip
                                        content={
                                            <span>
                                                If you selected instructor for a role, you link your skills
                                                to your courses
                                            </span>
                                        }
                                        direction="right"
                                    >
                                        <img src={iIcon} alt="" />
                                    </Tooltip>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <div>
                                        <div className="font14DarkGrey mt-2 mb-2">Select your skills for this role</div>
                                    </div>
                                    {myRoles?.length > 0 &&
                                        <div
                                            className="d-flex align-items-start mt-1 justify-content-end relative mr-3"
                                            ref={ref}
                                        >
                                            <div
                                                className="d-flex pointer"
                                                onClick={() => {
                                                    setShowCopySkillsModal(true)
                                                }}
                                            >
                                                <span className="font15DarkBlue mb-0 mr-2">Copy From Other Roles</span>

                                            </div>
                                            <div className={showRole ? "otherRoleHolder" : "d-none"}>
                                                {
                                                    myRoles?.map((role, i) => {
                                                        return (
                                                            <div
                                                                key={i}
                                                                className="font14Blue pt-1 pb-1"
                                                                onClick={() => {
                                                                    copySkillsFromRole(role)
                                                                }}>
                                                                {role.myRole?.role}
                                                            </div>
                                                        )
                                                    })
                                                }
                                                {
                                                    myRoles?.length === 0 &&
                                                    <div className="font14Black pt-1 pb-1">
                                                        No More Roles Available
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div className="w-50">
                                    {getSearchSkillsInput()}
                                </div>

                            </div>
                            <div className="roleFormSkillWrapper skillsHeight">
                                {selectedSkills?.length > 0 && (
                                    <>
                                        {
                                            selectedSkillsTechCategories.length > 0 &&
                                            <div className="font16DarkGrey boldFont">
                                                Technical Skills - Selected Skills
                                            </div>
                                        }
                                        {selectedSkillsTechCategories?.map((category, index) => {
                                            return (
                                                <div key={index}>
                                                    {selectedSkills
                                                        ?.filter((obj) =>
                                                            obj?.discipline?.includes("Technical")
                                                        )
                                                        .map((data, index) => {
                                                            if (category === data?.group) {
                                                                return (
                                                                    <div
                                                                        className="instructorkillsAvailableRolesIndividualRolePage"
                                                                        key={index}
                                                                    >
                                                                        <Chips
                                                                            data={data.skill}
                                                                            label={data.skill}
                                                                            className="mt-2 mr-2 mb-2 newChipsBtn"
                                                                            size="small"
                                                                            onCrossClick={() =>
                                                                                removeSelectedSkills(data)
                                                                            }
                                                                            icon={false}
                                                                            clickable={true}
                                                                        />

                                                                        <div className="checkboxSectionRolesSkills ml-auto mr-2">
                                                                            <Checkbox
                                                                                label="I am certified to teach"
                                                                                name="certifiedToTeach"
                                                                                value={data?.certifiedToTeach}
                                                                                checked={data?.certifiedToTeach}
                                                                                onChange={(e) => {
                                                                                    handleCertifiedToTeachCheck(e, data)
                                                                                }}
                                                                                containerClass="instructorcheckbox"
                                                                                id={`checkbox` + data.skill}
                                                                            />
                                                                            <Checkbox
                                                                                label="I have my own course material"
                                                                                name="ownCourseMaterial"
                                                                                id={
                                                                                    `checkbox` + data.skill + data.skill
                                                                                }
                                                                                checkboxClass={data?.ownCourseMaterial}
                                                                                value={data?.ownCourseMaterial}
                                                                                checked={data?.ownCourseMaterial}
                                                                                innerRef={register}
                                                                                onChange={(e) => {
                                                                                    handleOwnCourseMaterialCheck(e, data)
                                                                                }}
                                                                                containerClass="instructorcheckbox "
                                                                            ></Checkbox>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            }
                                                        })}
                                                </div>
                                            );
                                        })}
                                        {selectedSkillsSoftCategories.length > 0 && (
                                            <>
                                                <div className="font16DarkGrey boldFont mt-3">
                                                    Soft Skills - Selected Skills
                                                </div>
                                                {selectedSkillsSoftCategories?.map((cat, index) => {
                                                    return (
                                                        <div key={index}>
                                                            {selectedSkills
                                                                ?.filter((obj) =>
                                                                    obj?.discipline?.includes("Soft Skills")
                                                                )
                                                                .map((data, index) => {
                                                                    if (cat === data?.group) {
                                                                        return (
                                                                            <div
                                                                                className="instructorkillsAvailableRolesIndividualRolePage"
                                                                                key={index}
                                                                            >
                                                                                <div className="grid-item">
                                                                                    <Chips
                                                                                        data={data.skill}
                                                                                        label={data.skill}
                                                                                        className="mt-2 mr-2 newChipsBtn"
                                                                                        addIndustryCustomChip={
                                                                                            data.isUnapprovedSkill === true
                                                                                        }
                                                                                        size="small"
                                                                                        onCrossClick={() =>
                                                                                            removeSelectedSkills(data)
                                                                                        }
                                                                                        icon={false}
                                                                                        clickable={true}
                                                                                    />
                                                                                </div>

                                                                                <Checkbox
                                                                                    label="I am certified to teach"
                                                                                    name="certifiedToTeach"
                                                                                    value={data?.certifiedToTeach}
                                                                                    checked={data?.certifiedToTeach}
                                                                                    innerRef={register}
                                                                                    onChange={(e) => {
                                                                                        handleCertifiedToTeachCheck(e, data)
                                                                                    }}
                                                                                    containerClass="instructorcheckbox"
                                                                                    id={
                                                                                        `checkbox` +
                                                                                        data.skill +
                                                                                        data.skill +
                                                                                        data.skill
                                                                                    }
                                                                                ></Checkbox>
                                                                                <Checkbox
                                                                                    label="I have my own course material"
                                                                                    name="ownCourseMaterial"
                                                                                    value={data?.ownCourseMaterial}
                                                                                    checked={data?.ownCourseMaterial}
                                                                                    innerRef={register}
                                                                                    onChange={(e) => {
                                                                                        handleOwnCourseMaterialCheck(e, data)
                                                                                    }}
                                                                                    containerClass="instructorcheckbox  "
                                                                                    id={
                                                                                        `checkbox` +
                                                                                        data.skill +
                                                                                        data.skill +
                                                                                        data.skill +
                                                                                        data.skill
                                                                                    }
                                                                                ></Checkbox>
                                                                            </div>
                                                                        );
                                                                    }
                                                                })}
                                                        </div>
                                                    );
                                                })}
                                            </>
                                        )}
                                    </>
                                )}
                                {popularSkills?.length > 0 && (
                                    <>
                                        <div className="border-top mt-3 mb-2" />
                                        <div className="mt-2 font14Black boldFont">
                                            Popular Skills
                                        </div>
                                        <div className=" mx-auto">
                                            <div className="d-flex flex-wrap">
                                                {popularSkills.map((skillData, index) => {
                                                    return (
                                                        <React.Fragment key={index}>
                                                            <Chips
                                                                data={selectedPopularSkill.map((res) => { return res.skill })}
                                                                label={skillData.skill}
                                                                className="mt-2 newChipsBtn mr-2"
                                                                onClick={() => {
                                                                    handlePopularSkillChipClick(skillData)
                                                                }}
                                                                clickable={true}
                                                                browseSkillChip={true}
                                                                id={skillData.skill}
                                                                icon={true}
                                                            />
                                                        </React.Fragment>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                            <div
                                className="d-flex align-items-center justify-content-end mr-4 mt-2 pb-2"
                                onClick={() => setNewSkillModal(true)}
                            >
                                <img src={CirclePlus} className="iconShadow mr-2 " alt="" />
                                <span className="font16Blue boldFont ">Browse More Skills</span>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="">
                                <div className="font16Black boldFont">Your Skills</div>
                            </div>
                            <div className="roleFormSkillWrapper">
                                <div className="d-flex justify-content-between align-items-center">
                                    <div className="font18Black boldFont">Skills</div>
                                    {myRoles?.length > 0 &&
                                        <div
                                            className="d-flex align-items-center justify-content-end relative "
                                            ref={ref}
                                        >
                                            <div
                                                className="d-flex pointer"
                                                onClick={() => {
                                                    setShowCopySkillsModal(true)
                                                }}
                                            >
                                                <span className="font15DarkBlue mb-0 mr-2">Copy From Other Roles</span>
                                            </div>
                                            <div className={showRole ? "otherRoleHolder" : "d-none"}>
                                                {
                                                    myRoles?.map((role, index) => {
                                                        return (
                                                            <div
                                                                className="font14Blue pt-1 pb-1"
                                                                key={index}
                                                                onClick={() => {
                                                                    copySkillsFromRole(role)
                                                                }}>
                                                                {role.myRole?.role}
                                                            </div>
                                                        )
                                                    })
                                                }
                                                {
                                                    myRoles?.length === 0 &&
                                                    <div className="font14Black pt-1 pb-1">
                                                        No More Roles Available
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div className="font14DarkGrey">Select skills Required for this role.</div>
                                {getSearchSkillsInput()}
                                {selectedSkills?.length > 0 && (
                                    <div className="mt-2 font14Black boldFont">
                                        Selected Skills
                                        <div className="d-flex flex-wrap">
                                            {selectedSkills.map((skillData, index) => {
                                                return (
                                                    <Chips
                                                        key={index}
                                                        data={selectedSkills.map((res) => { return res.skill })}
                                                        label={skillData.skill}
                                                        className="mt-2 mr-2 newChipsBtn"
                                                        size="small"
                                                        onCrossClick={() => {
                                                            removeSelectedSkills(skillData)
                                                        }}
                                                        id={skillData.skill}
                                                        clickable={true}
                                                    />
                                                );
                                            })}
                                        </div>
                                    </div>
                                )}
                                {popularSkills?.length > 0 && (
                                    <>
                                        <div className="border-top mt-3 mb-2" />
                                        <div className="mt-2 font14Black boldFont">
                                            Popular Skills
                                        </div>
                                        <div className="d-flex flex-wrap">
                                            {popularSkills.map((skillData, index) => {
                                                return (
                                                    <React.Fragment key={index}>
                                                        <Chips
                                                            data={selectedPopularSkill.map((res) => { return res.skill })}
                                                            label={skillData.skill}
                                                            className="mt-2 newChipsBtn mr-2"
                                                            onClick={() => {
                                                                handleSkillChipClick(skillData)
                                                            }}
                                                            clickable={true}
                                                            browseSkillChip={true}
                                                            id={skillData.skill}
                                                            icon={true}
                                                        />
                                                    </React.Fragment>
                                                );
                                            })}
                                        </div>
                                    </>
                                )
                                }
                                {suggestedSkills?.length > 0 && (
                                    <>
                                        <div className="border-top mt-3 mb-2" />
                                        <div className="mt-2 font14Black boldFont">
                                            Suggested Skills
                                        </div>
                                        {allSuggestedCategories?.map((category, index) => {
                                            return (
                                                <React.Fragment key={index}>
                                                    <div className="font14DarkGrey boldFont mt-2">
                                                        {category}
                                                    </div>
                                                    <div className="d-flex flex-wrap">
                                                        {suggestedSkills.map((skillData, i) => {
                                                            if (category === skillData?.category) {
                                                                return (
                                                                    <Chips
                                                                        key={i}
                                                                        data={selectedSuggestedSkills.map((res) => { return res.skill })}
                                                                        label={skillData.skill}
                                                                        className="mt-2 mr-2 newChipsBtn "
                                                                        size="small"
                                                                        onClick={() =>
                                                                            handleSkillChipClick(skillData)
                                                                        }
                                                                        icon={true}
                                                                        clickable={true}
                                                                        browseSkillChip={true}
                                                                    />
                                                                );
                                                            }
                                                        })}
                                                    </div>
                                                </React.Fragment>
                                            );
                                        })}
                                    </>
                                )}
                                <div className="border-top mt-3 mb-2" />
                                <div
                                    className="d-flex align-items-center justify-content-end"
                                    onClick={() => setNewSkillModal(true)}
                                >
                                    <img src={CirclePlus} className="iconShadow mr-2" alt="" />
                                    <span className="font16Blue boldFont ">Browse More Skills</span>
                                </div>
                            </div>
                        </>
                    )}
                    {(submitClicked && selectedSkills.length === 0) && (
                        <p className="text-center mt-3">
                            <i className="fa fa-exclamation-circle errorIcon mr-1" />
                            <span className="errorText">
                                Select at least one skill
                            </span>
                        </p>
                    )}
                </div>
                <div
                    className="rolesandskillsButtonWrapper border-top"
                    style={{ left: "0", marginTop: "10px", width: "100%" }}
                >
                    <div className="t my-4 mb-0 buttonRolesInstructionwrapper">
                        <Button
                            label="Cancel"
                            className="btn-light main_btn  mr-2 RolesInstructionBack"
                            onClick={toggleModal}
                        />
                        <Button
                            label={"Save"}
                            type="submit"
                            onClick={() => {
                                setSubmitClicked(true)
                            }}
                            className="btn-blue main_btn  "
                        />
                    </div>
                </div>
            </form>
        </>
    )
}

export default RoleForm;