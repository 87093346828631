import { getCompanyId, getWebRole } from "../util/localStorage";
import { GetUserLoginData } from "../util/reduxData";

const data = getCompanyId();
const WebRole = getWebRole();
const navbarMenuLinkClientMobile = [
    {
        name: 'Account Settings',
        path: '/client-profile/myinformation-account',
        legacy: false
    },
    {
        name: "Documents",
        path: "/documents/1",
        legacy: false,
        active: "documents",
    },
    // {
    //     name: 'Change Email',
    //     path: '/client-profile/changeemail',
    //     legacy: false
    // },
    // {
    //     name: 'Change Password',
    //     path: '/client-profile/changepassword',
    //     legacy: false
    // },
    {
        name: 'Company Profile',
        path: '/client-profile/client-profile-account',
        legacy: false
    },
    {
        name: 'Company Contacts',
        path: '/client-profile/client-contact-account',
        legacy: false
    },
    {
        name: 'Company Locations',
        path: '/client-profile/client-location-account',
        legacy: false
    },
    {
        name: 'Billing Information',
        path: '/client-profile/billing-info',
        legacy: false
    },
    {
        name: "Legal Documents",
        path: "/client/legal-docs",
      },
      {
        name: 'Notification Settings',
        path: '/client-profile/notification-settings/1',
        legacy: false
    },
    {
        name: 'Logout',
        path: '/logout',
        legacy: false
    }
]

const GetNavbarMenuLinkClientMobile = () => {

    const userData = GetUserLoginData()


    if (data > 0 && WebRole === '1' && userData.isWizardFinished) {
        return [
            {
                name: 'Account Settings',
                path: '/client-profile/myinformation-account',
                legacy: false
            },
            // {
            //     name: 'Change Email',
            //     path: '/client-profile/changeemail',
            //     legacy: false
            // },
            // {
            //     name: 'Change Password',
            //     path: '/client-profile/changepassword',
            //     legacy: false
            // },
            {
                name: 'Logout',
                path: '/logout',
                legacy: false
            }
        ];
    } else if (data > 0 && WebRole === '3' && userData.isWizardFinished) {
        return [
            ...navbarMenuLinkClientMobile
        ];
    } else {
        return [
            {
                name: 'Logout',
                path: '/logout',
                legacy: false
            }
        ]
    }
}

export default GetNavbarMenuLinkClientMobile;