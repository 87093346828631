import React from "react";
import { currency_formatter } from "../../../../../util/currency_formatter";
import { generateJsonFromHTML } from "../../../../../util/generateHTMLfromJSON";
import { Editor } from "react-draft-wysiwyg";
import "./index.scss";

const ProposalDetails = ({
  proposalData,
  tab,
  className
}) => {
  return (
    <div className={className}>
      <div className="mt-3 d-flex justify-content-between">
        <div className="d-flex flex-wrap">
          <div className="freelanceSubmission mr-3">
            {
              tab === "Rejected" ? "Rejected" :
                ["Hired", "Accepted"].includes(tab) ?
                  tab :
                  "Proposal"
            }
          </div>
          { proposalData?.rateType && 
            <>
              <div className="d-flex align-items-center mr-4">
                <div className="font16Black boldFont">
                  Rate Type:
                </div>
                <div className="font16Black ml-1">
                  {proposalData?.rateType}
                </div>
              </div>
              
              <div className="d-flex align-items-center">
                <div className="font16Black boldFont">
                  Proposal Amount:
                </div>
                <div className="font16Black ml-1">
                  { isNaN(proposalData?.ratePrice) ? proposalData?.ratePrice : currency_formatter(proposalData?.ratePrice)}
                </div>
              </div>
            </>
          }

        </div>

      </div>
      { proposalData?.proposalDescription && 
      <>
        <div className="mt-4 font14Black boldFont">
          Proposal Description
        </div>
        <div className="font14Grey mt-2">
          <Editor
            editorState={generateJsonFromHTML(proposalData?.proposalDescription)}
            readOnly={true}
            toolbarClassName={"d-none"}
          />
        </div>
        </>
      }
      
    </div>
  );
};

export default ProposalDetails;
