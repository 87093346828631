/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { GetCompanyContact, PostCompanyContact } from "../../../../../services/companyContact";
import Button from "../../../../../components/button";
import TextInputDropdown from "../../../../../components/textInputDropdownNew";
import Modal from "../../../../../components/modal";
import AddCompanyContact from "../../../Account/CompanyContact/addCompanyContact/addCompanyContact";
import InviteSucess from "../../../../ClientOnboarding/CompanyContact/inviteSuccess";
import { GetUserLoginData } from "../../../../../util/reduxData";
import "./addProjectContact.scss";

const AddProjectContact = ({
    toggleProjectContactModal,
    addProjectContactModal,
    addProjectContactProp,
    existingContactId,
    isPublic
}) => {
    const [contactsObj, setContactsObj] = useState([])
    const [openModal, setOpenModal] = useState(false);
    const [existingEmails, setExistingEmails] = useState([]);
    const [allContacts, setAllContacts] = useState([]);
    const [submitClicked, setSubmitClicked] = useState(false);
    const [projectContact, setProjectContact] = useState(null);
    const [alreadyAddedContact, setAlreadyAddedContact] = useState(false);
    const [inviteSuccess, setInviteSuccess] = useState({})
    const userData = GetUserLoginData()

    useEffect(() => {
        if (!isPublic && addProjectContactModal) {
            getCompanyContact()
            saveExisitingEmail()
        }
    }, [addProjectContactModal])

    useEffect(() => {
        if (!addProjectContactModal) {
            setProjectContact(null)
            setSubmitClicked(false)
            setOpenModal(false)
        }
    }, [addProjectContactModal])


    const saveExisitingEmail = () => {
        const emailArray = [];
        allContacts?.map((comp) => {
            emailArray.push(comp.email);
        });
        setExistingEmails(emailArray);
    };

    const createContactObj = (list) => {
        const tempList = []
        list.forEach((res) => {
            tempList.push({
                name: `${res.firstName} ${res.lastName} (${res.email})`,
                contactID: res.contactID
            })
        })
        setContactsObj(tempList)
    }

    const getCompanyContact = () => {
        GetCompanyContact()
            .then((res) => {
                setAllContacts(res.data)
                createContactObj(res.data)
            })
    }

    const createCompanyContact = (data) => {
        PostCompanyContact(data).then((res) => {
            setInviteSuccess({
                isOpen: true,
                firstName: data.firstName
            })
            const finalData = {
                ...data,
                contactID: res.data
            }
            createContactObj([
                ...contactsObj,
                finalData
            ])
            setProjectContact(res.data)
            setOpenModal(false);
        });
    };

    const addProjectContact = () => {
        setSubmitClicked(true)
        if (!projectContact || alreadyAddedContact) {
            return
        }
        addProjectContactProp(projectContact)
    }

    const getProjectContactName = (contactID) => {
        const res = contactsObj.filter((res) => {
            if (res.contactID === contactID) {
                return res.name
            }
        })
        if (res.length > 0) {
            return res[0].name
        }
    }

    const getProjectContactID = (name) => {
        const res = contactsObj.filter((res) => {
            if (res.name === name) {
                return res.contactID
            }
        })
        if (res.length > 0) {
            return res[0].contactID
        }
    }

    return (
        <>
            
            <Modal
                isOpen={addProjectContactModal}
                containerStyle={{
                    width: "650px",
                    maxWidth: "95%",
                    minHeight: "295px",
                    paddingBottom: "0px"
                }}
                size="medium"
                hideCloseButton={true}
                toggleModal={toggleProjectContactModal}
                title={
                    "Company Contact"
                }
                disableBackdropToggle={true}
                containerClass={"addProjectContactModal"}
            >
                <InviteSucess
                    isOpen={inviteSuccess?.isOpen}
                    toggleModal={()=>setInviteSuccess({})}
                    firstName={inviteSuccess?.firstName}
                />
                <Modal
                    isOpen={openModal}
                    size="mediumCompanyContact"
                    hideCloseButton={true}
                    toggleModal={() => setOpenModal(false)}
                    disableBackdropToggle={true}
                    title={
                        "Add Company Contact"
                    }
                >
                    <AddCompanyContact
                        toggleModal={() => setOpenModal(!openModal)}
                        addCompanyContact={(data) => createCompanyContact(data)}
                        editCompanyContact={(data) => { return }}
                        companyContactData={{}}
                        existingEmail={existingEmails}
                        setProjectContact={setProjectContact}
                        setAlreadyAddedContact={setAlreadyAddedContact}
                    />
                </Modal>

                <div className="mt-3 border-top mobileSep" />
                <div className="modalContentWrapper mx-auto">

                    <TextInputDropdown
                        label={"Select from existing contacts*"}
                        optionnew={contactsObj
                            .filter((res) => {
                                return userData?.contactId !== res.contactID
                            })
                            .map((res) => { return res.name })
                        }
                        id="billingContact"
                        name="billingContact"
                        firstval="Select Contact"
                        containerClass={"projectContactDropDown"}
                        maxMenuHeight={150}
                        selectedValue={getProjectContactName(projectContact)}
                        onChange={(val) => {
                            if (val.value === "Add a New Contact") {
                                setOpenModal(true)
                            } else {
                                const contactId = getProjectContactID(val.value)
                                if (existingContactId.includes(contactId)) {
                                    setAlreadyAddedContact(true)
                                    setProjectContact(contactId)
                                } else {
                                    setProjectContact(contactId)
                                    setAlreadyAddedContact(false)
                                }
                            }

                        }}
                        error={(submitClicked && !projectContact) ?
                            "Contact is Required" :
                            (submitClicked && alreadyAddedContact) ?
                                "Contact already added" :
                                null
                        }
                        addNewContact={() => setOpenModal(true)}

                    ></TextInputDropdown>
                    <div className="mt-4">
                        <span className="font14Black">
                            Could not find the contact you are looking for?
                        </span>
                        <span className="font14Blue boldFont ml-2" onClick={() => setOpenModal(true)}>
                            + Add New Contact
                        </span>
                    </div>
                </div>
                <div className="timelinemodalButtonHolder">
                    <div className="mt-3 border-top" />
                    <div className="text-center mt-4 mb-0 float-bottom">
                        <Button
                            label="Cancel"
                            onClick={toggleProjectContactModal}
                            type="btn"
                            className="btn-light mx-2 cancelButtonDelete"
                        />
                        <Button
                            label="Add"
                            type="submit"
                            className="btn-blue mx-2"
                            onClick={() => {
                                addProjectContact()
                            }}
                        />
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default AddProjectContact;