import publicAxios from "../util/publicAxios";
import Axios from "../util/axiosInstance";

export const GetNotification = (notificationType) => {
  return Axios.get(`/api/Notification/Get?notificationType=${notificationType}`);
};

export const ReadNotification = (id) => {
  return Axios.put(`/api/Notification/ReadNotification?notificationId=${id}`);
};

export const DeleteNotification = (id) => {
  return Axios.put(
    `/api/Notification/DeleteNotification?notificationId=${id}`

  );
};


export const ClearAllNotification = () => {
  return Axios.put(
    `/api/Notification/ClearAllNotifications`
  );
};

export const GetEmailSubscriptions = () => {
  return Axios.get("/api/Notification/GetEmailSubscriptions")
}
export const GetTalentEmailSubscriptions = (instrId, email) => {
  return Axios.get(`/api/Notification/GetEmailSubscriptions?instrId=${instrId}&email=${email}`)
}
export const AddEmailSubscriptions = (payload) => {
  return Axios.post("/api/Notification/AddUpdateEmailSubscriptions", payload)
}

export const GetAccountNotifications = () => {
  return Axios.get("/api/Notification/GetAccountNotifications")
}
export const GetTalentAccountNotifications = (instrID) => {
  return Axios.get(`/api/Notification/GetAccountNotifications?instrId=${instrID}`)
}
export const GetPublicEmailSubscriptions = (instrID, email) => {
  return publicAxios.get(`/api/Notification/GetEmailSubscriptions?instrId=${instrID}&email=${email}`)
}

export const UpdateAccountNotifications = payload => {
  return Axios.post("/api/Notification/AddUpdateAccountNotifications", payload);

};

export const getClientEmailSubscription = (contactId, email) => {
  return Axios.get(`/api/Notification/GetContactEmailSubscriptions?contactId=${contactId}&email=${email}`)
}

export const UpdateClientEmailSubscription = (payload) => {
  return Axios.post("/api/Notification/AddUpdateContactEmailSubscriptions", payload)
}