import React, { Fragment } from "react";
import ShowMore from "../../../../../components/showMore";
import "./JobCertificationDetails.scss";

function JobCertificationDetails({
  submitProposal,
  roleGroup,
  contentRequirement,
  certificationRequiredComments,
  isCertificationRequired,
  AllIndustry,
  languageList,
  projectDuration,
  projectTimeframe,
  timeRequirement,
  skill,
  projectSpecificationDetails,
  projectCertificationDetails,
  projectDetails
}) {
  const getProficiencyName = (value) => {
    if (value === 1) {
      return "Basic";
    } else if (value === 2) {
      return "Conversational";
    } else if (value === 3) {
      return "Fluent";
    }
  };

  return (
    <Fragment>
      {(contentRequirement ||
        certificationRequiredComments ||
        isCertificationRequired ||
        AllIndustry?.length ||
        languageList?.length ||
        projectDuration ||
        projectTimeframe ||
        timeRequirement) && (
          <div className={!submitProposal ? "JobCertificationDetailsWrapper" : "ProposalCertificationDetailsWrapper border-top"}>
            <div className="font18Black boldFont">Requirements</div>
            {
              skill?.length > 0 &&
              <>
                <div className="font-weight-bold mt-3"> Skills</div>
                <div className="d-flex flex-wrap mb-4">
                  {skill?.map((data, idx) => {
                    return (
                      <div key={idx}>
                        <div className="rolesandratesInactiveChipFreelancer mt-2 mr-2">{data.name}</div>
                      </div>
                    );
                  })}
                </div>
              </>
            }
            {roleGroup === "C" ? (
              <>
                {AllIndustry?.length > 0 && (
                  <div className="columnWidth mb-3">
                    <div className="font-weight-bold mb-3">
                      Industry Experience
                    </div>
                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                      {AllIndustry?.map((data, idx) => {
                        return (
                          <div key={idx}>
                            <div className="skillChipUpdate mr-3 mb-2">
                              {data}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </>
            ) : (
              <>
                <div className="desktopView">
                  <div className="parentWrap">
                    {AllIndustry?.length > 0 && (
                      <div className="columnWidth mb-3">
                        <div className="font-weight-bold mb-1">
                          Industry Experience
                        </div>
                        <div style={{ display: "flex", flexWrap: "wrap" }}>
                          {AllIndustry?.map((data, idx) => {
                            return (
                              <div key={idx}>
                                <div className="skillChipUpdate mr-3 mb-2">
                                  {data}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                    {languageList?.length > 0 && (
                      <div className="columnWidth mb-3">
                        <div className="font-weight-bold mb-1">Language</div>
                        <div style={{ display: "flex", flexWrap: "wrap" }}>
                          {languageList?.map((data, idx) => {
                            return (
                              <div key={idx}>
                                <div className="skillChipUpdate mr-3 mb-2">
                                  {data?.language}{" "}
                                  <span className="fluency ml-1">
                                    ({getProficiencyName(data?.proficiency)})
                                  </span>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                    {(roleGroup === "A" && projectSpecificationDetails?.isCertificationFieldVisible) && (
                      <div className="columnWidth">
                        <div className="font-weight-bold mb-1">
                          Certification Requirement
                        </div>
                        <div className="font14Grey">
                          {isCertificationRequired === true
                            ? "Yes, a specific certification is Required."
                            : "No"}
                        </div>
                        {isCertificationRequired === true && (
                          <div className="jobSpecificationTextt">
                            {certificationRequiredComments && (
                              <ShowMore
                                text={certificationRequiredComments}
                                more="More"
                                less="Less"
                                textClass="font14Grey"
                                anchorClass="font14Blue noWrap"
                                lines={5}
                                id={"certificationRequiredComments"}
                              />
                            )}
                          </div>
                        )}
                      </div>
                    )}
                    {(roleGroup === "TC") && (
                      <div className="columnWidth">
                        <div className="font-weight-bold mb-1">
                          Certification Requirement
                        </div>
                        <div className="font14Grey">
                          {projectCertificationDetails?.isCertificationRequired
                            ? "Yes, a specific certification is Required."
                            : "No"}
                        </div>
                        {projectCertificationDetails?.certificationRequiredComments && (
                          <div className="jobSpecificationTextt">
                            <ShowMore
                              text={projectCertificationDetails?.certificationRequiredComments}
                              more="More"
                              less="Less"
                              textClass="font14Grey"
                              anchorClass="font14Blue noWrap"
                              lines={1}
                              id={"certificationRequiredComments"}
                            />
                          </div>
                        )}
                      </div>
                    )}
                    {(roleGroup === "TC") && (
                      <div className="columnWidth">
                        <div className="font-weight-bold mb-1">
                          Content Requirement
                        </div>
                        <div className="font14Grey">
                          {projectDetails?.contentRequirement}
                        </div>
                        {projectDetails?.contentComments && (
                          <div className="jobSpecificationTextt">
                            <ShowMore
                              text={projectDetails?.contentComments}
                              more="More"
                              less="Less"
                              textClass="font14Grey"
                              anchorClass="font14Blue noWrap"
                              lines={2}
                              id={"certificationContentComments"}
                            />
                          </div>
                        )}
                      </div>
                    )}
                    {roleGroup === "B" && (
                      <>
                        {projectDuration && (
                          <div className="columnWidth">
                            <div className="font-weight-bold">
                              Estimated Project Duration
                            </div>
                            <div className="font14Grey">
                              {projectDuration}
                            </div>
                          </div>
                        )}
                        {projectTimeframe && (
                          <div className="columnWidth">
                            <div className="font-weight-bold">
                              Starting Timeframe
                            </div>
                            <div className="font14Grey">
                              {projectTimeframe}
                            </div>
                          </div>
                        )}
                      </>
                    )}

                    {(roleGroup === "A" && contentRequirement && projectSpecificationDetails?.isContentFieldVisible) && (
                      <div className="columnWidth">
                        <div className="font-weight-bold">
                          Content Requirement
                        </div>
                        <div className="font14Grey">
                          {contentRequirement}
                        </div>
                      </div>
                    )}
                    {roleGroup === "B" && timeRequirement && (
                      <div className="columnWidth">
                        <div className="font-weight-bold ">
                          Estimated Time Requirement
                        </div>
                        <div className="font14Grey">
                          {timeRequirement}
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className="mobileView">
                  {AllIndustry?.length > 0 && (
                    <>
                      <div className="font-weight-bold mb-1">
                        Industry Experience
                      </div>
                      <div style={{ display: "flex", flexWrap: "wrap" }}>
                        {AllIndustry?.map((data, idx) => {
                          return (
                            <div key={idx}>
                              <div className="skillChipUpdate mr-3 mb-2">
                                {data}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </>
                  )}

                  {languageList?.length > 0 && (
                    <>
                      <div className="font-weight-bold mt-4 mb-1">Language</div>
                      <div style={{ display: "flex", flexWrap: "wrap" }}>
                        {languageList?.map((data, idx) => {
                          return (
                            <div key={idx}>
                              <div className="skillChipUpdate mr-3 mb-2">
                                {data?.language}{" "}
                                <span className="fluency ml-1">
                                  {" "}
                                  ({getProficiencyName(data?.proficiency)})
                                </span>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </>
                  )}

                  {(roleGroup === "A" && projectSpecificationDetails?.isCertificationFieldVisible) && (
                    <>
                      <div className="font-weight-bold mb-1 mt-4">
                        Certification Requirement
                      </div>
                      <div className="font14Grey">
                        {isCertificationRequired === true
                          ? "Yes, a specific certification is Required."
                          : "No"}
                      </div>
                      {isCertificationRequired === true && (
                        <div className="jobSpecificationTextt">
                          {certificationRequiredComments && (
                            <ShowMore
                              text={certificationRequiredComments}
                              more="More"
                              less="Less"
                              textClass="font14Grey"
                              anchorClass="font14Blue noWrap"
                              lines={3}
                              id={"certificationRequiredCommentsA"}
                            />
                          )}
                        </div>
                      )}
                    </>
                  )}

                  {(roleGroup === "A" && projectSpecificationDetails?.isContentFieldVisible) && (
                    <>
                      <div className="font-weight-bold mt-4">
                        Content Requirement
                      </div>
                      <div className="font14Grey">
                        {contentRequirement}
                      </div>
                    </>
                  )}
                  {roleGroup === "B" && (
                    <>
                      <div className="font-weight-bold mt-4">
                        Estimated Time Requirement
                      </div>
                      <div className="font14Grey">
                        {timeRequirement}
                      </div>
                    </>
                  )}
                </div>
              </>
            )}
          </div>
        )}
    </Fragment>
  );
}

export default JobCertificationDetails;
