/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useEffect, useState } from "react";
import { useLocation, useHistory, Link } from "react-router-dom";
import { MenuLinks } from "../../constants/MenuLinks";
import { bulbIcon } from "../../assets/images";
import { HelpCenterURL } from "../../constants/legacySiteURL";
import { getToken } from "../../util/localStorage";
import { GetUserLoginData } from "../../util/reduxData";
import FeedBack from "../../screens/Authentication/Feedback";
import RedirectConfirmModal from "../redirectConfirmModal";
import { useOnClickOutside } from "./Navbar";
import "./MobileNavbar.css";

let MobileNavbar = ({ closeNav }) => {
  let location = useLocation();
  let history = useHistory();
  const getLoginData = GetUserLoginData()
  const ref = useRef(null);
  const [openFeedback, setOpenFeedback] = useState(false);

  const newRefSidebar = useRef();
  const [openRedirectModal, setOpenRedirectModal] = useState(false);
  const [openRedirectModalHelp, setOpenRedirectModalHelp] = useState(false);

  let handleClick = (link) => {

    if (link?.name === "My Jobs") {
      setOpenRedirectModal(true);
    } else if (link?.subMenu) {
      history.push(link.path);
      closeNav();
      window.scrollTo(0, 0);
    } else if (link.legacy) {
      history.push(location.pathname);
      closeNav();
      window.scrollTo(0, 0);
    } else {
      history.push(link.path);
      closeNav();
      window.scrollTo(0, 0);
    }
  };
  useOnClickOutside(newRefSidebar, (event) => {
    if (
      (event.target.id !== "drawer" && event.target.id === "blackOut") ||
      event.target.id === "dropdown" ||
      event.target.id === "notifications"
    ) {
      closeNav();
    }
  });
  function handleClickEvent(event) {
    if (event.target.id === "feedback") {
      setOpenFeedback(true);
      return;
    } else if (
      (event.target.id !== "drawer" && event.target.id === "blackOut") ||
      event.target.id === "dropdown" ||
      event.target.id === "notifications"
    ) {
      closeNav();
    }
  }

  useEffect(() => {
    document.addEventListener("click", handleClickEvent, true);
    return () => {
      document.removeEventListener("click", handleClickEvent, true);
    };
  }, [ref]);

  const auth = getToken();

  return (
    <React.Fragment>
      <RedirectConfirmModal
        isOpen={openRedirectModal}
        toggleModal={() => setOpenRedirectModal(!openRedirectModal)}
        redirectUrl={
          "https://ttaconnect.ttacorp.com/TrainingProfessionals/MyJobs.aspx"
        }
      />
      <RedirectConfirmModal
        isOpen={openRedirectModalHelp}
        toggleModal={() => setOpenRedirectModalHelp(!openRedirectModalHelp)}
        redirectUrl={
          HelpCenterURL
        }
      />
      <div className="sidebarWrapper" id="blackOut">
        <div
          className="container-fluid px-0 m-0 newMobileNavSection"
          id="drawer"
        >
          <FeedBack
            openFeedBack={openFeedback}
            handleClose={() => setOpenFeedback(false)}
          />
          <div>
            {auth &&
              MenuLinks.map((link, idx) => {
                return (
                  <React.Fragment key={idx}>
                    {link.legacy ? (
                      <div key={link.name}>
                        <div
                          onClick={() => handleClick(link)}
                          className={
                            location.pathname === link.path
                              ? "mobileNavLinksSection mobileNavLinksSectionActive "
                              : "mobileNavLinksSection "
                          }
                          key={link.name}
                        >
                          {link.name === "Help Center" ? (
                            <a
                              href={link.path}
                              target="_blank"
                              rel="noopener noreferrer"
                              className={
                                location.pathname === link.path
                                  ? "mobileNavLinksActive"
                                  : "mobileNavLinks"
                              }
                            >
                              {" "}
                              {link.name}
                            </a>
                          ) : (
                            <a
                              onClick={() => handleClick(link)}
                              className={
                                location.pathname === link.path
                                  ? "mobileNavLinksActive"
                                  : "mobileNavLinks"
                              }
                            >
                              {link.name}
                            </a>
                          )}
                        </div>
                        <div className="w-100 mt-0 border-top" />
                      </div>
                    ) : link.name === "Feedback" ? (
                      <div key={link.name}>
                        <div
                          className={
                            location.pathname === link.path
                              ? "mobileNavLinksSection mobileNavLinksSectionActive "
                              : "mobileNavLinksSection "
                          }
                          key={link.name}
                          id="feedback"
                        >
                          <span
                            id="feedback"
                            className={
                              location.pathname === link.path
                                ? "mobileNavLinksActive"
                                : "mobileNavLinks"
                            }
                          >
                            {" "}
                            {link.name}
                          </span>
                        </div>
                        <div className="w-100 border-top" />
                      </div>
                    ) : (
                      <div key={link.name}>
                        <div
                          onClick={() => handleClick(link)}
                          className={
                            location.pathname === link.path
                              ? "mobileNavLinksSection mobileNavLinksSectionActive "
                              : "mobileNavLinksSection "
                          }
                          key={link.name}
                        >
                          <Link
                            to={link.path}
                            className={
                              location.pathname === link.path
                                ? "mobileNavLinksActive"
                                : "mobileNavLinks"
                            }
                          >
                            {" "}
                            {
                              link.name === "My Tasks" ?
                              !getLoginData?.agencyInstrId  ? "My Tasks" : "Tasks"
                                :
                                (!getLoginData.agencyInstrId && link.name === "Profiles") ?
                                  "My Profile" :
                                  link.name
                              }
                          </Link>
                        </div>
                        <div className="w-100 border-top" />
                      </div>
                    )}
                  </React.Fragment>
                );
              })}
            {!auth && (
              <div
                className={
                  history.location.pathname === "/submittestimonial"
                    ? "d-none"
                    : "mobileNavLinksSection"
                }
              >
                <Link
                  className="mobileNavLinks"
                  onClick={() =>
                    window.open(
                      HelpCenterURL,
                      "_blank"
                    )
                  }
                >
                  {" "}
                  Help Center
                </Link>
              </div>
            )}
          </div>
          <div className="bottonInfo d-none">
            <div className="w-100 border-top" />
            <div className="bottomContent">
              <img src={bulbIcon} alt="" className="bulb" />
              <span className="helpText">
                Looking for help in finding the right talent?
                <span className="font14Blue"> Get in touch</span> with us and we
                can help you out.
              </span>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default MobileNavbar;
