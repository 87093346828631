import Axios from "../util/axiosInstance";

export const getUserInfo = (instrId) => {
  return Axios.get(`/api/TrainerProfile/Get?instrId=${instrId}`)
 
};

export const postPreferenceApi = (payload) => {
  return Axios.post("/api/TrainerProfile/UpdatePreferences", payload)
 
};