import React, { Fragment, useState } from "react";
import { Edit } from "../../../../../assets/images/index";
import { useHistory } from "react-router-dom";
import Label from "../../../../../components/label/Label";
import "./index.scss";

function Certification({ edit, Certifications,fromTalent, client }) {
  let [itemsToShow, setitemsToShow] = useState(3);

  let history = useHistory();

  const showMore = () => {
    itemsToShow === 3
      ? setitemsToShow(Certifications?.length)
      : setitemsToShow(3);
  };

  const certificationEditFunc = () => {
    history.push("/profile/certifications/edit");
  };
  return (
    <Fragment>
      <div className={(fromTalent && !client)?"freelancerModalComponentWrap ml-0":"freelancerModalComponentWrap"}>
        <div className="d-flex justify-content-between">
          <Label
            className="font18Black boldFont mt-3 mb-0"
            label="Certifications"
          ></Label>
          {edit && (
            <img
              src={Edit}
              className="pointer"
              onClick={certificationEditFunc}
              alt=""
            />
          )}
        </div>
        {Certifications?.length > 0 && (
          <div className="">
            {Certifications?.slice(0, itemsToShow).map((item) => {
              return (
                <div key={item?.id}>
                  <div className="mb-3 pt-1 d-flex">
                    <div>
                      <h6
                        className="certificateNameList d-block mb-0"
                        style={{
                          fontWeight: "bold",
                          overflowWrap: "anywhere",
                        }}
                      >
                        {item.certificationName}
                      </h6>
                      <span className="organisationNameList mb-0 d-block ">
                        {item.vendor}
                      </span>
                      <div className="d-flex" style={{ color: "#666666" }}>
                        <div>
                          <div className="d-flex flex-wrap mt-1">
                            <span className="certificateDetailsTitle">
                              Issued On:
                            </span>{" "}
                            <span className="certificateDetailsSubTitle ml-1">
                              {item?.startMonth?.substr(0, 3)} {item?.startYear}
                            </span>
                          </div>
                          {item.credentialID && (
                            <div className="d-flex flex-wrap mt-1">
                              <span className="certificateDetailsTitle">
                                Credential ID:
                              </span>{" "}
                              <span className="certificateDetailsSubTitle ml-1">
                                {item?.credentialID}
                              </span>
                            </div>
                          )}
                          {item.credentialLink && (
                            <div className="d-flex flex-wrap mt-1">
                              <span className="certificateDetailsTitle">
                                Credential URL:
                              </span>{" "}
                              <span 
                                className="font14Blue ml-1" 
                                onClick={()=>{
                                      window.open(item.credentialLink, '_blank');
                                    }}
                                >
                                {item.credentialLink}
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
        {Certifications?.length > 3 && (
          <div
            style={{
              justifyContent: "center",
              textAlign: "center",
            }}
            className="profileEducationMore"
          >
            <span className="profileEducationMore" onClick={showMore}>
              {itemsToShow !== 3 ? "Less" : "More"}
            </span>
          </div>
        )}
      </div>
    </Fragment>
  );
}

export default Certification;
