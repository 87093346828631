import React, { useRef, useState } from "react";
import ReactCrop, {
    centerCrop,
    makeAspectCrop
  } from 'react-image-crop'
import Button from "../button";
import Modal from "../modal"
import "./imageCropper.scss";
import 'react-image-crop/src/ReactCrop.scss';

const centerAspectCrop = (
    mediaWidth,
    mediaHeight,
    aspect
  ) => {
    return centerCrop(
      makeAspectCrop(
        {
          unit: 'px',
          width: 150,
        },
        aspect,
        mediaWidth,
        mediaHeight,
      ),
      mediaWidth,
      mediaHeight,
    )
  }

const ImageCropper = ({
    fullImageSrc,
    isOpen,
    toggleModal,
    uploadCroppedImage
}) => {
    const imgRef = useRef()
    const [cropConfigData, setCropConfig] = useState(
        // default crop config
        {
        unit: '%', // Can be 'px' or '%'
        x: 25,
        y: 25,
        width: 30,
        height: 30
        }
    );

    const centerCrop = () => {
        if (imgRef.current) {
            const { width, height } = imgRef.current
            setCropConfig(centerAspectCrop(width, height, 1))
          }
    }

    function getCroppedImage(sourceImage=imgRef) {
        // creating the cropped image from the source image
        const canvas = document.createElement('canvas');
        const scaleX =  sourceImage.current.naturalWidth / sourceImage.current.width;
        const scaleY = sourceImage.current.naturalHeight / sourceImage.current.height;
        canvas.width = cropConfigData.width;
        canvas.height = cropConfigData.height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(
            sourceImage.current,
            cropConfigData.x * scaleX,
            cropConfigData.y * scaleY,
            cropConfigData.width * scaleX,
            cropConfigData.height * scaleY,
            0,
            0,
            cropConfigData.width,
            cropConfigData.height
        );
        return canvas.toDataURL('image/jpeg');
    }

    const saveCropped = () => {
        const imgData = getCroppedImage()
        uploadCroppedImage(imgData)
    }

    return (
        <>
        <Modal
            isOpen={isOpen}
            size="medium"
            hideCloseButton={true}
            toggleModal={toggleModal}
            title={
                <div>
                    <div className="font18Black boldFont">Crop Photo</div>
                    <div className="font12Grey fontNormal noWrap">Click and drag the box to crop the photo</div>
                </div>
            }
            disableBackdropToggle={true}
            containerClass={"cropperModal"}
        >
            <form>
                <div className="cropperContentWrapper mt-5">
                    {
                        fullImageSrc &&
                        <ReactCrop
                            src={fullImageSrc}
                            crop={cropConfigData}
                            onChange={(cropConfig) => setCropConfig(cropConfig)}
                            aspect={1}
                            minHeight={40}
                            keepSelection={true}
                        >
                            <img
                                src={fullImageSrc}
                                ref={imgRef}
                                className="cropperImage"
                                onLoad={centerCrop}
                                alt="cropper"
                            />
                        </ReactCrop>
                    }
                </div>
                <div className="croppermodalButtonHolder">
                    <div className="border-top" />
                    <div className="text-center mt-4 mb-0 float-bottom">
                        <Button
                            label="Cancel"
                            onClick={toggleModal}
                            className="btn-light mx-2 cancelButtonDelete"
                        />
                        <Button
                            label="Crop & Save"
                            type="button"
                            className="btn-blue mx-2"
                            onClick={saveCropped}
                        />
                    </div>
                </div>
            </form>
        </Modal>
        </>
    )
}
export default ImageCropper;