/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from "react";
import classnames from "classnames";
import { crossIcon } from "../../assets/images";
import "./index.css";

const Tooltip = (props) => {
  let showCrossIcon = props.crossIcon !== null ? props.crossIcon : true
  let timeout;
  const ref = useRef();
  const [active, setActive] = useState(false);

  const showTip = () => {
    timeout = setTimeout(() => {
      setActive(true);
    }, props.delay || 400);
  };

  const hideTip = () => {
    clearInterval(timeout);
    setActive(false);
  };

  const handleClickEvent = (event) => {
    if (event.target?.id === "url") {
      window.open("https://thetrainingassociates.com/", '_blank');
    } else {
      hideTip()
    }

  }
  useEffect(() => {
    document.addEventListener("click", handleClickEvent, true);
    return () => {
      document.removeEventListener("click", handleClickEvent, true);
    };
  }, [ref]);

  return (
    <div
      className="Tooltip-Wrapper"
      // When to show the tooltip
      onMouseEnter={showTip}
      onClick={showTip}
      onMouseLeave={hideTip}
    >
      {/* Wrapping */}
      {props.children}
      {active && (
        <div className={classnames(`Tooltip-Tip ${props.direction || "top"} ${props?.accountSettingStyle ? "accountSettingStyle" : '' || ""}`, props.classnames)} id="info">
          {/* Content */}
          <div className={classnames("d-flex justify-content-between", props.classnames1 || "")}>
            {props.content}
            {showCrossIcon && <div className="pointer">
              <img src={crossIcon} alt="" />
            </div>}
          </div>
        </div>
      )}
    </div>
  );
};

export default Tooltip;
