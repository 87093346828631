import { HelpCenterURL } from "./legacySiteURL";

const allLinks = [
  {
    name: "Dashboard",
    path: "/client-dashboard",
    subMenu: false,
    legacy: false,
    active: "client-dashboard",
  },
  {
    name: "Job Center",
    path: "/client-job/jobStatussection/1",
    subMenu: false,
    legacy: false,
    active: "client-job",
  },
  {
    name: "Talent",
    path: "/talents",
    subMenu: false,
    legacy: false,
    active: "talents",
  },
  {
    name: "Invoices",
    path: "/client-payment",
    subMenu: false,
    legacy: false,
    active: "client-payment",
  },
  {
    name: "Help Center",
    path: HelpCenterURL,
    subMenu: false,
    legacy: true,
  }
]
const GetMenuLink = () => {

  return [...allLinks]

}


export default GetMenuLink;
