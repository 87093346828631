/* eslint-disable no-use-before-define */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { Suspense, useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { useIdleTimer } from "react-idle-timer";
import { ToastContainer } from "react-toastify";
import addNotification from "react-push-notification";
import { sendMsg } from "./assets/images";
import { getTwilioChatToken } from "./services/chatServices";
import { getToken, getCompanyId, getWebRole, getChatUser, getInstrId } from "./util/localStorage";
import Footer from "./components/footer/Footer";
import networkDetector from "./util/networkDetector";
import FeedBack from "./screens/Authentication/Feedback";
import ClientNavbar from "./components/clientNavbar";
import BetaRibbon from "./components/betaRibbon";
import UploadProgress from "./screens/UploadProgress";
import ScrollToTop from "./util/scrollToTop";
import SubmitProposals from "./screens/JobCenter/Components/JobDetails/components/SubmitProposals";
import "react-toastify/dist/ReactToastify.css";
import 'react-tippy/dist/tippy.css';
import "./App.scss";

const Portfolio = React.lazy(() =>
  import("./screens/Onboarding/portfolio")
);

const Files = React.lazy(() =>
  import("./screens/Files")
);

const JobFiles = React.lazy(() =>
  import("./screens/JobFiles")
);

const LandingScreen = React.lazy(() =>
  import("./screens/LandingScreen/landingScreen")
);

const ResendActivationEmail = React.lazy(() =>
  import("./screens/Authentication/ResendActivationEmail/resendActivationEmail")
);
// const SubmitProposal = React.lazy(() =>
//   import("./screens/SubmitProposal/submitProposal")
// );

const MyInformation = React.lazy(() =>
  import("./screens/Onboarding/myInformation/MyInformation")
);

const WorkExperienceMain = React.lazy(() =>
  import("./screens/Onboarding/workExperience")
);
const Certifications = React.lazy(() =>
  import("./screens/Onboarding/certificates/certificates")
);

const CertificationsEdit = React.lazy(() =>
  import("./screens/Onboarding/certificates/certificates")
);

const PortfolioEdit = React.lazy(() =>
  import("./screens/Onboarding/portfolio")
);

const WelcomeToTTA = React.lazy(() =>
  import("./screens/Authentication/welcomeScreen/WelcomeToTTA")
);
const UserProfileView = React.lazy(() =>
  import("./screens/Dashboard/Screens/UserProfileView/UserProfileView")
);

const UserView = React.lazy(() =>
  import("./screens/Dashboard/Screens/UsersView/UsersView")
);

const StatsDashboard = React.lazy(() =>
  import("./screens/StatsDashboard")
);

const JobCenter = React.lazy(() => import("./screens/JobCenter"));

const LetsGetStartedConfirmScreen = React.lazy(() =>
  import(
    "./screens/Authentication/LetsGetStartedConfirmScreen"
  )
);

const ForgotPassword = React.lazy(() =>
  import("./screens/Authentication/ForgotPassword")
);

const PasswordChangeConfirmation = React.lazy(() =>
  import(
    "./screens/Authentication/PasswordChangeConfirmation"
  )
);

const ForgotPasswordConfirmationBox = React.lazy(() =>
  import(
    "./screens/Authentication/ForgotPasswordConfirmationBox"
  )
);

const EmailVerificationLink = React.lazy(() =>
  import("./screens/Authentication/EmailVerification")
);

const ForgetPasswordEmail = React.lazy(() =>
  import("./screens/Authentication/ForgetPasswordEmail")
);

const WelcomeToTTASignUp = React.lazy(() =>
  import("./screens/Authentication/WelcomeToTTA/welcomeToTTASignUp")
);

const SignupTypeSelector = React.lazy(() =>
  import("./screens/Authentication/SignupTypeSelector/signupTypeSelector")
);

const NewSignUp = React.lazy(() =>
  import("./screens/Authentication/Signup")
);

const TermsAndConditions = React.lazy(() =>
  import("./screens/TermsAndConditions")
);

const Login = React.lazy(() => import("./screens/Authentication/Login"));

const OnboardingLayout = React.lazy(() =>
  import("./Layouts/onboardingLayout/OnboardingLayout")
);
const Logout = React.lazy(() => import("./components/Logout/Logout"));

const WelcomeUser = React.lazy(() =>
  import("./screens/Onboarding/welcomeUser")
);

const CompanyInformation = React.lazy(() =>
  import("./screens/Onboarding/CompanyInformation/CompanyInformation")
);

const Roles = React.lazy(() => import("./screens/Onboarding/Roles"));

const Education = React.lazy(() =>
  import("./screens/Onboarding/education")
);
const EducationEdit = React.lazy(() =>
  import("./screens/Onboarding/education")
);

const AboutMe = React.lazy(() =>
  import("./screens/Onboarding/aboutMe/AboutMe")
);

const Resume = React.lazy(() =>
  import("./screens/Onboarding/resume")
);

const RolesAndSkils = React.lazy(() =>
  import("./screens/Onboarding/RolesAndSkills")
);

const NotificationViewAll = React.lazy(() =>
  import("./screens/Dashboard/ViewAllNotification/ViewAllNotification")
);

const NotFoundLogout = React.lazy(() =>
  import("./screens/NotFoundLogout/NotFoundLogout")
);

const NotFound = React.lazy(() => import("./screens/NotFound/NotFound"));

const Navbar = React.lazy(() => import("./components/navbar/Navbar"));

const ImpersonateLink = React.lazy(() =>
  import("./screens/Authentication/Impersonate")
);

const RequestTestimonial = React.lazy(() =>
  import("./screens/RequestTestimonial/RequestTestimonial")
);

const SubmitTestimonial = React.lazy(() =>
  import("./screens/SubmitTestimonial")
);

const SubmitTestimonialConfirm = React.lazy(() =>
  import(
    "./screens/SubmitTestimonialConfirmation"
  )
);

const TestimonialList = React.lazy(() =>
  import("./screens/TestimonialList")
);

const TalentsJobDetails = React.lazy(() =>
  import("./screens/JobCenter/Components/JobDetails/jobDetails")
);

const InProgressJobDetails = React.lazy(() =>
  import(
    "./screens/JobCenter/Components/InProgress/InProgressJobDetails/inProgressJobDetails"
  )
);
const SavedJobDetails = React.lazy(() =>
  import("./screens/JobCenter/Components/JobDetails/SavedJobDetails")
);

const ProposalSubmittedJobDetails = React.lazy(() =>
  import(
    "./screens/JobCenter/Components/JobDetails/ProposalSubmittedJobDetails"
  )
);
const TalentAccountLayout = React.lazy(() =>
  import("./Layouts/TalentAccountLayout/TalentAccountLayout")
);

const ManageJobAlert = React.lazy(() =>
  import("./screens/TalentAccountSetting/ManageJobAlert/ManageJobAlert")
);
const TalentpaymentInformation = React.lazy(() =>
  import("./screens/TalentAccountSetting/Payment Info/paymentInformation")
);

const LegalDocuments = React.lazy(() =>
  import("./screens/TalentAccountSetting/LegalDocuments")
);

const ManageReviews = React.lazy(() =>
  import("./screens/TalentAccountSetting/manageReviews")
);

const ManageReference = React.lazy(() =>
  import("./screens/TalentAccountSetting/Manage reference")
)
const AddProfesionalRefernce = React.lazy(() =>
  import("./screens/TalentAccountSetting/Manage reference/AddReferences")
)

const NotificationSetting = React.lazy(() =>
  import("./screens/TalentAccountSetting/NotificationSetting/Notification")
);

const AddJobAlert = React.lazy(() =>
  import(
    "./screens/TalentAccountSetting/ManageJobAlert/AddJobAlert"
  )
);
const CompletedJobDetails = React.lazy(() =>
  import(
    "./screens/JobCenter/Components/Completed/CompletedJobDetails/CompletedJobDetails"
  )
);
const SignDocuments = React.lazy(() =>
  import(
    "./screens/TalentAccountSetting/LegalDocuments/signDocuments"
  )
);
const ViewSignedDocuments = React.lazy(() =>
  import("./screens/JobCenter/Components/InProgress/InProgressJobDetails/inProgressJobTabs/jobDetails/SignedDocument")
);
const ManageMembers = React.lazy(() =>
  import("./screens/AgencyAccountSettings/manageMembers")
);

const SignWorkOrder = React.lazy(() =>
  import(
    "./screens/JobCenter/Components/InProgress/InProgressJobDetails/inProgressJobTabs/signWorkOrder"
  )
);

const AcceptWorkOrder = React.lazy(() =>
  import(
    "./screens/JobCenter/Components/InProgress/acceptOrder"
  )
);

const ViewDocuments = React.lazy(() =>
  import(
    "./screens/TalentAccountSetting/LegalDocuments/viewDocuments"
  )
);
const AllPayments = React.lazy(() =>
  import("./screens/AllPayments/allPayments")
);
const TalentCalendar = React.lazy(() =>
  import("./screens/TalentCalendar/CalendarView")
);
const RejectedJobDetail = React.lazy(() =>
  import(
    "./screens/JobCenter/Components/Completed/CompletedJobDetails/RejectedJobDetails"
  )
);
const TravelFeeAcceptance = React.lazy(() =>
  import(
    "./screens/TalentAccountSetting/LegalDocuments/travelFeeAcceptance"
  )
)

const W9SignForm = React.lazy(() =>
  import("./screens/W9SignForm")
);

const W8SignForm = React.lazy(() =>
  import("./screens/W8SignForm")
);

const W8Selector = React.lazy(() => import("./screens/W8Selector"))


/**************************************Client Links*****************************************/

const SignUpInvite = React.lazy(() =>
  import("./screens/ClientScreens/Authentication/SignupInvite")
);

const ClientLegalDocuments = React.lazy(() =>
  import("./screens/ClientScreens/Account/LegalDocuments")
);

const ClientOnboardingLayout = React.lazy(() =>
  import("./Layouts/clientOnboardingLayout/clientOnboardingLayout")
);

const ClientAccountLayout = React.lazy(() =>
  import(
    "./Layouts/clientOnboardingLayoutAccount/ClientOnboardingLayoutAccount"
  )
);

const ClientOnboardingLayoutJob = React.lazy(() =>
  import("./Layouts/clientOnboardingLayoutJob/JobCreationLayout")
);

const JobStatusLayout = React.lazy(() =>
  import("./Layouts/clientOnboardingLayoutJobStatus/JobCreationLayout")
);

const FreelanceDetail = React.lazy(() =>
  import("./screens/ClientScreens/FreelanceDetail")
);

const JobPostLayout = React.lazy(() =>
  import("./screens/ClientScreens/JobPost/JobPostLayout/JobPostLayout")
);

const JobPostSection = React.lazy(() =>
  import("./screens/ClientScreens/JobPost/SectionsJob")
);

const JobEntityLayout = React.lazy(() =>
  import("./Layouts/jobEntityLayout/jobEntityLayout")
);

const JobEntitySections = React.lazy(() =>
  import("./Layouts/jobEntityLayout/jobEntitySection")
);

const JobStatusSections = React.lazy(() =>
  import("./screens/ClientScreens/JobStatus/Sections")
);

const CompanyContact = React.lazy(() =>
  import("./screens/ClientOnboarding/CompanyContact")
);

const MyInformationAccount = React.lazy(() =>
  import(
    "./screens/ClientScreens/Account/ClientMyInformation"
  )
);

const ChangeEmailAccount = React.lazy(() =>
  import("./screens/ClientScreens/Account/ChangeEmail")
);

const ChangePasswordAccount = React.lazy(() =>
  import("./screens/ClientScreens/Account/ChangePassword")
);

const CompanyProfileAccount = React.lazy(() =>
  import("./screens/ClientScreens/Account/CompanyProfile")
);

const CompanyLocationAccount = React.lazy(() =>
  import(
    "./screens/ClientScreens/Account/ClientCompanyLocation/ClientCompanyLocation"
  )
);
const BillingInformation = React.lazy(() =>
  import(
    "./screens/ClientScreens/Account/BillingInformation"
  )
);

const ConfirmClientOrder = React.lazy(() =>
  import(
    "./screens/ClientScreens/JobEntity/confirmOrder"
  )
)

const SignSOWOrder = React.lazy(() =>
  import("./screens/ClientScreens/JobEntity/signClientSow")
)

const ViewProposal = React.lazy(() =>
  import("./screens/ClientScreens/JobEntity/viewProposal")
)

const CompanyContactAccount = React.lazy(() =>
  import("./screens/ClientScreens/Account/CompanyContact")
);
const ClientWelcome = React.lazy(() =>
  import("./screens/ClientOnboarding/welcomeUser")
);
const ClientCompanyProfile = React.lazy(() =>
  import("./screens/ClientOnboarding/CompanyProfile")
);
const WelcomeUserClient = React.lazy(() =>
  import("./screens/ClientScreens/Onboarding/welcomeUser")
);
const OnboardingLayoutClient = React.lazy(() =>
  import("./Layouts/onboardingLayoutClient/onboardingLayoutClient")
);
const ClientMyInformation = React.lazy(() =>
  import("./screens/ClientOnboarding/ClientMyInformation")
);
const ClientJobNew = React.lazy(() =>
  import("./screens/ClientScreens/JobSection/CreateNewJob")
);

const ClientJobDetails = React.lazy(() =>
  import("./screens/ClientScreens/JobSection/JobDetails")
);

const ClientDashboard = React.lazy(() =>
  import("./screens/ClientScreens/ClientDashboard/dashboard")
);

const Talent = React.lazy(() =>
  import("./screens/ClientScreens/Talent")
);
const ClientTalentInformation = React.lazy(() =>
  import(
    "./screens/ClientScreens/JobSection/TalentInformation"
  )
);

const ClientAudience = React.lazy(() =>
  import("./screens/ClientScreens/JobSection/Audience")
);

const ClientJobSpecification = React.lazy(() =>
  import("./screens/ClientScreens/JobSection/JobSpecification/JobSpecification")
);

const ClientBudget = React.lazy(() =>
  import("./screens/ClientScreens/JobSection/Budget")
);

const JobCompanyContacts = React.lazy(() =>
  import("./screens/ClientScreens/JobSection/CompanyContacts")
);

const ClientReviewPost = React.lazy(() =>
  import("./screens/ClientScreens/JobSection/ReviewPost")
);
// const CompanyDocuments = React.lazy(() =>
//   import("./screens/ClientScreens/Documents/documents")
// );

const ClientPayment = React.lazy(() => import("./screens/ClientScreens/ClientInvoiceSummary"))

const ClientInvoiceSummary = React.lazy(() => import("./screens/ClientScreens/ClientInvoiceSummary"))

const PaytraceCheck = React.lazy(() => import("./screens/ClientScreens/PaytraceCheck"))

/*******************************************************************************/

const AgencyWelcome = React.lazy(() =>
  import("./screens/Authentication/AgencyWelcome")
)

const PublicReview = React.lazy(() => import("./screens/PublicReviews"));

let App = () => {
  const Conversations = require("@twilio/conversations");
  const emailGet = getChatUser();
  const [twilioChatToken, setTwilioChatToken] = useState(null);
  const [userClient, setUserClient] = useState(null);
  let [auth, setAuth] = useState(false);
  const data = getCompanyId();
  const role = getWebRole();
  const token = getToken();
  const path = window.location.pathname;

  function isChatActive(state) {
    // If im messaging 
    if( state?.author === emailGet){
      return true;
    }
    // If tab is not visible
    if (document.hidden) {
      return false;
    }

    if (path !== `/jobcenter/inprogress/job-details/${state?.attributes?.projectId}/5` && path !== `/client-job/job-entity/7/${state?.attributes?.projectId}`) {
      return false;
    }

    const currentSearch = window.location.search;
    const searchParams = new URLSearchParams(currentSearch);

    if (state?.attributes?.groupName) {
      return searchParams.get("groupId") === state.attributes?.groupId
    } else {
      return searchParams.get("email") === state?.author
    }
  }

  const instrId = getInstrId()

  const [titleChange, setTitleChange] = useState(false)

  const changeTitle = (msg) => {
    document.title = msg
  }

  const handleVisibilityChange = () => {
    if (document.hidden) {
      // Tab is not visible (user switched to another tab or minimized the browser)
      // console.log('Tab is not visible');
    } else {
      // Tab is visible (user switched back to the tab)
      // console.log('Tab is visible');
      setTitleChange(false)
    }
  };

  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange);

    // Cleanup the event listener when the component unmounts
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    let intervalId
    if (titleChange) {
      intervalId = setInterval(() => {
        // Update the title based on your logic
        changeTitle(document.title === `New Message` ? `TTA Connect` : "New Message");
      }, 1000); // Update every 1000 milliseconds (1 second)
    } else {
      setTitleChange(false);
      clearInterval(intervalId);
      changeTitle("TTA Connect");
    }

    // Clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [titleChange])

  const sendNewMessageNotification = (e) => {
    if (e && !isChatActive(e.state) && e.state.attributes?.main) {
      setTitleChange(true)
      addNotification({
        title: e.state.attributes?.groupName ? `${e.state.attributes?.name} - ${e.state.attributes?.groupName}` : `${e.state.attributes?.name}`,
        message: e.state.attributes?.type === "media" ? `${e.state.attributes?.fileArray.length} file(s) sent` : e.state.body,
        duration: 5000,
        icon: sendMsg,
        onClick: () => {
          if (data > 0 && role === "3") {    // Client
            if (e?.state?.attributes?.groupName) {
              window.open(`${window.location.origin}/client-job/job-entity/7/${e.conversation?._internalState?.attributes?.projectId}?groupId=${e.state.attributes?.groupId}`, "_blank")
            } else {
              window.open(`${window.location.origin}/client-job/job-entity/7/${e.conversation?._internalState?.attributes?.projectId}?email=${e.state?.author}`, "_blank")
            }
          } else if (instrId > 0 && role === "2") { // Talent
            if (e?.state?.attributes?.groupName) {
              window.open(`${window.location.origin}/jobcenter/inprogress/job-details/${e.conversation?._internalState?.attributes?.projectId}/5?groupId=${e.state.attributes?.groupId}`, "_blank")
            } else {
              window.open(`${window.location.origin}/jobcenter/inprogress/job-details/${e.conversation?._internalState?.attributes?.projectId}/5?email=${e.state?.author}`, "_blank")
            }
          }
        },
        native: true // when using native, your OS will handle theming.
      });
    }
  }

  useEffect(() => {
    if (auth) {
      getLoggedInUsersChatToken()
    }
  }, [auth])

  useEffect(() => {
    if (twilioChatToken) {
      createLoggedInUserClient()
    }
  }, [twilioChatToken])

  useEffect(() => {
    if (userClient) {
      userClient?.on("messageAdded", e => {
        sendNewMessageNotification(e)
      })
    }
    return () => {
      if (!auth) {
        userClient?.removeAllListeners();
        userClient?.shutdown();
      }
    }
  }, [userClient])

  const createLoggedInUserClient = async () => {
    const userClientIns = new Conversations.Client(twilioChatToken)
    if (userClientIns) {
      setUserClient(userClientIns)
    }
  }

  const getLoggedInUsersChatToken = () => {
    getTwilioChatToken({ email: emailGet })
      .then((res) => {
        setTwilioChatToken(res.data)
      }).catch(e => {
        console.log("Eee error", e)
      })
  }

  useEffect(() => {
    setAuth(getToken());
  }, [token]);

  const handleOnIdle = (event) => {
    if (token) {
      window.location.href = "/logout";
    }
  };

  useIdleTimer({
    timeout: 1000 * 60 * 45,
    onIdle: handleOnIdle,
    debounce: 250,
  });

  return (
    <>
      {!auth ? (
        <Suspense fallback={null}>
          <Router>
            <ScrollToTop />
            <div>
              <Navbar />
              <Footer />
              <Switch>
                <Route exact path="/client-job/jobpost/:jobId">
                  <JobPostLayout section={<JobPostSection preview={true} />} preview={true} />
                </Route>
                <Route exact path="/reviews/">
                  <PublicReview />
                </Route>
                <Route exact path="/proposal">
                  <ViewProposal preview={true} />
                </Route>
                <Route path="/signupinvite">
                  <SignUpInvite />
                </Route>
                <Route path="/notification-settings">
                  <NotificationSetting isPublic={true} />
                </Route>
                <Route exact path="/resend-activation-email">
                  <ResendActivationEmail />
                </Route>
                <Route exact path="/sign-up">
                  <SignupTypeSelector />
                </Route>
                <Route exact path="/sign-up/client">
                  <NewSignUp />
                </Route>
                <Route exact path="/letsGetStartedConfirmScreen">
                  <LetsGetStartedConfirmScreen />
                </Route>
                <Route exact path="/login">
                  <Login />
                </Route>
                <Route exact path="/forgetpassword">
                  <ForgotPassword />
                </Route>
                <Route path="/submit-testimonial">
                  <SubmitTestimonial />
                </Route>
                <Route path="/submit-testimonial-confirm">
                  <SubmitTestimonialConfirm />
                </Route>
                <Route exact path="/passwordChangeConfirmation">
                  <PasswordChangeConfirmation />
                </Route>
                <Route exact path="/forgotPasswordConfirmationBox">
                  <ForgotPasswordConfirmationBox />
                </Route>
                <Route path="/emailverification">
                  <EmailVerificationLink />
                </Route>
                <Route path="/forgetpasswordemail">
                  <ForgetPasswordEmail />
                </Route>
                <Route path="/welcome">
                  <WelcomeToTTASignUp />
                </Route>
                <Route path="/sign-up/talent">
                  <NewSignUp />
                </Route>
                <Route exact path="/termsAndConditions">
                  <TermsAndConditions />
                </Route>
                <Route exact path="/">
                  <WelcomeToTTASignUp />
                </Route>
                <Route path="/lmiiwatta">
                  <ImpersonateLink />
                </Route>
                <Route exact path="/preview">
                  <FreelanceDetail preview={true} />
                </Route>
                <Route exact path="/view-job-details/:jobId/:hash">
                  <TalentsJobDetails isPublic={true} />
                </Route>
                <Route path="*" exact>
                  <NotFoundLogout />
                </Route>
              </Switch>
              <ToastContainer autoClose={5000} />
            </div>
          </Router>
        </Suspense>
      ) : data > 0 && role === "3" ? (
        <Suspense fallback={null}>
          <Router>
            <ScrollToTop />
            <div>
              <ClientNavbar />
              <BetaRibbon />
              <Footer />
              <div className="DesktopfeedBack">
                <FeedBack />
              </div>
              <UploadProgress />
              <Switch>
                <Route exact path="/login">
                  <Login />
                </Route>
                <Route exact path="/client-job/jobpost/:jobId">
                  <JobPostLayout section={<JobPostSection />} />
                </Route>
                <Route exact path="/client/legal-docs">
                  <ClientAccountLayout section={<ClientLegalDocuments />} />
                </Route>
                <Route exact path="/view-document">
                  <ViewDocuments client={true} />
                </Route>
                <Route exact path="/preview">
                  <FreelanceDetail />
                </Route>
                <Route path="/lmiiwatta">
                  <ImpersonateLink />
                </Route>
                <Route exact path="/">
                  <LandingScreen />
                </Route>
                <Route exact path="/welcome">
                  <WelcomeToTTA />
                </Route>
                <Route exact path="/paytrace">
                  <PaytraceCheck />
                </Route>
                <Route exact path="/reviews/">
                  <PublicReview isClient={true} />
                </Route>
                <Route exact path="/client-profile/company-contact">
                  <ClientOnboardingLayout section={<CompanyContact />} />
                </Route>
                <Route exact path="/client-profile/welcome-user">
                  <ClientOnboardingLayout section={<ClientWelcome />} />
                </Route>
                <Route exact path="/client-profile/company-profile">
                  <ClientOnboardingLayout section={<ClientCompanyProfile />} />
                </Route>
                <Route exact path="/client-profile/my-information">
                  <ClientOnboardingLayout section={<ClientMyInformation />} />
                </Route>

                <Route exact path="/client-profile/myinformation-account">
                  <ClientAccountLayout section={<MyInformationAccount />} />
                </Route>

                <Route exact path="/client-profile/changeemail">
                  <ClientAccountLayout section={<ChangeEmailAccount />} />
                </Route>

                <Route exact path="/client-profile/changepassword">
                  <ClientAccountLayout section={<ChangePasswordAccount />} />
                </Route>
                <Route exact path="/termsAndConditions">
                  <TermsAndConditions />
                </Route>
                <Route exact path="/client-profile/client-profile-account">
                  <ClientAccountLayout section={<CompanyProfileAccount />} />
                </Route>
                <Route exact path="/client-profile/client-location-account">
                  <ClientAccountLayout section={<CompanyLocationAccount />} />
                </Route>
                <Route exact path="/client-profile/billing-info">
                  <ClientAccountLayout section={<BillingInformation />} />
                </Route>
                <Route exact path="/client-profile/notification-settings/:tabId">
                  <ClientAccountLayout section={<NotificationSetting client={true} />} />
                </Route>
                <Route exact path="/client-profile/client-contact-account">
                  <ClientAccountLayout section={<CompanyContactAccount />} />
                </Route>
                <Route exact path="/client-profile-job/create-new-job">
                  <ClientJobNew />
                </Route>
                <Route exact path="/client-dashboard/:tabId">
                  <ClientDashboard />
                </Route>
                <Route exact path="/client-dashboard">
                  <ClientDashboard />
                </Route>
                <Route exact path="/talents">
                  <Talent />
                </Route>
                <Route exact path="/client-job/jobstatussection/:tabId">
                  <JobStatusLayout section={<JobStatusSections />} />
                </Route>
                <Route exact path="/freelancedetail">
                  <FreelanceDetail />
                </Route>
                <Route exact path="/client-job/jobpost/:jobId">
                  <JobPostLayout section={<JobPostSection />} />
                </Route>
                <Route exact path="/client-job/job-entity/:tabId/:jobId">
                  <JobEntityLayout section={<JobEntitySections />} />
                </Route>

                <Route exact path="/client-profile-job/job-details">
                  <ClientOnboardingLayoutJob section={<ClientJobDetails />} />
                </Route>

                <Route exact path="/edit/client-profile-job/job-details">
                  <ClientOnboardingLayoutJob
                    section={<ClientJobDetails />}
                    sidebarHide={true}
                  />
                </Route>

                <Route exact path="/client-profile-job/talent-information">
                  <ClientOnboardingLayoutJob
                    section={<ClientTalentInformation />}
                  />
                </Route>

                <Route exact path="/edit/client-profile-job/talent-information">
                  <ClientOnboardingLayoutJob
                    section={<ClientTalentInformation />}
                    sidebarHide={true}
                  />
                </Route>

                <Route exact path="/client-profile-job/audience">
                  <ClientOnboardingLayoutJob section={<ClientAudience />} />
                </Route>

                <Route exact path="/edit/client-profile-job/audience">
                  <ClientOnboardingLayoutJob
                    section={<ClientAudience />}
                    sidebarHide={true}
                  />
                </Route>

                <Route exact path="/client-profile-job/job-specification">
                  <ClientOnboardingLayoutJob
                    section={<ClientJobSpecification />}
                  />
                </Route>

                <Route exact path="/edit/client-profile-job/job-specification">
                  <ClientOnboardingLayoutJob
                    section={<ClientJobSpecification />}
                    sidebarHide={true}
                  />
                </Route>
                <Route exact path={`/notificationviewall`}>
                  <NotificationViewAll />
                </Route>
                <Route exact path="/client-profile-job/budget">
                  <ClientOnboardingLayoutJob section={<ClientBudget />} />
                </Route>
                <Route exact path="/client-profile-job/company-contacts">
                  <ClientOnboardingLayoutJob section={<JobCompanyContacts />} />
                </Route>
                <Route exact path="/documents/:tabId">
                  <Files client={true} />
                </Route>
                {/* <Route exact path="/profile/changeprimarypassword">
                  <ChangePrimaryPassword />
                </Route> */}
                <Route exact path="/edit/client-profile-job/budget">
                  <ClientOnboardingLayoutJob
                    section={<ClientBudget />}
                    sidebarHide={true}
                  />
                </Route>
                <Route exact path="/client-profile-job/company-contacts">
                  <ClientOnboardingLayoutJob section={<JobCompanyContacts />} />
                </Route>

                <Route exact path="/client-profile-job/review">
                  <ClientOnboardingLayoutJob section={<ClientReviewPost />} />
                </Route>

                <Route exact path="/client-payment" >
                  <ClientPayment talent={false} />
                </Route>
                <Route exact path="/invoice-summary" >
                  <ClientInvoiceSummary />
                </Route>
                {/* <Route exact path="/files/:tabId">
                  <Files client={true} />
                </Route> */}
                <Route exact path="/project/files/:projectId">
                  <JobFiles client={true} />
                </Route>
                <Route exact path="/logout">
                  <Logout />
                </Route>
                <Route exact path="/view-work-document">
                  <AcceptWorkOrder view={true} />
                </Route>
                <Route exact path="/sign-document">
                  <SignDocuments />
                </Route>
                <Route exact path="/sign-work">
                  <SignWorkOrder />
                </Route>
                <Route exact path="/sign-sow">
                  <SignSOWOrder docType="SOW" />
                </Route>
                <Route exact path="/sign-nda">
                  <SignSOWOrder docType="NDA" />
                </Route>
                <Route exact path="/sign-msa">
                  <SignSOWOrder docType="MSA" />
                </Route>
                <Route exact path="/sign-coc">
                  <SignSOWOrder docType="COC" />
                </Route>
                <Route exact path="/proposal">
                  <ViewProposal />
                </Route>
                <Route exact path="/confirm-order">
                  <ConfirmClientOrder />
                </Route>


                <Route path="*" exact>
                  <NotFound />
                </Route>
              </Switch>
              <ToastContainer autoClose={5000} />
            </div>
          </Router>
        </Suspense>
      ) : data > 0 && role === "1" ? (
        <Suspense fallback={null}>
          <Router>
            <ScrollToTop />
            <div>
              <ClientNavbar />
              <BetaRibbon />
              <Footer />
              <div className="DesktopfeedBack">
                <FeedBack />
              </div>
              <UploadProgress />
              <Switch>
                <Route exact path="/login">
                  <Login />
                </Route>
                {/* <Route exact path="/files/:tabId">
                  <Files client={true} />
                </Route> */}
                <Route exact path="/project/files/:projectId">
                  <JobFiles client={true} />
                </Route>
                <Route exact path="/welcome">
                  <WelcomeToTTA />
                </Route>
                <Route exact path="/preview">
                  <FreelanceDetail />
                </Route>
                <Route exact path="/termsAndConditions">
                  <TermsAndConditions />
                </Route>
                <Route path="/lmiiwatta">
                  <ImpersonateLink />
                </Route>
                <Route exact path="/client-job/jobpost/:jobId">
                  <JobPostLayout section={<JobPostSection />} />
                </Route>
                <Route exact path="/client-profile/welcome-user">
                  <ClientOnboardingLayout section={<ClientWelcome />} />
                </Route>
                <Route exact path="/client-profile/client-profile-account">
                  <ClientAccountLayout section={<CompanyProfileAccount />} />
                </Route>
                <Route exact path="/client/legal-docs">
                  <ClientAccountLayout section={<ClientLegalDocuments />} />
                </Route>
                <Route exact path="/view-document">
                  <ViewDocuments client={true} />
                </Route>
                <Route exact path="/view-work-document">
                  <AcceptWorkOrder view={true} />
                </Route>
                <Route exact path="/sign-sow">
                  <SignSOWOrder docType="SOW" />
                </Route>
                <Route exact path="/sign-nda">
                  <SignSOWOrder docType="NDA" />
                </Route>
                <Route exact path="/sign-msa">
                  <SignSOWOrder docType="MSA" />
                </Route>
                <Route exact path="/sign-coc">
                  <SignSOWOrder docType="COC" />
                </Route>
                <Route exact path="/proposal">
                  <ViewProposal />
                </Route>
                <Route exact path="/">
                  <LandingScreen />
                </Route>
                <Route exact path="/documents/:tabId">
                  <Files client={true} />
                </Route>
                <Route exact path={`/notificationviewall`}>
                  <NotificationViewAll />
                </Route>
                <Route exact path="/client-profile/my-information">
                  <ClientOnboardingLayout section={<ClientMyInformation />} />
                </Route>
                <Route exact path="/client-profile/client-contact-account">
                  <ClientAccountLayout section={<CompanyContactAccount />} />
                </Route>
                <Route exact path="/client-profile-job/create-new-job">
                  <ClientJobNew />
                </Route>
                <Route exact path="/client-payment" >
                  <ClientPayment talent={false} />
                </Route>
                <Route exact path="/invoice-summary" >
                  <ClientInvoiceSummary />
                </Route>
                <Route exact path="/client-dashboard/:tabId">
                  <ClientDashboard />
                </Route>
                <Route exact path="/client-dashboard">
                  <ClientDashboard />
                </Route>
                <Route exact path="/talents">
                  <Talent />
                </Route>
                <Route exact path="/client-job/jobstatussection/:tabId">
                  <JobStatusLayout section={<JobStatusSections />} />
                </Route>

                <Route exact path="/client-profile/myinformation-account">
                  <ClientAccountLayout section={<MyInformationAccount />} />
                </Route>
                <Route exact path="/client-profile/client-location-account">
                  <ClientAccountLayout section={<CompanyLocationAccount />} />
                </Route>
                <Route exact path="/client-profile/changeemail">
                  <ClientAccountLayout section={<ChangeEmailAccount />} />
                </Route>

                <Route exact path="/client-profile/changepassword">
                  <ClientAccountLayout section={<ChangePasswordAccount />} />
                </Route>

                <Route exact path="/client-job/jobpost/:jobId">
                  <JobPostLayout section={<JobPostSection />} />
                </Route>
                <Route exact path="/client-job/job-entity/:tabId/:jobId">
                  <JobEntityLayout section={<JobEntitySections />} />
                </Route>

                <Route exact path="/client-profile-job/job-details">
                  <ClientOnboardingLayoutJob section={<ClientJobDetails />} />
                </Route>

                <Route exact path="/edit/client-profile-job/job-details">
                  <ClientOnboardingLayoutJob
                    section={<ClientJobDetails />}
                    sidebarHide={true}
                  />
                </Route>

                <Route exact path="/client-profile-job/talent-information">
                  <ClientOnboardingLayoutJob
                    section={<ClientTalentInformation />}
                  />
                </Route>

                <Route exact path="/edit/client-profile-job/talent-information">
                  <ClientOnboardingLayoutJob
                    section={<ClientTalentInformation />}
                    sidebarHide={true}
                  />
                </Route>
                <Route exact path="/client-profile/notification-settings/:tabId">
                  <ClientAccountLayout section={<NotificationSetting client={true} />} />
                </Route>

                <Route exact path="/client-profile-job/audience">
                  <ClientOnboardingLayoutJob section={<ClientAudience />} />
                </Route>

                <Route exact path="/edit/client-profile-job/audience">
                  <ClientOnboardingLayoutJob
                    section={<ClientAudience />}
                    sidebarHide={true}
                  />
                </Route>
                {/* <Route exact path="/profile/changeprimarypassword">
                  <ChangePrimaryPassword />
                </Route> */}
                <Route exact path="/client-profile-job/job-specification">
                  <ClientOnboardingLayoutJob
                    section={<ClientJobSpecification />}
                  />
                </Route>

                <Route exact path="/edit/client-profile-job/job-specification">
                  <ClientOnboardingLayoutJob
                    section={<ClientJobSpecification />}
                    sidebarHide={true}
                  />
                </Route>

                <Route exact path="/client-profile-job/budget">
                  <ClientOnboardingLayoutJob section={<ClientBudget />} />
                </Route>
                <Route exact path="/client-profile-job/company-contacts">
                  <ClientOnboardingLayoutJob section={<JobCompanyContacts />} />
                </Route>

                <Route exact path="/edit/client-profile-job/budget">
                  <ClientOnboardingLayoutJob
                    section={<ClientBudget />}
                    sidebarHide={true}
                  />
                </Route>

                <Route exact path="/client-profile-job/review">
                  <ClientOnboardingLayoutJob section={<ClientReviewPost />} />
                </Route>
                <Route exact path="/sign-document">
                  <SignDocuments />
                </Route>
                <Route exact path="/confirm-order">
                  <ConfirmClientOrder />
                </Route>
                <Route exact path="/logout">
                  <Logout />
                </Route>
                <Route path="*" exact>
                  <NotFound />
                </Route>
              </Switch>
              <ToastContainer autoClose={5000} />
            </div>
          </Router>
        </Suspense>
      ) : (
        <Suspense fallback={null}>
          <Router>
            <ScrollToTop />
            <div>
              <Navbar />
              <BetaRibbon />
              <Footer />
              <div className="DesktopfeedBack">
                <FeedBack />
              </div>
              <UploadProgress />
              <Switch>
                <Route exact path="/login">
                  <Login />
                </Route>
                <Route exact path="/client-profile/changeemail">
                  <ClientAccountLayout section={<ChangeEmailAccount />} />
                </Route>
                <Route exact path="/profile/welcome-user-client">
                  <OnboardingLayoutClient section={<WelcomeUserClient />} />
                </Route>
                <Route exact path="/preview">
                  <FreelanceDetail />
                </Route>
                <Route exact path="/proposal">
                  <ViewProposal preview={true} />
                </Route>
                <Route path="/lmiiwatta">
                  <ImpersonateLink />
                </Route>
                <Route path="/ttta-backdoor">
                  <ImpersonateLink />
                </Route>
                <Route exact path="/profile/testimonials">
                  <TestimonialList />
                </Route>
                {/* <Route exact path="/files/:tabId">
                  <Files talent={true} />
                </Route> */}
                <Route exact path="/project/files/:projectId">
                  <JobFiles talent={true} />
                </Route>
                <Route exact path="/welcome">
                  <WelcomeToTTA />
                </Route>
                <Route exact path="/">
                  <LandingScreen />
                </Route>
                <Route exact path="/user/dashboard">
                  <StatsDashboard />
                </Route>

                <Route exact path="/jobcenter/:tabId">
                  <JobCenter />
                </Route>
                <Route exact path="/documents/:tabId">
                  <Files talent={true} />
                </Route>
                <Route exact path="/view-job-details/:jobId">
                  <TalentsJobDetails />
                </Route>
                <Route
                  exact
                  path="/jobcenter/inprogress/job-details/:jobId/:tabId"
                >
                  <InProgressJobDetails />
                </Route>
                <Route
                  exact
                  path="/view-signed-work-order"
                >
                  <ViewSignedDocuments />
                </Route>
                <Route
                  exact
                  path="/jobcenter/completed/job-details/:jobId/:tabId"
                >
                  <CompletedJobDetails />
                </Route>
                <Route exact path="/jobcenter/rejected/job-details/:jobId">
                  <RejectedJobDetail />
                </Route>
                <Route exact path="/view-saved-job-details/:jobId">
                  <SavedJobDetails />
                </Route>
                <Route exact path="/view-proposal-submitted-job-details/:jobId">
                  <ProposalSubmittedJobDetails />
                </Route>

                <Route exact path="/logout">
                  <Logout />
                </Route>
                <Route exact path="/dashboard">
                  <OnboardingLayout section={WelcomeUser} />
                </Route>
                <Route exact path="/profile">
                  <UserProfileView />
                </Route>
                <Route exact path="/irs-form">
                  <W9SignForm />
                </Route>
                <Route exact path="/irs-form/w8">
                  <W8SignForm />
                </Route>
                <Route exact path="/usersview">
                  <UserView />
                </Route>
                <Route exact path="/jobCenter">
                  <OnboardingLayout section={WelcomeUser} />
                </Route>
                <Route exact path="/myJobs">
                  <OnboardingLayout section={WelcomeUser} />
                </Route>
                <Route exact path="/helpCenter">
                  <OnboardingLayout section={WelcomeUser} />
                </Route>
                <Route exact path="/termsAndConditions">
                  <TermsAndConditions />
                </Route>
                <Route exact path="/profile/company-information">
                  <OnboardingLayout section={CompanyInformation} />
                </Route>
                <Route exact path={`/profile/roles/:value`}>
                  <OnboardingLayout section={RolesAndSkils} />
                </Route>
                <Route exact path={`/profile/roles/profile-edit/:value`}>
                  <OnboardingLayout section={RolesAndSkils} />
                </Route>

                <Route exact path="/profile/roles">
                  <OnboardingLayout section={Roles} />
                </Route>
                <Route exact path="/profile/profile-edit/roles">
                  <OnboardingLayout section={Roles} />
                </Route>

                <Route exact path="/profile/welcome-user">
                  <OnboardingLayout section={WelcomeUser} />
                </Route>
                <Route exact path="/profile/my-information">
                  <OnboardingLayout section={MyInformation} />
                </Route>
                <Route exact path="/settings/my-information">
                  <TalentAccountLayout
                    section={<MyInformation updateMyinfo={true} />}
                  />
                </Route>
                <Route exact path="/settings/notification-settings/:tabId">
                  <TalentAccountLayout
                    section={<NotificationSetting />}
                  />
                </Route>
                <Route exact path="/settings/legal-documents">
                  <TalentAccountLayout section={<LegalDocuments />} />
                </Route>
                <Route exact path="/settings/manage-job-alerts">
                  <TalentAccountLayout section={<ManageJobAlert />} />
                </Route>
                <Route exact path="/settings/manage-reviews">
                  <TalentAccountLayout section={<ManageReviews />} />
                </Route>
                <Route exact path="/settings/manage-reference">
                  <TalentAccountLayout section={<ManageReference />} />
                </Route>
                <Route exact path="/add-professional-reference">
                  <AddProfesionalRefernce />
                </Route>
                <Route exact path="/edit-professional-reference/:refId">
                  <AddProfesionalRefernce update={true} />
                </Route>
                <Route exact path="/settings/company-informations">
                  <TalentAccountLayout
                    section={
                      <CompanyInformation
                        updateCompanyinfo={true}
                        setting={true}
                      />
                    }
                  />
                </Route>
                <Route exact path="/settings/payment-information">
                  <TalentAccountLayout section={<TalentpaymentInformation />} />
                </Route>
                <Route exact path="/settings/manage-members">
                  <TalentAccountLayout section={<ManageMembers />} />
                </Route>
                <Route exact path="/settings/manage-testimonials">
                  <TalentAccountLayout section={<TestimonialList />} />
                </Route>
                <Route exact path="/sign-document">
                  <SignDocuments />
                </Route>
                <Route exact path="/agency-welcome">
                  <AgencyWelcome />
                </Route>
                <Route exact path="/sign-work">
                  <SignWorkOrder />
                </Route>
                <Route exact path="/accept-work">
                  <AcceptWorkOrder />
                </Route>
                <Route exact path="/view-work-document">
                  <AcceptWorkOrder view={true} />
                </Route>
                <Route exact path="/accept-travel-fee" >
                  <TravelFeeAcceptance />
                </Route>
                <Route exact path="/view-document">
                  <ViewDocuments />
                </Route>
                <Route exact path="/all-payments">
                  <AllPayments />
                </Route>
                <Route exact path="/calendar-view">
                  <TalentCalendar />
                </Route>
                <Route exact path="/add-job-alerts">
                  <AddJobAlert />
                </Route>
                <Route exact path="/edit-job-alerts/:alertId">
                  <AddJobAlert update={true} />
                </Route>
                <Route exact path="/settings/company-information">
                  <OnboardingLayout
                    section={<CompanyInformation updateCompanyinfo={true} />}
                    sidebarHide={true}
                  />
                </Route>
                <Route exact path="/edit/about-me">
                  <OnboardingLayout
                    section={<AboutMe edit={true} />}
                    sidebarHide={true}
                  />
                </Route>
                <Route exact path="/edit/work-experience">
                  <OnboardingLayout
                    section={<WorkExperienceMain editWorkExperience={true} />}
                    sidebarHide={true}
                  />
                </Route>
                <Route exact path="/profile/portfolio">
                  <OnboardingLayout section={Portfolio} />
                </Route>
                <Route exact path="/profile/work-experience">
                  <OnboardingLayout section={WorkExperienceMain} />
                </Route>
                <Route exact path="/profile/education">
                  <OnboardingLayout section={Education} />
                </Route>
                <Route exact path="/profile/education/edit">
                  <OnboardingLayout
                    sidebarHide={true}
                    section={<EducationEdit />}
                  />
                </Route>

                <Route exact path={`/notificationviewall`}>
                  <NotificationViewAll />
                </Route>
                <Route exact path="/profile/certifications/edit">
                  <OnboardingLayout
                    sidebarHide={true}
                    section={<CertificationsEdit />}
                  />
                </Route>

                <Route exact path="/profile/courses/edit">
                  <OnboardingLayout
                    sidebarHide={true}
                    section={<PortfolioEdit editCourseSection={true} />}
                  />
                </Route>
                <Route exact path="/profile/portfolio/edit">
                  <OnboardingLayout
                    sidebarHide={true}
                    section={<PortfolioEdit editPortfolio={true} />}
                  />
                </Route>
                <Route exact path="/profile/certifications">
                  <OnboardingLayout section={Certifications} />
                </Route>
                <Route exact path="/profile/about-me">
                  <OnboardingLayout section={AboutMe} />
                </Route>
                <Route exact path="/profile/resume">
                  <OnboardingLayout section={Resume} />
                </Route>
                <Route exact path="/job/submit-proposal/:jobId">
                  <SubmitProposals />
                </Route>
                <Route exact path="/job/edit-proposal/:jobId">
                  <SubmitProposals />

                  {/* <SubmitProposal /> */}
                </Route>
                {/* <Route exact path="/settings/changeprimaryemail">
                  <ChangePrimaryEmail />
                </Route> */}
                {/* <Route exact path="/settings/changeprimarypassword">
                  <ChangePrimaryPassword />
                </Route> */}
                <Route path="/requesttestimonial">
                  <RequestTestimonial />
                </Route>
                <Route exact path="/client-profile">
                  <ClientOnboardingLayout section={<ClientWelcome />} />
                </Route>
                <Route exact path="/irs-form">
                  <W9SignForm />
                </Route>
                <Route exact path="/irs-form/w8">
                  <W8SignForm />
                </Route>
                <Route exact path="/irs-form/w8e">
                  <W8SignForm isW8e={true} />
                </Route>
                <Route exact path="/w8-select">
                  <W8Selector />
                </Route>
                <Route path="*" exact>
                  <NotFound />
                </Route>
              </Switch>
              <ToastContainer autoClose={5000} />
            </div>
          </Router>
        </Suspense>
      )}
      { !auth && (path === '/login' || path.includes('/sign-up')) &&
        <a href="https://helpcenter.ttaconnect.com" rel="noopener noreferrer" target="_blank" className="help-center">
          <i className="fa fa-question"></i>
        </a> 
      }
    </>
  );
};

export default networkDetector(App);
