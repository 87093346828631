import { ttaCertifiedBadge, ttaQualifiedBadge, ttaVerifiedBadge } from "../assets/images";

export const getInstructorBadge = (status, instrId, setShowBadgeFunc, showBadge, wrapBadgeName) => {
  let className = wrapBadgeName ? "wrapBadge badgeTextWrap" : "overFlowBadge badgeTextWrap"

  if (status === "V") {
    return (
      <>
        <div className={showBadge === instrId ? className : "d-none"}>
          Verified: Independent contractor with experience in the field of learning and development
        </div>
        <img
          src={ttaVerifiedBadge}
          alt=""
          onMouseEnter={() => setShowBadgeFunc(instrId)}
          onMouseLeave={() => setShowBadgeFunc(null)}
        />
      </>
    );
  } else if (status === "Q") {
    return (
      <>
        <div className={showBadge === instrId ? className : "d-none"}>
          Qualified: Learning and development professional that has gone through our talent qualification process and has a demonstrated track record and expertise in their domain
        </div>
        <img
          src={ttaQualifiedBadge}
          alt=""
          onMouseEnter={() => setShowBadgeFunc(instrId)}
          onMouseLeave={() => setShowBadgeFunc(null)}
        />
      </>
    );
  } else if (status === "CT") {
    return (
      <>
        <div className={showBadge === instrId ? className : "d-none"}>
          Certified Trainer: Expert in learning and development with a consistent track record for providing superior training services that exceed client expectations
        </div>
        <img
          src={ttaCertifiedBadge}
          alt=""
          onMouseEnter={() => setShowBadgeFunc(instrId)}
          onMouseLeave={() => setShowBadgeFunc(null)}
        />
      </>
    );
  } else if (status === "CID") {
    return (
      <>
        <div className={showBadge === instrId ? className : "d-none"}>
          Certified Instructional Designer: Expert in learning and development with a consistent track record for providing superior instructional design services that exceed client expectations
        </div>
        <img
          src={ttaCertifiedBadge}
          alt=""
          onMouseEnter={() => setShowBadgeFunc(instrId)}
          onMouseLeave={() => setShowBadgeFunc(null)}
        />
      </>
    );
  } else if (status === "CTCID") {
    return (
      <>
        <div className={showBadge === instrId ? className : "d-none"}>
          Certified Trainer & Instructional Designer: Expert in learning and development with a consistent track record for providing superior training and instructional design services that exceed client expectations
        </div>
        <img
          src={ttaCertifiedBadge}
          alt=""
          onMouseEnter={() => setShowBadgeFunc(instrId)}
          onMouseLeave={() => setShowBadgeFunc(null)}
        />
      </>
    );

  } else {
    return null;
  }
};