import React from "react";
import { Link } from "react-router-dom";
import "./footer.scss";

let Footer = () => {
  return (
    <React.Fragment>
      <div className="container-fluid footerSection m-0 p-0 ">
        <div className="footerContainer px-3 py-md-none py-3 d-flex flex-wrap align-items-center justify-content-center">
          <span className="footerText mb-md-0 mb-1">
            &copy; {new Date().getFullYear()} TTA. &nbsp;All Rights Reserved
          </span>
          <div className="footerDot  mx-1" />
          <Link to={"/termsAndConditions"} className="footerText footerLink mb-md-0 mb-1">
            Terms of Use
          </Link>
          <div className="footerDot  mx-1" />
          <Link to={"/termsAndConditions?privacy=True"} className="footerText footerLink mr-2 mb-md-0 mb-1">
            Privacy Policy
          </Link>
          <div className="footerText text-center mb-md-0 mb-1">
            <span className="vl_footer footerText mr-2  " />{" "}
            <span style={{ lineHeight: "18px" }}>
              The Training Associates, TTA, TTA Connect are
              trademarks of The Training Associates Corporation.
            </span>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default Footer;
