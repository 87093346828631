import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { getUserInfo } from "../../services/userInfo";
import { updateUserData } from "../../ducks/Login";
import { GetUserLoginData } from "../../util/reduxData";
import { AddSkillForNewRole, GetSplitRoles } from "../../services/splitRole";
import AddRoleModal from "../../screens/Onboarding/addRoleModal";
import Modal from "../modal";
import RadioButton from "../radioButton";
import Button from "../button";
import "./index.scss";

const SelectSplittedRole = () => {

    const dispatch = useDispatch()
    const [selectedRoleIds, setSelectedRoleIds] = useState({})
    const [apiData, setApiData] = useState([]);
    const [showAddRole, setShowAddRole] = useState(false);
    const [roleDataStructure, setRoleDataStructure] = useState([]);
    const [nextClicked, setNextClicked] = useState(false)
    const [userRoles, setUserRoles] = useState([]);
    const userData = GetUserLoginData()

    useEffect(()=>{
        if(userData?.showTrainerRole) {
            getSplitRoles()
            getUserRoles()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[userData?.showTrainerRole])

    const getUserRoles = () =>{
        getUserInfo(userData?.instrId).then((response) => {
            setUserRoles(response.data.profileRoles);
            if(response.data?.profileRoles > 0) {
                onSaveSuccess()
            }
        });
    }
    
    const getSplitRoles = () => {
        GetSplitRoles()
        .then((res)=>{
            setApiData(res.data)
            if(res.data.length === 0) {
                dispatch(updateUserData({
                    ...userData,
                    showTrainerRole: false
                }))
            }
            setShowAddRole(false)
        })
    }

    const handleRadioCheck = (prevRoleData, newRoleData) => {
        setSelectedRoleIds({
            [prevRoleData?.roleName]: newRoleData?.roleId
        })

        setRoleDataStructure([
            {
                roleId: newRoleData?.roleId,
                role: newRoleData?.roleName,
                previousRoleId: prevRoleData?.roleId
            }
        ])
    }

    const onNext = () => {
        setNextClicked(true)
        if(roleDataStructure.length === 0) {
            return
        }
        const payload = {
            previousRoleId: roleDataStructure[0].previousRoleId,
            newRoleId: roleDataStructure[0].roleId
        }
        AddSkillForNewRole(payload)
        .then(()=>{
            setShowAddRole(true)
        })
    }

    const onSaveSuccess = () => {
        getSplitRoles()
    }

    return (
        <>
            <Modal
                isOpen={userData?.showTrainerRole}
                size="medium"
                hideCloseButton={false}
                toggleModal={() => {}}
                disableBackdropToggle={true}
                containerClass="splitRoleModal"
            >   
                {
                    showAddRole &&
                    <AddRoleModal
                        roleDetails={roleDataStructure[0]}
                        isOpen={showAddRole}
                        toggleModal={() => {
                            setShowAddRole(false)
                        }}
                        onSaveSuccess={onSaveSuccess}
                        userRoles={userRoles}
                    />
                }
                <div className="selectNewRoleWrap">
                    <div className="boldFont font20Black">Select a New Role</div>
                    <div className="font14DarkGrey">We have revised the categorization of the role(s) that you have chosen in your profile. Please select a new role from the menu option below that best represents your skills and expertise. This new role will replace your previous role in your profile.</div>
                    {
                        apiData?.map((roleData)=>{
                            return (
                                <React.Fragment key={roleData?.previousRole?.roleId}>
                                    <div className="border-top mt-3 mb-3" />
                                    <div className="d-flex">
                                        <div className="font12DarkGrey roleWidth mt-1">Previous Role:</div>
                                        <div className="previousRoleWrap">
                                            {roleData?.previousRole?.roleName}
                                        </div>
                                    </div>
                                    <div className="d-flex mt-3 align-items-baseline">
                                        <div className="font12DarkGrey mt-1 roleWidth">Select New Role:</div>
                                        <div>
                                        {
                                            roleData?.newRoles?.map((newRoleData)=>{
                                                return (
                                                    <div className="mb-2" key={newRoleData?.roleId}>
                                                        <RadioButton
                                                            id={newRoleData?.roleId}
                                                            label={newRoleData?.roleName}
                                                            name={newRoleData?.roleId}
                                                            className="mt-0"
                                                            onChange={() => {
                                                                handleRadioCheck(roleData?.previousRole, newRoleData)
                                                            }}
                                                            checked={selectedRoleIds[roleData?.previousRole?.roleName] === newRoleData?.roleId}
                                                        />
                                                        <div className="font12DarkGrey ml-4 pl-2">
                                                            {newRoleData?.notes}
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                        </div>
                                    </div>
                                </React.Fragment>
                            )
                        })
                    }
                    {
                        (nextClicked && roleDataStructure.length === 0) &&
                        <div>
                            <span className="d-flex align-items-center justify-content-center my-2">
                                <i className="fa fa-exclamation-circle errorIcon" />
                                <p className="errorText mb-0 ml-0 ">
                                    Select a New Role
                                </p>
                            </span>
                        </div>
                    }
                </div>
                <div className="border-top mt-4 mb-4" />
                <div className="text-align-center">
                    <Button
                        label={"Next"}
                        className={"btn-blue mt-1"}
                        onClick={()=>{
                            onNext()
                        }}
                    />
                </div>

            </Modal>
        </>
    )
}
export default SelectSplittedRole;