/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

const ScrollToTop = ({ history }) => {
  useEffect(() => {
    const unlisten = history.listen(() => {
      setTimeout(function () {
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
      }, 10)
    });
    return () => {
      unlisten();
    }
  }, []);

  return (null);
}

export default withRouter(ScrollToTop);