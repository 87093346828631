import React, { Fragment } from "react";
import { noInternet } from "../../assets/images";
import Button from "../../components/button";

import "./noInternet.scss";

function NoInternet({hide}) {
  
  return (
    <Fragment>
      <div className={hide?"d-none":"noInternetBox"}>
        <div className="noInternetContent">
          <img src={noInternet} className="noInternetImage" alt=""/>
          <form>
            <div className="noInternetTextWrapper">
              <div className="noInternetText">
                <div className="font18Black boldFont">No Internet Connection!</div>
                <div className="noInternetTextPara">
                    We are not able to fetch any data because, we can not find a network connection. Try refreshing the page.
                </div>
                <Button
                  onClick={()=>window.location.reload()}
                  label="Try Again"
                  className="btn-blue main_btn  "
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </Fragment>
  );
}

export default NoInternet;
