/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";
import moment from "moment";
import {
  GetNotification,
  ReadNotification,
} from "../../services/notifications";
import ShowMore from "../../components/showMore";
import { GetUserLoginData } from "../../util/reduxData";
import { notificationBlank } from "../../assets/images";
import "./navbarPopupNotificatoons.scss";

const NavbarPopupNotificatons = ({ handleOutsideClick, handleLinkClick }) => {

  const userData = GetUserLoginData()

  const executeOnClick = (isExpanded, read) => {
    if (read === false) {
      readNotificationFunc(isExpanded);
    }
  };

  const readNotificationFunc = (data) => {
    const readNotification = ReadNotification(data).then((response) => { })
  };

  const [notifications, setNotifications] = useState();
  const [count, setCount] = useState();

  const sortByDate = (data) => {
    return data.sort((a, b) => new Date(b.notificationDate) - new Date(a.notificationDate));
  }

  useEffect(() => {
    const getNotification = GetNotification(userData.instrId ? "T" : "C").then((response) => {
      const data = response.data.filter((data) => {
        if (data.read === false) {
          return data;
        }
      });

      setNotifications(sortByDate(data));
      setCount(data.length);
    });

    window.scroll(0, 0);
  }, []);

  const formatDate = (date) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit', timeZoneName: 'short' };
    return new Date(date).toLocaleString('en-US', options);
  };

  function convertUtcToLocal(utcDateStr) {
    // Create a Date object from the UTC date string, specifying it's in UTC
    const utcDate = new Date(utcDateStr + 'Z');

    return utcDate;
  }

  function div(a, b) {
    return a / b;
  }

  const humanReadableDate = (date) => {
    const currentDate = new Date(); // Current UTC date
    const notificationDate = moment(convertUtcToLocal(date)).local()
    const diffTime = Math.abs(currentDate - notificationDate);
    const diffDays = Math.ceil(div(diffTime, (1000 * 60 * 60 * 24)));

    if (diffDays > 2) {
      return formatDate(date);
    }

    const timeAgo = new Date(currentDate - notificationDate);

    const daysAgo = Math.floor(timeAgo / (1000 * 60 * 60 * 24));
    const hoursAgo = Math.floor((timeAgo % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutesAgo = Math.floor((timeAgo % (1000 * 60 * 60)) / (1000 * 60));

    if (daysAgo > 0) {
      return `${daysAgo} day${daysAgo > 1 ? 's' : ''} ago`;
    } else if (hoursAgo > 0) {
      return `${hoursAgo} hour${hoursAgo > 1 ? 's' : ''} ago`;
    } else {
      return `${minutesAgo <= 0 ? 0 : minutesAgo} minute${minutesAgo > 1 ? 's' : ''} ago`;
    }
  };

  const [expanded, setExpanded] = useState(false)

  return (
    <>
      <div className="notificationsWrapper">
        <div className="notificationFirstPart">
          <div className="notificationText font18Black">
            {" "}
            Notifications{" "}
            {count > 0 && <span className="notificationAllCircle">{count}</span>}
          </div>
          {
            // notifications?.length > 0 &&
            <Link
              to="/notificationviewall"
              className="notificationFirstPartViewAll"
            >
              <div onClick={handleLinkClick}>View All</div>
            </Link>
          }
        </div>
        <div className="notificationDivider" />
        <div className={classnames("notificationsItemContentWrapper", expanded && "h80vh")}>
          {notifications?.slice(0, 3)?.map((data, idx) => {
            return (
              <React.Fragment key={idx}>
                <div className="notificationsItemWrapperPopUP">
                  <div className="menuAllNotificationWrapper">
                    <div className="menuAllNotificationTitleUnread">
                      {data.subject}
                    </div>
                  </div>
                  <div className="menuAllNotificationDescription">
                    <ShowMore
                      id="notificationBody"
                      lines={1}
                      textClass="content-css"
                      onClick={() => {
                        executeOnClick(data.notificationId, data.read)
                        setExpanded(!expanded)
                      }}
                      anchorClass="my-anchor-css-class1"
                      text={<div dangerouslySetInnerHTML={{ __html: data.body }} />}
                    />
                  </div>
                  <div className="menuViewAllTimee">
                    {humanReadableDate(data.notificationDate)}
                  </div>
                </div>
                <div className="notificationDivider" />
              </React.Fragment>
            );
          })}
          {notifications?.length === 0 && (
            <div className="viewAllContent mt-4 mb-3">
              <img src={notificationBlank} className="viewAllImage" alt="" />
              <div className="viewAllTextWrapper">
                <div className="viewAllText">
                  <div className="font18Black boldFont">No New Notifications</div>
                  <div className="font14Black">
                    There are no new notifications to display.
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default NavbarPopupNotificatons;
