/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import { down } from "../../assets/images";
import Button from "../button";
import Chips from "../chips/Chips";
import Label from "../label";
import Modal from "../modal";
import useWindowSize from "../../util/useWindowSize";
import "./index.scss";

const CopySkillsFromRole = ({
    showModal,
    hideModal,
    myRoles,
    onSave,
}) => {

    const [roleSpecificSkills, setRoleSpecificSkills] = useState([])
    const [roleSelected, setRoleSelected] = useState({})
    const [selectedSkills, setSelectedSkills] = useState([])
    const [selectedSkillsName, setSelectedSkillsName] = useState([])
    const [openRoles, setOpenRoles] = useState([])
    const [width,] = useWindowSize();

    useEffect(() => {
        if (!showModal) {
            setSelectedSkills([])
            setRoleSpecificSkills([])
            setSelectedSkillsName([])
            setRoleSelected({})
        }
    }, [showModal])

    useEffect(()=>{
        if(!roleSelected?.roleId && myRoles?.length > 0 && showModal) {
            showSkillsOfRole(myRoles[0])
        }
    },[roleSelected, myRoles, showModal])

    

    const checkIfAllSelected = (skills) => {
        const selectedRolesSkillsName = skills.map((res)=> {return res.skill})
        const allSelected =  selectedRolesSkillsName.every(v => selectedSkillsName.includes(v));
        return allSelected   
    }

    const showSkillsOfRole = (data) => {
        setRoleSelected(data.myRole)
        setRoleSpecificSkills(data.mySkills)
    }

    const handleSelectAll = (skills=roleSpecificSkills) => {
        const tempName = []
        const tempData = []
        skills.map((res)=>{
            if(!selectedSkillsName.includes(res.skill)) {
                tempName.push(res.skill)
                tempData.push(res)
            }
        })
        setSelectedSkillsName([
            ...selectedSkillsName,
            ...tempName
        ])
        setSelectedSkills([
            ...selectedSkills,
            ...tempData
        ])
    }

    const handleRemoveAll = (skills) => {
        const tempName = [...selectedSkillsName]
        const tempData = [...selectedSkills]
        skills.map((res)=>{
            const skillDataIdx = tempData.indexOf(res)
            const skillNameIdx = tempName.indexOf(res.skill)
            if(skillDataIdx > -1 && skillNameIdx > -1) {
                tempName.splice(skillNameIdx, 1)
                tempData.splice(skillDataIdx, 1)
            }
        })
        setSelectedSkillsName([
            ...tempName
        ])
        setSelectedSkills([
            ...tempData
        ])
    }

    const handleSkillChip = (data) => {
        const tempSkillName = [...selectedSkillsName]
        const tempSkills = [...selectedSkills]
        if (selectedSkillsName.includes(data.skill)) {
            const idx = tempSkillName.indexOf(data.skill)
            if (idx > -1) {
                tempSkillName.splice(idx, 1)
                tempSkills.splice(idx, 1)
            }
            setSelectedSkillsName(tempSkillName)
            setSelectedSkills(tempSkills)
        } else {
            setSelectedSkillsName([
                ...selectedSkillsName,
                data?.skill
            ])
            setSelectedSkills([
                ...selectedSkills,
                data
            ])
        }
    }

    const onBrowseClose = () => {
        onSave(selectedSkills)
    }

    const openCategoryOption = (group) => {
        const tempList = [...openRoles]
        if (openRoles.includes(group)) {
            const idx = tempList.indexOf(group)
            if (idx > -1) {
                tempList.splice(idx, 1)
            }
            setOpenRoles(tempList)
        } else {
            setOpenRoles([
                ...openRoles,
                group
            ])
        }
    }

    return (
        <>
            <Modal
                isOpen={showModal}
                size="browseSkills"
                hideCloseButton={true}
                toggleModal={() => {
                    hideModal();
                }}
                title={
                    <Label
                        label={"Add Skills from Other Roles"}
                        className="font18Black boldFont"
                    />
                }
                disableBackdropToggle={false}
            >
                
                <div className="mt-3 border-top mobileView" />
                {
                        <>
                            <div className="border-top mt-3 desktopView" />
                            {
                                width > 760 ?
                                    <div className="skillInnerWrap">
                                        <div className="roleListWrap">
                                            {
                                                myRoles.map((data, idx) => {
                                                    return (
                                                        <React.Fragment key={idx}>
                                                            <div
                                                                className={
                                                                    roleSelected?.roleId === data?.myRole?.roleId ?
                                                                        "activeFilterCatName" :
                                                                        "filterCatName"
                                                                }
                                                                onClick={() => showSkillsOfRole(data)}
                                                                id={data.myRole.roleId + "browse"}
                                                            >
                                                                {data.myRole?.role}
                                                            </div>
                                                        </React.Fragment>
                                                    )
                                                })
                                            }
                                        </div>
                                        <div className="skillAndCatSeperator" />
                                        <div className="roleSkillInnerWrap">
                                            <div className="d-flex justify-content-between align-items-center mr-4">
                                                <div className="mt-3 font14Black boldFont">
                                                    {roleSelected?.role}
                                                </div>
                                                {
                                                    checkIfAllSelected(roleSpecificSkills) ?
                                                    <div className="font14Blue" onClick={()=>handleRemoveAll(roleSpecificSkills)}>
                                                        Deselect All
                                                    </div> :
                                                    <div className="font14Blue" onClick={()=>handleSelectAll(roleSpecificSkills)}>
                                                        Select All
                                                    </div>
                                                }
                                                
                                            </div>
                                            <div className="roleSkillNameWrapp">
                                                {
                                                    roleSpecificSkills.map((data, idx) => {
                                                        return (
                                                            <React.Fragment key={idx}>
                                                                <Chips
                                                                    data={selectedSkillsName}
                                                                    label={data?.skill}
                                                                    className="mt-2 mr-2 newChipsBtn"
                                                                    onClick={(e) => {
                                                                        handleSkillChip(data)
                                                                    }}
                                                                    isUnapprovedSkill={data?.isUnapprovedSkill}
                                                                    id={`${data?.skill}browse`}
                                                                    browseSkillChip={true}
                                                                    clickable={true}
                                                                    icon={true}
                                                                />
                                                            </React.Fragment>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div> :
                                    <div className="mobileCatList fixHeight">
                                        {
                                            myRoles.map((data, idx) => {
                                                return (
                                                    <React.Fragment key={idx}>
                                                        <div
                                                            className="mobileCatWrapp"
                                                            onClick={() => {
                                                                openCategoryOption(data?.myRole?.roleId)
                                                                showSkillsOfRole(data)
                                                            }}
                                                        >
                                                            <div className="font15Black">{data.myRole?.role}</div>
                                                            <div className="d-flex">
                                                                <img
                                                                    src={down}
                                                                    alt=""
                                                                    className={
                                                                        openRoles.includes(data.myRole?.roleId) ?
                                                                            "openedIndication" :
                                                                            ""}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className={openRoles.includes(data.myRole?.roleId) ? "" : "d-none"}>
                                                            <div className={"d-flex flex-wrap"}>
                                                                {
                                                                    data?.mySkills?.map((data, idx) => {
                                                                            return (
                                                                                <React.Fragment key={idx}>
                                                                                    <Chips
                                                                                        data={selectedSkillsName}
                                                                                        label={data?.skill}
                                                                                        className="mt-2 mr-2 newChipsBtn"
                                                                                        onClick={(e) => {
                                                                                            handleSkillChip(data)
                                                                                        }}
                                                                                        isUnapprovedSkill={data?.isUnapprovedSkill}
                                                                                        id={`${data?.skill}browseMobile`}
                                                                                        browseSkillChip={true}
                                                                                        clickable={true}
                                                                                        icon={true}
                                                                                    />
                                                                                </React.Fragment>
                                                                            )
                                                                    })
                                                                }
                                                                
                                                            </div>
                                                            {
                                                                checkIfAllSelected(data?.mySkills) ?
                                                                <div className="font14Blue mt-2 mb-2 fitContent" onClick={()=>handleRemoveAll(data?.mySkills)}>
                                                                    Deselect All
                                                                </div> :
                                                                <div className="font14Blue mb-2 mt-2 fitContent" onClick={()=>handleSelectAll(data?.mySkills)}>
                                                                    Select All
                                                                </div>
                                                            }
                                                        </div>
                                                        <div className="border-top mt-2 mb-2" />
                                                    </React.Fragment>
                                                )
                                            })
                                        }

                                    </div>
                            }
                            
                            <div
                                className="border-top Buttonshadow copySkillButtonWrap"
                                key={"Instructor"}
                            >
                                <div className="browseSkillSaveButton">
                                    <Button
                                        label="Cancel"
                                        onClick={() => hideModal()}
                                        className="btn-light mx-2 cancelButtonDelete"
                                    />
                                    <Button
                                        label={`Add ${selectedSkills.length} Skill(s)`}
                                        className="btn-blue"
                                        onClick={() => {
                                            onBrowseClose();
                                        }}
                                        disabled={selectedSkills.length === 0}
                                    />
                                </div>
                            </div>
                        </> 
                }

            </Modal>
        </>
    )
}

export default CopySkillsFromRole;
