import { allowedFiletypeHandler } from "../util/allowedFileTypeHandler";

const handleDocument = (event,setStateDocumentSizeError,setStateDocumentName,setStateBinaryDocument,setInvalidTypeError,allowImage=false, setDocumentSize = () => {}, extensions=["pdf","docx","doc"]) => {
  let allowedExtenstions = extensions
    if(allowImage) {
      allowedExtenstions = allowedExtenstions.concat(["jpg","png","jpeg"])
    }
    if (event && event[0]) {

      const fileSize = (event[0].size / 1024 / 1024).toFixed(2);
        if (fileSize > 20) {
            setStateDocumentSizeError(true);
          return
        } else {
          setDocumentSize(fileSize)
          let reader = new FileReader();
          reader.onload = (e) => {
            const fullFileName = event[0].name
            const allow = allowedFiletypeHandler(allowedExtenstions, fullFileName)
            if(!allow) {
              setInvalidTypeError(true)
              return
            }
            const split = fullFileName.split('.');
            const filename = split[0];
            const extension = split[1];
            const lasthreeChar = filename.substr(filename.length - 3)
            const shortFileName = filename.substring(0, 15);
            const newFileName = `${shortFileName}....${lasthreeChar}.${extension}`
            if(newFileName.length < fullFileName.length) {
              setStateDocumentName(newFileName)
            } else {
              setStateDocumentName(event[0].name)
            }
            setStateBinaryDocument(event[0])
            setStateDocumentSizeError(false);
          }
          reader.readAsDataURL(event[0]);
        };
      }
      return
  };

  export default handleDocument;