import { currency_formatter } from "./currency_formatter";

export const getRateFromRateType = (type, min, max, rateNegotiable, showUnit) => {
    if(type === "Project Budget" ) {
        return `${currency_formatter(min)}`;
    } else if(rateNegotiable || (min === null && max === null) || (min === 0 && max === 0)) {
        return 'Negotiable';
    } else if(!showUnit || type==="Flat") {
        if(max !== null && max !== 0) {
            return `Min. ${currency_formatter(min)} to Max. ${currency_formatter(max)}`;
        } else {
            return `${currency_formatter(min)}`;
        }
    } else if(type === "Daily") {
        if(max !== null && max !== 0) {
            return `Min. ${currency_formatter(min)}/day to Max. ${currency_formatter(max)}/day`;
        } else {
            return `${currency_formatter(min)}/day`;
        }
    } else if(type?.toLowerCase() === "hourly") {
        if(max !== null && max !== 0) {
            return `Min. ${currency_formatter(min)}/hr to Max. ${currency_formatter(max)}/hr`;
        } else {
            return `${currency_formatter(min)}/hr`;
        }
    } else if(type === "Per Minute") {
        if(max !== null && max !== 0) {
            return `Min. ${currency_formatter(min)}/minute to Max. ${currency_formatter(max)}/minute`;
        } else {
            return `${currency_formatter(min)}/minute`;
        }
    } else if(type === "Per Word") {
        if(max !== null && max !== 0) {
            return `Min. ${currency_formatter(min)}/word to Max. ${currency_formatter(max)}/word`;
        } else {
            return `${currency_formatter(min)}/word`;
        }
    } else {
        return null;
    }
}