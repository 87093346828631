/* eslint-disable no-useless-escape */
export const onlyCharsPattern = /^[a-zA-Z]+$/;
export const lastNamePattern = /^[a-zA-Z\-]+$/;
export const allowAllPattern = /[^\n]+/;
export const URLPattern = /^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/;
export const roleError = "You must indicate if you are looking for work.";
export const nonUSPhoneNumberPattern = /^((?:9[679]|8[035789]|6[789]|5[90]|42|3[578]|2[1-689])|9[0-58]|8[1246]|6[0-6]|5[1-8]|4[013-9]|3[0-469]|2[70]|7|1)(?:\W*\d){0,16}\d$/
export const USPhoneNumberPattern = /^[0-9() +-]*$/
export const termsAndConditionError =
  " You must accept the terms and conditions to continue.";
export const emailValidations = {
  pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  errors: {
    required: "Email address is Required.",
    invalid: "Invalid email format.",
  },
  minLength: 2,
  maxLength: /(?=^.{1,2}$)/,
};

/*
  Password Validation
  - At least 1 Uppercase Letter (?=.*?[A-Z])
  - At least 1 Lowercase Letter (?=(.*[a-z]){1,})
  - At least 1 Number (?=(.*[\d]){1,})
  - At least 1 Special Character (?=(.*[\W_]){1,})
  - No Spaces (?!.*\s)
  - More than 8 Characters {8,}
*/
export const passwordValidations = {
  pattern: /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W_]){1,})(?!.*\s).{8,}$/,
  errors: {
    required: "Password is Required",
    invalid: "Invalid password format.",
    message:
      "Your password must include the following: At least 8 characters, an uppercase letter, a lowercase letter, a number, and a special character #$@!%*?&.",
    message1: "Password must be at least 8 characters long, include at least one uppercase letter, one number and one special character.",

  },
  minLength: 8,
  maxLength: 32,
};
export const firstName = {
  pattern: allowAllPattern,
  errors: {
    required: "First Name is Required.",
    message: "First name is Required and should only contain letters.",
    maxLength: "First name should not exceed 20 characters.",
  },
  minLength: null,
  maxLength: null,
};
export const lastName = {
  pattern: allowAllPattern,
  errors: {
    required: "Last Name is Required.",
    message: "Last name is Required and should only contain letters.",
    maxLength: "Last name should not exceed 20 characters.",
  },
  minLength: null,
  maxLength: null,
};
export const emailValidationsFortestimonial = {
  pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  errors: {
    required: "Email address is Required.",
    invalid: "Invalid email format.",
  },
  minLength: null,
  maxLength: null,
};
export const firstNameWithSpace = {
  pattern: allowAllPattern,
  errors: {
    required: "First Name is Required.",
    message: "First Name is Required and should only contain letters.",
    maxLength: "First Name should not exceed 20 characters.",
  },
  minLength: null,
  maxLength: null,
}
export const lastNameWithSpace = {
  pattern: allowAllPattern,
  errors: {
    required: "Last Name is Required.",
    message: "Last Name is Required and should only contain letters.",
    maxLength: "Last Name should not exceed 20 characters.",
  },
  minLength: null,
  maxLength: null,
}

export const AccountNoValidation = {
  pattern: /^[0-9]{6,17}$/
}

export const IbanValidation = {
  pattern: /^[a-zA-Z0-9 ]{6,40}$/
}

export const IbanNoValidation = {
  pattern:
    /^([a-zA-Z]{2})([0-9]{2})(?:[ ]?[a-zA-Z0-9]){10,30}$/,
}

export const SwiftCodeValidation = {
  pattern: /^([A-Za-z]{4})([A-Za-z]{2})([A-Za-z0-9]{2})([A-Za-z0-9]{3})?$/
}

/*
  Payment Name Validation
  - Allow alphanumeric characters
  - Allow special characters !@#$%&*()+=/-
  - Allow spaces
*/
export const paymentNameValidation = /^[\w\d\s!@#$%&*()+=/-]+$/

export const vimeoValidation = /(?:https?\:\/\/)?(?:www\.)?(?:vimeo\.com\/)([0-9]+)/
export const youtubeValidation = /(youtu.*be.*)\/(watch\?v=|embed\/|v|shorts|)(.*?((?=[&#?])|$))/
export const cityValidations = {
  pattern: /^[a-zA-Z_.,'& -]*$/,
  errors: {
    invalid: "City Name should only consist of alphabets and .,'-& these special characters."
  }
}