export const SET_CANCEL_FILES = "SET_CANCEL_FILES";
export const DELETE_CANCEL_FILES = "DELETE_CANCEL_FILES";

export const initialState = {
  cancelledFiles:[]
}
const cancelFileIndexReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_CANCEL_FILES:
      return {...payload}
    case DELETE_CANCEL_FILES:
      return {}
    default:
      return state;
  }
};

export const addCancelFilesIndex = (payload) => {
  return (dispatch) => {
    dispatch({
      type: SET_CANCEL_FILES,
      payload,
    });
  };
};

export const removeCancelFileIndex = () => {
  return (dispatch) => {
    dispatch({
      type: DELETE_CANCEL_FILES,
      payload:{},
    });
  };
};


export default cancelFileIndexReducer;
