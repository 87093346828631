import React from "react";
import { useForm } from "react-hook-form";
import Modal from "../../../../components/modal";
import Button from "../../../../components/button";

import "./removeFromBookmark.scss";

const RemoveFromBookmark = ({
  isOpen,
  toggleModal,
  jobId,
  removeFromBookmarkFunc
 
}) => {
  const { handleSubmit } = useForm({
    reValidateMode: "onBlur",
  });

  const closeModal = () => {
    toggleModal();
  };
  const onSubmit = () => {
    removeFromBookmarkFunc(jobId);
  };

  return (
    <Modal
      isOpen={isOpen}
      containerStyle={{
        width: "400px",
        maxWidth: "95%",
        minHeight: "200px",
        paddingBottom: "0px",
      }}
      size="medium"
      hideCloseButton={true}
      toggleModal={closeModal}
      title={"Remove  Saved Jobs?"}
      disableBackdropToggle={true}
      containerClass={"removeFromSavedModal"}
    >
      <div className="mt-3 border-top mobileSep" />

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="modalContentWrapper mx-auto ">
          <div className="mb-3 mt-4 ml-2">
            <span className="font14Grey">
              Are you sure you want to remove this Job from your saved list?
            </span>
          </div>
        </div>
        <div className="timelinemodalButtonHolder">
          <div className="text-center mt-2 mb-0 float-bottom">
            <Button
              label="Cancel"
              onClick={closeModal}
              className="btn-light mx-2 cancelButtonDelete"
            />
            <Button label="Remove" type="submit" className="btn-blue mx-2" />
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default RemoveFromBookmark;
