/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import GetMenuLink from "../../constants/ClientMenuLinks";
import { getToken } from "../../util/localStorage";
import { GetUserInfo, GetUserLoginData } from "../../util/reduxData";
import { GetNotification } from "../../services/notifications";
import {
  getMyInformation,
  updateProfileImage,
} from "../../ducks/myInformation";
import { DownloadDocGet } from "../../services/portfolio";
import { getClientInformation } from "../../services/clientMyInformation";
import { HelpCenterURL } from "../../constants/legacySiteURL";
import MobileNavbar from "./ClientMobileNavbar";
import NavbarPopupMenuClient from "../navbarPopupMenuClient/NavbarPopupMenuClient";
import NavbarNotificationMenu from "../navbarPopupNotificatoons/navbarPopupNotificatoons.jsx";
import Modal from "../modal";
import ForceChangePassword from "../../screens/Authentication/ForcePassowordChange";
import {
  bellIcon,
  chatIcon,
  logo,
  notificationCircle,
  uploadImg,
  bellIconBlue,
  whiteArrowDown,
  redWarning
} from "../../assets/images";
import "./ClientNavbar.css";

export function useOnClickOutside(ref, handler) {
  useEffect(() => {
    const listener = (event) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    };
    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);
    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref, handler]);
}

const ClientNavbar = () => {
  const token = getToken();
  const ref = useRef();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const user = GetUserInfo();

  const [count, setCount] = useState();
  const userData = GetUserLoginData()
  const [forceChangePassword, setForcePasswordChange] = useState(false)

  useLayoutEffect(() => {
    if (userData.forceChangePassword > 0) {
      setForcePasswordChange(true)
    } else {
      setForcePasswordChange(false)
    }
  }, [userData])

  const notificationState = useSelector(
    (state) => state.toggleNotificationExitReducer.exit
  );

  useEffect(() => {
    window.scroll(0, 0);
    if (token) {
      getMyInformationAPI();
    }
  }, []);

  useEffect(() => {
    if (token) {
      getNotification();
    }
  }, [notificationState, history.location])

  const getNotification = () => {
    GetNotification("C").then((response) => {
      const data = response?.data?.filter((data) => {
        if (data.read === false) {
          return data;
        }
      });

      setCount(data?.length);
    });
  };
  const showProfilePic = (guid) => {
    if (guid) {
      DownloadDocGet(guid).then((response) => {
        dispatch(
          updateProfileImage(
            `${new Buffer(response.data, "binary").toString("base64")}`
          )
        );
      });
    } else {
      updateProfileImage("")
    }
  };

  const [openNav, setOpenNav] = useState(false);
  const [openNavDropdown, setOpenNavDropdown] = useState(false);
  const [openNotification, setopenNotification] = useState(false);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [openNav])

  const getMyInformationAPI = () => {
    try {
      const res = getClientInformation()
        .then((response) => {
          dispatch(getMyInformation(response.data));
          if (response.data?.thumbnailBlobId) {
            showProfilePic(response.data?.thumbnailBlobId);
            dispatch(updateProfileImage(user?.imageAsBase64String));
          } else {
            dispatch(updateProfileImage(""));
          }
        })
        .catch((err) => {
          console.log(err?.response?.data);
        });
    } catch (err) {
      console.err("err", err);
    }
  };

  const closeNav = () => {
    setOpenNav(false);
  };
  const handleOutsideClick = (event, ref) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setOpenNavDropdown(false);
    }
  };

  useOnClickOutside(ref, () => setopenNotification(!openNotification));

  const handleLinkClick = () => {
    setOpenNavDropdown(false);
  };

  const handleLinkClickNotification = () => {
    setopenNotification(false);
  };

  const auth = getToken();

  return (
    <React.Fragment>
      {auth &&
        <Modal
          isOpen={forceChangePassword}
          size="extrasmall"
          hideCloseButton={false}
          toggleModal={() => { }}
          disableBackdropToggle={true}
          containerClass={"addTimeLineModal"}
          classname="z102"
          title={
            <div className="ml-2">
              <img src={redWarning} alt="warning" className="pb-1" />
              <span className="boldFont font18Black ml-2" >Change Password</span>
            </div>
          }>
          <ForceChangePassword />
        </Modal>}
      <div
        className={
          ((user?.nickName?.length || user?.firstName?.length) < 4)
            ? "container-fluid newNavbarContainer d-flex align-items-center navBarPadding"
            : "container-fluid newNavbarContainer d-flex align-items-center"
        }
      >
        {(history.location.pathname.includes("/submit-testimonial/") || history.location.pathname.includes("/submit-testimonial-confirm")) ? (
          <div className="d-none"></div>
        ) : (
          <div
            className={
              openNav ? "mobileMenuBtn mobileMenuBtnActive" : "mobileMenuBtn"
            }
            onClick={() => setOpenNav(!openNav)}
          >
            <div className="my-1 menuLines" />
            <div className="my-1 menuLines" />
            <div className="my-1 menuLines" />
          </div>
        )}

        <div>
          {(history.location.pathname.includes("/submit-testimonial/") || history.location.pathname.includes("/submit-testimonial-confirm")) ? (
            <a
              href={"https://thetrainingassociates.com/"}
              rel="noopener noreferrer"
              target="_blank"
            >
              <img className="NavbarBrandLogo" src={logo} alt="logo" />
            </a>
          ) : (!userData.isWizardFinished) ?
            <img className="NavbarBrandLogo" src={logo} alt="logo" /> : (
              <Link to={(auth) ? "/client-dashboard" : "/login"}>
                <img className="NavbarBrandLogo" src={logo} alt="logo" />
              </Link>
            )}
        </div>
        {auth && (
          <React.Fragment>
            <div className="d-flex align-items-center">
              {GetMenuLink().map((links, idx) => {
                return (
                  <React.Fragment key={idx}>
                    {links.legacy ? (
                      <a
                        key={links.name}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={
                          location.pathname === links.path ||
                            location.pathname.includes(links?.active)
                            ? "NewNavBarLinks NewNavBarLinksActive"
                            : "NewNavBarLinks"
                        }
                        href={links.path}
                      >
                        {links.name}
                      </a>
                    ) : (
                      <Link
                        to={links.path}
                        key={links.name}
                        className={
                          location.pathname === links.path ||
                            location.pathname.includes(links?.active)
                            ? "NewNavBarLinks NewNavBarLinksActive"
                            : "NewNavBarLinks"
                        }
                      >
                        {links.name}
                      </Link>
                    )}
                  </React.Fragment>
                );
              })}
            </div>
            <div className="ml-auto">
              <div className="d-flex align-items-center">
                <div className="navbarnotificationWrapper mr-3">
                  {location.pathname === "/notificationviewall" ? (
                    <img
                      src={bellIconBlue}
                      className="bellIcon"
                      onClick={(e) => {
                        e.stopPropagation();
                        setopenNotification(
                          (openNotification) => !openNotification
                        );
                      }}
                      alt=""
                    />
                  ) : (
                    <img
                      src={bellIcon}
                      className="bellIcon"
                      onClick={(e) => {
                        e.stopPropagation();
                        setopenNotification(
                          (openNotification) => !openNotification
                        );
                      }}
                      alt=""
                    />
                  )}
                  {location.pathname === "/notificationviewall" ? (
                    <div className="d-none"></div>
                  ) : (
                    count > 0 && (
                      <div className="notificationSectionCountWrapper" onClick={(e) => {
                        e.stopPropagation();
                        setopenNotification(
                          (openNotification) => !openNotification
                        );
                      }}>
                        <div className="notificationCountNeww">{count}</div>
                      </div>
                    )
                  )}
                  {openNotification && (
                    <>
                      <div className="traingle"></div>
                      <div className="overlayNavbar" ref={ref}>
                        <NavbarNotificationMenu
                          handleLinkClick={handleLinkClickNotification}
                        />
                      </div>
                    </>
                  )}
                </div>
                <div className="chatIconSection d-none">
                  <img src={chatIcon} alt="" />
                  <div className="notificationSection">
                    <div style={{ position: "relative" }}>
                      <img
                        src={notificationCircle}
                        className="notificationCircle"
                        alt=""
                      />
                      <span className="notificationCount">1</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <span className="border-right ml-2 mr-3 borderRightSpan d-none">.</span>
            <img
              src={
                user?.imageAsBase64String
                  ? "data:image/jpeg;base64,".concat(user?.imageAsBase64String)
                  : uploadImg
              }
              onClick={() => setOpenNavDropdown(!openNavDropdown)}
              className="userIconImg"
              alt=""
            />

            {openNavDropdown && (
              <NavbarPopupMenuClient
                handleOutsideClick={handleOutsideClick}
                handleLinkClick={handleLinkClick}
              />
            )}
            <span
              className="NewNavBarLinks px-0 ml-2 text-capitalize"
              onClick={() => setOpenNavDropdown(!openNavDropdown)}
            >
              {user?.nickName?.substr(0, 15) || user?.firstName?.substr(0, 15)}
              <img src={whiteArrowDown} alt="" className="rotateArrow" />
            </span>
          </React.Fragment>
        )}
        {!auth && (
          <a
            className={
              (history.location.pathname.includes("/submit-testimonial/") || history.location.pathname.includes("/submit-testimonial-confirm"))
                ? "d-none"
                : "ml-auto NewNavBarLinks d-block w-auto mr-2"
            }
            href={
              HelpCenterURL
            }
            rel="noopener noreferrer"
            target="_blank"
          >
            Help Center
          </a>
        )}
      </div>

      <React.Fragment>
        {openNav && <MobileNavbar closeNav={closeNav} />}
      </React.Fragment>
    </React.Fragment>
  );
};
export default ClientNavbar;
