/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { backArrow } from "../../../../../assets/images";
import TalentsJobDetails from "../../../Components/JobDetails/jobDetails"
import SubmitProposal from "../../../../SubmitProposal";
import "./../jobDetails.scss"

const SubmitProposals = () => {
    const history = useHistory()

    return (
        <div className="submitProposalWrapper">
            <span className="mb-2 desktopView" onClick={() => history.goBack()}>
                <img src={backArrow} alt="" className="pointer" /><span className="font14Blue ml-2 ">Back</span>
            </span>
            <div className="font20Black boldFont jobCenterHeader">
                {history.location.pathname.includes("edit") ? "Edit Proposal" : "Your Proposal"}
            </div>
            <div className='row'>
                <div className='col col-12 col-lg-4' style={{ paddingRight: "5px" }}>
                    <SubmitProposal />
                </div>
                <div className='col col-12 col-lg-8' style={{ padding: "0 5px" }}>
                    <TalentsJobDetails submitProposal={true} />
                </div>
            </div>
        </div>
    )
}

export default SubmitProposals