/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { useParams } from 'react-router-dom';
import { upArrow, arrowDown, uploadImg, rejectIcon, greyFilledEllipsis, chatBubbleIcon, viewProfile, redWarning } from "../../../../../assets/images";
import { DeleteProjectContact, GetProjectContact, GetProjectTalents, PostProjectContact } from '../../../../../services/projectContact';
import { DownloadBlobByEntity } from '../../../../../services/portfolio';
import { GetUserLoginData } from '../../../../../util/reduxData';
import { useGlobalClick } from '../../../../../util/clickListener';
import FreelanceDetail from '../../../FreelanceDetail';
import ViewAllTalentJob from '../../../Talent/viewAllTalentJob';
import Modal from "../../../../../components/modal";
import Button from "../../../../../components/button";
import AddProjectContact from '../../../JobSection/CompanyContacts/addProjectContact/addProjectContact';
import { GetContactPublicPhoto } from '../../../../../services/reviewSubmission';
import "./Contact.scss";

const Contact = ({ repDetails, talent, remove, hideCompanyContact, isPublic, isChatVisible, publicProjectContacts = [] }) => {
    const params = useParams()
    const history = useHistory()
    const projectId = params.jobId;
    const hash = params.hash
    const queryString = history.location.search
    const email = new URLSearchParams(queryString).get("email");
    const ref = useRef()
    const ref1 = useRef()

    const [addProjectContactModal, setAddProjectContactModal] = useState(false);
    const [open, setOpen] = useState(true);
    const [projectContacts, setProjectContacts] = useState([]);
    const [projectTalents, setProjectTalents] = useState([]);
    const [contactOpen, setContactOpen] = useState(true);
    const [talentOpen, setTalentOpen] = useState(true);
    const [openedOption, setOpenedOption] = useState(null);
    const [openedOption1, setOpenedOption1] = useState(null);
    const [deleteContactId, setDeleteContactId] = useState(null);
    const [showDeleteContactModal, setShowDeleteContactModal] = useState(false);
    const [talentShowMore, setTalentShowMore] = useState(false);
    const [contactShowMore, setContactShowMore] = useState(false);
    const [talentJobData, setTalentJobData] = useState({});
    const [viewAllJob, setViewAllJob] = useState(false);
    const [freelanceModal, setfreelanceModal] = useState(false);
    const [freelanceInstrId, setFreelanceInstrId] = useState(null);
    const [lastContactModal, setLastContactModal] = useState(false)
    const userData = GetUserLoginData()

    const sendMail = (mail) => {
        const mailto = `mailto:${mail}`;
        window.location.href = mailto;
    }
    useEffect(() => {
        if (!isPublic) {
            if ((!remove && !hideCompanyContact)) {
                getProjectContacts()
            }
            getProjectTalents()
        }
    }, [])

    useEffect(() => {
        if (publicProjectContacts.length > 0) {
            setProjectContactsFunc();
            setProjectTalentsFunc();
        }
    }, [publicProjectContacts])

    const setProjectContactsFunc = () => {
        const newContactArray = [...publicProjectContacts].sort((a, b) => a["firstName"].toLowerCase().localeCompare(b["firstName"].toLowerCase()))
        newContactArray.map((dataDict) => {
            // Fetches the profile pic of contact
            if (dataDict?.imageBlobGuid) {
                const params = {
                    projectId: projectId,
                    hash: hash,
                    email: email,
                    blobNameGUID: dataDict?.imageBlobGuid,
                    entityName: "contact"
                }
                GetContactPublicPhoto(params).then((response) => {
                    const Imagedata = `data:${response?.headers["content-type"]
                        };base64,${new Buffer(response?.data, "binary")?.toString(
                            "base64"
                        )}`;
                    let newDict = {
                        ...dataDict,
                        thumbnailImage: Imagedata,
                    };
                    const index = publicProjectContacts.indexOf(dataDict);
                    if (index > -1) {
                        newContactArray[index] = newDict;
                    }
                    setProjectContacts([...newContactArray]);
                });
            } else {
                const index = publicProjectContacts.indexOf(dataDict);
                if (index > -1) {
                    newContactArray[index] = dataDict;
                }
                setProjectContacts([...newContactArray]);
            }
        });
        setProjectContacts([...publicProjectContacts]);
    }

    const setProjectTalentsFunc = () => {
        const newContactArray = [...publicProjectContacts].sort((a, b) => a["firstName"].toLowerCase().localeCompare(b["firstName"].toLowerCase()))
        newContactArray.map((dataDict) => {
            // Fetches the profile pic of contact
            if (dataDict?.imageBlobGuid) {
                const params = {
                    projectId: projectId,
                    hash: hash,
                    email: email,
                    blobNameGUID: dataDict?.imageBlobGuid,
                    entityName: "contact"
                }
                GetContactPublicPhoto(params).then((response) => {
                    const Imagedata = `data:${response?.headers["content-type"]
                        };base64,${new Buffer(response?.data, "binary")?.toString(
                            "base64"
                        )}`;
                    let newDict = {
                        ...dataDict,
                        thumbnailImage: Imagedata,
                    };
                    const index = publicProjectContacts.indexOf(dataDict);
                    if (index > -1) {
                        newContactArray[index] = newDict;
                    }
                    setProjectTalents([...newContactArray]);
                });
            } else {
                const index = publicProjectContacts.indexOf(dataDict);
                if (index > -1) {
                    newContactArray[index] = dataDict;
                }
                setProjectTalents([...newContactArray]);
            }
        });
        setProjectTalents([...publicProjectContacts]);
    }

    const getProjectContacts = () => {
        // Gets project contact
        GetProjectContact(params.jobId)
            .then((response) => {
                const sortedResponse = [...response.data].sort((a, b) => a["firstName"].toLowerCase().localeCompare(b["firstName"].toLowerCase()));
                let contactArray = []
                if(userData.instrId > 0) {
                    contactArray = sortedResponse.filter((res)=>{
                        if(res.contactStatus === "A") {
                            return res
                        }
                    })
                } else {
                    contactArray = [...sortedResponse]
                }
                
                const newContactArray = [...contactArray];
                contactArray.map((dataDict) => {
                    // Fetches the profile pic of contact
                    if (dataDict?.imageBlobGuid) {
                        DownloadBlobByEntity(dataDict?.imageBlobGuid, "contact", dataDict?.contactID).then((response) => {
                            const Imagedata = `data:${response?.headers["content-type"]
                                };base64,${new Buffer(response?.data, "binary")?.toString(
                                    "base64"
                                )}`;
                            let newDict = {
                                ...dataDict,
                                thumbnailImage: Imagedata,
                            };
                            const index = contactArray.indexOf(dataDict);
                            if (index > -1) {
                                newContactArray[index] = newDict;
                            }
                            setProjectContacts([...newContactArray]);
                        });
                    } else {
                        const index = contactArray.indexOf(dataDict);
                        if (index > -1) {
                            newContactArray[index] = dataDict;
                        }
                        setProjectContacts([...newContactArray]);
                    }
                });
                setProjectContacts(contactArray);
            });
    }

    const [thumbnailDict, setThumbnailDict] = useState({});

    const getTalentThumbnails = (talentArray) => {
        const tempDict = { ...thumbnailDict }
        // Downloads the thumbnail of talents and updates it to the thumbnail dict
        let promises = talentArray.map((dataDict, index) => {
            if (dataDict.imageBlobGuid) {
                tempDict[dataDict?.instrID] = index;
                return DownloadBlobByEntity(
                    dataDict?.imageBlobGuid,
                    "instructor",
                    dataDict?.instrID
                )
            } else {
                tempDict[dataDict?.instrID] = null;
            }
        })
        Promise.allSettled(promises).then(results => {
            results.forEach((result, index) => {
                if (result.status === "rejected") {
                    console.error(result.error);
                } else {
                    if (result.value) {
                        for (let [key, value] of Object.entries(tempDict)) {
                            if (parseInt(value) === index) {
                                const Imagedata = `data:${result.value?.headers["content-type"]
                                    };base64,${new Buffer(result.value?.data, "binary")?.toString("base64")}`;
                                tempDict[key] = Imagedata;
                            }
                        }
                        setThumbnailDict(tempDict);
                    }
                }
            })
        })
    }

    const getProjectTalents = () => {
        // Gets project talents
        GetProjectTalents(params.jobId)
            .then((response) => {
                const contactArray = [...response.data].sort((a, b) => a["firstName"].toLowerCase().localeCompare(b["firstName"].toLowerCase()));
                const newContactArray = [...contactArray];
                getTalentThumbnails(newContactArray)
                setProjectTalents(contactArray);
            });
    }

    const addProjectContact = (data) => {
        const payload = {
            "projectId": params.jobId,
            "contactId": data
        }
        // adds contact to the project
        PostProjectContact(payload)
            .then((res) => {
                getProjectContacts()
                setAddProjectContactModal(false)
            })
    }

    const countReps = (repDetails) => {
        let count = 0
        if (repDetails?.srName) {
            count += 1
        }
        if (repDetails?.recName) {
            count += 1
        }
        if (repDetails?.pmName) {
            count += 1
        }
        return count
    }

    const handleClick = (event) => {
        setOpenedOption(null);
        if (ref?.current && !ref?.current?.contains(event.target)) {
            setOpenedOption(null);
        }
    };

    const handleClickTalent = e => {
        setOpenedOption1(null);
        if (ref?.current && !ref?.current?.contains(e.target)) {
            setOpenedOption1(null);
        }
    }

    useGlobalClick(handleClick, ref)

    useGlobalClick(handleClickTalent, ref1)

    const showDeleteContactModalFunc = (data) => {
        if(getProjectContactsData().length === 1) {
            setLastContactModal(true)
        } else {
            // sets the modal visiblity to true and store the contact id to be removed
            setDeleteContactId(data)
            setShowDeleteContactModal(true)
        }
    }

    const deleteContact = () => {
        // Removes contact from the project
        DeleteProjectContact(params.jobId, deleteContactId)
            .then((res) => {
                setShowDeleteContactModal(false)
                setDeleteContactId(null)
                getProjectContacts()
            })
    }

    const toggleDeleteContact = () => {
        setShowDeleteContactModal(false)
        setDeleteContactId(null)
    }

    const generateUrl = (name) => {

        let url = ""
        if (name) {
            const split = name.split(" ")
            const firstName = split[0]
            const lastName = split[1]
            url = `https://ttaassets.azureedge.net/assets/${firstName.toLowerCase()}_${lastName.toLowerCase()}.jpg`
        }
        return url
    }

    // const triggerCall = (phoneNumber) => {
    //     const clearNumber = phoneNumber.replace(/[^0-9]/g, '');
    //     window.location.href = `tel:${clearNumber}`
    // }

    const getProjectContactsData = () => {
        if (projectContacts.length <= 3) {
            return projectContacts
        } else if (projectContacts.length > 3) {
            if (contactShowMore) {
                return projectContacts
            } else {
                return projectContacts.slice(0, 3)
            }
        }
    }

    const getProjectTalentsData = () => {
        if (projectTalents.length <= 3) {
            return projectTalents
        } else if (projectTalents.length > 3) {
            if (talentShowMore) {
                return projectTalents
            } else {
                return projectTalents.slice(0, 3)
            }
        }
    }

    const viewAllTalentJobs = (data) => {
        setTalentJobData(data)
        setViewAllJob(true)
    }
    return (
        <>
            <Modal
                isOpen={lastContactModal}
                size="extrasmall"
                title={"Remove Contact"}
                titleClass="ml-1"
                hideCloseButton={true}
                toggleModal={() => setLastContactModal(false)}
                disableBackdropToggle={true}
                containerClass="submitHoursModal"
            >
                <div className="mt-5 mb-4 ml-2 mr-2">
                    <div>
                        <img src={redWarning} className="w-100 redWarningImage" alt="paynow" />
                    </div>
                    <div className="font14Black text-align-center mt-3">
                    Atleast one contact is required on a Job
                    </div>
                </div>
            </Modal>
            <Modal
                isOpen={freelanceModal}
                size="extralarge"
                hideCloseButton={true}
                containerClass="freelancerModal freelancerModal1"
                toggleModal={() => setfreelanceModal(!freelanceModal)}
                classname="zIndex999"
            >
                <FreelanceDetail
                    projectId={params.jobId}
                    instrId={freelanceInstrId}
                    refreshList={() => { }}
                    tab={"Client"}
                    isFavorite={false}
                    fromTalent={true}
                    client={true}
                    completed={false}
                    talentDetails={{}}
                    viewAllTalentJobs={viewAllTalentJobs}
                />
                <ViewAllTalentJob
                    isOpen={viewAllJob}
                    toggleModal={() => setViewAllJob(false)}
                    talentData={talentJobData}
                />
            </Modal>
            <Modal
                isOpen={showDeleteContactModal}
                size="extrasmall"
                title={
                    "Remove Contact"
                }
                titleClass="ml-1"
                hideCloseButton={true}
                toggleModal={toggleDeleteContact}
                disableBackdropToggle={true}
                containerClass="removeContactModal"
            >
                <div className="mt-4 mb-3 ml-4 mr-3">
                    <span className="font14Black">Are you sure you want to remove this person from the project?</span>
                </div>
                <div
                    className="border-top text-center"
                    style={{ left: "0", width: "100%" }}
                >
                    <div className="buttonHolder">
                        <Button
                            label="Cancel"
                            type="button"
                            onClick={toggleDeleteContact}
                            className="btn-light main_btn mx-2 profileButtonDelete"
                        />
                        <Button
                            label="Remove"
                            type="submit"
                            onClick={deleteContact}
                            className="btn-blue main_btn  "
                        />
                    </div>
                </div>
            </Modal>
            <AddProjectContact
                toggleProjectContactModal={() => setAddProjectContactModal(false)}
                addProjectContactModal={addProjectContactModal}
                addProjectContactProp={(data) => addProjectContact(data)}
                existingContactId={projectContacts.map((res) => { return res.contactID })}
                isPublic={isPublic}
            />
            <div className="JobPostcontactWrapper">
                <div className="headerBackground mb-2">
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="font-weight-bold">TTA Team</div>
                        <div className="d-flex" onClick={() => setOpen(!open)}>
                            <div className="contactCount mr-2">{countReps(repDetails)}</div>
                            {!open ?
                                <img src={arrowDown} alt="down" className="cursor-pointer" />
                                :
                                <img src={upArrow} alt="up" className="cursor-pointer" />
                            }
                        </div>
                    </div>
                </div>
                <div className={open ? "ttaContactsClass" : "d-none"}>
                    {repDetails?.srName &&
                        <>
                            <div className='d-flex'>
                                <div>
                                    <img
                                        src={generateUrl(repDetails?.srName)}
                                        alt={""}
                                        className="ttaTeamImg mr-2 mt-1"
                                        onError={({ currentTarget }) => {
                                            currentTarget.onerror = null; // prevents looping
                                            currentTarget.src = uploadImg;
                                        }} />
                                </div>
                                <div className='mb-3'>
                                    { repDetails?.srName === "House Account SR" ?
                                        <div className="font14Black font-weight-bold"> Business Development Team</div>
                                        :
                                        <>
                                            <div className="font16Black font-weight-bold"> {repDetails?.srName}</div>
                                            <div className="font12Black">
                                                <div>Sales Representative</div>
                                                <div
                                                    className="font14Grey"
                                                >{repDetails?.srPhone}</div>
                                            </div>
                                        </>
                                    }
                                    <div className="font14Blue" onClick={() => sendMail(repDetails?.srEmail)}>
                                        {repDetails?.srEmail}{" "}
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                    {repDetails?.recName &&
                        <>
                            <div className='d-flex'>
                                <div>
                                    <img
                                        src={generateUrl(repDetails?.recName)}
                                        alt={""}
                                        className="ttaTeamImg mr-2 mt-1"
                                        onError={({ currentTarget }) => {
                                            currentTarget.onerror = null; // prevents looping
                                            currentTarget.src = uploadImg;
                                        }} />
                                </div>
                                <div className='mb-3'>
                                    <div className="font-weight-bold">{repDetails?.recName}</div>
                                    <div className="font12Black">
                                        <div> Recruiter</div>
                                        <div className="font14Grey">
                                            {repDetails?.recPhone}</div>
                                    </div>
                                    <div className="font14Blue" onClick={() => sendMail(repDetails?.recEmail)}>
                                        {repDetails?.recEmail}
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                    {repDetails?.pmName &&
                        <>
                            <div className='d-flex'>
                                <div>
                                    <img
                                        src={generateUrl(repDetails?.pmName)}
                                        alt={""}
                                        className="ttaTeamImg mr-2 mt-1"
                                        onError={({ currentTarget }) => {
                                            currentTarget.onerror = null; // prevents looping
                                            currentTarget.src = uploadImg;
                                        }} />
                                </div>
                                <div className='mb-3'>
                                    <div className="font-weight-bold">{repDetails?.pmName} </div>
                                    <div className="font12Black">
                                        <div>Project Manager</div>
                                        <div
                                            className="font14Grey"
                                        >{repDetails?.pmPhone}</div>
                                    </div>
                                    <div className="font14Blue" onClick={() => sendMail(repDetails?.pmEmail)}>
                                        {repDetails?.pmEmail}
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                </div>
                { getProjectContactsData().length > 0 && (!remove && !hideCompanyContact) &&
                    <div className="headerBackground mb-2">
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="font-weight-bold">Company Contacts</div>
                            <div className="d-flex " onClick={() => setContactOpen(!contactOpen)}>
                                <div className="contactCount mr-2">{projectContacts.length}</div>
                                {!contactOpen ?
                                    <img src={arrowDown} alt="down" className='cursor-pointer' />
                                    :
                                    <img src={upArrow} alt="up" className='cursor-pointer' />
                                }
                            </div>
                        </div>
                    </div>
                }
                {(contactOpen && !hideCompanyContact) &&
                    <div className={remove ? "d-none" : ""}>
                        {!talent && !isPublic &&
                            <>
                                <div className="companyContactsClass pb-0">
                                    <Button
                                        label="+ Add Contact"
                                        className="btn-blue"
                                        onClick={() => setAddProjectContactModal(true)}
                                    ></Button>
                                    <div className="font12Grey mt-2">
                                        Invite coworkers to work together on the job.
                                    </div>
                                </div>
                            </>
                        }
                        { getProjectContactsData().length > 0 &&
                            <>
                                {
                                    getProjectContactsData().map((data, index) => {
                                        return (
                                            <React.Fragment key={index}>
                                                <div className="companyContactsClass">
                                                    <div className="contactListWrap mt-3">
                                                        <div className="d-flex">
                                                            <img
                                                                src={
                                                                    data.thumbnailImage
                                                                        ? data.thumbnailImage
                                                                        : uploadImg
                                                                }
                                                                className="contactIconImg mr-2 mt-1"
                                                                alt="sort"
                                                            />
                                                            <div>
                                                                <div className="font16Black boldFont">
                                                                    {data.firstName} {data?.lastName}
                                                                    {
                                                                        (data.isOwner && !talent) ?
                                                                            <span> (You)</span> :
                                                                            null
                                                                    }
                                                                </div>
                                                                {
                                                                    !talent &&
                                                                    <>
                                                                        {
                                                                            data?.contactStatus === "P" ?
                                                                                <div className="statusBlock">
                                                                                    Invited
                                                                                </div> :
                                                                                <div className="font12Black">
                                                                                    {data.jobTitle}
                                                                                </div>
                                                                        }
                                                                    </>
                                                                }
                                                                <div className="font14Grey">
                                                                    {data.phoneNumber}
                                                                </div>
                                                                <div className="font14Blue" onClick={() => sendMail(data?.email)}>
                                                                    {data?.email}
                                                                </div>
                                                                
                                                            </div>
                                                        </div>
                                                        {!talent &&
                                                            <>
                                                                {
                                                                    data?.isDeleteAbleContact &&
                                                                    <div className="pointer relative ml-3">
                                                                        <img src={greyFilledEllipsis} alt="" onClick={() => setOpenedOption(index)} className="optionEllipsis"/>
                                                                        <div className={openedOption === index ? "optionCard" : "d-none"}>
                                                                            <div className="d-flex option" onClick={() => showDeleteContactModalFunc(data.contactID)}>
                                                                                <img src={rejectIcon} alt="" className="mr-2 ml-1" />
                                                                                <span>Remove from Job</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </>
                                                        }
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        )
                                    })
                                }
                                <div className="companyContactsClass">
                                    {
                                        projectContacts.length > 3 ?
                                            !contactShowMore ?
                                                <p className="font14Blue" onClick={() => setContactShowMore(true)}>Show More </p>
                                                : <p className="font14Blue" onClick={() => setContactShowMore(false)}>Show Less </p>
                                            : null
                                    }
                                </div>
                            </>
                        }
                    </div>
                }
                { getProjectTalentsData().length > 0 &&
                    <div className="headerBackground">
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="font-weight-bold">
                                {
                                    userData?.instrId ?
                                    "Project Team" :
                                    "Talent"
                                }
                                </div>
                            <div className="d-flex " onClick={() => setTalentOpen(!talentOpen)}>
                                <div className="contactCount mr-2">{projectTalents.length}</div>
                                {!talentOpen ?
                                    <img src={arrowDown} alt="down" className='cursor-pointer' />
                                    :
                                    <img src={upArrow} alt="up" className='cursor-pointer' />
                                }
                            </div>
                        </div>
                    </div>
                }
                {   getProjectTalentsData().length > 0 &&
                    talentOpen &&
                    <>
                        {getProjectTalentsData().map((data, index) => {
                            return (
                                <React.Fragment key={index}>
                                    <div className="companyContactsClass">
                                        <div className="contactListWrap mt-3">
                                            <div className="d-flex">
                                                <img
                                                    src={
                                                        thumbnailDict[data?.instrID]
                                                            ? thumbnailDict[data?.instrID]
                                                            : uploadImg
                                                    }
                                                    className="contactIconImg mr-2 mt-1"
                                                    alt="sort"
                                                />
                                                <div>
                                                    <div className="font16Black boldFont talentName" title={`${data.firstName} ${data?.lastName}`}>
                                                        {data.firstName} {data?.lastName}
                                                    </div>
                                                    <div className="font14Grey">
                                                        {data.homePhone}
                                                    </div>
                                                    <div className="font14Blue" onClick={() => sendMail(data?.email)}>
                                                        {data?.email}
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                               (isChatVisible || !userData.instrId) && (userData?.instrId !== data.instrID) &&
                                                <div className="pointer relative ml-3" ref={ref1}>
                                                    <img src={greyFilledEllipsis} alt="" onClick={() => setOpenedOption1(index)} className="optionEllipsis"/>
                                                    <div className={openedOption1 === index ? "optionCard" : "d-none"}>
                                                        {
                                                            isChatVisible &&
                                                            <div className="d-flex option noWrap" onClick={() => {
                                                                if (talent) {
                                                                    history.push(`/jobcenter/inprogress/job-details/${projectId}/5?email=${data?.chatEmail}`)
                                                                } else {
                                                                    history.push(`/client-job/job-entity/7/${projectId}?email=${data?.chatEmail}`)
                                                                }
                                                            }}>
                                                                <img src={chatBubbleIcon} alt="" className="mr-2 ml-1" />
                                                                <span>Open Chat</span>
                                                            </div>
                                                        }
                                                        {
                                                            !userData.instrId &&
                                                            <div 
                                                                className="d-flex option noWrap" 
                                                                onClick={()=>{
                                                                    setfreelanceModal(true)
                                                                    setFreelanceInstrId(data?.instrID)
                                                                }}
                                                            >
                                                                <img src={viewProfile} alt="" className="mr-2 ml-1" />
                                                                <span>View Profile</span>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>

                                </React.Fragment>
                            )
                        })}
                        <div className="companyContactsClass">
                            {
                                projectTalents.length > 3 ?
                                    !talentShowMore ?
                                        <p className="font14Blue" onClick={() => setTalentShowMore(true)}>Show More </p>
                                        : <p className="font14Blue" onClick={() => setTalentShowMore(false)}>Show Less </p>
                                    : null
                            }
                        </div>
                    </>
                }
            </div>
        </>
    )
}

export default Contact;