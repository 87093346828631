/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { emailValidations, passwordValidations } from "../../../constants/Validations";
import { loginApi } from "../../../services/login";
import Modal from "../../../components/modal";
import Button from "../../../components/button"
import ReCaptcha from "../../../components/reCaptcha/ReCaptcha";
import Checkbox from "../../../components/checkbox/Checkbox";
import TextInput from "../../../components/textInput";
import { setAllAgencyMember } from "../../../ducks/AgencyMember/AllAgencyMemberDetail";
import { setCurrentAgencyMember } from "../../../ducks/AgencyMember";
import { setLastVisitedPage } from "../../../ducks/lastVisitedPage";
import { setLoginData } from "../../../ducks/Login";
import { removeRememberMe, saveRememberMe } from "../../../util/localStorage";
import "./index.scss";

const LoginModal = ({ isOpen, toggleModal }) => {
  const history = useHistory();
  const dispatch = useDispatch()
  const { register, handleSubmit, errors } = useForm({ reValidateMode: 'onBlur' });
  const [load, setload] = useState(false);
  const [captcha, setCaptcha] = useState(true);
  const [loginError, setLoginError] = useState("");
  const [checkBox, setCheckBox] = useState(false);
  const [email, setEmail] = useState(undefined);
  const [showCaptcha, setShowCaptcha] = useState(false)

  const onSubmit = (data) => {
    let { email, password, rememberMe } = data;
    setload(true)
    const loginDataNew = {
      email: email.toString(),
      password: password.toString(),
    };
    if (email && password && captcha) {
      try {
        const res = loginApi(loginDataNew)
          .then((response) => {
            let { token, refreshToken, loginId, chatEmail, contactId, showContactSplashScreen, clientId, instrId, fullName, isWizardFinished, lastVisitedPage, webRole, forceChangePassword, agencyInstrId, showTalentSplashScreen, showTrainerRole } = response.data;
            const loginReducerData = { 
              email: data?.email,
              rememberMe: rememberMe,
              token: token,
              refreshToken: refreshToken,
              auth: true,
              loginId: loginId,
              contactId: contactId,
              clientId: clientId,
              instrId: instrId,
              fullName: fullName,
              isWizardFinished: isWizardFinished,
              lastVisitedPage: lastVisitedPage,
              webRole: webRole,
              forceChangePassword: forceChangePassword,
              agencyInstrId: agencyInstrId,
              showTalentSplashScreen: showTalentSplashScreen,
              nextPage: history.location.pathname + history.location.search,
              fromForce: false,
              showContactSplashScreen: showContactSplashScreen,
              chatEmail: chatEmail,
              showTrainerRole: showTrainerRole || false
            };
            if(!agencyInstrId) {
              dispatch(setAllAgencyMember([]))
            }
            if (instrId) {
              dispatch(setCurrentAgencyMember(instrId))
            } else {
              dispatch(setCurrentAgencyMember(0))
            }
            if (rememberMe) {
              saveRememberMe(data?.email);
              setload(false)
              dispatch(setLastVisitedPage(lastVisitedPage))
              dispatch(setLoginData(loginReducerData));
            } else {
              removeRememberMe(data?.email)
              dispatch(setLastVisitedPage(lastVisitedPage))
              dispatch(setLoginData(loginReducerData));
              setload(false)
            }
          })
          .catch((err) => {
            setload(false)
            if (!showCaptcha) {
              setCaptcha(!captcha)
            }
            setShowCaptcha(true)
            console.log(err?.response?.data);
            setLoginError(err.response?.data?.error);
          });
      } catch (err) {
        console.err("err", err);
      }
    }
  };

  const handleCaptcha = (value) => {
    if (value) {
      setCaptcha(value);
    } else if (value === null) setCaptcha(null);
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        size="medium"
        hideCloseButton={true}
        toggleModal={toggleModal}
        title={""}
        disableBackdropToggle={true}
        containerClass={"loginModal"}
      >
        <div className="loginModalWrap">
          <div className="font26Black boldFont">
            Please login for full access
          </div>
          <div className="loginFormWrap">
            <form onSubmit={handleSubmit(onSubmit)}>
              <TextInput
                name="email"
                label={"Email Address"}
                innerRef={register({
                  required: true,
                  pattern: {
                    value: emailValidations.pattern,
                    message: emailValidations.errors.invalid,
                  },
                })}
                error={
                  errors.email?.type === "required"
                    ? emailValidations.errors.required
                    : errors.email?.type === "pattern"
                      ? emailValidations.errors.invalid
                      : null
                }
                containerClass="mt-4"
                type="email"
                placeholder="Email Address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                id="emailLogin"
              />
              <TextInput
                name="password"
                label={"Password"}
                innerRef={register({
                  required: true,
                })}
                error={
                  errors.password?.type === "required"
                    ? passwordValidations.errors.required
                    : null
                }
                containerClass="mt-3 mb-2"
                type="password"
                placeholder="Password"
              />
              <div className="d-flex align-items-center justify-content-between">
                <Checkbox
                  name="rememberMe"
                  innerRef={register}
                  checkboxClass={checkBox}
                  onChange={(e) =>
                    e.target.checked ? setCheckBox(true) : setCheckBox(false)
                  }
                  id="checkboxRememberMe"
                  containerClass="my-2 d-flex pl-md-2 pl-0 "
                  label={<span>Remember Me</span>}
                  checked={checkBox}
                />
                <span>
                  <span
                    className="font14Blue"
                    onClick={() => {
                      history.push("/forgetpassword")
                    }}
                  >
                    Forgot Password?
                  </span>
                </span>
              </div>
              {
                showCaptcha &&
                <ReCaptcha onChange={handleCaptcha} className="my-1" />
              }
              <div className="text-align-center">
                <Button
                  label={load === true ? "Loading" : "Continue"}
                  type="submit"
                  className={"btn-blue w-100 mt-3"}
                  disabled={!captcha}
                />
              </div>

              {
                loginError?.includes("not activated") ?
                  <div className="mb-3 font14Red text-center">
                    Your account is not activated yet.{" "}
                    <span
                      className="font14Blue"
                      onClick={
                        () => history.push("/resend-activation-email")
                      }>Click here
                    </span> to resend the activation email
                  </div>
                  : <div className="mb-3 font14Red text-center">{loginError}</div>
              }

            <div className="orSignUp">
              <span>OR</span>
            </div>
            <Button
              label="Don’t have an account? Sign Up"
              type="btn"
              className="btn-light mt-1 w-100 signUpBtn"
              onClick={() => {
                history.push("/sign-up")
              }}
            />
            </form>
          </div>
        </div>

      </Modal>
    </>
  )
}
export default LoginModal;