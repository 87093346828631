/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { navbarMenuLink } from "../../constants/NavbarMenuLinks";
import { useGlobalClick } from "../../util/clickListener";
import { GetUserLoginData } from "../../util/reduxData";
import RedirectConfirmModal from "../redirectConfirmModal";
import "./navbarPopupMenu.scss";

const NavbarPopupMenu = ({ handleOutsideClick, handleLinkClick, toggleLegacyModal }) => {
  const location = useLocation();
  const ref = useRef(null);
  const [openRedirectModal, setOpenRedirectModal] = useState(false);
  const userInfo = GetUserLoginData();


  const updatedNavbarLinks = () => {
    return [{
      name: 'Account Settings',
      path: userInfo?.agencyInstrId > 0 ? "/settings/company-informations" : '/settings/my-information',
      legacy: false
    }].concat(navbarMenuLink)

  }

  function handleClick(event) {

    if (event.target?.name === "continue") {
    } else if (event.target?.id === 'Payment Information') {

    } else if (
      ref.current &&
      !ref.current.contains(event.target) &&
      !openRedirectModal
    ) {
      handleOutsideClick(event, ref);
    }
  }

  let handleClickNew = (link) => {
    if (link.name === "Payment Information") {
      setOpenRedirectModal(true);
    }
  };

  useGlobalClick(handleClick, ref)

  return (
    <>
      <RedirectConfirmModal
        isOpen={openRedirectModal}
        toggleModal={() => setOpenRedirectModal(!openRedirectModal)}
        redirectUrl={
          "https://ttaconnect.ttacorp.com/TrainingProfessionals/ChangePaymentInfo.aspx"
        }
      />
      <div className="menu-tooltip" ref={ref}>
        <div className="menu">
          {updatedNavbarLinks().map((item, count) => {
            return (
              <React.Fragment key={count}>
              {
                userInfo.agencyInstrId && item.name === "Profile Builder" ?
                "" :
                item.legacy ? (
                  <a
                    href={item.path}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: "none" }}
                  >
                    <div className="menu-item" onClick={handleLinkClick}>
                      {item.name}
                    </div>
                    {count + 1 < updatedNavbarLinks().length && (
                      <div className="menu-divider" />
                    )}
                  </a>
                ) : item.path ? (
                  <Link to={item.path} style={{ textDecoration: "none" }}>
                    <div className="menu-item" onClick={handleLinkClick}>
                      {item.name}
                    </div>
                    {count + 1 < updatedNavbarLinks().length && (
                      <div className="menu-divider" />
                    )}
                  </Link>
                ) :
                  <>
                    <div className="menu-item" onClick={() => toggleLegacyModal()}>
                      {item.name}
                    </div>
                    {count + 1 < updatedNavbarLinks().length && (
                      <div className="menu-divider" />
                    )}
                  </>
              }
              </React.Fragment>
            );
          })}
        </div>
      </div>
      <div className="mobile-menu-tooltip">
        <div className="mobile-menu">
          {updatedNavbarLinks().map((item, idx) => {
            return (
              <React.Fragment key={idx}>
              {
                userInfo.agencyInstrId && item.name === "Profile Builder" ?
                "" :
                item.legacy ? (
                  <>
                    <a
                      onClick={() => handleClickNew(item)}
                      style={{ textDecoration: "none" }}
                    >
                      <div
                        className={
                          location.pathname === item.path
                            ? "mobile-menu-item active"
                            : "mobile-menu-item"
                        }
                        id={item.name}
                        onClick={() => handleClickNew(item)}
                      >
                        {item.name}
                      </div>
                    </a>
                    <div className="w-100 border-top" />
                  </>
                ) : item.path ? (
                  <Link to={item.path} style={{ textDecoration: "none" }}>
                    <div
                      className={
                        location.pathname === item.path
                          ? "mobile-menu-item active"
                          : "mobile-menu-item"
                      }
                      onClick={handleLinkClick}
                    >
                      {item.name}
                    </div>
                    <div className="w-100 border-top" />
                  </Link>
                ) :
                  <>
                    <div className="mobile-menu-item" onClick={() => toggleLegacyModal()} id={item.name}>
                      {item.name}
                    </div>
                    <div className="w-100 border-top" />
                  </>
              }
              </React.Fragment>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default NavbarPopupMenu;
