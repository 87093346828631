/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import classnames from "classnames";
import "./index.css";

const Button = ({ className, ref, onClick, label, disabled, style, type, id, name, title, tabIndex }) => {
  const btnRef = useRef();

  const [onClickClassName, setOnClickClassName] = useState("");

  const addClickClassName = (e) => {
    if (className.includes("btn-light")) {
      setOnClickClassName("btn-light-clicked")
    }
    if (className.includes("btn-blue")) {
      setOnClickClassName("btn-blue-clicked")
    }
    if (onClick) {
      onClick(e)
    }
  }

  useEffect(() => {
    if (tabIndex) {
      btnRef.current.tabIndex = tabIndex
    }
  }, [btnRef?.current?.tabIndex])

  return (
    <button
      ref={ref || btnRef}
      className={classnames("btn main_btn", className, onClickClassName)}
      style={{
        ...style,
      }}
      disabled={disabled}
      onClick={
        (e) => {
          addClickClassName(e)
        }
      }
      type={(type && type === "btn") ? "button" : type ? type : "button"}
      id={id ? id : ""}
      name={name}
      title={title}
      tabIndex={tabIndex}
    >
      {label}
    </button>
  );
};

export default Button;
