export const SET_COMPLETED = "SET_COMPLETED";
export const DELETE_COMPLETED_LINKS = "DELETE_COMPLETED_LINKS";

export const initialState = []
const completedStepsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_COMPLETED:
      return [...payload] ;
    case DELETE_COMPLETED_LINKS:
      return [];
    default:
      return state;
  }
};

export const addCompletedSteps = (payload) => {
  return (dispatch) => {
    dispatch({
      type: SET_COMPLETED,
      payload,
    });
  };
};

export const removecompletedSteps = () => {
  return (dispatch) => {
    dispatch({
      type: DELETE_COMPLETED_LINKS,
      payload:[],
    });
  };
};


export default completedStepsReducer;
