export const SET_CANCEL_TOKEN = "SET_CANCEL_TOKEN";
export const DELETE_CANCEL_TOKEN = "DELETE_CANCEL_TOKEN";

export const initialState = {
  tokenList:[]
}
const filesTokenReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_CANCEL_TOKEN:
      return {...payload}
    case DELETE_CANCEL_TOKEN:
      return {}
    default:
      return state;
  }
};

export const addFilesAPIToken = (payload) => {
  return (dispatch) => {
    dispatch({
      type: SET_CANCEL_TOKEN,
      payload,
    });
  };
};

export const removeFilesToken = () => {
  return (dispatch) => {
    dispatch({
      type: DELETE_CANCEL_TOKEN,
      payload:{},
    });
  };
};


export default filesTokenReducer;
