/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Label from "../../../../../components/label/Label";
import "./index.scss";

const RolesAndSkills = ({ profileRoles, industry, fromTalent, client }) => {

  const [skillsToShow, setSkillsToShow] = useState({})

  useEffect(() => {
    if (profileRoles) {
      setSkillsCountToShow()
    }
  }, [profileRoles])

  const setSkillsCountToShow = () => {
    // Sets the count of the skills belonging to a role
    const tempDict = {}
    profileRoles.map((data) => {
      tempDict[data.myRole.role] = data.mySkills?.length > 12 ? 12 : data?.mySkills?.length
    })
    setSkillsToShow(tempDict)
  }

  const toggleShowSkills = (roleName, allSkills) => {
    // Displays all skills and sliced skill base on the below condtion
    if (allSkills?.length > skillsToShow[roleName]) {
      setSkillsToShow({
        ...skillsToShow,
        [roleName]: allSkills?.length
      })
    } else {
      setSkillsToShow({
        ...skillsToShow,
        [roleName]: 12
      })
    }
  }

  return (
    <div className={(fromTalent && !client) ? "freelancerModalComponentWrap ml-0" : "freelancerModalComponentWrap"}>
      {profileRoles?.length > 0 &&
        <>
          <Label label="Roles" className="font18Black boldFont mt-3 mb-0"></Label>
          <div className="d-flex flex-wrap">
            {profileRoles?.map((data, index) => {
              return (
                <div key={index} className=" rolesandratesInactiveChipFreelancer  mr-2 mb-2">
                  {data.myRole.role}
                </div>
              );

            })}
          </div>
          <Label label="Skills" className="font18Black boldFont mt-3 mb-0" />
          <div className=" flex-wrap">
            {profileRoles?.map((data, index) => {
              return (
                <React.Fragment key={index}>
                  <div> {data.myRole.role}</div>
                  <div className="d-flex mt-2 flex-wrap">
                    {data.mySkills?.slice(0, skillsToShow[data.myRole.role]).map((obj, idx) => {
                      return (
                        <div key={idx} className="rolesandratesInactiveChipFreelancer mr-2 mb-2">
                          {obj.skill}
                        </div>
                      );
                    })}
                  </div>
                  {
                    data.mySkills.length > 12 &&
                    <div className="font14Blue showMoreText">
                      <span onClick={() => { toggleShowSkills(data.myRole.role, data.mySkills) }}>
                        {(
                          data.mySkills.length > skillsToShow[data.myRole.role]
                        ) ? "More" : "Less"}
                      </span>
                    </div>
                  }
                </React.Fragment>
              );
            })}
          </div>
        </>
      }
      {
        industry?.length > 0 &&
        <>
          <Label
            label="Industry Experience"
            className="font18Black boldFont mt-1 mb-0"
          />
          <div className="d-flex mt-2 flex-wrap">
            {industry?.map((data, index) => {
              return (
                <div key={index} className=" rolesandratesInactiveChipFreelancer  mr-2 mb-2">
                  {data}
                </div>
              );
            })}
          </div>
        </>
      }

    </div>
  );
};

export default RolesAndSkills;
