import React from "react";
import Label from "../../../../../components/label/Label";
import { convertDate } from "../../../../../util/dateTime";
import "./index.scss";

const JobDescription = ({ proposalData, tab }) => {
  return (
    <>
    {
      tab === "Client" ?
      <>
        <div className=" wrapperJobFreelancer">
          <div className="font18Black boldFont">Talent Details</div>
        </div>
      </> :
      <div className=" wrapperJobFreelancer">
        <Label
          label={proposalData?.title || ""}
          className="font18Black boldFont mb-0"
        />
        <div className="font12Grey">
          Job# {proposalData?.projectID} | Created by {proposalData?.createdBy} |
          Posted {convertDate(proposalData?.submittalDate, true)}
        </div>
      </div>
    }
    </>
  );
};

export default JobDescription;
