export const getAddressString = (data) => {
    let addString = ""
    if(data?.locationName) {
        addString = data.locationName+ " - "
    }
    const tempArray = []
    if(data?.city) {
        tempArray.push(data.city)
    }
    if(data?.state) {
        tempArray.push(data.state)
    }
    if(data?.country) {
        tempArray.push(data.country)
    }
    return addString + tempArray.join(', '); 
}