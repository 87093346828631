/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { CloseCircular } from "../../assets/images";
import { GetUserLoginData } from '../../util/reduxData';
import "./BetaRibbon.css";

const BetaRibbon = () => {
  const userInfo = GetUserLoginData();
  const [dismiss, setDismiss] = useState(true)

  useEffect(() => {
    if (userInfo.agencyInstrId > 0) {
      setDismiss(false)
    }
  }, [userInfo.instrId])

  return (
    <React.Fragment>
      {!dismiss && (
        <div className={"betaInfo"}>
          <span className={"betaInfoText"}>
            Welcome to the beta release of TTA Connect! We're excited you are here to help test.
          </span>
          <img 
            className="modal_close_button" 
            src={CloseCircular} 
            alt="Dismiss" 
            onClick={() => {
              setDismiss(true)
            }} 
          />
        </div>
      )
      }
    </React.Fragment>
  );
};
export default BetaRibbon;
