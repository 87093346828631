export const path = {
  signWorkOrder: "/sign-work",
  submitProposal: "/job/submit-proposal/",
  inprogress: "/jobcenter/4",
  completed: "/jobcenter/5",
  helpCenter:"https://helpcenter.ttaconnect.com/new-features-tta-connect/",
  editProposal: "/job/edit-proposal/",
  profile:"/profile/my-information",
  acceptWork:"/accept-work",
  viewWorkDoc:"/view-work-document",
  inprogressJobDetails:"/jobcenter/inprogress/job-details/",
  myProfile:"/profile"
};
