import React from "react";
import classnames from "classnames";
import Select, { components } from "react-select";
import { iIcon } from "../../assets/images";
import { Scrollbars } from "react-custom-scrollbars";
import Tooltip from "../Tooltip";
import "./index.scss";

const TextInputDropdown = ({
  label,
  menuPlacementTop,
  innerRef,
  containerClass,
  optionnew,
  firstval,
  error,
  details,
  name,
  required,
  labelFent,
  labelBlue,
  value,
  id,
  onChange,
  containerStyle,
  selectedValue,
  disabled,
  companyTypeInfo,
  maxMenuHeight,
  isObject,
  getRoleName,
  linkObject,
  solidBorder,
  ttaAccessDetail,
  overFlowLabel,
  filter,
  addNewContact,
  groupedDropdown,
  onMenuOpen,
  onMenuClose,
  tabIndex,
  isSearch,
  classNamePrefix,
}) => {
  const colourStyles = {

    control: () => ({
      height: "40px",
      paddingLeft: "3px",
      paddingRight: "3px",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "14px",
      lineHeight: "21px",
      boxSizing: "border-box",
      boxShadow: solidBorder ? "" : "inset 0 0 3px 2px rgb(0 0 0 / 10%)",
      borderRadius: filter ? "0px" : "2px",
      outline: "none",
      display: "flex",
      backgroundColor: filter ? "#F3F6FB" : "white",
      border: solidBorder ? "1px solid #6B5F5B " : "none"
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? "#3cb4e5" : null,
        color: isFocused
          ? "#ffffff"
          : data.value === "Add a New Contact"
            ? "#008FC9"
            : "black",
        fontWeight: data.value === "Add a New Contact" ? "bold" : "normal",
        fontSize: data.value === "Add a New Contact" ? "16px" : "14px",
        paddingLeft: groupedDropdown ? "30px" : "10px",
        cursor: data.value === "Add a New Contact" ? "pointer" : "default",
        borderTop: data.value === "Add a New Contact" ? "1px solid #d6d7d9" : "none",
      };
    },
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: 'hsl(0, 0%, 60%)',
      }
    }
  };
  let options = []
  if (groupedDropdown) {
    options = optionnew
  } else {
    options = optionnew?.map((optionnew) => ({
      value: linkObject ? optionnew?.id : optionnew,
      label: isObject ? getRoleName(optionnew) : optionnew,
    }));
  }

  const renderScrollbar = props => {
    return (
      <div >
        <Scrollbars
          autoHeightMax={maxMenuHeight || 200}
          autoHeightMin={30}
          autoHeight
          renderTrackHorizontal={props => <div {...props} className="track-horizontal" style={{ display: "none", height: 100 }} />}
        >{props.children}</Scrollbars>
      </div>
    );
  };

  const NoOptionsMessage = props => {
    return (
      <components.NoOptionsMessage {...props}>
        <div className="blueBoldText" onClick={() => addNewContact()}>
          <span>Add a New Contact</span>
        </div>
      </components.NoOptionsMessage>
    );
  };

  const formatGroupLabel = data => (
    <div className="option-group">
      <span className="group-option-label">{data.label}</span>
    </div>
  );

  const onMenuOpenFunc = () => {
    if (onMenuOpen) {
      onMenuOpen()
    }
    setTimeout(() => {
      const selectedEl = document.getElementsByClassName("dropClass__option--is-selected")[0];
      if (selectedEl) {
        selectedEl.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
      }
    }, 10);
  }
  return (
    <>
      <div className={classnames("dropWrapper", containerClass)} style={containerStyle}>
        {overFlowLabel && <p className={"formLabel mb-0"}>{overFlowLabel}</p>}
        <div>
          {label && <p className="formLabel mb-0">{label}</p>}
          {labelFent && <p className="formLabelDropdown mb-0">{labelFent}</p>}
          {labelBlue && <p className="formLabelDropdownBlue mb-0">{labelBlue}</p>}
          <div className="d-flex align-items-center w-100">
            <div className="position-relative w-100" tabIndex={tabIndex}>
              <Select
                key={`selectKey${selectedValue}`}
                name={name}
                ref={innerRef}
                required={required}
                id={id}
                components={addNewContact ? {
                  IndicatorSeparator: () => null,
                  MenuList: renderScrollbar,
                  NoOptionsMessage
                } : {
                  IndicatorSeparator: () => null,
                  MenuList: renderScrollbar,
                }}
                isSearchable={isSearch !== undefined ? isSearch : true}
                placeholder={firstval ? firstval : "Select"}
                value={value || options?.find(o => o.value === selectedValue)}
                defaultValue={selectedValue}
                onChange={onChange}
                options={options}
                styles={{ colourStyles, menu: provided => ({ ...provided, zIndex: 9999 })}}
                maxMenuHeight={maxMenuHeight || 200}
                isDisabled={disabled}
                className={"dropClass"}
                classNamePrefix={classnames("dropClass", classNamePrefix)}
                formatGroupLabel={groupedDropdown ? formatGroupLabel : null}
                onMenuOpen={onMenuOpenFunc}
                onMenuClose={onMenuClose}
                inputProps={{ autoComplete: 'nope' }}
                menuPlacement={menuPlacementTop ? "top" : "auto"}
              // menuIsOpen={true}
              />

            </div>

            {details === true && !companyTypeInfo && (
              <React.Fragment>
                <div className="ml-2 desktopTooltip">
                  <div className="ml-2">
                    <Tooltip
                      content="TTA connect charges a service fee that is a percentage of your earnings on TTA connect. For more information visit our FAQs."
                      direction="right"
                    >
                      <img src={iIcon} alt="" />
                    </Tooltip>
                  </div>
                </div>

                <div className="ml-2 phoneTooltip">
                  <div className="ml-2">
                    <Tooltip
                      content="TTA connect charges a service fee that is a percentage of your earnings on TTA connect. For more information visit our FAQs."
                      direction="bottom"
                    >
                      <img src={iIcon} alt="" />
                    </Tooltip>
                  </div>
                </div>
              </React.Fragment>
            )}
            {details === true && companyTypeInfo === true && (
              <React.Fragment>
                <div className="ml-2 desktopTooltip">
                  <div className="ml-2">
                    <Tooltip
                      content="A type of business entity defined by the legal system. Company types could include corporations, cooperatives, partnerships, sole traders, limited liability companies and other specifically labeled types of entities."
                      direction="right"
                    >
                      <img src={iIcon} alt="" />
                    </Tooltip>
                  </div>
                </div>

                <div className="ml-2 phoneTooltip">
                  <div className="ml-2">
                    <Tooltip
                      content="A type of business entity defined by the legal system. Company types could include corporations, cooperatives, partnerships, sole traders, limited liability companies and other specifically labeled types of entities."
                      direction="bottom"
                    >
                      <img src={iIcon} alt="" />
                    </Tooltip>
                  </div>
                </div>
              </React.Fragment>
            )}
            {ttaAccessDetail === true && (
              <React.Fragment>
                <div className="ml-2 desktopTooltip">
                  <div className="ml-2">
                    <Tooltip
                      content={
                        <div>
                          Select the level of access you would like the user to have on TTA Connect.
                          <div><span className="boldFont">Administrator:</span> Full access to manage all jobs</div>
                          <div><span className="boldFont">Standard User:</span> Access to only assigned jobs</div>
                        </div>
                      }
                      direction="left"
                    >
                      <img src={iIcon} alt="" />
                    </Tooltip>
                  </div>
                </div>

                <div className="ml-2 phoneTooltip">
                  <div className="ml-2">
                    <Tooltip
                      content={
                        <div>
                          Select the level of access you would like the user to have on TTA Connect.
                          <div><span className="boldFont">Administrator:</span> Full access to manage all jobs</div>
                          <div><span className="boldFont">Standard User:</span> Access to only assigned jobs</div>
                        </div>
                      }
                      direction="left"
                    >
                      <img src={iIcon} alt="" />
                    </Tooltip>
                  </div>
                </div>
              </React.Fragment>
            )}
          </div>
        </div>

        {error && (
          <div className="d-flex align-items-center my-2">
            <i className="fa fa-exclamation-circle errorIcon" />
            <p className="errorText mb-0 ml-1 ">{error}</p>
          </div>
        )}
      </div>
    </>
  );
};

export default TextInputDropdown;