import Axios from "../util/axiosInstance";

export const PostJobDetailsAPI = (payload) => {
    return Axios.post("/api/Project/AddProjectDetail", payload)
};
export const GetJobDetailsAPI = (jobId) => {
  return Axios.get(`/api/Project/GetProjectDetails?Projectid=${jobId}`)
};
export const UpdateJobDetailsAPI = (payload) => {
  return Axios.put("/api/Project/UpdateProjectDetail", payload)
};
export const GetJobStatusAPI = (jobId) => {
  return Axios.get(`/api/Project/GetProjectStage?Projectid=${jobId}`)
}

export const GetProjectDetails = (jobId) => {
  return Axios.get(`/api/Project/Get?projectid=${jobId}` )
};
export const UpdateProjectLastVisitedPage = (params) => {
  return Axios.put("/api/Project/UpdateProjectLastVisitedPage",params)
}

export const UploadProjectDocumentAtCreation = (entityType, entityId, payload, progressConfig) => {
  Axios.put(
    `/api/Blob/UploadDocument?entityType=${entityType}&entityId=${entityId}&isJobPostDoc=true`,
    payload,
    progressConfig
  )
}