import React from "react";
import { useHistory } from "react-router-dom";
import { convertDate } from "../../../../../util/dateTime";
import { trimString } from "../../../../../util/trimString";
import "./index.scss";

const UserJobs = ({
    data,
    viewAllTalentJobs,
    fromTalent,
    client
}) => {

    const history = useHistory()

    return (
      
        <div className={(fromTalent && !client) ? "freelancerModalComponentWrap ml-0" : "freelancerModalComponentWrap"}>
            <div className="userJobHeader pt-3">
                <span className="font18Black boldFont mr-3">Jobs with {data?.companyName}: {data?.noOfJobs}</span>
                <div className="d-flex">
                    <span className="smallFont mr-4 text-nowrap">Last Hired: {convertDate(data?.lastHiredDate)}</span>
                    <span className="font14Blue  mr-3 pointer text-nowrap desktopView" onClick={() => viewAllTalentJobs(data)}>View All</span>
                </div>
            </div>
            <ul className="font14Blue userJobNameWrap">
                {data?.talentProjects?.slice(0, 5).map((data, idx) => {
                    return (
                        <li
                            key={idx}
                            className="pointer"
                            onClick={() => {
                                history.push("/client-job/job-entity/1/" + data?.projectID)
                            }}
                        >Job: {data?.projectID} - {trimString(data?.title, 40)}</li>
                    )
                })
                }
            </ul>
            <div className="text-align-center font14Blue mb-2 mt-2 mr-3 mobileView" onClick={() => viewAllTalentJobs(data)}>
            View All
            </div>
        </div>
    )
}

export default UserJobs