/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { GetUserLoginData } from "../../../util/reduxData";
import { setLoginData } from "../../../ducks/Login";
import { ChangePrimaryPasswordAPI } from "../../../services/changeEmailAndPassword";
import { passwordValidations } from "../../../constants/Validations";
import TextInput from "../../../components/textInput";
import Modal from "../../../components/modal";
import Button from "../../../components/button";
import "../ChangePrimaryPassword/index.scss";
import "./index.scss";

const ForceChangePassword = () => {

    let dispatch = useDispatch();
    let history = useHistory();
    const loginData = GetUserLoginData()
    const unblockHandle = useRef();

    const [err, setErr] = useState();
    const [confirmModal, setConfirmModal] = useState(false);
    const [loader, setLoader] = useState(false);
    const [data, setData] = useState({})

    useEffect(() => {
        unblockHandle.current = history.block((targetLocation) => {
            if (targetLocation.pathname === "/logout") {
                return true
            }
            else if (loginData.forceChangePassword === 0) {
                return true
            } else {
                return false
            }
        });
        return () => {
            unblockHandle.current.current && unblockHandle.current.current();
        };
    }, []);

    const onSubmit = (data) => {
        setErr("");
        ChangePassword(data)
    };

    const { register, handleSubmit, errors, getValues, watch } = useForm({
        reValidateMode: "onSubmit",
    });

    const ChangePassword = () => {
        setLoader(true)
        ChangePrimaryPasswordAPI({ oldPassword: data?.oldPassword, newPassword: data?.password })
            .then((response) => {
                if (response.status === 200) {
                    setConfirmModal(true)
                }
            })
            .catch((err) => {
                console.log(err?.response?.data.validationFailures);
                setErr(err?.response?.data.error);
            }).finally(e => setLoader(false))
    };

    const handleChange = (e) => {
        let tempData = { ...data }
        tempData[e.target.name] = e.target.value
        setData(tempData)
    }
    const onPasswordChanged = () => {
        dispatch(setLoginData({ ...loginData, fromForce: true, forceChangePassword: 0 }));
        window.location.reload()
    }

    return (
        <>
            <Modal
                isOpen={confirmModal}
                size="extrasmall"
                hideCloseButton={false}
                // toggleModal={toggleModal}
                disableBackdropToggle={true}
            >
                <div className="d-flex mt-4 justify-content-center align-items-center flex-column">
                    <p className="font18Black boldFont">
                        Password Changed Successfully
                    </p>
                    <Button
                        label="Ok"
                        onClick={() => {
                            onPasswordChanged()
                        }}
                        type="btn"
                        className="btn-blue "
                    />
                </div>
            </Modal>
            <div className="changePrimaryPasswordTitle"></div>
            <div className="mobileView mb-2">
                <hr className="seperator" />
            </div>
            <div className="changePrimaryPasswordWrapper">
                <div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="changePrimaryPasswordBoxContent mx-auto">
                            <div className="font14Grey mb-3">We have updated our password policy.  For security reasons we require that you change your password before proceeding to TTA Connect.</div>
                            <div className='font14Black mb-1' >Current Password</div>
                            <TextInput
                                name="oldPassword"
                                innerRef={register({
                                    required: "Required",
                                })}
                                error={
                                    errors.oldPassword?.type === "required"
                                        ? "Password is Required"
                                        : null
                                }
                                containerClass=""
                                placeholder="Current Password"
                                type="password"
                                signuperrornone={
                                    errors.oldPassword?.type === "required" ? false : true
                                }
                                onChange={handleChange}
                            />

                            <div className='mt-2 font14Black mb-1' >New Password</div>
                            <TextInput
                                name="password"
                                innerRef={register({
                                    required: "Required",
                                    minLength: 8,
                                    maxLength: 32,
                                    pattern: {
                                        value: passwordValidations.pattern,
                                        message: "Invalid password format",
                                    },
                                })}
                                error={
                                    errors.password?.type === "required"
                                        ? "Password is Required"
                                        : errors.password
                                            ? true
                                            : null
                                }

                                placeholder="New Password"
                                type="password"
                                signuperrornone={
                                    errors.password?.type === "required" ? false : true
                                }
                                onChange={handleChange}
                            />
                            <div className={
                                errors.password
                                    ? "changePrimaryPasswordErrorColor"
                                    : "changePrimaryPasswordError"
                            }>
                                {passwordValidations.errors.message1}
                            </div>
                            <div className='mt-2 font14Black mb-1' >Confirm Password</div>
                            <TextInput
                                name="confirmpassword"
                                innerRef={register({
                                    validate: () =>
                                        getValues("confirmpassword") === getValues("password"),
                                })}
                                error={
                                    errors.confirmpassword &&
                                    "Confirmed password does not match the password."
                                }
                                containerClass="mb-3"
                                type="password"
                                placeholder="Confirm New Password"
                                onChange={handleChange}
                            />
                        </div>
                        {watch('password') && watch('oldPassword') === watch('password') &&
                            <p className="text-center mt-3">
                                <i className="fa fa-exclamation-circle errorIcon mr-1" />
                                <span className="rolesStartError">New password should not be same as Current Password</span>
                            </p>

                        }
                        {err && (
                            <p className="text-center mt-3">
                                <i className="fa fa-exclamation-circle errorIcon mr-1" />
                                <span className="rolesStartError">{err}</span>
                            </p>
                        )}
                        <div className="desktopView">
                            <hr className="seperator" />
                        </div>
                        <div className="changePrimaryEmailButtonWrapper changePrimaryEmailNewBox">
                            <div className="t my-4 mb-0 buttonWrapperPrimaryPassword">
                                <Button
                                    label="Logout"
                                    className="btn-light main_btn mr-2 changePrimaryPasswordBack"
                                    onClick={() => history.push("/logout")}
                                />

                                <Button
                                    label="Change"
                                    type="submit"
                                    className="btn-blue main_btn ml-2 "
                                    disabled={loader}
                                />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

export default ForceChangePassword;
