/* eslint-disable array-callback-return */
import { getAddressString } from "./getAddressString";
import { sumOfValues } from "./sumOfValues";

const JobStatus = (data) => {
    if (data) {
        const today = new Date();
        const jobDate = new Date(data);
        if (today >= jobDate) {
            return "Started";
        } else {
            return "Upcoming";
        }
    } else {
        return "Started";
    }
};

export const sortByKey = (setSortAscending, setSortingColName, setDataList, key, recordList, sortingColName, sortAscending, index = -1) => {
    // sort by the key passed and sets the state
    let orderedRecord = [];
    const dataList = [];
    const nullRecords = [];
    let roleString = false;
    if (key === "proposals") {
        const sortedList = recordList.map((data) => ({
            ...data,
            proposals: sumOfValues(data?.openRoles, "proposalCount"),
        }));

        sortedList.sort((a, b) => {
            const sumA = a.proposals;
            const sumB = b.proposals;
            return sortAscending ? sumA - sumB : sumB - sumA;
        });

        setSortAscending(!sortAscending);
        setSortingColName(key);
        setDataList([...sortedList], index);
        return;
    }
    if (key.toLowerCase().includes("roles") || key === "expenseDocumentItems") {
        recordList.map((data) => {
            if (data[key]) {
                if (typeof data[key] === "object") {
                    if (data[key].length > 0) {
                        const tempList = data[key]
                        tempList[0] = {
                            ...data[key][0],
                            roleName: data[key][0]["roleName"].length > 0 ? data[key][0]["roleName"] : "Other",
                        }
                        dataList.push({ ...data, [key]: tempList })
                    } else {
                        nullRecords.push(data)
                    }
                } else {
                    roleString = true;
                    if (data[key].length > 0) {
                        dataList.push(data)
                    } else {
                        nullRecords.push(data)
                    }

                }
            } else {
                nullRecords.push(data)
            }
        })
    } else {
        recordList.map((data) => {
            if (key === "location" && index <= -1) {
                dataList.push({
                    ...data,
                    location: getAddressString(data) || "Virtual"
                })
            } else {
                if (data[key] || typeof data[key] === 'number' || typeof data[key] === 'boolean') {
                    dataList.push(data)
                } else {
                    nullRecords.push(data)
                }
            }

        })
    }
    if (key === "jobStatus") {
        if (sortAscending) {
            if (recordList.length > 0) {
                orderedRecord = [...recordList].sort((a, b) => JobStatus(a.startDate).localeCompare(JobStatus(b.startDate)))
            }
            setSortAscending(false)
        } else {
            if (recordList.length > 0) {
                orderedRecord = [...recordList].sort((a, b) => JobStatus(b.startDate).localeCompare(JobStatus(a.startDate)))
            }
            setSortAscending(true)
        }
        setSortAscending(!sortAscending);
        setSortingColName(key);
        setDataList([...orderedRecord], index);
        return;
    }
    if (sortingColName === key) {
        if (sortAscending) {
            if (dataList.length > 0) {
                if (key.toLowerCase().includes("date")) {
                    // sorting when the value is date
                    orderedRecord = [...dataList].sort((a, b) => new Date(a[key]).getTime() - new Date(b[key]).getTime());
                } else if (key.toLowerCase().includes("roles") && !roleString) {
                    orderedRecord = [...dataList].sort((a, b) => a[key][0]["roleName"].toLowerCase().localeCompare(b[key][0]["roleName"].toLowerCase()))
                } else if (key === "expenseDocumentItems") {
                    orderedRecord = [...dataList].sort((a, b) => a[key][0]["fileName"].toLowerCase().localeCompare(b[key][0]["fileName"].toLowerCase()))
                } else {
                    if (typeof dataList[0][key] === "number") {
                        // sorting when the value is number
                        orderedRecord = [...dataList].sort((a, b) => a[key] - b[key])
                    } else if (typeof dataList[0][key] === "boolean") {
                        // sorting when the value is bool
                        const trueRecord = []
                        const falseRecord = []
                        dataList.map(res => {
                            if (res[key]) {
                                trueRecord.push(res)
                            } else {
                                falseRecord.push(res)
                            }
                        })
                        orderedRecord = [...trueRecord, ...falseRecord]
                    } else {
                        // sorting when the value is string
                        orderedRecord = [...dataList].sort((a, b) => a[key].toLowerCase().localeCompare(b[key].toLowerCase()))
                    }
                }
            }
            setSortAscending(false)

        } else {
            if (dataList.length > 0) {
                if (key.toLowerCase().includes("date")) {
                    orderedRecord = [...dataList].sort((a, b) => new Date(b[key]).getTime() - new Date(a[key]).getTime());
                } else if (key.toLowerCase().includes("roles") && !roleString) {
                    orderedRecord = [...dataList].sort((a, b) => b[key][0]["roleName"].toLowerCase().localeCompare(a[key][0]["roleName"].toLowerCase()))
                } else if (key === "expenseDocumentItems") {
                    orderedRecord = [...dataList].sort((a, b) => b[key][0]["fileName"].toLowerCase().localeCompare(a[key][0]["fileName"].toLowerCase()))
                } else {
                    if (typeof dataList[0][key] === "number") {
                        orderedRecord = [...dataList].sort((a, b) => b[key] - a[key])
                    } else if (typeof dataList[0][key] === "boolean") {
                        // sorting when the value is boolean
                        const trueRecord = []
                        const falseRecord = []
                        dataList.map(res => {
                            if (res[key]) {
                                trueRecord.push(res)
                            } else {
                                falseRecord.push(res)
                            }
                        })
                        orderedRecord = [...falseRecord, ...trueRecord]
                    } else {
                        orderedRecord = [...dataList].sort((a, b) => b[key].toLowerCase().localeCompare(a[key].toLowerCase()))
                    }
                }
            }
            setSortAscending(true)
        }
    } else {
        if (dataList.length > 0) {
            if (key.toLowerCase().includes("date")) {
                orderedRecord = [...dataList].sort((a, b) => new Date(a[key]).getTime() - new Date(b[key]).getTime());
            } else if (typeof dataList[0][key] === "number") {
                orderedRecord = [...dataList].sort((a, b) => b[key] - a[key])
            } else if (key.toLowerCase().includes("roles") && !roleString) {
                orderedRecord = [...dataList].sort((a, b) => a[key][0]["roleName"].toLowerCase().localeCompare(b[key][0]["roleName"].toLowerCase()))
            } else if (key === "expenseDocumentItems") {
                orderedRecord = [...dataList].sort((a, b) => a[key][0]["fileName"].toLowerCase().localeCompare(b[key][0]["fileName"].toLowerCase()))
            } else if (typeof dataList[0][key] === "boolean") {
                // sorting when the value is boolean
                const trueRecord = []
                const falseRecord = []
                dataList.map(res => {
                    if (res[key]) {
                        trueRecord.push(res)
                    } else {
                        falseRecord.push(res)
                    }
                })
                orderedRecord = [...falseRecord, ...trueRecord]
            } else {
                orderedRecord = [...dataList].sort((a, b) => a[key].toLowerCase().localeCompare(b[key].toLowerCase()))
            }
        }
        setSortAscending(false)
    }
    if (index > -1) {
        setSortingColName(key, index)
    } else {
        setSortingColName(key)
    }
    setDataList([...orderedRecord, ...nullRecords], index)
}