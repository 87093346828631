import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { SendFeedbackApi } from "../../../services/feedback";
import { testimonialConfirm } from "../../../assets/images";
import Modal from "../../../components/modal";
import Label from "../../../components/label";
import TextInputDropdown from "../../../components/textInputDropdownNew";
import FormTextarea from "../../../components/formTextarea/FormTextarea";
import Button from "../../../components/button";
import "./index.scss";

const FeedBack = ({ openFeedBack = false, handleClose }) => {
  const [showFeedbackForm, setShowFeedbackForm] = useState(openFeedBack);
  const [feedbackData, setFeedbackData] = useState({});
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  useEffect(() => {
    setShowFeedbackForm(openFeedBack)
  }, [openFeedBack])

  const {
    register,
    handleSubmit,
    errors,
    control,
    clearErrors,
  } = useForm({
    reValidateMode: "onBlur",
  });

  const onSubmit = (data) => {

    if (!feedbackData.feedback || !feedbackData.feedbackType) {
      setError("Please fill all the fields");
    } else {
      SendFeedbackApi(feedbackData).then((res) => {
        setError(false);
        setSuccess(true);
        setFeedbackData({});
      });
    }
  };
  const cancelFeedback = () => {
    setShowFeedbackForm(false);
    setError(null);
    setSuccess(false);
    setFeedbackData({});
    if (handleClose) {
      handleClose();
    }

  };
  return (
    <>
      <div
        className="feedbackWrapper"
        onClick={() => setShowFeedbackForm(!showFeedbackForm)}
      >
        <span className="feedbackText">Feedback</span>
      </div>
      <Modal
        isOpen={showFeedbackForm}
        containerStyle={{
          width: "450px",
          maxWidth: "95%",
          minHeight: "252px",
        }}
        size="extrasmall"
        hideCloseButton={true}
        toggleModal={cancelFeedback}
        title={
          success ? "Feedback Received" : "We Would Like to Hear From You!"
        }
        disableBackdropToggle={true}
      >
        {success ? (
          <>
            <div className="requestTestimonailConfirmWrapperBoxInner">
              <div className="requestTestimonailConfirmTextWrapper">
                <img
                  src={testimonialConfirm}
                  alt=""
                  className="requestTestimonailImage"
                />
                <Label
                  label={<span>Thank you for your feedback. We’re on it!</span>}
                  className="font18Black boldFont mb-0"
                ></Label>
                <div className="requestTestimonailConfirmText">
                We appreciate your feedback and will be in touch if necessary.
                </div>
              </div>
            </div>
            <div className="w-100 border-top" />

            <div className="text-center mb-0 my-4">
              <Button
                label="Close"
                type="button"
                onClick={cancelFeedback}
                className="btn-blue mx-2 mt-md-0 mt-2"
              />
            </div>
          </>
        ) : (
          <>
            <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
              <div className="mt-3 ml-3 mr-3">
                <p className="feedbackInfoText">
                  Please provide feedback, suggestions, or report any technical
                  issues here.
                </p>
                <div className="mt-3">
                  <Controller
                    name="Category"
                    control={control}
                    rules={{ required: true }}
                    render={({ onChange, value, name, ref }) => (
                      <TextInputDropdown
                        id="Category"
                        name="Category"
                        containerClass="ml-auto"
                        optionnew={["Technical", "Suggestions", "Other"]}
                        firstval="Select"
                        error={
                          errors.Category?.type === "required"
                            ? "Category is Required"
                            : null
                        }
                        onChange={(e) => {
                          setFeedbackData({
                            ...feedbackData,
                            feedbackType: e.value,
                          });
                          clearErrors("Category");
                          onChange(e.value);
                        }
                        }
                        solidBorder={true}
                      />
                    )}
                  />
                </div>
                <div className="mt-3">
                  <FormTextarea
                    maxLength={500}
                    rows={5}
                    name="description"
                    containerStyle={{ marginBottom: 0 }}
                    onChange={(e) =>
                      setFeedbackData({
                        ...feedbackData,
                        feedback: e.target.value,
                      })
                    }
                    innerRef={register({
                      required: true,
                    })}
                    error={
                      errors.description &&
                      "A description is Required for the feedback you are providing"
                    }
                    placeholder="Write your message here"
                    showTextLength={true}
                    currentLenght={feedbackData?.feedback?.length || 0}
                  ></FormTextarea>
                </div>
              </div>

              <div className="w-100 border-top mt-2" />
              {error && (
                <p
                  className="mt-1 text-center text-danger"
                  style={{ fontSize: "13px" }}
                >
                  {error}
                </p>
              )}
              <div className="text-center mb-0 my-4">
                <Button
                  label="Cancel"
                  type="button"
                  onClick={cancelFeedback}
                  className="btn-light mx-2 mt-md-0 mt-1"
                />
                <Button
                  label={"Send"}
                  type="submit"
                  className="btn-blue mx-2 mt-md-0 mt-1"
                />
              </div>
            </form>
          </>
        )}
      </Modal>
    </>
  );
};

export default FeedBack;
