import Axios from "../util/axiosInstance";

export const GetAllSkills = (id, instrId) => {
  if (id && instrId) {
    return Axios.get(`/api/Skill/GetAll?roleId=${id}&instrId=${instrId}`)

  } else {
    return Axios.get(`/api/Skill/GetAll`)

  }
};

export const GetSuggestedSkills = (id) => {
  return Axios.get(`/api/Skill/GetSuggestedSkills?roleId=${id}`)
};

export const GetMySkills = (id, instrId) => {
  return Axios.get(`/api/Skill/GetMySkills?roleId=${id}&instrId=${instrId}`)
};

export const postSkills = (payload) => {
  return Axios.post(`/api/Skill/Post`, payload)
};

export const postAllSkillsInfo = (payload) => {
  return Axios.put(`/api/Role/Put`, payload)
};

export const getAllRoleInfo = (id, instrId) => {
  return Axios.get(`/api/Role/Get?roleId=${id}&instrId=${instrId}`)
};

export const deleteSkills = (payload) => {
  return Axios.delete(`/api/Skill/Delete`, payload)
};

export const instructorPost = (payload) => {
  return Axios.put('/api/Skill/Put', payload)
}

export const getVendorList = () => {
  return Axios.get('/api/Master/Vendors')
}


export const getCategoryList = () => {
  return Axios.get('/api/Master/SkillCategories')
}

export const postUnregisteredSkill = (payload) => {
  return Axios.post(`/api/Skill/AddUnapprovedSkill`, payload)
};

export const GetPopularSkills = (id) => {
  return Axios.get(`/api/Project/GetPopularSkills?Projectid=${id}`)
};