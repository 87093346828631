import { useSelector, useDispatch } from 'react-redux';
import { getCountryApi } from "../services/getCountry";
import { useEffect, useState } from 'react';

export const GetUserInfo = () => {
  const userInfo = useSelector(
    (state) => state.informationReducer.myInformation
  );
  return userInfo;
};

export const GetReduxCountries = () => {
  const dispatch = useDispatch();
  const countries = useSelector((state) => state.informationReducer.countries);
  const [localCountries, setLocalCountries] = useState(null);

  useEffect(() => {
    if (!countries || countries.length === 0) {
      getCountryApi().then((res) => {
        dispatch({ type: "GET_COUNTRIES", payload: res.data });
        setLocalCountries(res.data);
      }).catch((err) => {
        console.log(err?.response?.data);
      });
    } else {
      setLocalCountries(countries);
    }
  }, [countries, dispatch]);

  return countries && countries.length > 0 ? countries : localCountries;
};

export const GetReduxStates = () => {
  const states = useSelector((state) => state.informationReducer.states);
  return states;
};

export const GetReduxMyInfo = () => {
  const myInformation = useSelector(
    (state) => state.informationReducer.myInformation
  );
  return myInformation;
};
export const GetUserEmail = () => {
  const email = useSelector((state) => state.loginReducer.email);
  return email;
};
export const GetReduxCompletedSteps = () => {
  const completedSteps = useSelector((state) => state.completedStepsReducer);
  return completedSteps ? completedSteps : [];
};
export const GetLastVisitedPage = () => {
  const lastVisitedPage = useSelector((state) => state.lastVisitedPageReducer);
  return lastVisitedPage;
};
export const GetUserLoginData = () => {
  const userLoginData = useSelector((state) => state.loginReducer);
  return userLoginData
}
export const GetJobId = () => {
  const jobId = useSelector((state) => state.jobDetailReducer?.jobId)
  return jobId
}
export const GetTemplateId = () => {
  const templateID = useSelector((state) => state.jobDetailTemplateReducer?.templateID)
  return templateID
}
export const GetJobGroup = () => {
  const group = useSelector((state) => state.jobDetailReducer?.group)
  return group
}
export const GetJobCompletedSteps = () => {
  const completedJobSteps = useSelector((state) => state.completedJobStepsReducer);
  return completedJobSteps;
};

export const GetFileProgress = () => {
  const fileProgress = useSelector((state) => state.filesProgressReducer.fileList);
  return fileProgress
}
export const GetFileToken = () => {
  const fileTokens = useSelector((state) => state.filesTokenReducer.tokenList);
  return fileTokens
}
export const GetCancelFileIndex = () => {
  const cancelledFiles = useSelector((state) =>
    state.cancelFileIndexReducer.cancelledFiles
  );
  return cancelledFiles
}
export const UpdateSchedule = () => {
  const update = useSelector((state) => state.updateScheduleReducer);
  return update
}
export const GetCurrentAgencyMember = () => {
  const data = useSelector((state) => state.currentAgencyMemberReducer)
  return data
}

export const GetAllMembersList = () => {
  const members = useSelector((state) => state.allAgencyMemberReducer);
  return members
}

export const GetCurrentAgencyMemberDetail = () => {
  const member = useSelector(state => state.currentAgencyMemberDetailReducer)
  return member
}