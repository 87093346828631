import React, { useState, useEffect } from "react";
import { useGlobalClick } from "../../../../../util/clickListener";
import {
    CloseCircular,
    acceptTick,
    favourite,
    greyFilledEllipsis,
    print,
    rejectIcon,
    removeFavorite,
    removeFromInterviewNes 
} from "../../../../../assets/images";
import { useRef } from "react";
import { AddToFavourite, RemoveToFavourite } from "../../../../../services/favourite";
import { DownloadDocByEntity } from "../../../../../services/portfolio";
import { convertDate } from "../../../../../util/dateTime";
import RemoveFromFavourite from "../../../JobPost/ReviewSubmission/RemoveFromFavourite/RemoveFromFavourite";
import Button from "../../../../../components/button";
import "./index.scss"

const ProposalHeader = ({
    proposalData,
    instrID,
    handleAcceptClick,
    projectID,
    handleInterviewClick,
    rejectTalent,
    tab,
    cancelInterview,
    removeFromRejected,
    hideOptions,
    completed,
    isFavorite,
    refreshList
}) => {
    const [open, setOpen] = useState(false);
    const [favouriteTal, setFavourite] = useState(false);
    const [removeFromFavourite, setRemoveFromFavourite] = useState(false);
    const ref = useRef()

    useEffect(() => {
        setFavourite(isFavorite)
    }, [isFavorite])
    
    const handleClick = (event) => {
    setOpen(false)
    if (ref?.current && !ref?.current?.contains(event.target)) {
        setOpen(false)
    }
    };

    const removeInstrAsFavorite = () => {
        const payload = {
          instrID: instrID,
        };
        RemoveToFavourite(payload).then((res) => {
          setFavourite(false);
          setRemoveFromFavourite(false);
          refreshList();
        });
    }

    const downloadResume = (data) => {
        DownloadDocByEntity(data.resumeBlobId, "instructor", instrID)
          .then((response) => {
            const url = window.URL
              .createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${data.talentName}.pdf`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          })
      }
    
      const updateInstrAsFavorite = () => {
        const payload = {
          instrID: instrID,
        };
        AddToFavourite(payload).then((response) => {
          setFavourite(true)
          refreshList()
        });
      }
    
    useGlobalClick(handleClick, ref)

    return (
        <>
            <RemoveFromFavourite
                isOpen={removeFromFavourite}
                toggleModal={() => setRemoveFromFavourite(!removeFromFavourite)}
                instId={instrID}
                RemoveToFavouriteFuncAPi={(data) => removeInstrAsFavorite(data)}
            />
                <div className="proposalHeaderWrap">
                    <div className="d-flex align-items-center">
                        <span className="font18Black boldFont mr-5 noWrap">Proposal Details</span>
                        <span className="font12Grey noWrap">Updated: {convertDate(proposalData?.updatedDate)}</span>
                    </div>
                    {
                      !hideOptions &&
                      <div className="proposalDetailsButtonWrapper">

                        <div className="proposalDetailsButtonWrapperInner">
                          {
                            proposalData?.resumeBlobId &&
                            <Button
                              className={"btn-rqst small-btn mr-2 w-100"}
                              label={"Download Resume"}
                              onClick={() => downloadResume(proposalData)}
                            />
                          }

                          {
                            tab === "Submission" &&
                            <Button
                              className={"btn-rqst small-btn mr-2 w-100"}
                              label={"Interview"}
                              onClick={() =>
                                handleInterviewClick({
                                  projectId: projectID,
                                  instrID: instrID,
                                })
                              }
                            />
                          }
                          {
                            tab === "Interview" &&
                            <Button
                              className={"btn-rqst small-btn mr-2 w-100"}
                              label={"Accept"}
                              onClick={() =>
                                handleAcceptClick({
                                  projectId: projectID,
                                  instrID: instrID,
                                  talentName: proposalData?.talentName
                                })
                              }
                            />
                          }
                        </div>
                        {
                          !(completed && tab === "Rejected") &&
                          <div className="pointer relative ml-3" >
                            <img src={greyFilledEllipsis} alt="" onClick={() => setOpen(!open)} className="optionEllipsis"/>
                            <div className={open ? "freeLanceoptionCard" : "d-none"}>
                              {
                                !completed &&
                                <>
                              {tab === "Submission" &&
                                <div
                                  className="d-flex option"
                                  onClick={() =>
                                    handleInterviewClick({
                                      projectId: projectID,
                                      instrID: instrID,
                                    })
                                  }
                                >
                                  <img src={acceptTick} alt="" className="mr-1 ml-1" />
                                  <span>Interview</span>
                                </div>
                              }
                              {
                                (tab === "Interview" || tab === "Submission") &&
                                <div
                                  className="d-flex option"
                                  onClick={() =>
                                    handleAcceptClick({
                                      projectId: projectID,
                                      instrID: instrID,
                                      talentName: proposalData?.talentName
                                    })
                                  }
                                >
                                  <img src={acceptTick} alt="" className="mr-1 ml-1" />
                                  <span>Accept Proposal</span>
                                </div>
                              }
                              {
                                tab === "Rejected" &&
                                <div
                                  className="d-flex option"
                                  onClick={() =>
                                    removeFromRejected({
                                      projectId: projectID,
                                      instrID: instrID,
                                      talentName: proposalData?.talentName
                                    })
                                  }>
                                  <img src={CloseCircular} alt="" className="mr-2 ml-1" />
                                  <span> Remove from Not Selected</span>
                                </div>
                              }
                              {proposalData?.resumeBlobId &&
                                <div className="d-flex option" onClick={() => downloadResume(proposalData)}>
                                  <img src={print} alt="" className="mr-1 ml-1" />
                                  <span>Download Resume</span>
                                </div>
                              }
                              {
                                tab === "Interview" &&
                                <div
                                  className="d-flex option"
                                  onClick={() => {
                                    cancelInterview({
                                      projectId: projectID,
                                      instrID: instrID,
                                      talentName: proposalData?.talentName
                                    })
                                  }}
                                >
                                  <img src={removeFromInterviewNes} alt="" className="mr-1 ml-1" />
                                  <span> Cancel Interview</span>
                                </div>
                              }
                              {
                                (tab !== "Rejected" && tab !== "Hired" && tab !== "Client" && !completed) &&
                                <div
                                  className="d-flex option"
                                  onClick={() => {
                                    rejectTalent({
                                      projectId: projectID,
                                      instrID: instrID,
                                    })
                                  }}
                                >
                                  <img src={rejectIcon} alt="" className="mr-1 ml-1" />
                                  <span>Reject Proposal</span>
                                </div>
                              }
                              </>
                              }
                                <>
                                  {favouriteTal ?
                                    <div className="d-flex option" onClick={() => setRemoveFromFavourite(true)}>
                                      <img src={removeFavorite} alt="" className=" ml-1 crossHeartIcon" />
                                      <span>Remove from Favorite</span>
                                    </div>
                                    :
                                    <div className="d-flex option" onClick={updateInstrAsFavorite}>
                                      <img src={favourite} alt="" className="mr-2 ml-1" />
                                      <span>Add to Favorite</span>
                                    </div>
                                  }
                                </>

                            </div>
                          </div>
                        }
                      </div>
                    }
                </div>
        </>
    )
}

export default ProposalHeader;