/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import $ from "jquery";
import Label from "../../../../components/label";
import {
  ArrowLeft,
  changeThumbnailIcon,
  CirclePlus,
  Edit,
  greyFilledEllipsis,
  leftScrollIcon,
  pdfPortfolioThumbnail,
  rolesDelete,
  urlPortfolioThumbnail
} from "../../../../assets/images/index";
import { GetCurrentAgencyMember } from "../../../../util/reduxData";
import { DeleteItemAPI, DeleteVideoAPI, DownloadBlobByEntity, UploadThumbnailPut } from "../../../../services/portfolio";
import { dataURLtoFile } from "../../../../constants/datToFileConverter";
import { allowedFiletypeHandler } from "../../../../util/allowedFileTypeHandler";
import { DownloadFreelancerBlobByEntity } from "../../../../services/freelancer";
import { useGlobalClick } from "../../../../util/clickListener";
import Button from "../../../../components/button";
import Modal from "../../../../components/modal";
import AddPortfolio from "../../../Onboarding/portfolio/addPortfolio";
import ImageCropper from "../../../../components/imageCropper/imageCropper";
import ViewPortfolioModal from "../viewPortfolioModal";
import "./index.scss";

const PortfolioSection = ({
  edit = false,
  linksAndVideos,
  portfolioItems,
  setRefresh,
  fromFreelanceModal,
  fromTalent,
  instrId,
  projectId,
  preview
}) => {

  const [openedOption, setOpenedOption] = useState(null);
  const [deleteModal, setDeleteModal] = useState({})
  const currentInstrId = GetCurrentAgencyMember()
  const ref = useRef()
  const [links, setLinks] = useState([])
  const [videos, setVideos] = useState([])
  const [items, setItems] = useState([])
  const [uploadThumbnailData, setUploadThumbnailData] = useState({})
  const [viewPortfolioData, setViewPortfolioData] = useState({})
  const [thumbnailDict, setThumbnailDict] = useState({})
  const [showAddPortfolioModal, setShowAddPortfolioModal] = useState(false);
  const [thumbnailErrors, setThumbnailErrors] = useState({})
  const [fullSizeImageSrc, setFullSizeImageSrc] = useState("");
  const [openCropper, setOpenCropper] = useState(false);
  const [editPortfolioData, setEditPortfolioData] = useState(null);
  const history = useHistory()
  const queryString = history.location.search
  const searchParams = new URLSearchParams(queryString);
  const lowerCaseParams = new URLSearchParams(
    Array.from(searchParams, ([key, value]) => [key.toLowerCase(), value])
  );
  const projectIDParam = projectId || lowerCaseParams.get("project_id");
  const hash = lowerCaseParams.get("hash");

  useEffect(() => {
    if (linksAndVideos && portfolioItems) {
      getLinksAndVideos()
    }
  }, [linksAndVideos, portfolioItems])

  const uploadCroppedImage = (croppedImage) => {
    const file = dataURLtoFile(croppedImage)
    const data = new FormData();
    data.append("formFile", file);
    UploadThumbnailPut(
      uploadThumbnailData.type,
      uploadThumbnailData.id,
      data,
      {},
      currentInstrId
    ).then((res) => {
      setThumbnailErrors({})
      if (uploadThumbnailData.type === "Item") {
        setRefresh(true)
      }
      else {
        setRefresh(true)
      }
    }).catch((err) => {
      console.log('errr', err)
      setThumbnailErrors({
        [uploadThumbnailData.id]: {
          imageUploadFailed: true
        }
      })
    })
    toggleCropper()

  };
  const showCropperModal = (e) => {
    setThumbnailErrors({})
    const allowedExtenstions = ["jpg", "jpeg", "png"]
    if (e.target.files && e.target.files.length > 0) {
      const fileName = e.target.files[0]
      const allow = allowedFiletypeHandler(allowedExtenstions, fileName.name)
      if (!allow) {
        setOpenedOption(null)
        setThumbnailErrors({
          [uploadThumbnailData.id]: {
            invalidFileType: true
          }
        })
        return
      }
      const fileSize = (e.target.files[0].size / 1024 / 1024).toFixed(4);
      if (fileSize > 5) {
        setOpenedOption(null)
        setThumbnailErrors({
          [uploadThumbnailData.id]: {
            imageSizeError: true
          }
        })
        return
      }
      const reader = new FileReader();
      reader.addEventListener('load', () =>
        setFullSizeImageSrc(reader.result)
      );
      setOpenCropper(true)
      reader.readAsDataURL(e.target.files[0]);
      e.target.value = ''
    }
  }

  const toggleCropper = () => {
    setFullSizeImageSrc("")
    setOpenCropper(false)
  }

  const onInputClick = (id, type) => {
    $("#uploadImage").trigger("click");
    setUploadThumbnailData({
      id: id,
      type: type === "File" ? "Item" : "Link"
    })
  }

  const handleEditPortfolio = (data, type) => {
    setEditPortfolioData({
      ...data,
      portfolioType: type
    })
    setShowAddPortfolioModal(true)
  }

  const getEllipsisOptions = (data, id, type) => {
    return (
      <div className={id === openedOption ? "portfolioElipsisOptionsCard" : "d-none"}>
        <div
          className="option font14Blue"
          onClick={() => {
            handleEditPortfolio(data, type)
          }}>
          <img src={Edit} alt="" className="crossHeartIcon mr-2 ml-1" />
          <span>Edit</span>
        </div>
        <div
          className="option font14Blue"
          onClick={() => {
            onInputClick(id, type)
          }}>
          <img src={changeThumbnailIcon} alt="" className="crossHeartIcon mr-2 ml-1" />
          <span>Change Thumbnail</span>
        </div>
        <div
          className="option font14Blue"
          onClick={() => {
            if (type === "File") {
              handleDeleteModal(
                data,
                "Portfolio Item",
                handleDeleteItem
              )
            } else {
              handleDeleteModal(
                data,
                `Portfolio ${type}`,
                handleDeleteVideo
              )
            }
          }}
        >
          <img src={rolesDelete} alt="" className="crossHeartIcon mr-2 ml-1" />
          <span>Delete</span>
        </div>
      </div>
    )
  }

  const embedURL = (url) => {
    if (url?.includes(`vimeo.com/`)) {
      const vimeoPlayerURL = `https://player.vimeo.com/video/`;
      const embedVimeoID = url?.split("/").slice(-1)[0];
      return `${vimeoPlayerURL}${embedVimeoID}`;
    } else {
      const youtubePlayerURL = `//www.youtube.com/embed/`;
      var regExp =
        /^.*(youtu.be\/|v\/|u\/\w\/|shorts\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
      var match = url?.match(regExp);

      if (match && match[2].length === 11) {
        return `${youtubePlayerURL}${match[2]}?rel=0`;
      } else {
        return "error";
      }
    }
  };

  const showPortfolioModal = (url, portfolioData, type) => {
    setViewPortfolioData({
      showModal: true,
      src: url,
      title: portfolioData?.title,
      description: portfolioData?.description,
      type: type,
      portfolioData: portfolioData
    });
  };

  const handleClick = (event) => {
    setOpenedOption(null);
    if (ref?.current && !ref?.current?.contains(event.target)) {
      setOpenedOption(null);
    }
  };

  useGlobalClick(handleClick, ref)

  const getLinksAndVideos = () => {
    const videoResults = linksAndVideos?.filter(
      (data) => data?.urlTypeId === 13
    );
    const linkResults = linksAndVideos?.filter(
      (data) => data?.urlTypeId !== 13
    );
    setItems(portfolioItems)
    setVideos(videoResults)
    setLinks(linkResults)
    getPortfolioThumbnail([...videoResults, ...linkResults, ...portfolioItems])

  };

  const getPortfolioThumbnail = (portfolioRecords) => {
    const tempDict = { ...thumbnailDict }
    portfolioRecords.forEach((portfolioData) => {
      if (portfolioData.thumbnail && !tempDict[portfolioData?.thumbnail]) {

        if(hash){
          DownloadFreelancerBlobByEntity(
            portfolioData.thumbnail,
            "instructor",
            instrId,
            projectIDParam,
            hash,
            instrId
          ).then((response) => {
            const Imagedata = `data:${response?.headers["content-type"]
              };base64,${new Buffer(response?.data, "binary")?.toString("base64")}`;
      
              tempDict[portfolioData.thumbnail] = Imagedata
              setThumbnailDict({ ...tempDict })
          });

        }else{
          DownloadBlobByEntity(portfolioData.thumbnail, "instructor", instrId).then((response) => {
            const Imagedata = `data:${response?.headers["content-type"]
              };base64,${new Buffer(response?.data, "binary")?.toString("base64")}`;
            tempDict[portfolioData.thumbnail] = Imagedata
            setThumbnailDict({ ...tempDict })
          });
        }
      }
    })
    setThumbnailDict({ ...tempDict })
  }

  const handleDeleteVideo = (video) => {
    const id = video?.webID;
    try {
      DeleteVideoAPI(id, currentInstrId)
        .then(() => {
          setDeleteModal({ modal: false });
          setRefresh(true)
          setViewPortfolioData({})
        })
        .catch((err) => {
          console.log(err?.response?.data);
        });
    } catch (err) {
      console.err("err", err);
    }
  };

  const handleDeleteItem = (item) => {
    const id = item?.docID;
    try {
      DeleteItemAPI(id, currentInstrId)
        .then(() => {
          setDeleteModal({ modal: false });
          setRefresh(true)
          setViewPortfolioData({})
        })
        .catch((err) => {
          console.log(err?.response?.data);
        });
    } catch (err) {
      console.err("err", err);
    }
  };

  const handleDeleteModal = (portfolioItem, heading, deleteFunction) => {
    setDeleteModal({
      modal: true,
      data: portfolioItem,
      title: heading,
      deleteItem: portfolioItem?.title,
      onDelete: deleteFunction,
    });
  };

  const getThumbnailErrorMsg = (id) => {
    if (thumbnailErrors[id]?.invalidFileType) {
      return (
        <>
          <span className="d-flex justify-content-center align-items-center my-2">
            <i className="fa fa-exclamation-circle errorIcon" />
            <p className="errorText mb-0 ml-0 ">
              {"File type not allowed"}
            </p>
          </span>
        </>
      )
    } else if (thumbnailErrors[id]?.imageSizeError) {
      return (
        <span className="d-flex justify-content-center align-items-center my-2">
          <i className="fa fa-exclamation-circle errorIcon" />
          <p className="errorText mb-0 ml-0 ">
            {"File Size is to large or not compatible"}
          </p>
        </span>
      )
    } else if (thumbnailErrors[id]?.imageUploadFailed) {
      return (
        <div className="text-center">
          <span className="d-flex justify-content-center align-items-center my-2">
            <i className="fa fa-exclamation-circle errorIcon" />
            <p className="errorText mb-0 ml-0 ">{"Image Upload failed"}</p>
          </span>
        </div>
      )
    } else {
      return null
    }
  }

  const sideScroll = (direction, step = 15) => {
    const element = document.getElementById('scrollDiv');
    let scrollAmount = 0;
    if (element) {
      const slideTimer = setInterval(function () {
        if (direction === 'left') {
          element.scrollLeft -= step;
        } else {
          element.scrollLeft += step;
        }
        scrollAmount += step;
        if (scrollAmount >= 250) {
          window.clearInterval(slideTimer);
        }
      }, 25);
    }
  }


  const toggleModal = () => {
    setViewPortfolioData({})
    setEditPortfolioData({})
  }

  const toggleAddPortfolioModal = () => {
    setEditPortfolioData({})
    setShowAddPortfolioModal(false)
  }

  const onAddSuccess = () => {
    setRefresh(true)
    setEditPortfolioData({})
    setShowAddPortfolioModal(false)
    setViewPortfolioData({})
  }

  useGlobalClick(handleClick, ref)

  return (
    <>
      <ViewPortfolioModal
        viewPortfolioData={viewPortfolioData}
        toggleModal={toggleModal}
        handleEditPortfolio={handleEditPortfolio}
        handleDeleteModal={handleDeleteModal}
        handleDeleteItem={handleDeleteItem}
        handleDeleteVideo={handleDeleteVideo}
        edit={edit}
        instrId={instrId}
        preview={preview}
      />
      <input
        type="file"
        name="profileImg"
        className="d-none"
        id="uploadImage"
        onChange={showCropperModal}
      />
      <ImageCropper
        fullImageSrc={fullSizeImageSrc}
        toggleModal={toggleCropper}
        isOpen={openCropper}
        uploadCroppedImage={uploadCroppedImage}
      />
      <Modal
        isOpen={deleteModal?.modal}
        size="extrasmall"
        title={
          <Label
            label={`Delete ${deleteModal?.title}`}
            className="font18Black boldFont"
          />
        }
        titleClass="ml-1"
        hideCloseButton={true}
        toggleModal={() => setDeleteModal(undefined)}
        disableBackdropToggle={true}
        containerClass="portfolioDeleteModal"
      >
        <div className="modalTextHolder">
          <Label
            label={
              <span className="mx-md-0 mx-4 deleteTextPortfolio">
                Are you sure you want to delete <br />
                <span className="capitalizeWord">
                  {deleteModal?.title}
                </span>{" "}
                {deleteModal?.deleteItem} ?
              </span>
            }
            className="subTextWithoutColorWithoutBold mt-4"
          />
        </div>
        <div
          className="border-top text-center w-100"
        >
          <div className="buttonHolder">
            <Button
              label="Cancel"
              type="button"
              onClick={() => setDeleteModal(false)}
              className="btn-light main_btn mr-2 portfolioButtonDelete"
            />
            <Button
              label="Delete"
              type="submit"
              onClick={() => deleteModal?.onDelete(deleteModal?.data)}
              className="btn-blue main_btn  "
            />
          </div>
        </div>
      </Modal>

      <div
        className={
          edit || [...links, ...items, ...videos].length > 0
            ? (fromFreelanceModal && !fromTalent) ? "freelancerModalComponentWrap relative" :
              (fromFreelanceModal && fromTalent) ?
                "freelancerModalComponentWrap relative ml-0" :
                "userProfileDataCard relative" :
            "d-none"
        }
      >
        <div className={[...links, ...items, ...videos]?.length > (fromTalent ? 6 : 4) ? "scroller" : "d-none"}>
          <div className="rightPortfolioScroll" onClick={() => sideScroll("right")}>
            <img
              src={ArrowLeft}
              alt=""
              style={{ height: 28 }}
            />
          </div>
          <div className="leftPortfolioScroll" onClick={() => sideScroll("left")}>
            <img
              src={ArrowLeft}
              alt=""
              style={{
                height: 28,
              }}
            />
          </div>
        </div>
        <div className={[...links, ...videos, ...items]?.length > 1 ? "mobileScroller" : "d-none"}>
          <div className="rightPortfolioScroll" onClick={() => sideScroll("right")}>
            <img
              src={leftScrollIcon}
              alt=""
              style={{ height: 28 }}
            />
          </div>
          <div className="leftScroll" onClick={() => sideScroll("left")}>
            <img
              src={leftScrollIcon}
              alt=""
              style={{
                height: 28,
              }}
            />
          </div>
        </div>
        <div className="myprofileTitleHeaderWrap flex-wrap">
          <div className="d-flex align-items-center">
            <Label
              className="font18Black boldFont mb-0 noWrap"
              label="Portfolio"
            ></Label>
          </div>
          <div>
            {
              edit &&
              <div
                className="d-flex align-items-center justify-content-end relative"
                onClick={() => {
                  setShowAddPortfolioModal(true)
                  setViewPortfolioData({})
                }}
                ref={ref}
              >
                <img src={CirclePlus} className="iconShadow mr-2" alt="" />
                <span className="font15DarkBlue">Add To Portfolio</span>
                <Modal
                  isOpen={showAddPortfolioModal}
                  size="extralarge"
                  hideCloseButton={true}
                  title={
                    (editPortfolioData?.webID || editPortfolioData?.docID) ?
                      "Update Portfolio Item" :
                      "Add a Portfolio Item"
                  }
                  toggleModal={() => {
                    setEditPortfolioData({})
                    setShowAddPortfolioModal(false)
                  }}
                  disableBackdropToggle={true}
                  containerClass=""
                >
                  <AddPortfolio
                    existingItems={[...links, ...videos, ...items]}
                    onSuccess={(id) => {
                      onAddSuccess(id)
                    }}
                    editPortfolioData={editPortfolioData}
                    toggleModal={() => {
                      toggleAddPortfolioModal()
                    }}
                  />
                </Modal>
              </div>
            }
          </div>
        </div>
        <div className="MyProfileAddSep" />
        <div>
          {(
            <div className="portfolioListUserProfileView mt-3" id="scrollDiv">
              {

                links.map((linkData, idx) => {
                  return (
                    <React.Fragment key={idx}>
                      <div className="mr-3 maxPortfolioWidth" id={linkData.webID}>
                        <img
                          src={
                            thumbnailDict[linkData.thumbnail] ?
                              thumbnailDict[linkData.thumbnail] :
                              urlPortfolioThumbnail
                          }
                          className="portfolioListImgProfileView"
                          onClick={() => showPortfolioModal(null, linkData, "Link")}
                          alt="portfolio"
                        />
                        {getThumbnailErrorMsg(linkData?.webID)}

                        <div className="font14Black boldFont mt-1 portfolioStringTrimming">{linkData.title}</div>
                        <div className="font14Grey portfolioStringTrimming">{linkData.description}</div>
                        {edit &&
                          <div className="pointer relative" >
                            <img src={greyFilledEllipsis} alt="" onClick={() => setOpenedOption(linkData?.webID)} className="optionEllipsis"/>
                            {getEllipsisOptions(linkData, linkData.webID, "Link")}
                          </div>
                        }
                      </div>
                    </React.Fragment>
                  )
                })
              }
              {
                videos.map((videoData, index) => {
                  return (
                    <React.Fragment key={index}>
                      <div className="mr-3 maxPortfolioWidth" id={index}>
                        {
                          thumbnailDict[videoData.thumbnail] ?
                            <img
                              src={thumbnailDict[videoData.thumbnail]}
                              className="portfolioListImgProfileView"
                              onClick={() => showPortfolioModal(null, videoData, "Video")}
                              alt="portfolioVideo"
                            /> :
                            <div className="relative">
                              <iframe
                                className="portfolioListImgProfileView"
                                src={embedURL(videoData?.url)}
                                frameBorder="0"
                                allowFullScreen
                                title="portfolio"
                              />
                              <div
                                className="hiddenDivisionVideoFrame"
                                onClick={() => showPortfolioModal(embedURL(videoData?.url), videoData, "Video")}
                              />
                            </div>
                        }
                        {getThumbnailErrorMsg(videoData?.webID)}
                        <div className="font14Black boldFont portfolioStringTrimming">{videoData.title}</div>
                        <div className="font14Grey portfolioStringTrimming">{videoData.description}</div>

                        {edit && <div className="pointer relative" >
                          <img src={greyFilledEllipsis} alt="" onClick={() => setOpenedOption(videoData?.webID)} className="optionEllipsis"/>
                          {getEllipsisOptions(videoData, videoData.webID, "Video")}
                        </div>}

                      </div>
                    </React.Fragment>
                  )
                })
              }
              {
                items.map((itemData, idx) => {
                  return (
                    <React.Fragment key={idx}>
                      <div className="mr-3 maxPortfolioWidth">
                        <img
                          src={
                            thumbnailDict[itemData.thumbnail] ?
                              thumbnailDict[itemData.thumbnail] :
                              pdfPortfolioThumbnail
                          }
                          className="portfolioListImgProfileView"
                          onClick={() => showPortfolioModal(null, itemData, "File")}
                          alt="profile"
                        />
                        {getThumbnailErrorMsg(itemData.docID)}

                        <div className="font14Black boldFont mt-1 portfolioStringTrimming">{itemData.title}</div>
                        <div className="font14Grey portfolioStringTrimming">{itemData.description}</div>
                        {edit && <div className="pointer relative" >
                          <img src={greyFilledEllipsis} alt="" onClick={() => setOpenedOption(itemData.docID)} className="optionEllipsis"/>
                          {getEllipsisOptions(itemData, itemData.docID, "File")}
                        </div>}
                      </div>
                    </React.Fragment>
                  )
                })
              }
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default PortfolioSection;