import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Edit } from "../../../../../assets/images";
import Label from "../../../../../components/label";
import Testimonials from "../../../../Dashboard/Components/Testimonials/testimonials";
import Reviews from "../Reviews";
import "./index.scss";

const TestimonialsAndReview = ({ edit, review, fromTalent, testimonials, instrId, client }) => {
  const [activeTab, setActiveTab] = useState("Testimonials");
  const history = useHistory();

  useEffect(() => {
    if (review?.length > 0) {
      setActiveTab("Reviews")
    } else {
      setActiveTab("Testimonials")
    }
  }, [review, testimonials])

  const toggleTabs = (tabActive) => {
    setActiveTab(tabActive);
  };

  return (
    <>
      <div className={(fromTalent && !client) ? "freelancerModalComponentWrap ml-0 pl-0 pr-0" : "freelancerModalComponentWrap pl-0 pr-0"}>
        <div className="ReviewAndTestimonial pt-1 pl-1">
          <div className="tabWrappStyle">
            <>
              {
                review?.length > 0 &&
                <div onClick={() => toggleTabs("Reviews")}>
                  <Label
                    className={
                      activeTab === "Reviews" ?
                        "activeTabStyle" :
                        "inactiveTabStyle mb-0"
                    }
                    label="Reviews"
                  />
                </div>
              }
              {
                testimonials[instrId]?.length > 0 && 
                <div onClick={() => toggleTabs("Testimonials")}>
                  <Label
                    className={
                      activeTab === "Testimonials" ?
                        "activeTabStyle" :
                        "inactiveTabStyle mb-0"
                    }
                    label="Testimonials"
                  />
                </div>
              }
            </>
          </div>
          {edit && (
            <img
              src={Edit}
              style={{ cursor: "pointer" }}
              alt=""
              onClick={() => {
                history.push("/profile/testimonials");
              }}
            />
          )}
        </div>
        <div className="border-top mt-1"></div>
        <div>
          {
            activeTab === "Reviews" && (
              <Reviews edit={edit} review={review} />
            )}
          {
            activeTab === "Testimonials" && (
              <Testimonials testimonialData={testimonials} instrId={instrId} setTestimonialsCount={() => { }} fromFreelance={true} />
            )}
        </div>
      </div>
    </>
  );
};

export default TestimonialsAndReview;
