/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { convertDate } from "../../../../util/dateTime";
import { trimString } from "../../../../util/trimString";
import { sortingIcon, sortingIconAscending, sortingIconDescending, uploadImg } from "../../../../assets/images";
import { getAddressString } from "../../../../util/getAddressString";
import { sortByKey } from "../../../../util/sortByKey";
import { DownloadBlobByEntity } from "../../../../services/portfolio";
import Modal from "../../../../components/modal";
import "./index.scss";

const ViewAllTalentJob = ({
    isOpen,
    toggleModal,
    talentData
}) => {

    const history = useHistory()
    const [image, setImage] = useState(null)
    const [talentJobRecords, setTalentJobRecords] = useState([])
    const [sortingColName, setSortingColName] = useState(null);
    const [sortAscending, setSortAscending] = useState(true);

    const tableHeaders = ["Job#", "Job Date", "Role on the job"]
    const dataRecordKeys = ["projectID","projectStartDate", "roles"]

    useEffect(() => {
        if(talentData?.talentProjects) {
            getProfilePhoto()
            setTalentJobRecords(talentData?.talentProjects)
        }
    }, [talentData])

    const setSortAscendingFunc = (data) => setSortAscending(data);

    const setSortingColNameFunc = (data) => setSortingColName(data);

    const setTalentJobRecordsFunc = (orderedRecord) => setTalentJobRecords(orderedRecord);

    const sortColumn = (key) => {
        sortByKey(
        setSortAscendingFunc,
        setSortingColNameFunc,
        setTalentJobRecordsFunc,
        key,
        talentJobRecords,
        sortingColName,
        sortAscending
        );
    };

    const getProfilePhoto = () => {
        DownloadBlobByEntity(
            talentData?.thumbnailBlobId,
            "instructor",
            talentData?.instrID
        ).then((response) => {
            const Imagedata = `data:${response?.headers["content-type"]
                };base64,${new Buffer(response?.data, "binary")?.toString("base64")}`;

            setImage(Imagedata);
        });
    }

    return (
        <>
            <Modal
                isOpen={isOpen}
                containerStyle={{
                    width: "600px",
                    maxWidth: "95%",
                    minHeight: "250px",
                    paddingBottom: "0px"
                }}
                size="medium"
                hideCloseButton={true}
                toggleModal={toggleModal}
                title={
                    "Jobs of " + talentData.talentName
                }
                disableBackdropToggle={true}
                containerClass={"addTimeLineModal"}
            >
                <div className="mt-3 border-top mobileSep" />
                <div className="viewAllContentWrapper mx-auto">
                    <div className="d-flex">
                        <img src={image ? image : uploadImg} alt="" className="mr-3 mb-3 avtar" />
                        <div>
                            <div className="talentName">
                                {talentData.talentName}
                                <span className="approved ml-1 d-none">
                                    <img src={talentData?.thumbnailImage ? talentData?.thumbnailImage : uploadImg} alt="" className="approveBadge mr-1 mb-1" />
                                    TTA Approved
                                </span>
                            </div>
                            <div className=""><span className="smallHeader">Location:</span> <span className="normalSpanText">{getAddressString(talentData, true)}</span></div>


                            <span className="font12Grey" title={talentData?.roles}>{trimString(talentData?.roles, 60)}</span>
                        </div>
                    </div>
                    <div className="border-top mt-2" />
                    <div className="smallHeader mt-3">Jobs: {talentData.noOfJobs}</div>
                    <div className="jobListWrapp mt-3">
                        <table className="table table-borderless table-hover noHeadSeperator">
                            <thead>
                                <tr>
                                    {tableHeaders.map((data, index) => {
                                        return (
                                            <th
                                                className={
                                                    sortingColName === dataRecordKeys[index]
                                                        ? "highlightedHeader"
                                                        : ""
                                                }
                                                key={index}
                                            >
                                                <span
                                                    onClick={() => sortColumn(dataRecordKeys[index])}
                                                    style={{ whiteSpace: "nowrap" }}
                                                >
                                                    {data}
                                                    {data && (
                                                        <span style={{ whiteSpace: "nowrap" }}>
                                                            {data && (
                                                                <span className="ml-2">
                                                                    <img
                                                                        src={
                                                                            sortingColName ===
                                                                                dataRecordKeys[index]
                                                                                ? sortAscending
                                                                                    ? sortingIconAscending
                                                                                    : sortingIconDescending
                                                                                : sortingIcon
                                                                        }
                                                                        alt="sort"
                                                                    />
                                                                </span>
                                                            )}
                                                        </span>
                                                    )}
                                                </span>
                                            </th>
                                        );
                                    })}
                                </tr>
                            </thead>
                            <tbody>
                                {talentJobRecords.map((data, index) => {
                                    return (
                                        <tr key={index}>
                                            <td
                                                className="font14Blue"
                                                onClick={() => {
                                                    history.push("/client-job/job-entity/1/" + data.projectID)
                                                }}
                                                title={data.title}
                                            >
                                                {data.projectID} - {trimString(data.title, 25)}
                                            </td>
                                            <td>
                                                {convertDate(data.projectStartDate)}
                                            </td>
                                            <td title={data.roles}>
                                                {trimString(data.roles, 25)}
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>

            </Modal>
        </>
    )
}

export default ViewAllTalentJob;