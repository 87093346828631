import React from "react";
import Modal from "../modal";
import Label from "../label/Label";
import Button from "../button";
import "./index.scss";

const RedirectConfirmModal = ({ isOpen, toggleModal, redirectUrl }) => {
  const redirectUser = () => {
    toggleModal();
    window.open(redirectUrl, "_blank");
  };

  return (
    <Modal
      isOpen={isOpen}
      size="extrasmallgobackRedirect"
      hideCloseButton={true}
      toggleModal={toggleModal}
      disableBackdropToggle={true}
    >
      <div className="redirectConfirmationWrapper">
        <Label
          label="Switch to Classic View"
          className="font18Black boldFont"
        ></Label>
        <div className="wrapperText">
          <div className="redirectModalText">
            <span >
              While we are developing the new TTA Connect you'll have to switch
              to the classic view to access certain features. If you continue,
              you'll be asked to login to the old site using your same username
              and password. We appreciate any feedback you have as we continue
              to build your better and new user experience.
            </span>
          </div>
        </div>
      </div>
      <div
        style={{
          position: "absolute",
          bottom: 0,
          width: "100%",
        }}
      >
        <div className="redirectConfirmModalButtonWrapper border-top changePrimaryEmailNewBox">
          <div className="t my-3 mb-0 buttonEducationwrapper">
            <Button
              label="Cancel"
              type="btn"
              onClick={toggleModal}
              className="btn-light mx-2 cancelButtonDelete"
            />
            <Button
              label="Continue"
              onClick={redirectUser}
              type="btn"
              className="btn-blue "
              name="continue"
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default RedirectConfirmModal;
