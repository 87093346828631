import React from "react";
import { BigRight } from "../../../../assets/images";
import Modal from "../../../../components/modal";
import Button from "../../../../components/button";

const InviteSucess = ({
    isOpen,
    toggleModal,
    firstName
}) => {
    return (
        <>
            <Modal
                isOpen={isOpen}
                size="extrasmallgoback"
                hideCloseButton={false}
                toggleModal={() => {
                    toggleModal();
                }}
                disableBackdropToggle={true}
                containerStyle={{
                    width: "550px"
                }}
            >
                <div className="mt-3 mb-3 text-align-center">
                    <img src={BigRight} alt="" className="mb-2" />
                    <div className="font18Black boldFont">Invite Sent Successfully</div>
                    <div className="font14Grey mt-2">
                        An invite is sent successfully to {firstName}.
                    </div>
                    <div className="border-top mt-5 mb-1" />
                    <Button
                        label="Ok"
                        className="btn-blue mt-3"
                        onClick={() => {
                            toggleModal()
                        }}
                    ></Button>
                </div>
            </Modal>
        </>
    )
}

export default InviteSucess;