
const navbarMenuLinkClient = [
    {
        name: 'Account Settings',
        path: '/client-profile/myinformation-account',
        legacy:false
    },
    {
      name: "Documents",
      path: "/documents/1",
      legacy: false,
      active: "documents",
    },
    // {
    //     name: 'Change Email',
    //     path: '/client-profile/changeemail',
    //     legacy:false
    // },
    // {
    //     name: 'Change Password',
    //     path: '/client-profile/changepassword',
    //     legacy:false
    // },
    {
        name: 'Logout',
        path: '/logout',
        legacy:false
    }
]

const GetNavbarMenuLinksClient = () => {

    return  [
      ...navbarMenuLinkClient
    ];
  
}

export default GetNavbarMenuLinksClient;