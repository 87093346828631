import React from "react";
import Label from "../../../../../components/label";
import Modal from "../../../../../components/modal";
import Button from "../../../../../components/button";


const DeactivateContact = ({
    showDeactivateConfirm,
    successfullDeactivate,
    deactivateContactData,
    deactivateContact,
    toggleDeactivate

}) => {
    return (
        <>
            <Modal
                isOpen={showDeactivateConfirm}
                size="extrasmall"
                title={<Label label={"Deactivate Contact"} className="font18Black boldFont" />}
                titleClass="ml-1"
                hideCloseButton={true}
                toggleModal={() => toggleDeactivate()}
                disableBackdropToggle={true}
                containerClass="deactivateModal"
            >
                <div className="DeativatemodalTextHolder">
                    {   successfullDeactivate ?
                        <Label
                            label={
                            <span>
                                Contact {deactivateContactData.email} has been successfully deactivated.
                            </span>
                            }
                            className="subTextWithoutColorWithoutBold mt-4 ml-4 mr-4"
                        /> :
                        <Label
                            label={
                            <span>
                                You are about to deactivate a contact. This does not delete the account.
                                This person will no longer have access to your company profile on TTA Connect <br/><br/>
                                Are you sure you want to deactivate this contact? <br/>{deactivateContactData?.firstName} {" "} {deactivateContactData?.lastName}
                            </span>
                            }
                            className="subTextWithoutColorWithoutBold mt-4 ml-4 mr-4"
                        />
                        
                    }
                
                </div>
                <div
                className="border-top text-center"
                style={{ left: "0", width: "100%" }}
                >
                    {   successfullDeactivate ?
                        <div className="buttonHolder">
                            <Button
                                label="Close"
                                type="button"
                                onClick={() => toggleDeactivate()}
                                className="btn-blue main_btn"
                            />
                        </div> :
                        <div className="buttonHolder">
                            <Button
                                label="Cancel"
                                type="button"
                                onClick={() => toggleDeactivate()}
                                className="btn-light main_btn mr-2 portfolioButtonDelete"
                            />
                            <Button
                                label="Deactivate"
                                type="submit"
                                onClick={() => deactivateContact()}
                                className="btn-blue main_btn  "
                            />
                        </div> 
                            

                
                    }
                
                </div>
            </Modal>
        </>
    )
}

export default DeactivateContact;
