/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState, Fragment } from "react";
import { useHistory } from "react-router-dom";
import {
  uploadImg,
  print,
  removeFavorite,
  favourite,
  greyFilledEllipsis
} from "../../../../../assets/images/index";
import { getInstructorBadge } from "../../../../../util/getInstructorBadge";
import { getAddressString } from "../../../../../util/getAddressString";
import { useGlobalClick } from "../../../../../util/clickListener";
import { DownloadFreelancerBlobByEntity } from "../../../../../services/freelancer";
import { DownloadBlobByEntity, DownloadDocByEntity } from "../../../../../services/portfolio";
import { AddToFavourite, RemoveToFavourite } from "../../../../../services/favourite";
import RemoveFromFavourite from "../../../JobPost/ReviewSubmission/RemoveFromFavourite/RemoveFromFavourite";
import Button from "../../../../../components/button";
import ProposalDetails from "../ProposalDetails";
import RelatedPortfolioSection from "../ProposalDetails/RelatedPortfolio";
import AboutMe from "../AboutMe";
import "./index.scss";

const UserDetails = ({
  proposalData,
  projectID,
  instrID,
  myInformation,
  isFavorite,
  hideOptions,
  refreshList,
  fromTalent,
  tab,
  client,
  aboutMe
}) => {
  const [img, setImage] = useState();
  const [open, setOpen] = useState(false);
  const [favouriteTal, setFavourite] = useState(false);
  const [removeFromFavourite, setRemoveFromFavourite] = useState(false);
  const [showBadge, setShowBadge] = useState(null)
  const history = useHistory()
  const queryString = history.location.search
  const searchParams = new URLSearchParams(queryString);
  const lowerCaseParams = new URLSearchParams(
    Array.from(searchParams, ([key, value]) => [key.toLowerCase(), value])
  );
  const instrIDParam = instrID || parseInt(lowerCaseParams.get("instrid"));
  const projectIDParam = projectID || lowerCaseParams.get("project_id");
  const hash = lowerCaseParams.get("hash");

  const ref = useRef()

  useEffect(() => {
    if (proposalData?.thumbnailBlobId && projectIDParam && hash && instrIDParam) {
      getFreelanceProfilePhoto()
    } else if (history.location.pathname !== "/preview" && myInformation?.thumbnailBlobId && !hash) {
      getProfilePhoto()
    }
  }, [proposalData, myInformation])

  useEffect(() => {
    setFavourite(isFavorite)
  }, [isFavorite])

  const handleClick = (event) => {
    setOpen(false)
    if (ref?.current && !ref?.current?.contains(event.target)) {
      setOpen(false)
    }
  };

  useGlobalClick(handleClick, ref)

  const getProfilePhoto = () => {
    DownloadBlobByEntity(
      myInformation?.thumbnailBlobId,
      "instructor",
      instrID
    ).then((response) => {
      const Imagedata = `data:${response?.headers["content-type"]
        };base64,${new Buffer(response?.data, "binary")?.toString("base64")}`;

      setImage(Imagedata);
    });
  }
  const getFreelanceProfilePhoto = () => {
    DownloadFreelancerBlobByEntity(
      proposalData?.thumbnailBlobId,
      "instructor",
      instrIDParam,
      projectIDParam,
      hash,
      instrIDParam
    ).then((response) => {
      const Imagedata = `data:${response?.headers["content-type"]
        };base64,${new Buffer(response?.data, "binary")?.toString("base64")}`;

      setImage(Imagedata);
    });
  }
  const downloadResume = (data) => {
    DownloadDocByEntity(data.resumeBlobId, "instructor", instrID)
      .then((response) => {
        const url = window.URL
          .createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${data.talentName}.pdf`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
  }

  const updateInstrAsFavorite = () => {
    const payload = {
      instrID: instrID,
    };
    AddToFavourite(payload).then((response) => {
      setFavourite(true)
      if(refreshList) {
        refreshList()
      }
    });
  }

  const removeInstrAsFavorite = () => {
    const payload = {
      instrID: instrID,
    };
    RemoveToFavourite(payload).then((res) => {
      setFavourite(false);
      if(refreshList) {
        refreshList()
      }
      setRemoveFromFavourite(false)
    });
  }

  const setShowBadgeFunc = (data) => setShowBadge(data)

  return (
    <Fragment>
      <RemoveFromFavourite
        isOpen={removeFromFavourite}
        toggleModal={() => setRemoveFromFavourite(!removeFromFavourite)}
        instId={instrID}
        RemoveToFavouriteFuncAPi={(data) => removeInstrAsFavorite(data)}
      />
        <div className="secondwrapperProposalFreelancer mt-4">
          <div>
            <div className="d-flex ">
              <div>
                <img
                  src={img ? img : uploadImg}
                  alt=""
                  className="imageIcon mr-1 mb-1"
                />
                <div className="certifiedBadgeWrap newWrap mt-2 mr-2">
                  {getInstructorBadge(myInformation?.instrStatus, true, setShowBadgeFunc, showBadge, true)}
                </div>
              </div>
              <div className="w-100 ">
                <div className="d-flex flex-column  ml-2">
                  <div className="font18Black boldFont freelancerProposalDetailName">
                    <div>{myInformation?.talentName}</div>
                    {
                      !hideOptions &&
                      <div className="proposalDetailsButtonWrapper desktopView">

                      <div className="proposalDetailsButtonWrapperInner">
                        {
                          proposalData?.resumeBlobId &&
                          <Button
                            className={"btn-rqst small-btn mr-2"}
                            label={"Download Resume"}
                            onClick={() => downloadResume(proposalData)}
                          />
                        }
                      </div>
                      {
                        fromTalent &&
                        <div className="pointer relative" >
                          <img src={greyFilledEllipsis} alt="" onClick={() => setOpen(!open)} className="optionEllipsis"/>

                          <div className={open ? "freeLanceoptionCard" : "d-none"}>

                            {proposalData?.resumeBlobId &&
                              <div className="d-flex option" onClick={() => downloadResume(proposalData)}>
                                <img src={print} alt="" className="mr-1 ml-1" />
                                <span>Download Resume</span>
                              </div>
                            }
                            <>
                              {favouriteTal ?
                                <div className="d-flex option" onClick={() => setRemoveFromFavourite(true)}>
                                  <img src={removeFavorite} alt="" className=" ml-1 crossHeartIcon" />
                                  <span>Remove from Favorite</span>
                                </div>
                                :
                                <div className="d-flex option" onClick={updateInstrAsFavorite}>
                                  <img src={favourite} alt="" className="mr-2 ml-1" />
                                  <span>Add to Favorite</span>
                                </div>
                              }
                            </>

                          </div>
                        </div>
                      }
                    </div>
                  }
                </div>
                <div className="freelancerProposalDetailTIme ">
                  <span className="mr-1">
                    {myInformation ? client ? getAddressString(myInformation) : myInformation.country : ""}
                  </span>
                  {
                    myInformation?.zoneDisplay &&
                    <>
                      <span className="proposalDetailPipe">|</span>
                      <span>Time Zone: {myInformation?.zoneDisplay}</span>
                    </>
                  }
                </div>
                <div className=" freelancerProposalDetailRole font-weight-bold">
                  {proposalData?.roles}
                </div>
                {
                  !fromTalent && !client &&
                  <ProposalDetails
                    proposalData={proposalData}
                    tab={tab}
                  />
                }
                {
                  client &&
                  <AboutMe aboutMe={aboutMe} fromTalent={fromTalent} client={client} />
                }
              </div>

            </div>

          </div>
          <RelatedPortfolioSection
            linksAndVideos={proposalData?.portfolioLinks}
            portfolioItems={proposalData?.portfolioItems}
            relatedCourses={proposalData?.portfolioCourses}
            instrId={instrID}
          />
          <div className="freelancerdetailProposalDescriptiomWrapper">
            <div className="proposalDetailsButtonWrapperMobile">
              {
                proposalData?.resumeBlobId &&
                <Button
                  className={"btn-rqst small-btn mr-2"}
                  label={"Download Resume"}
                  onClick={() => downloadResume(proposalData)}
                />
              }
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default UserDetails;