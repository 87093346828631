export const SET_UPLOAD_IN_BACKGROUND = "SET_UPLOAD_IN_BACKGROUND";

export const initialState = {
  upload:false
}

const backgroudProgressReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_UPLOAD_IN_BACKGROUND: {
      return {...payload};
    }
    default:
      return state;
  }
};

export const startBackgroundUpload = (payload) => {
  return (dispatch) => {
    dispatch({
      type: SET_UPLOAD_IN_BACKGROUND,
      payload,
    });
  };
};


export default backgroudProgressReducer;
