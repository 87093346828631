import React from 'react';
import classnames from "classnames";
import { DownloadDocByEntity } from '../../../../../services/portfolio';
import { fileLogo } from '../../../../../assets/images';
import { trimString } from '../../../../../util/trimString';
import formatBytes from '../../../../../util/getFileSize';
import "./Document.scss";

const Document = ({ attachedDoc, submitProposal, talent = false, isPublic }) => {
    
    const downloadDoc = (data) => {
        if (!isPublic) {
            DownloadDocByEntity(data.azurePath, "contact", (data.contactID || data?.contactId))
                .then((response) => {
                    const url = window.URL
                        .createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `${data.fileName}.${data?.fileTypeName}`);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                })
        }
    }
    return (
        <div className={attachedDoc && attachedDoc.length ? (!submitProposal ? "documentWrapper" : "proposaldocumentWrapper border-top") : "d-none"}>
            <div className="d-flex justify-content-between mb-4">
                <div className="font18Black boldFont">{talent ? "Job Reference Documents" : "Documents with Posting"}</div>
            </div>
            <div className="desktopView">
                <div className="row ml-2">
                    {attachedDoc?.map((data, idx) => {
                        return (
                            <div key={idx} className={talent ? "d-flex mr-4 mb-4 w-25" : "d-flex mr-4 mb-4 w-25"} onClick={() => downloadDoc(data)}>
                                <div className="relative mr-2 heightFitContent">
                                    <img src={fileLogo} alt="" className="docfileLogo " />
                                    <div className="absoluteDocExtension">
                                        {trimString(data?.fileTypeName?.toUpperCase(), 4)}
                                    </div>
                                </div>
                                <div>
                                    <div className={isPublic ? "font14Black" : "font14Blue"} title={data.fileName}>{trimString(data.fileName, 12)}</div>
                                    <div className="fileSize">{formatBytes(data.fileSize, 2)}</div>
                                </div>
                            </div>

                        )
                    })}
                </div>
            </div>
            <div className="mobileView">
                <div>
                    {attachedDoc?.map((data, idx) => {
                        return (
                            <div key={idx} className="d-flex pointer mr-4 mb-4" onClick={() => downloadDoc(data)}>
                                <div className="relative mr-2">
                                    <img src={fileLogo} alt="" className="docfileLogo" />
                                    <div className={classnames("absoluteDocExtension", talent ? "" : "absoluteDocExtension1")}>
                                        {trimString(data?.fileTypeName?.toUpperCase(), 4)}
                                    </div>
                                </div>
                                <div>
                                    <div className={isPublic ? "font14Black" : "font14Blue"}>{data.fileName.length > 15 ? data.fileName.slice(0, 15) + "..." : data.fileName}</div>
                                    <div className="fileSize">{(data.fileSize / 1000).toFixed(2)} MB</div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}
export default Document;