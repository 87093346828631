export const getFileExtension = (filename) => {
    // Returns extension of the filename given
    if (filename) {
        const splittedName = filename?.split(".");
        if (splittedName.length > 1) {
            return splittedName[splittedName.length - 1].toLowerCase();
        } else {
            return "--"
        }
    }
    return false
}