export const ALL_AGENCY_MEMBERS = "ALL_AGENCY_MEMBERS"

export const initialState = ""

const allAgencyMemberReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case ALL_AGENCY_MEMBERS:
            return payload
        default:
            return state;
    }
}

export const setAllAgencyMember = payload => {
    return dispatch => {
        dispatch({
            type: ALL_AGENCY_MEMBERS,
            payload
        })
    }
}

export default allAgencyMemberReducer