/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useEffect, useRef, useState } from "react";
import { postUnregisteredSkill } from "../../services/rolesandskills";
import { useOnClickOutside } from "../navbar/Navbar";
import AddCustomSkills from "../addCustomSkill/addCustomSkill";
import Button from "../button";
import Chips from "../chips/Chips";
import Label from "../label";
import Modal from "../modal";
import TextInput from "../textInput";
import useWindowSize from "../../util/useWindowSize";
import { blueRightArrow, down } from "../../assets/images";
import "./browseSkill.scss";

const BrowseAndSelectSkill = ({
    showModal,
    hideModal,
    selectedSkillsProps,
    allSkillsAndCategories,
    onSave,
    hideCustom
}) => {
    const [technicalCategory, setTechnicalCategory] = useState([])
    const [softSkillCategory, setSoftSkillCategory] = useState([])
    const [allTechnicalSkills, setAllTechnicalSkills] = useState([])
    const [categorySpecificSkills, setCategorySpecificSkills] = useState([])
    const [allSoftSkills, setAllSoftSkills] = useState([])
    const [tabSelected, setTabSelected] = useState(null)
    const [categorySelected, setCategorySelected] = useState({})
    const [selectedSkills, setSelectedSkills] = useState([])
    const [selectedSkillsName, setSelectedSkillsName] = useState([])
    const [hoveredCategory, setHoveredCategory] = useState(null)
    const [countObj, setCountObj] = useState({})
    const [keywordSearchResults, setKeywordSearchResults] = useState([])
    const [searchText, setSearchText] = useState(null);
    const [openCategory, setOpenCategory] = useState([]);
    const [showAddCustomModal, setShowAddCustomModal] = useState(false);
    const [customSkillName, setCustomSkillName] = useState("");
    const [technicalCategoryToShow, setTechnicalCategoryToShow] = useState(12);
    const [softSkillCategoryToShow, setSoftSkillCategoryToShow] = useState(12);
    const [skillsToBeRemoved, setSkillsToBeRemoved] = useState([])

    const [width,] = useWindowSize();
    const ref = useRef()

    useEffect(() => {
        const selectedSkillsTempArray = selectedSkillsProps?.reduce((acc, res) => {
            if (res.isUnapprovedSkill) {
                acc.push({ "skillId": res.skillID, "skill": res.name });
            }
            return acc;
        }, []) || [];
        const selectecSkillsNameTempArray = selectedSkillsProps?.reduce((acc, res) => {
            if (res.isUnapprovedSkill) {
                acc.push(res.name);
            }
            return acc;
        }, []) || [];
        const selectedIDs = selectedSkillsProps?.map((res) => {
            return res.skillID
        })
        const tempCountObj = {}
        allSkillsAndCategories.forEach((res) => {
            if (selectedIDs.includes(res.skillId)) {
                selectecSkillsNameTempArray.push(res.skill)
                if (!res.group) {
                    selectedSkillsTempArray.push({ ...res, group: "Other" })
                    tempCountObj["Other"] = tempCountObj["Other"] ? tempCountObj["Other"] + 1 : 1
                } else {
                    selectedSkillsTempArray.push(res)
                    tempCountObj[res.group] = tempCountObj[res.group] ? tempCountObj[res.group] + 1 : 1
                    setCountOfCategories(res)
                }
            }
        })
        setCountObj(tempCountObj)
        setSelectedSkillsName(selectecSkillsNameTempArray)
        setSelectedSkills(selectedSkillsTempArray)
    }, [showModal])

    useEffect(() => {
        if (width <= 550) {
            if (!tabSelected) {
                setCategorySelected(technicalCategory[0])
                setTabSelected("Technical")
            }
        }
    }, [width, showModal])

    useEffect(() => {
        if (keywordSearchResults.length === 0 && width >= 550) {
            setFocus(categorySelected?.group + "browse")
        }
    }, [tabSelected])

    useEffect(() => {
        if (selectedSkillsName.length > 0 && width >= 550) {
            if (keywordSearchResults.length > 0) {
                const elem = document.getElementById(selectedSkills[selectedSkillsName.length - 1].skill + "browse")
                if (elem) {
                    elem.focus()
                }
                setTimeout(() => {
                    setFocus(selectedSkills[selectedSkillsName.length - 1].group + "browse")
                }, 200)
            }
        }

    }, [selectedSkillsName])

    useEffect(() => {
        getAllTechnicalCategories()
        getAllSoftSkillCategories()
    }, [allSkillsAndCategories])

    useEffect(() => {
        if (!showModal) {
            setTabSelected(null)
            setCountObj({})
            setSkillsToBeRemoved([])
        }

    }, [showModal])


    const setFocus = (element) => {
        const n = document.getElementById(element)
        if (n) {
            n.scrollIntoView({
                behavior: "smooth",
                block: "center",
                inline: "nearest",
            });

            setKeywordSearchResults([])
        }
    }
    const handleMobileOptionClick = (data) => {
        if (width < 550) {
            setOpenCategory([
                ...openCategory,
                data.group
            ])
            setTimeout(() => {
                setFocus(`${data?.skill}Mobile`)
            }, 200)
        }

    }
    const setCountOfCategories = (data) => {
        if (countObj[data.group]) {
            const temp = {
                [data.group]: countObj[data.group] + 1
            }
            setCountObj({
                ...countObj,
                ...temp
            })
            return
        } else {
            const temp = {
                [data.group]: 1
            }
            setCountObj({
                ...countObj,
                ...temp
            })
            return
        }
    }

    const getAllTechnicalCategories = () => {
        const tempList = []
        const allTechSkill = []
        const allTechSkillData = []
        allSkillsAndCategories.filter((res) => {
            if (res.discipline === "Technical" && res.skill) {
                if (res.group) {
                    allTechSkill.push(res)
                } else {
                    allTechSkill.push({
                        ...res,
                        group: "Other"
                    })
                }

            }
            if (res.discipline === "Technical" && !tempList.includes(res.group || "Other") && res.skill) {
                tempList.push(res.group || "Other")
                if (res.group) {
                    allTechSkillData.push(res)

                } else {
                    allTechSkillData.push({
                        ...res,
                        group: "Other"
                    })
                }
            }
        })
        setAllTechnicalSkills(allTechSkill)
        setTechnicalCategory(allTechSkillData)
    }

    const getAllSoftSkillCategories = () => {
        const tempList = []
        const allSoftSkills = []
        const allSoftSkillsData = []
        allSkillsAndCategories.forEach((res) => {
            if (res.group && res.discipline === "Soft Skills" && !tempList.includes(res.group) && res.skill) {
                tempList.push(res.group)
                allSoftSkillsData.push(res)
            }
            if (res.group && res.discipline === "Soft Skills" && res.skill) {
                allSoftSkills.push(res)
            }
        })
        setAllSoftSkills(allSoftSkills)
        setSoftSkillCategory(allSoftSkillsData)
    }

    const showSkillsOfCategory = (data, category, instantUpdate) => {
        setTabSelected(category)
        setCategorySelected(data)
        if (width >= 550) {
            if (category === "Technical") {
                const skills = allTechnicalSkills.filter((res) => {
                    if (data.group === res.group) {
                        return data
                    }
                })
                if (instantUpdate) {
                    setTechnicalCategory([
                        ...technicalCategory,
                        { ...data, isUnapprovedSkill: true }
                    ])
                    setCategorySpecificSkills([
                        ...skills,
                        { ...data, isUnapprovedSkill: true }
                    ])
                    setFocus(data?.group + "browse")
                } else {
                    setCategorySpecificSkills(skills)
                }

            } else {
                const skills = allSoftSkills.filter((res) => {
                    if (data.group === res.group) {
                        return data
                    }
                })
                if (instantUpdate) {
                    setSoftSkillCategory([
                        ...softSkillCategory,
                        { ...data, isUnapprovedSkill: true }
                    ])
                    setCategorySpecificSkills([
                        ...skills,
                        { ...data, isUnapprovedSkill: true }
                    ])
                    setFocus(data?.group + "browse")
                } else {
                    setCategorySpecificSkills(skills)
                }
            }
        }

    }

    const handleSkillChip = (data) => {
        const tempSkillName = [...selectedSkillsName]
        const tempSkills = [...selectedSkills]
        const removedSkillName = [...skillsToBeRemoved]
        if (selectedSkillsName.includes(data.skill)) {
            const idx = tempSkillName.indexOf(data.skill)
            if (idx > -1) {
                tempSkillName.splice(idx, 1)
                tempSkills.splice(idx, 1)
                setSkillsToBeRemoved([
                    ...skillsToBeRemoved,
                    data
                ])
            }
            if (countObj[data.group]) {
                const temp = {
                    [data.group]: countObj[data.group] - 1
                }
                setCountObj({
                    ...countObj,
                    ...temp
                })
            }
            setSelectedSkillsName(tempSkillName)
            setSelectedSkills(tempSkills)
        } else {
            const idx = removedSkillName.indexOf(data)
            if (idx > -1) {
                removedSkillName.splice(idx, 1)
                setSkillsToBeRemoved(removedSkillName)
            }
            setCountOfCategories(data)
            setSelectedSkillsName([
                ...selectedSkillsName,
                data?.skill
            ])
            setSelectedSkills([
                ...selectedSkills,
                data
            ])
        }
    }

    const handleSkillKeywordSearch = (text) => {
        const perfectMatch = []
        const includeMatch = []
        if (text.length > 0) {
            allSkillsAndCategories.forEach((data) => {
                if (data.skill.toLowerCase().startsWith(text.toLowerCase()) && data.discipline) {
                    perfectMatch.push({
                        ...data,
                        group: data.group || "Other"
                    })
                } else if (data.skill.toLowerCase().includes(text.toLowerCase()) && data.discipline) {
                    includeMatch.push({
                        ...data,
                        group: data.group || "Other"
                    })
                }
            })
            setKeywordSearchResults([
                ...perfectMatch,
                ...includeMatch
            ])
        }
    }

    const handleSkillOptionClick = (data) => {
        if (!selectedSkillsName.includes(data.skill)) {
            handleSkillChip(data)

        }
    }

    const openCategoryOption = (group) => {
        const tempList = [...openCategory]
        if (openCategory.includes(group)) {
            const idx = tempList.indexOf(group)
            if (idx > -1) {
                tempList.splice(idx, 1)
            }
            setOpenCategory(tempList)
        } else {
            setOpenCategory([
                ...openCategory,
                group
            ])
        }
    }

    const addCustomSkills = (data) => {
        setCustomSkillName(data)
        setShowAddCustomModal(true)

    }

    const toggleCustomSkillModal = () => {
        setShowAddCustomModal(false)
    }

    const onAddCustomAddSkillSubmit = (data) => {
        const payload = {
            "skill": customSkillName,
            ...data
        }
        postUnregisteredSkill(payload)
            .then((res) => {
                const response = {
                    ...res.data,
                    ...data,
                    group: data.otherVendorName || data.group
                }

                setTabSelected(payload.discipline)

                const elem = document.getElementById(payload.skill + "browse")
                if (elem) {
                    elem.focus()
                }

                if (payload.discipline === "Soft Skills") {
                    setAllSoftSkills([
                        ...allSoftSkills,
                        response
                    ])
                    showSkillsOfCategory(response, "Soft Skills", true)
                    handleSkillChip({ ...response, isUnapprovedSkill: true })

                } else {
                    setAllTechnicalSkills([
                        ...allTechnicalSkills,
                        response
                    ])
                    showSkillsOfCategory(response, "Technical", true)
                    handleSkillChip({ ...response, isUnapprovedSkill: true })

                }
                toggleCustomSkillModal()
            })
    }

    const onBrowseClose = () => {
        onSave({
            skillToBeRemoved: skillsToBeRemoved,
            selectedSkillIds: selectedSkills
        })
    }

    useOnClickOutside(ref, (event) => {
        if (event.target.className !== "keyWordSearchWrapp") {
            setSearchText("")
        }
    })
    return (
        <>

            <Modal
                isOpen={showModal}
                size="browseSkills"
                hideCloseButton={true}
                toggleModal={() => {
                    hideModal();
                }}
                title={
                    <Label
                        label={"Browse & Select Skills"}
                        className="font18Black boldFont"
                    />
                }
                disableBackdropToggle={false}
            >
                <AddCustomSkills
                    isOpen={showAddCustomModal}
                    newSkill={customSkillName}
                    vendorList={technicalCategory.map((res) => { return res.group })}
                    categoryList={softSkillCategory.map((res) => { return res.group })}
                    onSubmit={(data) => { onAddCustomAddSkillSubmit(data) }}
                    toggleModal={toggleCustomSkillModal}
                />
                <div className="mt-3 border-top mobileView" />
                {
                    tabSelected ?
                        <>
                            <div className="browseHeader">
                                <div className="categoryWrapp">
                                    <div
                                        className={
                                            tabSelected === "Technical" ?
                                                "activeCatName" :
                                                "catName"
                                        }
                                        onClick={() => {
                                            showSkillsOfCategory(technicalCategory[0], "Technical")
                                        }}
                                    >

                                        Technical Skills
                                    </div>
                                    <div className="verticalSeperator" />
                                    <div
                                        className={
                                            tabSelected === "Soft Skills"
                                                ? "activeCatName" :
                                                "catName"
                                        }
                                        onClick={() => {
                                            showSkillsOfCategory(softSkillCategory[0], "Soft Skills")
                                        }}
                                    >
                                        Soft Skills
                                    </div>
                                </div>
                                <div className="keyWordSearchWrapp" ref={ref}>
                                    <TextInput
                                        name="title"
                                        containerClass={""}
                                        type="searchIcon"
                                        placeholder="Keyword Search"
                                        className="overFlowSearchPadding"
                                        onChange={(e) => {
                                            setSearchText(e.target.value)
                                            handleSkillKeywordSearch(e.target.value)
                                        }}
                                        value={searchText}
                                        showCross={searchText?.length > 0}
                                        onCrossClick={() => {
                                            setSearchText("")
                                        }}
                                        onKeyPress={(e) => {
                                            if (e.key === "Enter") {
                                                if (searchText?.length > 0 && keywordSearchResults.length > 0) {
                                                    showSkillsOfCategory(keywordSearchResults[0], keywordSearchResults[0].discipline)
                                                    setSearchText("")
                                                    handleSkillOptionClick(keywordSearchResults[0])
                                                    handleMobileOptionClick(keywordSearchResults[0])
                                                } else if (searchText?.length > 0) {
                                                    addCustomSkills(searchText)
                                                }
                                            }
                                        }}
                                        onSearchClick={() => {
                                            if (searchText?.length > 0 && keywordSearchResults.length > 0) {
                                                showSkillsOfCategory(keywordSearchResults[0], keywordSearchResults[0].discipline)
                                                setSearchText("")
                                                handleSkillOptionClick(keywordSearchResults[0])
                                                handleMobileOptionClick(keywordSearchResults[0])
                                            }
                                        }}
                                    />
                                    <div className={searchText?.length > 0 ? "searchResultCard" : "d-none"}>

                                        {keywordSearchResults?.length > 0 ?
                                            <>
                                                {
                                                    keywordSearchResults?.map((data, idx) => {
                                                        return (
                                                            <div
                                                                key={idx}
                                                                className="skillNameFoundWrapp"
                                                                onClick={() => {
                                                                    showSkillsOfCategory(data, data.discipline)
                                                                    setSearchText("")
                                                                    handleSkillOptionClick(data)
                                                                    handleMobileOptionClick(data)

                                                                }}
                                                            >
                                                                {data.skill} ({data.group})
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </> :
                                            <div
                                                className="skillNameFoundWrapp d-flex justify-content-between"
                                                onClick={() => {
                                                    setSearchText("")
                                                }}
                                            >
                                                {
                                                    hideCustom ?
                                                        <span className="smallNormalFont">We could not find “{searchText}”</span> :
                                                        <>
                                                            <span className="smallNormalFont">We could not find “{searchText}”. Do you wish to add this skill ?</span>
                                                            <span className="font12Blue pr-3 noWrap" onClick={() => addCustomSkills(searchText)}>Add Skill</span>
                                                        </>
                                                }

                                            </div>


                                        }

                                    </div>
                                </div>
                            </div>
                            <div className="border-top mt-3 desktopView" />
                            {
                                width >= 550 ?
                                    <div className="skillInnerWrap">
                                        <div className="categoryListWrapp">
                                            {
                                                (tabSelected === "Technical" ? technicalCategory : softSkillCategory).map((data, idx) => {
                                                    return (
                                                        <React.Fragment key={idx}>
                                                            <div
                                                                className={
                                                                    categorySelected?.group === data.group ?
                                                                        "activeFilterCatName" :
                                                                        "filterCatName"
                                                                }
                                                                onClick={() => showSkillsOfCategory(data, data.discipline)}
                                                                id={data.group + "browse"}
                                                            >
                                                                <div className="titleFlex">
                                                                    <span>{data.group}</span>
                                                                    <span className="countObjWrapp">{countObj[data.group] > 0 ? countObj[data.group] : ""}</span>
                                                                </div>

                                                            </div>
                                                        </React.Fragment>
                                                    )
                                                })
                                            }
                                        </div>
                                        <div className="skillAndCatSeperator" />
                                        <div className="skillNameWrap">
                                            <div className="categoryHeader">
                                                {categorySelected?.group}
                                            </div>
                                            <div className="categoryNameWrapp">
                                                {
                                                    categorySpecificSkills.map((data, idx) => {
                                                        return (
                                                            <React.Fragment key={idx}>
                                                                <Chips
                                                                    data={selectedSkillsName}
                                                                    label={data?.skill}
                                                                    className="mt-2 mr-2 newChipsBtn"
                                                                    onClick={(e) => {
                                                                        handleSkillChip(data)
                                                                    }}
                                                                    isUnapprovedSkill={data?.isUnapprovedSkill}
                                                                    id={`${data?.skill}browse`}
                                                                    clickable={true}
                                                                />
                                                            </React.Fragment>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div> :
                                    <div className="mobileCatList">
                                        {
                                            (tabSelected === "Technical" ? technicalCategory : softSkillCategory).map((data, index) => {
                                                return (
                                                    <React.Fragment key={index}>
                                                        <div
                                                            className="mobileCatWrapp"
                                                            onClick={() => {
                                                                openCategoryOption(data.group)
                                                            }}
                                                        >
                                                            <div className="mobileCatNameFont">{data.group}</div>
                                                            <div className="d-flex">
                                                                <div className="titleFlex">
                                                                    <span className="countObjWrapp mr-4">{countObj[data.group] > 0 ? countObj[data.group] : ""}</span>
                                                                </div>
                                                                <img
                                                                    src={down}
                                                                    alt=""
                                                                    className={
                                                                        openCategory.includes(data.group) ?
                                                                            "openedIndication" :
                                                                            ""}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className={openCategory.includes(data.group) ? "d-flex flex-wrap" : "d-none"}>
                                                            {
                                                                allSkillsAndCategories.map((res, idx) => {
                                                                    <React.Fragment key={idx}>
                                                                        {
                                                                            res.group === data.group ?
                                                                                <Chips
                                                                                    data={selectedSkillsName}
                                                                                    label={res?.skill}
                                                                                    className={"mt-2 mr-2 newChipsBtn"}
                                                                                    isUnapprovedSkill={data?.isUnapprovedSkill}
                                                                                    onClick={(e) => {
                                                                                        handleSkillChip(res)
                                                                                    }}
                                                                                    id={`${res?.skill}Mobile`}
                                                                                    clickable={true}
                                                                                />

                                                                                : ""
                                                                        }
                                                                    </React.Fragment>

                                                                })
                                                            }
                                                        </div>
                                                        <div className="border-top mt-2 mb-2" />
                                                    </React.Fragment>
                                                )
                                            })
                                        }
                                    </div>
                            }
                            <div
                                className="border-top Buttonshadow"
                                key={"Instructor"}
                            >
                                <div className="browseSkillSaveButton">
                                    <Button
                                        label="Cancel"
                                        onClick={() => hideModal()}
                                        className="btn-light mx-2 cancelButtonDelete"
                                    />
                                    <Button
                                        label="Select"
                                        className="btn-blue"
                                        onClick={() => {
                                            onBrowseClose();
                                        }}
                                        disabled={selectedSkills.length === 0}
                                    />
                                </div>
                            </div>
                        </> :
                        <div className="categoryOuterWrapp">
                            <div>
                                <div className="vendorCatHeader">
                                    <Label
                                        label={"Technical Skills By Vendor"}
                                        className="font18Black boldFont mb-0"
                                    />
                                </div>
                                <div className="categoryNameWrapp">
                                    {
                                        technicalCategory.slice(0, technicalCategoryToShow).map((res, idx) => {
                                            return (
                                                <div className="smallBlueFont" key={idx}>
                                                    <span
                                                        className={hoveredCategory === res.group ? "pointer font-weight-bold d-flex" : "pointer d-flex"}
                                                        onClick={() => showSkillsOfCategory(res, res.discipline)}
                                                        onMouseEnter={() => setHoveredCategory(res.group)}
                                                        onMouseLeave={() => setHoveredCategory(null)}
                                                    >
                                                        <span>{res.group}</span>
                                                        <img src={blueRightArrow} alt="" className={hoveredCategory === res.group ? "arrowRight" : "d-none"} />

                                                    </span>
                                                    <div className="border-top mt-2" />
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                {
                                    (technicalCategory.length > technicalCategoryToShow) &&
                                    <div
                                        className="showMoreCategoryText font15Blue"
                                        onClick={() => setTechnicalCategoryToShow(technicalCategory.length)}
                                    >
                                        Show More
                                    </div>
                                }
                                {
                                    (technicalCategory.length === technicalCategoryToShow) &&
                                    <div
                                        className="showMoreCategoryText font15Blue"
                                        onClick={() => setTechnicalCategoryToShow(12)}
                                    >
                                        Show Less
                                    </div>
                                }
                            </div>
                            <div>
                                <div className="vendorCatHeader">
                                    <Label
                                        label={"Soft Skills By Category"}
                                        className="font18Black boldFont mb-0"
                                    />
                                </div>
                                <div className="categoryNameWrapp">
                                    {
                                        softSkillCategory.slice(0, softSkillCategoryToShow).map((res, idx) => {
                                            return (
                                                <div className="smallBlueFont" key={idx}>
                                                    <span
                                                        className={hoveredCategory === res.group ? "pointer font-weight-bold d-flex" : "pointer d-flex"}
                                                        onClick={() => showSkillsOfCategory(res, res.discipline)}
                                                        onMouseEnter={() => setHoveredCategory(res.group)}
                                                        onMouseLeave={() => setHoveredCategory(null)}
                                                    >
                                                        {res.group}
                                                        <img src={blueRightArrow} alt="" className={hoveredCategory === res.group ? "arrowRight" : "d-none"} />
                                                    </span>
                                                    <div className="border-top mt-2" />
                                                </div>
                                            )

                                        })
                                    }
                                </div>
                                {
                                    (softSkillCategory.length > softSkillCategoryToShow) &&
                                    <div
                                        className="showMoreCategoryText font15Blue"
                                        onClick={() => setSoftSkillCategoryToShow(softSkillCategory.length)}
                                    >
                                        Show More
                                    </div>
                                }
                                {
                                    (softSkillCategory.length === softSkillCategoryToShow) &&
                                    <div
                                        className="showMoreCategoryText font15Blue"
                                        onClick={() => setSoftSkillCategoryToShow(12)}
                                    >
                                        Show Less
                                    </div>
                                }
                            </div>
                        </div>
                }

            </Modal>
        </>
    )
}

export default BrowseAndSelectSkill;
