export const SET_JOB_COMPLETED = "SET_JOB_COMPLETED";
export const DELETE_JOB_COMPLETED_STEPS = "DELETE_JOB_COMPLETED_STEPS";

export const initialState = {}
const completedJobStepsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_JOB_COMPLETED:
      return {...payload} ;
    case DELETE_JOB_COMPLETED_STEPS:
      return {};
    default:
      return state;
  }
};


export const addJobCompletedSteps = (payload) => {
  return (dispatch) => {
    dispatch({
      type: SET_JOB_COMPLETED,
      payload,
    });
  };
};

export const removeJobCompletedSteps = () => {
  return (dispatch) => {
    dispatch({
      type: DELETE_JOB_COMPLETED_STEPS,
      payload:{},
    });
  };
};


export default completedJobStepsReducer;
