const handleDropdownErrors = (dropDownFields, errors) => {
    if(errors) {
        const erroredField = Object.keys(errors)[0];
        if(dropDownFields.includes(erroredField)) {
            const n = document.getElementById(erroredField)
            if(n) {
                n.scrollIntoView({
                behavior: "smooth",
                block: "center",
                inline: "nearest",
                });
            }
        }
        }
}
export default handleDropdownErrors;


    