import * as img from "../assets/images/index";
import { HelpCenterURL } from "./legacySiteURL";
export const MenuLinks = [
  { name: "Dashboard", path: "/user/dashboard", subMenu: false, legacy: false },
  { name: "Job Center", path: "/jobcenter/6", subMenu: false, legacy: false },
  { name: "My Tasks", path: "/calendar-view", subMenu: false, legacy: false },
  {
    name: "Profiles",
    path: "/profile",
    active: "profile",
    legacy: false,
    subMenu: [
      {
        name: "Welcome",
        path: "/profile/welcome-user",
        active: "profile",
        img: img.welcomeLogo,
        imgActive: img.welcomeLogoActive,
      },
      {
        name: "My Information",
        path: "/profile/my-information",
        active: "profile",
        img: img.sideMenuWhiteCheck,
        imgActive: img.sideMenuCheck,
      },
      {
        name: "Company Information",
        path: "/profile/company-information",
        active: "profile",
        img: img.sideMenuWhiteCheck,
        imgActive: img.sideMenuCheck,
      },
      {
        name: "Roles",
        path: "/profile/roles",
        active: "profile",
        img: img.sideMenuWhiteCheck,
        imgActive: img.sideMenuCheck,
      },
      {
        name: "Roles and Skills",
        path: "/profile/roles-and-skills",
        active: "profile",
        img: img.sideMenuWhiteCheck,
        imgActive: img.sideMenuCheck,
      },
      {
        name: "Work Experience",
        path: "/profile/work-experience",
        active: "profile",
        img: img.sideMenuWhiteCheck,
        imgActive: img.sideMenuCheck,
      },
      {
        name: "Education",
        path: "/profile/education",
        active: "profile",
        img: img.sideMenuWhiteCheck,
        imgActive: img.sideMenuCheck,
      },
      {
        name: "Certifications",
        path: "/profile/certifications",
        active: "profile",
        img: img.sideMenuWhiteCheck,
        imgActive: img.sideMenuCheck,
      },
      {
        name: "Portfolio",
        path: "/profile/portfolio",
        active: "profile",
        img: img.sideMenuWhiteCheck,
        imgActive: img.sideMenuCheck,
      },
      {
        name: "About Me",
        path: "/profile/about-me",
        active: "profile",
        img: img.sideMenuWhiteCheck,
        imgActive: img.sideMenuCheck,
      },
    ],
  },
  { name: "Help Center", path: HelpCenterURL, subMenu: false, legacy: true },
  { name: "Feedback", path: "", mobile: true, subMenu: false, legacy: false },
];
