/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  fileLogo,
  leftScrollIcon,
  pdfPortfolioThumbnail,
  portfolioDefaultImage,
  urlPortfolioThumbnail
} from "../../../../../assets/images/index";
import { DownloadBlobByEntity } from "../../../../../services/portfolio";
import { getFileExtension } from "../../../../../util/getFileExtension";
import ViewPortfolioModal from "../../../../Dashboard/Components/viewPortfolioModal";
import "./index.scss";
import "../Portfolio/index.scss"

const RelatedPortfolioSection = ({
  linksAndVideos,
  portfolioItems,
  relatedCourses,
  instrId,
  documents=[]
}) => {

  const [links, setLinks] = useState([])
  const [videos, setVideos] = useState([])
  const [items, setItems] = useState([])
  const [courses, setCourses] = useState([])
  const [viewPortfolioData, setViewPortfolioData] = useState({})
  const [thumbnailDict, setThumbnailDict] = useState({})

  useEffect(() => {
    if (linksAndVideos || portfolioItems || relatedCourses) {
      getLinksAndVideos()
    }
  }, [linksAndVideos, portfolioItems])

  const embedURL = (url) => {
    if (url?.includes(`vimeo.com/`)) {
      const vimeoPlayerURL = `https://player.vimeo.com/video/`;
      const embedVimeoID = url?.split("/").slice(-1)[0];
      return `${vimeoPlayerURL}${embedVimeoID}`;
    } else {
      const youtubePlayerURL = `//www.youtube.com/embed/`;
      var regExp =
        /^.*(youtu.be\/|v\/|u\/\w\/|shorts\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
      var match = url?.match(regExp);

      if (match && match[2].length === 11) {
        return `${youtubePlayerURL}${match[2]}?rel=0`;
      } else {
        return "error";
      }
    }
  };

  const showPortfolioModal = (url, portfolioData, type) => {
    setViewPortfolioData({
      showModal: true,
      src: url,
      title: portfolioData?.title,
      description: portfolioData?.description,
      type: type,
      portfolioData: portfolioData
    });
  };

  const getLinksAndVideos = () => {
    const videoResults = linksAndVideos?.filter(
      (data) => data?.urlTypeId === 13
    );
    const linkResults = linksAndVideos?.filter(
      (data) => data?.urlTypeId !== 13
    );
    setCourses(relatedCourses)
    setItems(portfolioItems)
    setVideos(videoResults)
    setLinks(linkResults)
    getPortfolioThumbnail([...videoResults, ...linkResults, ...portfolioItems])

  };

  const getPortfolioThumbnail = (portfolioRecords) => {
    const tempDict = { ...thumbnailDict }
    portfolioRecords.forEach((portfolioData) => {
      if (portfolioData.thumbnail && !tempDict[portfolioData?.thumbnail]) {
        DownloadBlobByEntity(portfolioData.thumbnail, "instructor", instrId).then((response) => {
          const Imagedata = `data:${response?.headers["content-type"]
            };base64,${new Buffer(response?.data, "binary")?.toString(
              "base64"
            )}`;
          tempDict[portfolioData.thumbnail] = Imagedata
          setThumbnailDict({ ...tempDict })
        });
      }
    })
    setThumbnailDict({ ...tempDict })
  }

  const sideScroll = (direction, step = 15) => {
    const element = document.getElementById('scrollDivID');
    let scrollAmount = 0;
    if (element) {
      const slideTimer = setInterval(function () {
        if (direction === 'left') {
          element.scrollLeft -= step;
        } else {
          element.scrollLeft += step;
        }
        scrollAmount += step;
        if (scrollAmount >= 250) {
          window.clearInterval(slideTimer);
        }
      }, 25);
    }
  }


  const toggleModal = () => {
    setViewPortfolioData({})
  }

  const downloadDoc = (data) => {
    DownloadBlobByEntity(
      data?.documentBlobID,
      "instructor",
      instrId
      ).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", data?.fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
    return;
  };

  return (
    <>
      <ViewPortfolioModal
        viewPortfolioData={viewPortfolioData}
        toggleModal={toggleModal}
        handleEditPortfolio={() => { }}
        handleDeleteModal={() => { }}
        handleDeleteItem={() => { }}
        handleDeleteVideo={() => { }}
        edit={false}
        instrId={instrId}
        fromCourse={viewPortfolioData?.type === "Course"}
      />
      <div className="relative">
        <div className={[...links, ...items, ...videos, ...courses, ...documents]?.length > 4 ? "scroller" : "d-none"}>
          <div className="rightRelatedPortfolioScroll" onClick={() => sideScroll("right")}>
            <img
              src={leftScrollIcon}
              alt=""
              style={{ height: 28 }}
            />
          </div>
          <div className="leftRelatedPortfolioScroll" onClick={() => sideScroll("left")}>
            <img
              src={leftScrollIcon}
              alt=""
              style={{
                height: 28,
              }}
            />
          </div>
        </div>
        <div className={[...links, ...videos, ...items, ...documents]?.length > 1 ? "mobileScroller" : "d-none"}>
          <div className="rightPortfolioScroll" onClick={() => sideScroll("right")}>
            <img
              src={leftScrollIcon}
              alt=""
              style={{ height: 28 }}
            />
          </div>
          <div className="leftScroll" onClick={() => sideScroll("left")}>
            <img
              src={leftScrollIcon}
              alt=""
              style={{
                height: 28,
              }}
            />
          </div>
        </div>
        {
          [...links, ...items, ...videos, ...courses, ...documents]?.length > 0 &&
          <>
            <div className="font14Black boldFont mt-4">Related Portfolio Items</div>
            <div>
              {(
                <div className="portfolioListUserProfileView" id="scrollDivID">
                  {
                    documents.map((res) => {
                      return (
                        <div className="maxPortfolioWidth pointer mr-3" onClick={()=>downloadDoc(res)}>
                          <div className="relatedDocumentHolder">
                            <img src={fileLogo} alt="" className="portfoliodocfileLogo" />
                            <span className="extensionName">{getFileExtension(res.fileName).toUpperCase()}</span>
                          </div>
                          <div className="font14Black boldFont mt-1 portfolioStringTrimming">{res.fileName}</div>
                        </div>
                      )
                    })
                  }
                  {

                    links.map((linkData) => {
                      return (
                        <>
                          <div className="mr-3 maxPortfolioWidth" id={linkData.webID}>
                            <img
                              src={
                                thumbnailDict[linkData.thumbnail] ?
                                  thumbnailDict[linkData.thumbnail] :
                                  urlPortfolioThumbnail
                              }
                              className="portfolioListImgProfileView"
                              onClick={() => showPortfolioModal(null, linkData, "Link")}
                              alt="portfolio"
                            />
                            <div className="font14Black boldFont mt-1 portfolioStringTrimming">{linkData.title}</div>
                            <div className="font14Grey portfolioStringTrimming">{linkData.description}</div>
                          </div>
                        </>
                      )
                    })
                  }
                  {
                    videos.map((videoData, index) => {
                      return (
                        <>
                          <div className="mr-3 maxPortfolioWidth" id={index}>
                            {
                              thumbnailDict[videoData.thumbnail] ?
                                <img
                                  src={thumbnailDict[videoData.thumbnail]}
                                  className="portfolioListImgProfileView"
                                  onClick={() => showPortfolioModal(null, videoData, "Video")}
                                  alt="portfolioVideo"
                                /> :
                                <div className="relative">
                                  <iframe
                                    className="portfolioListImgProfileView"
                                    src={embedURL(videoData?.url)}
                                    frameborder="0"
                                    allowFullScreen
                                    title="portfolio"
                                  />
                                  <div
                                    className="hiddenDivisionVideoFrame"
                                    onClick={() => showPortfolioModal(embedURL(videoData?.url), videoData, "Video")}
                                  />
                                </div>
                            }
                            <div className="font14Black boldFont portfolioStringTrimming">{videoData.title}</div>
                            <div className="font14Grey portfolioStringTrimming">{videoData.description}</div>
                          </div>
                        </>
                      )
                    })
                  }
                  {
                    items.map((itemData) => {
                      return (
                        <>
                          <div className="mr-3 maxPortfolioWidth">
                            <img
                              src={
                                thumbnailDict[itemData.thumbnail] ?
                                  thumbnailDict[itemData.thumbnail] :
                                  pdfPortfolioThumbnail
                              }
                              className="portfolioListImgProfileView"
                              onClick={() => showPortfolioModal(null, itemData, "File")}
                              alt="portfolio"
                            />
                            <div className="font14Black boldFont mt-1 portfolioStringTrimming">{itemData.title}</div>
                            <div className="font14Grey portfolioStringTrimming">{itemData.description}</div>
                          </div>
                        </>
                      )
                    })
                  }
                  {
                    courses.map((linkData) => {
                      return (
                        <>
                          <div className="mr-3 maxPortfolioWidth" id={linkData.webID}>
                            <img
                              src={
                                thumbnailDict[linkData.thumbnail] ?
                                  thumbnailDict[linkData.thumbnail] :
                                  portfolioDefaultImage
                              }
                              className="portfolioListImgProfileView"
                              onClick={() => showPortfolioModal(null, linkData, "Course")}
                              alt="portfolio"
                            />
                            <div className="font14Black boldFont mt-1 portfolioStringTrimming">{linkData.title}</div>
                            <div className="font14Grey portfolioStringTrimming">{linkData.description}</div>
                          </div>
                        </>
                      )
                    })
                  }
                </div>
              )}
            </div>
          </>
        }

      </div>
    </>
  );
};

export default RelatedPortfolioSection;