export const LETS_GET_STARTED_SAVE = "LETS_GET_STARTED_SAVE";

export const saveLetsGetStarted = (payload) => ({
  type: LETS_GET_STARTED_SAVE,
  payload,
});

export const initialState = {
  FirstName: "",
  LastName: "",
};

const LetsGetStartedReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case LETS_GET_STARTED_SAVE:
      return { ...state, ...payload };

    default:
      return state;
  }
};

export default LetsGetStartedReducer;
