import React from "react";
import classnames from "classnames";
import { rightIcon, close } from "../../assets/images";
import "./chips.css";

let Chips = ({
  className,
  onClick,
  label,
  data,
  disabled,
  style,
  language,
  languageName,
  languageProficiency,
  handleDelete,
  onClickEdit,
  keyValue,
  icon,
  size,
  id,
  objdata,
  addIndustryCustomChip,
  rolesObject,
  isUnapprovedSkill,
  onCrossClick,
  labelClass,
  browseSkillChip,
  clickable,
  title
}) => {
  return (
    <React.Fragment>
      {!language && !objdata && !rolesObject && (
        <button
          className={classnames(
            "btn chipsBtn d-flex align-items-center text-center",
            className,
            data?.includes(label)
              ? addIndustryCustomChip
                ? "chipBtnActiveIndustry text-center" :
                (browseSkillChip && !isUnapprovedSkill) ?
                  "browseSkillBtnActive" :
                  (browseSkillChip && isUnapprovedSkill) ?
                    "browseSkillUnapporovedActive"
                    : "chipBtnActive text-center"
              : "text-center",
            size === "small" ? "chipsBtnSmall" : (browseSkillChip && !data?.includes(label)) ? "browseSkillButton" : "chipsBtn"
          )}
          style={{
            ...style,
          }}
          id={id}
          disabled={disabled}
          onClick={onClick}
          type="button"
          title={title}
        >
          {
            (clickable) ?
              <span
                className={classnames(
                  labelClass,
                  data?.includes(label)
                    ? "newChipsBtnTextActive" :
                    "newChipsBtnText",

                  icon === true ? "ml-auto pl-2" : ""
                )}
              >
                {label}
              </span> :
              <span
                className={classnames(
                  labelClass,
                  data?.includes(label)
                    ? addIndustryCustomChip
                      ? "chipsBtnTextActiveIndustry text-center"
                      : "chipsBtnTextActive text-center"
                    : "chipsBtnText ml-auto",

                  icon === true ? "ml-auto pl-4" : ""
                )}
              >
                {label}
              </span>
          }

          {data?.includes(label) &&
            (icon ? (
              <img
                src={rightIcon}
                className={
                  addIndustryCustomChip ? "ml-auto " : "ml-auto"
                }
                alt=""
                style={{ position: "absolute", right: "10px" }}
              ></img>
            ) : browseSkillChip ?
              <img
                src={close}
                className={
                  ""
                }
                alt=""
                onClick={onCrossClick}
                style={{ position: "absolute", right: "10px" }}
              ></img>
              : (
                <img
                  src={close}
                  className={
                    addIndustryCustomChip ? "ml-auto " : "ml-auto"
                  }
                  alt=""
                  onClick={onCrossClick}
                  style={{ position: "relative", right: "10px" }}
                ></img>
              ))}
        </button>
      )}

      {objdata && (
        <button
          className={classnames(
            size === "small" ? "chipsBtnSmall" : "chipsBtn",
            "btn chipsBtn p-0 d-flex justify-content-center align-items-center ",
            className,

            objdata.find(
              (o) => o.roleId === label.roleId || o.id === label.roleId
            )
              ? "chipBtnActive "
              : "text-center",

          )}
          style={{
            ...style,
          }}

          id={id}
          disabled={disabled}
          onClick={onClick}
          type="button"
          title={title}
        >
          <span
            className={classnames(
              (objdata.find(
                (o) => o.roleId === label.roleId || o.id === label.roleId
              ) && clickable)
                ? "newChipsBtnTextActive justify-content-center text-center" :
                (objdata.find(
                  (o) => o.roleId === label.roleId || o.id === label.roleId
                ) && !clickable) ? "chipsBtnTextActive justify-content-center text-center"
                  : clickable ? "newChipsBtnText" : "chipsBtnText ml-auto",

              icon === true ? "ml-auto" : "pl-2 pr-2",
            )}
          >
            {label.role}
          </span>

          {objdata.find(
            (o) => o.roleId === label.roleId || o.id === label.roleId
          ) &&
            (icon ? (
              <img
                src={rightIcon}
                className="ml-auto"
                alt=""
                style={{ position: "relative", right: "10px" }}
              ></img>
            ) : (
              <img
                src={close}
                className="ml-auto"
                style={{ position: "relative", right: "10px" }}
                alt=""
              ></img>
            ))}
        </button>
      )}

      {rolesObject && (
        <button
          className={classnames(
            className,
            size === "small" ? "chipsBtnSmall" : "chipsBtn",
            "btn chipsBtn p-0 d-flex justify-content-center align-items-center ",
            rolesObject.find(
              (o) => o.role === label.role
            )
              ? "chipBtnActive "
              : "text-center",

          )}
          style={{
            ...style,
          }}
          id={id}
          disabled={disabled}
          onClick={onClick}
          type="button"
          title={title}
        >
          <span
            className={classnames(
              rolesObject.find(
                (o) => o.role === label.role
              )
                ? "newChipsBtnTextActive justify-content-center text-center"
                : "newChipsBtnText ml-auto",

              icon === true ? "ml-auto" : "pl-2 pr-2"
            )}
          >
            {label.role}
          </span>

          {rolesObject.find(
            (o) => o.role === label.role
          ) &&
            (icon ? (
              <img
                src={rightIcon}
                className="ml-auto"
                style={{ position: "relative", right: "10px" }}
                alt=""
              ></img>
            ) : (
              <img
                src={close}
                className="ml-auto"
                style={{ position: "relative", right: "10px" }}
                alt=""
              ></img>
            ))}
        </button>
      )}

      {language && (
        <button
          className={classnames(
            "btn chipsBtnLanguage px-2 d-flex  align-items-center chipBtnActiveLanguage",
            className
          )}
          style={{
            ...style,
          }}
          disabled={false}
          type="button"
          key={keyValue}
          title={title}
        >
          <div
            onClick={onClickEdit}
            className="d-block w-100 text-left"
            title="Edit"
          >
            <div className="chipsBtnTextLangName">{languageName}</div>
            <div className="chipsBtnTextLangProficiency">
              {languageProficiency}
            </div>
          </div>
          <span
            className="closeIconLanguage ml-auto text-right"
            onClick={handleDelete}
          />
        </button>
      )}
    </React.Fragment>
  );
};
export default Chips;
