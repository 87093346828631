// action types
export const EMAIL_SAVE = "EMAIL_SAVE";

// action creator
export const saveEmailForget = (payload) => ({
  type: EMAIL_SAVE,
  payload,
});

// selectors
export const authSelector = (state) => state.auth;

// initial state
export const initialState = {
  emailAddress: "",
};

// reducers
const authReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case EMAIL_SAVE:
      return { ...state, ...payload };

    default:
      return state;
  }
};

export default authReducer;
