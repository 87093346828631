/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { noRecords, portfolioDefaultImage } from "../../../assets/images";
import Button from "../../../components/button";
import CheckBox from "../../../components/checkbox/Checkbox";
import Modal from "../../../components/modal";
import TextInput from "../../../components/textInput";
import "./index.scss"

const SelectPortfolio = ({
    isOpen,
    toggleModal,
    allPortfolioItems,
    setSelectedPortfolios,
    selectedPortfolioIdProp,
    selectedPortfolioProp,
    embedURL
}) => {

    const [searchText, setSearchText] = useState("")
    const [submitClicked, setSubmitClicked] = useState(false)
    const [selectedPortfolioId, setSelectedPortfolioId] = useState([])
    const [selectedPortfolio, setSelectedPortfolio] = useState([])
    const [portfolioItems, setPortfolioItems] = useState([
        ...allPortfolioItems
    ])

    useEffect(() => {
        setPortfolioItems(allPortfolioItems)
    }, [allPortfolioItems])


    const submitPortfolio = () => {
        setSubmitClicked(true)
        if (selectedPortfolio.length === 0) {
            return
        } else {
            setSelectedPortfolios(selectedPortfolio)
        }
    }


    useEffect(() => {
        setSubmitClicked(false)
        setSearchText("")
        setPortfolioItems(allPortfolioItems)
        setSelectedPortfolioId(selectedPortfolioIdProp)
        setSelectedPortfolio(selectedPortfolioProp)
    }, [isOpen])

    useEffect(() => {
        if (searchText?.length === 0) {
            setPortfolioItems(allPortfolioItems)
        }
    }, [searchText])

    const searchPortfolio = (e) => {
        e.preventDefault();
        if (searchText.length > 0) {
            const results = [];
            for (var i = 0; i < allPortfolioItems.length; i++) {
                if (
                    allPortfolioItems[i]?.title && allPortfolioItems[i]?.title?.toLowerCase().startsWith(searchText.toLowerCase())
                ) {
                    results.push(allPortfolioItems[i]);
                }
            }
            for (var j = 0; j < allPortfolioItems.length; j++) {
                if (allPortfolioItems[j].title &&
                    !allPortfolioItems[j].title
                        .toLowerCase()
                        .startsWith(searchText.toLowerCase()) &&
                    allPortfolioItems[j].title.toLowerCase().includes(searchText.toLowerCase())
                ) {
                    results.push(allPortfolioItems[j]);
                }
            }
            setPortfolioItems(results);
        } else {
            setPortfolioItems([]);
        }
    };

    const addPortfolio = (res) => {
        const temp = [...selectedPortfolioId]
        const tempPort = [...selectedPortfolio]
        tempPort.push(res)
        temp.push(res.webID || res.courseID || res.docID)
        setSelectedPortfolioId(temp)
        setSelectedPortfolio(tempPort)
    }
    const removePortfolio = (res) => {
        const temp = [...selectedPortfolioId]
        const tempPort = [...selectedPortfolio]
        const idx = temp.indexOf(res.webID || res.courseID || res.docID)
        if (idx > -1) {
            temp.splice(idx, 1)
            tempPort.splice(idx, 1)
        }
        setSelectedPortfolio(tempPort)
        setSelectedPortfolioId(temp)
    }

    return (
        <Modal
            isOpen={isOpen}
            containerStyle={{
                width: "520px",
                maxWidth: "95%",
                minHeight: "455px",
                paddingBottom: "0px"
            }}
            size="medium"
            hideCloseButton={true}
            toggleModal={toggleModal}
            title={
                "Select from Portfolio"
            }
            disableBackdropToggle={true}
            containerClass={"addTimeLineModal"}
        >
            <div className="mt-3 border-top mobileSep" />
            <div className="portfolioContentWrapper mx-auto">
                <TextInput
                    name="title"
                    containerClass="mb-0"
                    className="overFlowPadding"
                    type="searchIcon"
                    placeholder="Keyword Search"
                    filterField={true}
                    value={searchText}
                    onChange={(e) => {
                        setSearchText(e.target.value);
                    }}
                    showCross={searchText.length > 0}
                    onCrossClick={() => {
                        setSearchText("")
                        setPortfolioItems(allPortfolioItems)
                    }}
                    onKeyPress={(e) => {
                        if (searchText?.length > 0) {
                            if (e.key === "Enter") {
                                searchPortfolio(e)
                            }
                        }
                    }}
                    onSearchClick={(e) => searchPortfolio(e)}
                />
                {
                    portfolioItems.length > 0 ?
                        <div className="portolioWrapper">
                            {
                                portfolioItems.map((res, odx) => {
                                    return (
                                        <>
                                            <div className="portfolioRow mt-2 mb-2">
                                                <div className="d-flex">
                                                    <CheckBox
                                                        name="ismyDoc"
                                                        containerClass="mt-4 mr-1 ml-1"
                                                        onChange={(e) => {
                                                            e.target.checked ? addPortfolio(res) : removePortfolio(res)
                                                        }}
                                                        checked={
                                                            selectedPortfolioId.includes(res?.webID || res?.courseID || res?.docID)
                                                                ? true
                                                                : false}
                                                        id={"checkbox" + (res?.webID || res?.courseID || res?.docID)}
                                                    />
                                                    <div>
                                                        {
                                                            res.urlTypeId === 13 ?
                                                                <iframe
                                                                    className="selectPortfolioThumbnail mr-3"
                                                                    src={embedURL(res?.url)}
                                                                    frameborder="0"
                                                                    allowFullScreen
                                                                    title="url"
                                                                />
                                                                :
                                                                <img
                                                                    src={
                                                                        res?.thumbnailImage
                                                                            ? res?.thumbnailImage
                                                                            : portfolioDefaultImage
                                                                    }
                                                                    alt=""
                                                                    className="selectPortfolioThumbnail mr-3"
                                                                />
                                                        }
                                                    </div>

                                                    <div>
                                                        <span className="titleText">{res.title}</span>
                                                        {res?.addedBy ? <div className="nameText">Added By: {res?.addedBy}</div> : ""}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="border-top" />
                                        </>
                                    )
                                })
                            }
                        </div> :
                        <div className="noRecord">
                            <div className="text-align-center">
                                <img className="folderIcon" src={noRecords} alt="" />
                                <span className="font14Black boldFont ">No Portfolio Found</span>

                            </div>
                        </div>
                }
                {
                    submitClicked && selectedPortfolioId.length === 0 &&
                    <div>
                        <span className="d-flex align-items-center justify-content-center my-2">
                            <i className="fa fa-exclamation-circle errorIcon" />
                            <p className="errorText mb-0 ml-0 ">
                                Select at least one portfolio
                            </p>
                        </span>
                    </div>
                }

            </div>
            <div className="timelinemodalButtonHolder">
                <div className="mt-3 border-top" />
                <div className="text-center mt-4 mb-0 float-bottom">
                    <Button
                        label="Cancel"
                        onClick={toggleModal}
                        type="btn"
                        className="btn-light mx-2 cancelButtonDelete"
                    />
                    <Button
                        label={`Select ${selectedPortfolio.length > 0 ? selectedPortfolio.length : ""}`}
                        type="submit"
                        className="btn-blue mx-2"
                        onClick={submitPortfolio}
                    />
                </div>
            </div>
        </Modal>
    )
}

export default SelectPortfolio;