/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import moment from "moment";
import { noTestimonial } from "../../../../assets/images";
import { GetCurrentAgencyMember } from "../../../../util/reduxData";
import Button from "../../../../components/button";
import SwitchButton from "../../../../components/toggleSwitch";
import Modal from "../../../../components/modal";
import RequestTestimonial from '../../../RequestTestimonial/RequestTestimonial'
import { updateTestimonialVisiblity } from "../../../../services/testimonial";
import ShowMore from "../../../../components/showMore";
import "./testimonials.scss"

const Testimonials = ({ edit, testimonialData, setTestimonialsCount, instrId, fromFreelance = false }) => {
    const [modal, setModal] = useState(false);
    const toggleModal = () => setModal(!modal);
    const [itemsToShow, setitemsToShow] = useState(3);
    const [testimonials, setTestimonials] = useState([]);
    const currentInstrId = instrId || GetCurrentAgencyMember()

    useEffect(() => {
        if (edit) {
            setTestimonials(testimonialData[currentInstrId])
        } else {
            handlePublicTestimonial(testimonialData[currentInstrId]);
        }
    }, [testimonialData])

    const showMore = () => {

        itemsToShow === 3
            ? setitemsToShow(testimonialData[currentInstrId]?.length)
            : setitemsToShow(3);

    };

    const handlePublicTestimonial = (testimonials) => {
        const publicTestimonial = []
        testimonials?.forEach((data) => {
            if (data.isResponsePublic) {
                publicTestimonial.push(data)
            }
        })
        setTestimonials(publicTestimonial)
        setTestimonialsCount(publicTestimonial)
    }

    const handleSwitchChange = (e, dataId) => {
        const objIndex = testimonials.findIndex((obj => obj.requestID === dataId));
        testimonials[objIndex].isResponsePublic = e.target.checked
        const payload = [{
            id: dataId,
            isResponsePublic: e.target.checked
        }]
        updateTestimonialVisiblity(payload)
            .then(() => {
                const newArray = [...testimonials]
                setTestimonials(newArray)
            })
    }

    return (
        <>
            <Modal
                isOpen={modal}
                size="extralargeTestimonial"
                hideCloseButton={true}
                toggleModal={toggleModal}
                disableBackdropToggle={true}
            >
                <RequestTestimonial clickMe={setModal} />
            </Modal>
            {testimonials?.length > 0 ?
                <>
                    <div className={edit ? "disclamerTextHolder d-none" : "disclamerTextHolder mb-3"}>
                        <span className="disclaimer">Disclaimer:</span><span className="disclaimerText"> These testimonials are gathered from clients outside of TTA Connect.</span>
                    </div>
                    <div className={fromFreelance ? "testimonialsWrapperFreelance" : "testimonialsWrapper"}>
                        {edit &&
                            <div className="d-flex justify-content-end pt-2">
                                <span className="font14Blue " onClick={toggleModal}>Request a Testimonial</span>
                            </div>
                        }

                        {
                            testimonials?.slice(0, itemsToShow).map((data, index) => {
                                return (
                                    <div key={index}>
                                        <div className="testimonialHeader">
                                            <div style={{ width: "65%" }}>
                                                <span className="font18Black boldFont">{data.projectName}</span>
                                            </div>
                                            <div className="d-flex pt-2">
                                                {edit &&
                                                    <div className="d-none">
                                                        <span className="visibilityStatus">{data.isResponsePublic ? "Public" : "Private"}</span>
                                                        <span>
                                                            <SwitchButton
                                                                name={`ProfileStatus${data.requestID}`}
                                                                removeClass={true}
                                                                onChange={(e) => handleSwitchChange(e, data.requestID)}
                                                                checked={data.isResponsePublic}
                                                                isDisabled={(itemsToShow === 3 && index === 2 && testimonials.length > 3) ? true : false}
                                                            ></SwitchButton>
                                                        </span>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        <span className="font14Black boldFont">{data.clientFirstName}{" "}{edit ? data.clientLastName : data.clientLastName[0]}</span>
                                        <div className="mobileTestFlex">
                                            <span className="clientTitle">{data.jobTitle}</span>
                                            <span className="date">{moment(data.submittedDate).format('MMMM D, Y')}</span>
                                        </div>
                                        <div>
                                            <ShowMore
                                                text={data?.testimonialResponse}
                                                textClass="description"
                                                anchorClass="moreText"
                                                lines={2}
                                                id={`testimonialResponse`+ index}
                                            />
                                            {(index !== (testimonials?.slice(0, itemsToShow).length - 1)) &&
                                                <div className="testimonialSeperator"></div>
                                            }
                                        </div>
                                    </div>
                                )
                            })
                        }
                        {testimonials?.length > 3 && (
                            <>
                                <div className="mb-3" />
                                <div className="font14Blue text-align-center" onClick={showMore}>
                                    {itemsToShow !== 3 ? "Less" : "More"}
                                </div>
                            </>
                        )}
                    </div>
                </> :
                <div className="noTestimonialWrapper">
                    <img src={noTestimonial} alt="" />
                    <span className="noTestText">No Testimonials To Display</span>
                    <div className={edit ? "noTestimonialWrapper" : "d-none"}>
                        <span className="noTestHelpText">Add a testimonial to your TTA Connect profile to showcase the great work you have done for clients.</span>
                        <Button
                            label={"Request"}
                            type="button"
                            onClick={setModal}
                            className="btn-blue mx-3 mt-3"
                        />
                    </div>
                </div>

            }
        </>
    )
};

export default Testimonials;