export const CURRENT_AGENCY_MEMBER_DETAIL = "CURRENT_AGENCY_MEMBER_DETAIL"

export const initialState = ""

const currentAgencyMemberDetailReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch(type) {
      case CURRENT_AGENCY_MEMBER_DETAIL:
        return payload
      default:
        return state;
    }
}

export const setCurrentAgencyMemberDetail = (payload) => {
    return dispatch => {
        dispatch({
            type: CURRENT_AGENCY_MEMBER_DETAIL,
            payload
        })
    }
}

export default currentAgencyMemberDetailReducer;