import Axios from "../util/axiosInstance";

export const GetAllRoles = (instrId) => {
  if (instrId) {
    return Axios.get("/api/Role/GetAllRoles?instrId=" + instrId)
  }
  return Axios.get("/api/Role/GetAllRoles")
};

export const PostRoles = (payload) => {
  return Axios.post("/api/Role/Post", payload)
};


export const GetMyRoles = (instrId) => {
  return Axios.get("/api/Role/GetMyRoles?instrId="+instrId);
};


export const DeleteRoles = (payload) => {
  return Axios.delete("/api/Role/Delete", payload);
};

