import React from "react";
import PropTypes from "prop-types";
import "./Label.css";

const Label = (props) => {
  return (
    <div className={props.containerClass} style={props.containerStyle}>
      <label htmlFor={props.id} style={props.labelStyle} className={props.className}>
        {props.label}
      </label>
    </div>
  );
};

Label.propTypes = {
  label: PropTypes.any.isRequired,
  id: PropTypes.string,
};

export default Label;
