/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useEffect, useState } from "react";
import { useLocation, useHistory, Link } from "react-router-dom";
import GetMenuLink from "../../constants/ClientMenuLinks";
import { HelpCenterURL } from "../../constants/legacySiteURL";
import { GetUserLoginData } from "../../util/reduxData";
import { bulbIcon } from "../../assets/images";
import { getToken } from "../../util/localStorage";
import RedirectConfirmModal from "../redirectConfirmModal";
import "./ClientMobileNavbar.css";

const ClientMobileNavbar = ({ closeNav }) => {
  const location = useLocation();
  const history = useHistory();
  const getLoginData = GetUserLoginData()
  const [openRedirectModal, setOpenRedirectModal] = useState(false)
  const [redirectUrl, setRedirectUrl] = useState("")
  const ref = useRef(null);

  const handleClick = (link) => {
    if (link?.subMenu) {
      history.push(link.path);
      closeNav();
      window.scrollTo(0, 0)
    } else if (link.legacy) {
      setOpenRedirectModal(true)
      setRedirectUrl(link.path)
    } else {
      history.push(link.path);
      closeNav();
      window.scrollTo(0, 0)
    }
  };
  function handleClickEvent(event) {
    if (event.target.id !== "drawer") {
      closeNav()
    }
  }
  useEffect(() => {
    document.addEventListener("click", handleClickEvent, true);
    return () => {
      document.removeEventListener("click", handleClickEvent, true);
    };
  }, [ref]);

  const auth = getToken();

  return (
    <React.Fragment>
      <div className="sidebarWrapper">
        <RedirectConfirmModal
          isOpen={openRedirectModal}
          toggleModal={() =>
            setOpenRedirectModal(!openRedirectModal)
          }
          redirectUrl={redirectUrl}
        />
        <div className="container-fluid px-0 m-0 newMobileNavSection">
          <div>
            {auth &&
              GetMenuLink().map((link) => {
                return (
                  <React.Fragment key={link.name}>
                    {link.legacy ?
                      <div key={link.name}>
                        <div
                          className={
                            location.pathname === link.path
                              ? "mobileNavLinksSection mobileNavLinksSectionActive "
                              : "mobileNavLinksSection "
                          }
                          key={link.name}
                        >
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            className={
                              location.pathname === link.path
                                ? "mobileNavLinksActive"
                                : "mobileNavLinks"}
                            href={link.path}
                          >
                            {" "}
                            {link.name}
                          </a>
                        </div>
                        <div className="w-100 border-top" />
                      </div> : <div key={link.name}>
                        <div
                          onClick={() => handleClick(link)}
                          className={
                            location.pathname === link.path
                              ? "mobileNavLinksSection mobileNavLinksSectionActive "
                              : "mobileNavLinksSection "
                          }
                          key={link.name}
                        >
                          <Link to={link.path} className={location.pathname === link.path ? "mobileNavLinksActive" : "mobileNavLinks"}>
                            {" "}
                            {

                              link.name === "My Tasks" ?
                              !getLoginData?.agencyInstrId ? "My Tasks" : "Tasks"
                                :
                                (!getLoginData.agencyInstrId && link.name === "Profiles") ?
                                  "My Profile" :
                                  link.name
                              }
                          </Link>
                        </div>
                        <div className="w-100 border-top" />
                      </div>}
                  </React.Fragment>
                );
              })}
            {!auth && (
              <div className={history.location.pathname === '/submittestimonial' ? 'd-none' : "mobileNavLinksSection"}>
                <Link className="mobileNavLinks" onClick={() => window.open(HelpCenterURL, '_blank')}> Help Center</Link>
              </div>
            )}
          </div>
          <div className="bottonInfo">
            <div className="w-100 border-top" />
            <div className="bottomContent">
              <img src={bulbIcon} alt="" className="bulb" />
              <span className="helpText">
                Looking for help in finding the right talent?<span className="font14Blue" > Get in touch</span> with us and we can help you out.
              </span>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default ClientMobileNavbar;
