import React from "react";
import classNames from "classnames";
import "./index.scss"

const RadioButton = ({
    checked,
    containerStyle,
    id,
    disabled,
    onChange,
    label,
    className,
    name,
    tabIndex,
    value,
    labelClassName,
    ref,
    title
}) => {

    return (
        <div
            className={`radio-item-custom ${className}`}
            style={containerStyle}
            ref={ref}
            tabIndex={tabIndex}
            title={title}
        >
            <input
                type="radio"
                id={id}
                name={name}
                onChange={onChange}
                disabled={disabled}
                checked={checked}
                value={value}
            />
            <label htmlFor={name} className={classNames("radioformLabel", labelClassName)}>{label}</label>
        </div>
    )
}

export default RadioButton;