export const SET_FILES_PROGRESS = "SET_FILES_PROGRESS";
export const DELETE_FILES_PROGRESS = "DELETE_FILES_PROGRESS";

export const initialState = {
  fileList:[]
}

const filesProgressReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_FILES_PROGRESS: {
      return {...payload};
    }
    case DELETE_FILES_PROGRESS:
      return {};
    default:
      return state;
  }
};

export const addFilesToBeUploaded = (payload) => {
  return (dispatch) => {
    dispatch({
      type: SET_FILES_PROGRESS,
      payload,
    });
  };
};

export const removeFilesProgress = () => {
  return (dispatch) => {
    dispatch({
      type: DELETE_FILES_PROGRESS,
      payload:{},
    });
  };
};


export default filesProgressReducer;
