export const ROLE_SAVE = "ROLE_SAVE";
export const DELETE_ROLE = "DELETE_ROLE";

export const roleSave = (payload) => ({
  type: ROLE_SAVE,
  payload,
});

export const roleDelete = (payload) => ({
  type: DELETE_ROLE,
  payload,
});

export const initialState = {
  Role: [],
};

const RoleReducer = (state = initialState, action) => {
  const { type, payload } = action;
  const { Role } = state;

  const NewArr = action.payload;

  switch (type) {
    case ROLE_SAVE:
      return { Role: payload };

    case DELETE_ROLE:
      return {
        ...state,
        Role: Role && Role.filter((value) => !NewArr.includes(value)),
      };

    default:
      return state;
  }
};

export default RoleReducer;
