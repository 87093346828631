import React, { Fragment, useState } from "react";
import { GreenIcon, testimonialConfirm } from "../../assets/images";
import { emailValidations } from "../../constants/Validations";
import { useForm } from "react-hook-form";
import { GetCurrentAgencyMember, GetUserLoginData } from "../../util/reduxData";
import { requestTestimonialApi } from "../../services/testimonial";
import Button from "../../components/button";
import TextInput from "../../components/textInput";
import FormTextarea from "../../components/formTextarea/FormTextarea";
import Label from "../../components/label/Label";
import Modal from "../../components/modal";
import "./RequestTestimonial.scss";

function RequestTestimonial({ clickMe, getTestimonial }) {
  const { register, handleSubmit, errors, getValues } = useForm({
    reValidateMode: "onBlur",
  });

  const [modal, setModal] = useState(false);
  const [FirstName, setFirstName] = useState();
  const [LastName, setLastName] = useState();
  const [showEmail, setShowEmail] = useState(false);
  const [error, seterror] = useState(false);
  const [errorMsg, seterrorMsg] = useState("");
  const [invalidEmail, setInvalidEmail] = useState(false)
  const toggleModal = () => setModal(!modal);
  let [getDescription, setDescription] = useState("");
  const currentInstrId = GetCurrentAgencyMember()
  const userInfo = GetUserLoginData();

  const HandleCustomCancel = () => {
    clickMe();
  };

  const [data, setData] = useState({})

  const onSubmit = async (data) => {
    seterror(false);
    const payload = {
      clientFirstName: data.clientFirstName,
      clientLastName: data.clientLastName,
      email: data.clientEmail,
      jobTitle: data.clientJobTitle,
      companyName: data.clientCompanyName,
      projectName: data.clientProjectName,
      requestMessage: getDescription,
      instrId: currentInstrId === "all" ? userInfo.instrId : currentInstrId
    };

    requestTestimonialApi(payload)
      .then(() => {
        setFirstName(data.clientFirstName);
        setLastName(data.clientLastName);
        toggleModal();
        if (getTestimonial) {
          getTestimonial();
        }
      })
      .catch((err) => {
        if (err?.response?.data.error === "Invalid Email") {
          setInvalidEmail(true)
        } else {
          seterror(true);
          seterrorMsg(err?.response?.data.error);
        }
        console.log("err", err);
      });
  };
  const handleChange = (e) => {
    setDescription(e.target.value);
  };
  return (
    <Fragment>
      <div className="requestTestimonailWrapper">
        <div className="requestTestimonailBox">
          <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <div className="requestTestimonailBoxWrapper">
              <Label
                label="Request a Testimonial"
                className="font18Black boldFont mb-0"
              ></Label>
              <div className="requestTestimonailBoxDescription">
                You can send a request to a previous client from outside TTA
                Connect, and have them write a Testimonial for display on your
                profile.
              </div>
              <div className="mt-2 font-weight-bold">Client Details</div>
              <div className="row">
                <div className="col-md-7 col-sm-7">
                  <div className="requestTestimonailFormNameWrapper">
                    <div className="requestTestimonailFormFirstName">
                      <TextInput
                        label="Client First Name*"
                        name="clientFirstName"
                        value={data?.clientFirstName}
                        onChange={e => {
                          setData({
                            ...data,
                            clientFirstName: e.target.value
                          })
                        }}
                        innerRef={register({
                          required: true,
                          maxLength: 50
                        })}
                        error={
                          errors.clientFirstName?.type === "required"
                            ? "The Client first name is Required and should only contain letters."
                            : errors.clientFirstName?.type === "maxLength"
                              ? "Client First name should not exceed 50 characters"
                              : null
                        }
                        containerClass="my-1"
                        type="clientFirstName"
                        placeholder="Client First Name"
                        id="clientFirstName"
                      />
                    </div>
                    <div className="requestTestimonailFormLastName">
                      <TextInput
                        label="Client Last Name*"
                        name="clientLastName"
                        value={data?.clientLastName}
                        onChange={e => {
                          setData({
                            ...data,
                            clientLastName: e.target.value
                          })
                        }}
                        innerRef={register({
                          required: true,
                          maxLength: 50
                        })}
                        error={
                          errors.clientLastName?.type === "required"
                            ? "The Client last name is Required and should only contain letters."
                            : errors.clientLastName?.type === "maxLength"
                              ? "Client Last name should not exceed 50 characters"
                              : null
                        }
                        containerClass="my-1"
                        type="clientLastName"
                        placeholder="Client Last Name"
                        id="clientLastName"
                      />
                    </div>
                  </div>
                  <TextInput
                    name="clientCompanyName"
                    innerRef={register({
                      required: true,
                      maxLength: 100
                    })}
                    value={data?.clientCompanyName}
                    onChange={e => {
                      setData({
                        ...data,
                        clientCompanyName: e.target.value
                      })
                    }}
                    error={
                      errors.clientCompanyName?.type === "required"
                        ? "The client’s company name is Required"
                        : errors.clientCompanyName?.type === "maxLength"
                          ? "Client Company name should not exceed 100 characters"
                          : null
                    }
                    containerClass="my-2"
                    type="clientCompanyName"
                    placeholder="Client Company Name"
                    label="Client Company Name*"
                    id="clientCompanyName"
                  />
                  <TextInput
                    name="clientEmail"
                    innerRef={register({
                      required: true,
                      pattern: emailValidations.pattern,
                    })}
                    value={data?.clientEmail}
                    onChange={e => {
                      setData({
                        ...data,
                        clientEmail: e.target.value
                      })
                      setShowEmail(true)
                      setInvalidEmail(false)
                    }}
                    error={
                      errors.clientEmail?.type === "required"
                        ? "The client’s email address is Required."
                        : errors.clientEmail?.type === "pattern"
                          ? "Invalid email format."
                          : invalidEmail ?
                            "Invalid email"
                            : null
                    }
                    containerClass="my-2"
                    type="clientEmail"
                    placeholder="Client Email"
                    label="Client Email*"
                    id="clientEmail"
                  />
                  {showEmail === true && (
                    <TextInput
                      name="clientConfirmEmail"
                      value={data?.clientConfirmEmail}
                      onChange={e => {
                        setData({
                          ...data,
                          clientConfirmEmail: e.target.value
                        })
                      }}
                      innerRef={register({
                        required: true,
                        validate: () =>
                          getValues("clientEmail").toLowerCase() ===
                          getValues("clientConfirmEmail").toLowerCase(),
                      })}
                      error={
                        errors.clientConfirmEmail &&
                        "Client Confirm Email should be same as Client Email."
                      }
                      containerClass="my-2"
                      type="clientConfirmEmail"
                      placeholder="Confirm Client Email*"
                      label="Confirm Client Email*"
                      id="clientConfirmEmail"
                    />
                  )}
                  <TextInput
                    name="clientProjectName"
                    value={data?.clientProjectName}
                    onChange={e => {
                      setData({
                        ...data,
                        clientProjectName: e.target.value
                      })
                    }}
                    innerRef={register({
                      required: true,
                    })}
                    error={
                      errors.clientProjectName?.type === "required"
                        ? "The client’s project name is Required."
                        : null
                    }
                    containerClass="my-2"
                    type="clientProjectName"
                    testimonialTooltip={true}
                    testimonialTooltipPhone={true}
                    placeholder="Project Name/Type"
                    label="Project Name/Type*"
                    id="clientProjectName"
                  />
                  <TextInput
                    name="clientJobTitle"
                    value={data?.clientJobTitle}
                    onChange={e => {
                      setData({
                        ...data,
                        clientJobTitle: e.target.value
                      })
                    }}
                    containerClass="my-2"
                    type="clientJobTitle"
                    innerRef={register({ required: true })}
                    error={
                      errors.clientJobTitle?.type === "required"
                        ? "Client Job Title is Required."
                        : null
                    }
                    placeholder="Job Title"
                    label="Job Title*"
                    id="clientJobTitle"
                  />
                  <FormTextarea
                    maxLength={3000}
                    rows={5}
                    name="description"
                    id="description"
                    label="Message to Contact"
                    textareaClass="resizeFalse"
                    placeholder="Hi, I am writing to you to request a testimonial of my work."
                    innerRef={register({
                      required: false,
                    })}
                    value={getDescription}
                    onChange={handleChange}
                  ></FormTextarea>
                  <div className="float-right submitTestimonailtextareaLine">
                    {getDescription?.length || 0}/3000 Characters
                  </div>
                </div>
                <div className="col-md-5 col-sm-5 px-0 requestTestimonailRightSideWrapper">
                  <div className="requestTestimonailRightSideBox">
                    <div className="requestTestimonailRightSideTitle">
                      <img
                        src={GreenIcon}
                        alt=""
                        className="mr-2 requestTestimonailRightSideImage"
                      />
                      <div className="requestTestimonailRightHeading">
                        Getting the most from testimonials
                      </div>
                    </div>
                    <ul className="requestTestimonailTextDecoration">
                      <li className="mt-2">
                        You can send a request to a previous client from outside TTA Connect and have them write a testimonial for display on your profile. Testimonials strengthen your profile and help our recruiting team better understand your experience.
                      </li>
                      <li className="mt-2">
                        It’s easy to do. You can send a testimonial request directly to non-TTA clients from TTA Connect.
                      </li>
                      <li className="mt-2">
                        Please let your contact know that they will receive your request for a testimonial.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {error === true && (
                <div className="errorTextTestimonial">
                  <i className="fa fa-exclamation-circle errorIcon mr-1" />
                  {errorMsg}
                </div>
              )}
            </div>
            <div
              className="requestTestimonailButtonWrapper border-top"
              valign="bottom"
            >
              <div className=" my-4 mb-0 ">
                <Button
                  label="Cancel"
                  type="btn"
                  onClick={HandleCustomCancel}
                  className="btn-light mx-2 mb-0 requestTestimonailcancelButtn"
                />
                <Button label="Request" type="submit" className="btn-blue mb-0" />
              </div>
            </div>
          </form>
        </div>
      </div>
      <Modal
        isOpen={modal}
        size="extralargeTestimonialConfirm"
        hideCloseButton={false}
        toggleModal={toggleModal}
        disableBackdropToggle={true}
      >
        <div className="requestTestimonailConfirmModalWrapper">
          <Label
            label="Testimonial Request Sent"
            className="font18Black boldFont mb-0"
          ></Label>
          <div className="requestTestimonailConfirmModalTextWrapper">
            <img src={testimonialConfirm} alt="" />
            <Label
              label={
                <span>
                  Your request has been emailed to <br />{FirstName} {LastName}.
                </span>
              }
              className="font18Black boldFont mb-0"
            ></Label>
            <div className="requestTestimonailConfirmModalText">
              You will be able to view the testimonial in your profile once the
              client submits it.
            </div>
          </div>
        </div>
        <div
          style={{
            position: "absolute",
            bottom: 0,
            paddingBottom: "20px",
            width: "100%",
          }}
        >
          <div className="w-100 mt-2 border-top" />
          <div className="text-center mt-4 mb-0 float-bottom">
            <Button
              label="Close"
              onClick={() => {
                setModal(false);
                clickMe();
              }}
              type="submit"
              className="btn-blue "
            />
          </div>
        </div>
      </Modal>
    </Fragment>
  );
}

export default RequestTestimonial;
