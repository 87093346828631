import React, { useState } from "react";
import classnames from "classnames";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import "./index.css";

const SwitchButton = ({
  label,
  checked,
  onChange,
  className,
  innerRef,
  name,
  error,
  link = "",
  linktext,
  linkTarget,
  removeClass,
  isDisabled,
  onClick,
  terms,
  alert
}) => {
  let [check, setCheck] = useState(checked);

  let handleChange = (e) => {
    setCheck(!check);
    onChange(e);
  };

  return (
    <React.Fragment>
      <div className={removeClass ? "" : "d-flex my-3 align-items-center"}>
        {alert && (
          <div>
            <span className={checked ? "switchLabelEnabled" : "switchLabelDisabled"} onClick={onClick} >{alert}</span>&nbsp;
          </div>
        )}
        <label className={classnames("switch mb-0", className)}>
          <input
            checked={checked}
            name={name}
            ref={innerRef}
            onChange={handleChange}
            type="checkbox"
            disabled={isDisabled}
          />

          <span className="slider round" />
        </label>
        {label && (
          <div className="ml-2">
            <span className="switchLabel" onClick={onClick} >{label}{terms && <span className="font14Blue">{"terms and conditions"}</span>}</span>&nbsp;
            <Link to={link} target={linkTarget} className="font14Blue">
              {linktext}
            </Link>
          </div>
        )}
      </div>
      {error && (
        <div className="d-flex align-items-center my-2">
          <i className="fa fa-exclamation-circle errorIcon" />
          <p className="errorText mb-0 ml-0 ">{error}</p>
        </div>
      )}
    </React.Fragment>
  );
};

SwitchButton.propTypes = {
  onClick: PropTypes.func,

};


export default SwitchButton;
