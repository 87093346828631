import React from "react";
import Button from "../../../../components/button";
import Label from "../../../../components/label";
import Modal from "../../../../components/modal";
import "./index.scss";

const ConfirmFileDownLoad = ({ showConfirmation, toggleModal }) => {

  return (
    <>
      <Modal
        isOpen={showConfirmation}
        size="extrasmall"
        title={<Label label={"File Download"} className="font18Black boldFont" />}
        titleClass="ml-1"
        hideCloseButton={true}
        toggleModal={() => toggleModal()}
        disableBackdropToggle={true}
        containerClass="documentDeleteModal"
      >
        <div className="modalTextHolder">
          <Label
            label={
              <span className="mx-md-0 mx-4">
                Your file is downloaded
                <br />
              </span>
            }
            className="subTextWithoutColorWithoutBold mt-4"
          />
        </div>
        <div
          className="border-top text-center"
          style={{ left: "0", width: "100%" }}
        >
          <div className="buttonHolder">

            <Button
              label="OK"
              type="submit"
              onClick={() => toggleModal()}
              className="btn-blue main_btn  "
            />
          </div>
        </div>
      </Modal>
    </>
  )
}

export default ConfirmFileDownLoad;