/* eslint-disable import/no-anonymous-default-export */
import React, { Component } from 'react';
import Navbar from '../components/navbar/Navbar';
import { BrowserRouter as Router } from "react-router-dom";
import Footer from '../components/footer/Footer';
import NoInternet from '../screens/NoInternet/noInternet';

export default function (ComposedComponent) {
    class NetworkDetector extends Component {
        state = {
            isDisconnected: false
        }

        componentDidMount() {
            window.addEventListener('online', ()=>{
                this.setState({ isDisconnected: false })
            });
            window.addEventListener('offline', ()=>{
                this.setState({ isDisconnected: true })
            });
        }

        componentWillUnmount() {
            window.removeEventListener('online', ()=>{
                this.setState({ isDisconnected: false })
            });
            window.removeEventListener('offline', ()=>{
                this.setState({ isDisconnected: true })
            });
        }


        render() {
            const { isDisconnected } = this.state;
            return (

                <div>
                    <NoInternet hide={true}/>
                    {isDisconnected ? (<>
                            <Router>
                                <div>
                                    <Navbar />
                                    <NoInternet />
                                    {
                                        window.location.pathname.toLowerCase() !== "/signup" &&
                                        window.location.pathname.toLowerCase() !== "/" && <Footer />
                                    }
                                </div>
                            </Router>
                    </>) :
                    <>
                        <ComposedComponent {...this.props} />
                    </>
                    }
                </div>
            );
        }
    }

    return NetworkDetector;
}