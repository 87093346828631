import React, { Fragment, useState } from "react";
import {  useHistory } from "react-router-dom";
import Label from "../../../../../components/label/Label";
import { Edit } from "../../../../../assets/images/index";
import "./index.scss";

function Education({ edit, Education, fromTalent, client }) {
  let [expanded, setExpanded] = useState(false);
  let [itemsToShow, setitemsToShow] = useState(3);

  let history = useHistory();

  const EducationEdit = () => {
    history.push("/profile/education/edit");
  };

  const showMore = () => {
      itemsToShow === 3 ? setitemsToShow(Education?.length) : setitemsToShow(3);
      itemsToShow === 3 ? setExpanded(true) : setExpanded(false);
  };

  return (
    <Fragment>
      <div className={(fromTalent && !client)?"freelancerModalComponentWrap ml-0":"freelancerModalComponentWrap"}>
        <div className="d-flex justify-content-between">
          <Label
            className="font18Black boldFont mt-3 mb-0"
            label="Education"
          ></Label>
          {edit && <img src={Edit} className='pointer' onClick={EducationEdit} alt=""/>}
        </div>
        
        {Education?.length !== 0 &&
          Education?.slice(0, itemsToShow)?.map((item, key) => {
            return (
              <div key={key} className="profileNameEducationWrapper">
                <div>
                  <div className="profileNameDisplay">{item.school}</div>
                  <div className="profileDegreeName ">
                    {item.degree} {"in"} {item.subject}
                    <div className="profileOtherInfo mb-2">
                    {item.city} | {item.startMonth?.substr(0, 3)}{" "}
                      {item.startYear}
                      {" - "}
                      {item.endMonth?.substr(0, 3)}{" "}
                      {item.endYear ? item.endYear : "Present"}
                    </div>
                    <div className="profileOtherInfo">{item.description}</div>
                  </div>
                </div>
              </div>
            );
          })}

        {Education?.length > 3 && (
          <div
            style={{
              justifyContent: "center",
              textAlign: "center",
            }}
            className="profileEducationMore"
          >
            <span className="profileEducationMore" onClick={showMore}>
              {expanded === true ? "Less" : "More"}
            </span>
          </div>
        )}
      </div>
    </Fragment>
  );
}

export default Education;
