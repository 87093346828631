import React, { useEffect, useState } from "react";
import Button from "../button";
import Modal from "../modal";
import TextInput from "../textInput";
import TextInputDropdown from "../textInputDropdownNew";
import RadioButton from "../radioButton";
import "./addCustomSkill.scss";

const AddCustomSkills = ({
    isOpen,
    toggleModal,
    onSubmit,
    newSkill,
    categoryList,
    vendorList
}) => {

    const [customSkillData, setCustomSkillData] = useState({})
    const [submitClicked, setSubmitClicked] = useState(false)
    const [isDisabled, setIsDisabled] = useState(false)

    useEffect(()=>{
        setCustomSkillData({})
        setSubmitClicked(false)
        setIsDisabled(false)
    },[isOpen])

    const onCustomSkillSubmit = () => {
        setSubmitClicked(true)
        if(!customSkillData.discipline || !customSkillData.group) {
            return
        }
        if(customSkillData.group==="Other" && (!customSkillData.otherVendorName || customSkillData.otherVendorName.length < 3)) {
            return
        }
        setIsDisabled(true)
        onSubmit(customSkillData)
    }

    return (
        <>
            <Modal
                isOpen={isOpen}
                size="extrasmallgoback"
                hideCloseButton={true}
                toggleModal={toggleModal}
                disableBackdropToggle={true}
                containerClass="ModalCss"
                id="modalAddCustom"
            >
                <form key="instructor">
                    <div className="modalAddCustomWrapper">
                        <div className="font18Black boldFont">Add skill</div>
                        <div className="font16Black boldFont modalAddCustomAddSkillTypeText">
                            Select Skill Type for {newSkill}
                        </div>
                        <RadioButton
                            id="ritema"
                            label={"Technical Skill"}
                            name="ritema"
                            className=""
                            onChange={() => {
                                setCustomSkillData({
                                    discipline: "Technical",
                                });
                            }}
                            checked={customSkillData?.discipline === "Technical"}
                        />
                        <RadioButton
                            id="ritemb"
                            label={"Soft Skills"}
                            name="ritemb"
                            className=""
                            onChange={() => {
                                setCustomSkillData({
                                    discipline: "Soft Skills",
                                });
                            }}
                            checked={customSkillData?.discipline === "Soft Skills"}
                        />
                        {(submitClicked && !customSkillData.discipline) && (
                            <div className="errorTextRoleLast">Please Select Skill Type</div>
                        )}
                        {customSkillData.discipline === "Soft Skills" && (
                            <>
                                <TextInputDropdown
                                    label="Select Category*"
                                    optionnew={categoryList}
                                    id="category"
                                    onChange={(val) => {
                                        setCustomSkillData({
                                            ...customSkillData,
                                            group: val.value,
                                        });
                                    }}
                                    name="category"
                                    maxMenuHeight={140}
                                />
                            {(submitClicked && !customSkillData.group) && (
                                <div className="errorTextRoleLast">Please Select Category</div>
                            )}
                            </>
                        )}
                        
                        {customSkillData.discipline === "Technical" && (
                            <>
                                <TextInputDropdown
                                    firstval="Vendor"
                                    label="Select Vendor"
                                    optionnew={vendorList}
                                    id="Vendor"
                                    onChange={(val) => {
                                        setCustomSkillData({
                                            ...customSkillData,
                                            group: val.value,
                                        });
                                        setSubmitClicked(false)
                                    }}
                                    name="Vendor"
                                    maxMenuHeight={140}
                                />
                                 
                                {(submitClicked && !customSkillData.group) && (
                                    <div className="errorTextRoleLast">Please Select Vendor</div>
                                )}
                                {customSkillData.group === "Other" && (
                                    <>
                                        <TextInput
                                            name="otherVendorName"
                                            type="text"
                                            placeholder="Add Vendor"
                                            label={"Add Vendor Name"}
                                            containerClass="mt-2"
                                            onChange={(e) => {
                                                setCustomSkillData({
                                                    ...customSkillData,
                                                    otherVendorName: e.target.value,
                                                });
                                                setSubmitClicked(false)
                                            }}

                                        />
                                        {(customSkillData?.group === "Other" && !customSkillData?.otherVendorName && submitClicked) && (
                                            <div className="errorTextRoleLast">
                                                Please Add Vendor Name
                                            </div>
                                        )}
                                        {
                                            (customSkillData?.group === "Other" && submitClicked && customSkillData?.otherVendorName?.length < 3) && 
                                            <div className="errorTextRoleLast">
                                                Vendor Name length should be greater than 3 characters.
                                            </div>
                                        }
                                    </>
                                )}
                            </>
                        )}
                        <div className="modalAddCustomNote">
                            *Note: Any new skill you add will be visible on your profile, but
                            cannot be found during search until it's approved by TTA and added
                            to our skills database *
                        </div>
                    </div>

                    <div
                        style={{
                            marginTop: "10px",
                        }}
                    >
                        <div className="w-100 border-top" />
                        <div className="text-center mt-4  mb-4 float-bottom">
                            <Button
                                label="Cancel"
                                onClick={toggleModal}
                                className="btn-light mx-2 cancelButtonDelete"
                            />
                            <Button
                                label="Add"
                                onClick={onCustomSkillSubmit}
                                className="btn-blue"
                                disabled={isDisabled}
                            />
                        </div>
                    </div>
                </form>
            </Modal>
        </>
    )
}

export default AddCustomSkills;