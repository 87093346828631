import {
    EditorState,
    ContentState,
    convertFromRaw
} from 'draft-js';
import { stateFromHTML } from 'draft-js-import-html'

export const generateHTMLFromValue = (value = "") => {
    let blocksFromHTML = "";
    try {
        blocksFromHTML = convertFromRaw(JSON.parse(value))
    }
    catch (e) {
        blocksFromHTML = ContentState.createFromText(value)
    }
    return EditorState.createWithContent(blocksFromHTML)
}


export const generateJsonFromHTML = (value = "") => {
    if (!value) {
        return EditorState.createEmpty()
    }
    let blocksFromHTML = "";
    try {
        blocksFromHTML = convertFromRaw(JSON.parse(value))
    }
    catch (e) {
        if (value && !value.includes("<")) {
            value = value.replace(/\n/g, "<br>")
        }

        blocksFromHTML = stateFromHTML(value);
    }
    return EditorState.createWithContent(blocksFromHTML)
}