import React, { useEffect, useState } from "react";
import { Edit } from "../../../../../assets/images/index";
import Label from "../../../../../components/label/Label";
import MainLanguages from "../../../../../components/languages/MainLanguages";
import "./index.scss";

const LanguagesSection = ({ edit, languages, fromTalent, client }) => {
  let [editClicked, setEditClicked] = useState(false);
  let [userLanguages, setUserLanguages] = useState(undefined);

  useEffect(() => {
    setUserLanguages(languages);
  }, [languages]);

  const getProficiencyName = (value) => {
    if (value === 1) {
      return "Basic";
    } else if (value === 2) {
      return "Conversational";
    } else if (value === 3) {
      return "Fluent";
    }
  };

  return (
    <div className={(fromTalent && !client)?"freelancerModalComponentWrap ml-0":"freelancerModalComponentWrap"}>
      <div className="d-flex justify-content-between">
        <Label
          className="font18Black boldFont mt-3 mb-0"
          label="Languages"
        ></Label>
        {edit && (
          <img
            src={Edit}
            style={{ cursor: "pointer" }}
            onClick={() => setEditClicked(!editClicked)} alt=""/>
        )}
      </div>
      {editClicked && (
        <MainLanguages
          languageListUserView={languages?.length > 0 && languages}
          usersProfileView={true}
          userLanguageList={(data) => setUserLanguages(data)}
        />
      )}
      {(!editClicked || !edit) && (
        <div className="d-flex flex-wrap">
          {userLanguages?.map((lang, idx) => {
            return (
              <div key={idx} className="languageUserViewList mt-2 d-flex flex-column justify-content-center">
                <p className="userProfileLangName mb-0">{lang?.language}</p>
                <p className="userProfileLangProficiency mb-0">
                  {getProficiencyName(lang?.proficiency)}
                </p>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default LanguagesSection;
