const ACCESS_TOKEN = "token";
const ACCESS_REFRESH_TOKEN = "refreshtoken";
const CONTACT_ID = "contactId";
const COMPANY_ID = "companyId";
const INSTRUCTOR_ID = "instructorId";
const REMEMBER_ME = "rememberMe";
const NEW_USER = "newUser";
const SIGNUP_ID = "signUpId";
const WEB_ROLE = 'webRole'
const CHAT_EMAIL = "chatEmail"

export const saveLoginInfo = (token, refreshToken) => {
  try {
    localStorage.setItem(ACCESS_TOKEN, token);
    localStorage.setItem(ACCESS_REFRESH_TOKEN, refreshToken);
  } catch (err) {
    console.log(err);
  }
};

export const saveLoginInfoAll = (contactId, companyId, instructorId, webRole) => {

  try {
    localStorage.setItem(CONTACT_ID, contactId);
    localStorage.setItem(COMPANY_ID, companyId);
    localStorage.setItem(INSTRUCTOR_ID, instructorId)
    localStorage.setItem(WEB_ROLE, webRole)
  } catch (err) {
    console.log(err);
  }
};

export const saveRememberMe = (email) => {
  try {
    localStorage.setItem(REMEMBER_ME, email);
  } catch (err) {
    console.log(err);
  }
};

export const removeRememberMe = (email) => {
  try {
    localStorage.removeItem(REMEMBER_ME, email);
  } catch (err) {
    console.log(err);
  }
};

export const saveNewUser = (email) => {
  try {
    localStorage.setItem(NEW_USER, email);
  } catch (err) {
    console.log(err);
  }
};

export const saveChatUser = email => {
  try {
    localStorage.setItem(CHAT_EMAIL, email);
  } catch (err) {
    console.log(err);
  }
}

export const deleteLoginInfo = () => {
  try {
    localStorage.removeItem(ACCESS_TOKEN);
    localStorage.removeItem(CONTACT_ID);
    localStorage.removeItem(COMPANY_ID);
    localStorage.removeItem(INSTRUCTOR_ID);
    localStorage.removeItem(WEB_ROLE);
    localStorage.removeItem(ACCESS_REFRESH_TOKEN);
    localStorage.removeItem(NEW_USER);
    localStorage.removeItem(CHAT_EMAIL);
  } catch (err) {
    console.log(err);
  }
};

export const deleteEverything = () => {
  try {
    caches.keys().then((names) => {
        names.forEach((name) => {
            caches.delete(name);
        });
    });

    localStorage.clear();
  } catch (err) {
    console.log(err);
  }
};

export const deleteLoginInfoRefresh = () => {
  try {
    localStorage.removeItem(ACCESS_REFRESH_TOKEN);
  } catch (err) {
    console.log(err);
  }
};

export const getToken = () => {
  try {
    return localStorage.getItem(ACCESS_TOKEN);
  } catch (err) {
    console.log(err);
  }
};

export const getCompanyId = () => {
  try {
    return localStorage.getItem(COMPANY_ID);
  } catch (err) {
    console.log(err);
  }
};

export const getInstrId = () => {
  try {
    return localStorage.getItem(INSTRUCTOR_ID);
  } catch (err) {
    console.log(err);
  }
}

export const getWebRole = () => {
  try {
    return localStorage.getItem(WEB_ROLE);
  } catch (err) {
    console.log(err);
  }
};


export const getRefreshToken = () => {
  try {
    return localStorage.getItem(ACCESS_REFRESH_TOKEN);
  } catch (err) {
    console.log(err);
  }
};

export const getRememberMe = () => {
  try {
    return localStorage.getItem(REMEMBER_ME);
  } catch (err) {
    console.log(err);
  }
};

export const getNewUser = () => {
  try {
    return localStorage.getItem(NEW_USER);
  } catch (err) {
    console.log(err);
  }
};

export const getChatUser = () => {
  try {
    return localStorage.getItem(CHAT_EMAIL);
  } catch (err) {
    console.log(err);
  }
};

export const saveSignUpID = (id) => {
  try {
    localStorage.setItem(SIGNUP_ID, id);
  } catch (err) {
    console.log(err);
  }
};
export const getSignUpID = () => {
  try {
    return localStorage.getItem(SIGNUP_ID);
  } catch (err) {
    console.log(err);
  }
};