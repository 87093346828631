import Axios from "../util/axiosInstance";

export const getClientInformation = () => {
    return Axios.get("/api/Contact/GetMyinformation")
}
export const updateClientInformation = (payload) => {
    return Axios.post("/api/Contact/UpdateMyInformation", payload)
}
export const getClientDepartments = () => {
    return Axios.get("/api/Master/Departments")
}